import { useState, useEffect } from 'react'
import {Flex, Box, Heading, Text, Stack, useToast, 
	Button, VStack, Progress
} from '@chakra-ui/react'
import { ScaleFade } from '@chakra-ui/transition'
import { useForm } from 'react-hook-form'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { Navigate, useSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { clearErrMsg, logout, partUserRegister } from '../../redux'
import { toastConfig } from '../../helpers/appConfig'
import { isPublicDomain } from '../../helpers/utils'
import StepTwo from '../../components/auth/StepTwo'
import StepThree from '../../components/auth/StepThree'
import StepFour from '../../components/auth/StepFour'
import StepFive from '../../components/auth/StepFive'
import StepNew from '../../components/auth/StepNew'
import CheckoutForm from '../../components/CheckoutForm'
import { MdArrowBackIosNew } from 'react-icons/md'
import '../../components/auth/auth.css'


const SignupStepTwo = ({ auth, handleLogout, handleUserRegister }) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [inProp, setInProp] = useState(false)
	const [bigWindow, setBigWindow] = useState(true)
	const [formProgress, setFormProgress] = useState({
		step: 2, business_name: '', industry_id: null, app_name: '',
		social_media: '', social_media_handler_name: '', price_id: '',
		mobile_template: '', price_amount: null, price_recurring: 'monthly',
		mgp_commission: 12, auth: false
	})

	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
	const { formState: { errors }, } = useForm()
	const toast = useToast()
	const navigate = useNavigate()
	let location = useLocation()

	function prevStep() {
		const step = formProgress.step
		setFormProgress({
			...formProgress,
			step: step - 1
		})
	}

	function nextStep() {
		const step = formProgress.step
		setFormProgress({
			...formProgress,
			step: step + 1
		})
		setInProp(!inProp)
	}

	function getUserDetails() {
		if (searchParams.has('g')) {
			handleUserRegister({
			unique_id: searchParams.get('g')
			}, (message, status) => {
				toast(toastConfig(message, status))
				searchParams.delete('g')
				setSearchParams(searchParams)
			})
		}
		if (searchParams.has('ui')) {
			setFormProgress({
				...formProgress,
				auth: true
			})
			handleUserRegister({
				ui: searchParams.get('ui'),
			}, (message, status) => {
				toast(toastConfig(message, status))
				searchParams.delete('ui')
				setSearchParams(searchParams)
			})
		}
    }

	function handleChange(newData) {
		setFormProgress(newData)
	}

	function handleExitUser() {
		const backUp = JSON.parse(localStorage.getItem('backUp'))
		localStorage.setItem('user', JSON.stringify(backUp))
		localStorage.removeItem('backUp')
		window.location.href = '/admin/trainers'
	}

	useEffect(() => {
		window.innerWidth < 600 ? setBigWindow(false) : setBigWindow(true)

        getUserDetails()
	}, [])

	if (isPublicDomain()) {
		return <Navigate to="/" />
	}

	if (!auth.user) {
		return <Navigate to='/login' state={{ from: location }}/>
	}

	if (auth.user?.app_name || auth.user?.business_name) {
		return <Navigate to='/trainer/webapp-builder?m=hello' />
	}

	console.log(auth.user)
	console.log(formProgress)


	return (
		<form onSubmit={(e) => {e.preventDefault()}}>
			{formProgress.step === 2 && (
				<ScaleFade in={true} reverse>
					<StepTwo 
						value={formProgress} 
						onChange={handleChange}
						logout={() => auth.user?.role === 'admin'
								? handleExitUser()
								: handleLogout((message, status) => {
									toast(toastConfig(message, status))
									navigate('/')
						  })
						} 
						nextStep={nextStep} 
					/>
				</ScaleFade>
			)}
			{formProgress.step === 3 && (
				<ScaleFade in={true} reverse>
					<StepNew 
						value={formProgress} 
						onChange={handleChange} 
						prevStep={prevStep} 
						nextStep={nextStep} 
					/>
				</ScaleFade>
			)}
			{formProgress.step === 4 && (
				<ScaleFade in={true} reverse>
					<StepThree 
						value={formProgress} 
						onChange={handleChange} 
						prevStep={prevStep} 
						nextStep={nextStep} 
					/>
				</ScaleFade>
			)}
			{formProgress.step === 5 && (
				<ScaleFade in={true} reverse>
					<StepFour 
						value={formProgress} 
						onChange={handleChange} 
						prevStep={prevStep} 
						nextStep={nextStep} 
					/>
				</ScaleFade>
			)}
			{formProgress.step === 6 && (
				<ScaleFade in={true} reverse>
					<StepFive 
						value={formProgress} 
						onChange={handleChange} 
						prevStep={prevStep} 
						nextStep={nextStep} 
						bigWindow={bigWindow}
					/>
				</ScaleFade>
			)}
			{formProgress.step === 7 && (
				<Box display={'flex'} h="100vh" bg="secondary.100" color="secondary.700">

					<Box
						flex='1'
						display={['block', 'block', 'flex', 'flex']}
						justifyContent="center"
						alignItems="center"
						sx={{
							zIndex: '2',
							background: 'transparent linear-gradient(137deg, #5E59FF 0%, #59FEF4 100%) 0% 0% no-repeat padding-box;',
							boxShadow: '4px 3px 14px #0000007D;'
						}}
						overflowX="hidden"
						p={['0', '0', '0', '60px']}
					>
						<Box
							w="100%"
							bg="white"
							boxShadow='lg'
							overflow="auto"
							maxH="100%"
							h={['100%','100%','auto','auto']}
							rounded={['0', '0', 'xl', 'xl']}
							p='40px'
							pb='100px'
							pr={['40px', '20px']}
						>
							<VStack w='full' mb='12'>
								<Box display={['none', 'none', 'flex', 'flex']} width='100%'>
									<Button
										mr='auto'
										variant='link'
										onClick={prevStep}
									>
										<Text 
											as='span'
											mr='auto'
										>
											<MdArrowBackIosNew size='0.9em' />
										</Text>
										<Text
											mr='auto'
										>
											Back
										</Text>
										
									</Button>
									<Heading mr='auto' as="h2" textAlign='left' fontSize={['3xl', '3xl', '2xl', '2xl']} fontWeight='black' color="black">
										Build once, sell forever
									</Heading>
								</Box>
								
								<Box display={['block', 'block', 'none', 'none']} width='100%'>
									<Button
										variant='link'
										onClick={prevStep}
										mb={3}
									>
										<Text 
											as='span'
											mr='auto'
										>
											<MdArrowBackIosNew size='0.9em' />
										</Text>
										<Text
											mr='auto'
										>
											Back
										</Text>
										
									</Button>
									<Heading as="h2" textAlign='left' fontSize={['3xl', '3xl', '2xl', '2xl']} fontWeight='black' color="black">
										Build once, sell forever
									</Heading>
								</Box>

								<Box display={['none','none','block','block']}>
									<Text   
										sx={{
											marginTop: '0px !important',
											marginBottom: '10px !important',
											marginLeft: '40px !important'
										}} 
										color='primary.500' 
										fontSize={['lg','lg','md','md']}  
										fontWeight='bold'  
										textAlign='center'
									>
										*60 day money-back guarantee*
									</Text>
								</Box>

								<Box display={['block','block','none','none']}>
									<Text   
										color='primary.500' 
										fontSize={['lg','lg','md','md']}  
										fontWeight='bold'  
										textAlign='center'
									>
										*60 day money-back guarantee*
									</Text>
								</Box>
								
							</VStack>

							<Stack pt={['2px','2px','10px','10px',]}>
								<Box display='flex' justifyContent='center'>
									<Elements stripe={stripePromise}>
										<CheckoutForm name={auth.user.name} email={auth.user.email} data={formProgress} />
									</Elements>
								</Box>
							</Stack>
						</Box>
					</Box>
		
					<Box
						display={['none', 'none', 'none', 'block']}
						flex='0.9'
						bg="white"
						pos="relative"
						p={8}
					>
						<VStack>
							<Flex justifyContent='center' w='75%'>
								<Heading textAlign='center' my='50px' as='h2' fontSize='4xl' fontWeight='black' color='black'>
										<Box as='span' fontSize='5xl'>Upgrading...</Box>
										<Progress 
												value="70" 
												colorScheme='primary'  
												isIndeterminate 
												mt="10"
												borderRadius='5' 
										/>
								</Heading>                  
              </Flex>
							<Box display={['none', 'none', 'block', 'block']} overflow='hidden'>
									<div className='video-sizer'>
										<video 
											src='/images/spinningphone_01_1.mp4'
											muted
											autoPlay={bigWindow}
											loop
										/>
									</div>
							</Box >
                		</VStack>
					</Box>
				</Box>
			)}
		</form>
	)
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		handleUserRegister: (data, callback) =>
			dispatch(partUserRegister(data, callback)),
		handleLogout: (callback) => dispatch(logout(callback)),
		handleClearErrMsg: () => dispatch(clearErrMsg())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupStepTwo)
