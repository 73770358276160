import React from 'react'
import { Box, AspectRatio } from '@chakra-ui/react'
import { getYoutubeVideoID } from '../../../helpers/utils'

function Video({ component }) {
	const { src, style } = component
	let videoElement = null

	if (src.type === 'external') {
		videoElement = (
			<Box display="flex" justifyContent={style.justifyContent}>
				<AspectRatio
					maxW={`${style.width}%`}
					ratio={16 / 9}
					height="auto"
					flex="1 1"
				>
					<iframe
						title="YouTube video player"
						src={`https://www.youtube.com/embed/${getYoutubeVideoID(
							src?.link
						)}?controls=`}
						// frameBorder="0"
						// allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					/>
				</AspectRatio>
			</Box>
		)
	} else {
		videoElement = (
			<Box display="flex" justifyContent={style.justifyContent}>
				<AspectRatio
					maxW={`${style.width}%`}
					ratio={16 / 9}
					height="auto"
					flex="1 1"
				>
					<iframe
						title="naruto"
						frameBorder="0"
						src={
							src?.path || 'https://www.youtube.com/embed/HdWQxGM6Rco?controls='
						}
						allow="encrypted-media; picture-in-picture"
						allowFullScreen
					/>
				</AspectRatio>
			</Box>
		)
	}
	return videoElement
}

export default Video
