import { CHANGE_PAGE_TITLE, SET_LANDING_TRAINER_INFO } from './actionTypes'

export const changePageTitle = (title) => {
	return {
		type: CHANGE_PAGE_TITLE,
		title,
	}
}

export const setLandingTrainerInfo = (payload) => {
	localStorage.setItem('trainer', JSON.stringify(payload))

	return {
		type: SET_LANDING_TRAINER_INFO,
		payload,
	}
}
