import axios from 'axios'

export const getRequest = (endpoint = '', config = {}) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}`, {
				...config,
				headers: requestHeaders(),
			})
			.then((res) => {
				resolve(res.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export const deleteRequest = (endpoint = '', config = {}) => {
	return new Promise((resolve, reject) => {
		axios
			.delete(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}`, {
				...config,
				headers: requestHeaders(),
			})
			.then((res) => {
				resolve(res.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export const postRequest = (endpoint = '', data = {}, config = {}) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}`, data, {
				headers: requestHeaders(),
				config,
			})
			.then((res) => {
				resolve(res.data)
			})
			.catch((error) => {
				console.log('Err: ', error)
				reject(error)
			})
	})
}

const requestHeaders = (rest = {}) => {
	const token = JSON.parse(localStorage.getItem('user'))?.token || ''

	return {
		...rest,
		Authorization: `Bearer ${token}`,
	}
}
