import { useState, useEffect } from 'react'
import { Box, Text, Flex } from '@chakra-ui/react'

const AppAccounts = ()  => {
    const [bigWindow, setBigWindow] = useState(true)
	
	useEffect(() => {
		if (window.innerWidth < 600) {
            setBigWindow(false)
        }
	}, [])

    return (
        <>
            {bigWindow && (
                <Box display='flex' justifyContent='center' w='100%' h='90vh' pb={3}>
                    <Box rounded='lg' mx='30px' w='100%' bg='white'>
                        <Flex justifyContent='center'>
                            <Text mt='25%' fontSize='5xl'>COMING SOON</Text>
                        </Flex>
                    </Box>
                </Box>
            )}    
        </>
    )
}

export default AppAccounts