import { Box } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import LandingPageHeader from './LandingPageHeader'

const SiteLayout = () => {
	return (
		<Box minH="100vh">
			{/* <LandingPageHeader /> */}
			<Outlet />
		</Box>
	)
}

export default SiteLayout
