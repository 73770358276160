import React from 'react'
import {
	Button,
	Center,
	Box,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverArrow,
	PopoverCloseButton,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Icon,
	Text,
	Flex,
	IconButton,
} from '@chakra-ui/react'
import LayoutButton from './LayoutButton'
import { useDisclosure } from '@chakra-ui/react'
import { FiPlus, FiPlusCircle } from 'react-icons/fi'

function SectionAddButton(props) {
	const { onOpen, onClose, isOpen } = useDisclosure()

	return (
		<>
			<Popover 
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				flip={true}
			>
				<PopoverTrigger>
					{props?.isBlocked ? (
						<Flex
							as="button"
							w="100%"
							p="20px"
							justifyContent="center"
							alignItems="center"
							borderWidth="1.5px"
							borderStyle="dashed"
							borderColor="secondary.200"
							_hover={{
								borderColor: 'primary.500',
								color: 'primary.500',
							}}
						>
							<Box>
								<Icon fontSize="36px" opacity="0.6" as={FiPlusCircle} />
								<Text mt="3" textTransform="uppercase" letterSpacing={`.1rem`}>
									Add new block
								</Text>
							</Box>
						</Flex>
					) : (
						<IconButton
							bg="white"
							_hover={{ bg: 'white' }}
							_active={{ bg: 'white' }}
							_focus={{ bg: 'white', outline: 'none', boxShadow: 'none' }}
							borderWidth="1px"
							borderStyle="dashed"
							borderColor="secondary.500"
							rounded={`full`}
							icon={<FiPlus />}
						/>
					)}
				</PopoverTrigger>
				<PopoverContent boxShadow="none" _focus={{ boxShadow: 'none' }}>
					<PopoverArrow />
					<PopoverCloseButton />
					<PopoverHeader fontSize="xl">Select a Layout</PopoverHeader>
					<PopoverBody>
						<LayoutButton index={props.index} onClose={onClose} />
					</PopoverBody>
				</PopoverContent>
			</Popover>
		</>
	)
}

export default SectionAddButton
