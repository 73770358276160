import { Box, Button, ButtonGroup, Text
} from '@chakra-ui/react'
import { MdArrowForwardIos, MdArrowBackIosNew} from 'react-icons/md'


const NavButtons = ({forward, backward, width, data}) => {
    function go(e) {
        e.preventDefault()
        forward()
    }

    function back(e) {
        e.preventDefault()
        backward()
    }

    return (
        <Box width={['100%', '100%', width, width]} >
            <ButtonGroup pt="3" spacing="1rem" display={'flex'}>
                <Button
                    variant='secondary'
                    display='flex'
                    w="60%"
                    onClick={back}
                >
                    <Text
                        as='span'
                        mr='auto'
                    >
                        <MdArrowBackIosNew size='0.9em' />
                    </Text>
                    <Text
                        mr='auto'
                    >
                        Back
                    </Text>
                </Button>
                <Button
                    variant="primary"
                    display='flex'
                    justifyContent='center'
                    w="100%"
                    disabled={!data.industry_id}
                    onClick={go}
                >
                    <Text
                        ml='auto'
                    >
                        Continue
                    </Text>
                    <Text 
                        as='span'
                        ml='auto'
                    >
                        <MdArrowForwardIos size='0.9em' />
                    </Text>
                </Button>
            </ButtonGroup>
        </Box>
    )
}

export default NavButtons