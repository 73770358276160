import { 
  Modal,
  ModalOverlay, 
  ModalContent, 
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Text,
  ModalBody
} from '@chakra-ui/react'

const SalesPageVideoModal = ({isOpen, onClose}) => {
  return (
    <Modal 
      size="4xl" 
      isOpen={isOpen} 
      onClose={onClose} 
      isCentered
      style={{backgroundColor: 'white !important'}}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader mb="2"></ModalHeader>
        <ModalBody>
          <div style={{ position: 'relative', paddingBottom: "56.25%", height: 0 }}>
            <iframe 
              src="https://www.loom.com/embed/277c19577fd84f63ba878c63e0e0d85b" 
              frameborder="0" 
              webkitallowfullscreen 
              mozallowfullscreen 
              allowfullscreen 
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: '100%',
                height: "100%"
              }}>
            </iframe>
          </div>
        </ModalBody>
        <ModalFooter justifyContent="center" flexDirection="column">
          <Text fontSize="xl" color="black" fontWeight="900">
            How to create pricing and coupons
          </Text>
          <Text fontSize="sm">
            Videos can be found in the "Resources" tab.
          </Text>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SalesPageVideoModal
