import React, { useContext, useRef } from 'react'
import {
	Box,
	Button,
	IconButton,
	Flex,
	AlertDialog,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	AlertDialogCloseButton,
	useDisclosure,
} from '@chakra-ui/react'
import { FiMove, FiEdit, FiTrash, FiPlusCircle } from 'react-icons/fi'
import { PageBuilderContext } from '../context/PageBuilderContext'
import SectionAddButton from './SectionAddButton'

function SectionConfigButton({ dragElement, sectionIndex }) {
	const [state, dispatch] = useContext(PageBuilderContext)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()

	const deleteSection = () => {
		onOpen()
	}

	const selectSection = () => {
		dispatch({
			type: 'SELECT_SECTION',
			payload: { sectionIndex: sectionIndex },
		})
	}

	return (
		<>
			<Box
				className="page-section-setting"
				p="7px 4px"
				bg="primary.500"
				rounded="0 5px 5px 0"
			>
				<Flex flexDir="column">
					<IconButton
						color="white"
						icon={<FiMove />}
						size="sm"
						{...dragElement}
						bg="transparent"
						_hover={{ background: 'transparent' }}
						_active={{
							outline: 'none',
							background: '#CBD5E0',
							background: 'transparent',
						}}
					/>

					<IconButton
						icon={<FiEdit />}
						size="sm"
						color="white"
						bg="transparent"
						_hover={{ background: 'transparent' }}
						_focus={{ outline: 'none' }}
						_active={{
							outline: 'none',
							background: '#CBD5E0',
							background: 'transparent',
						}}
						onClick={selectSection}
					/>

					<IconButton
						icon={<FiTrash />}
						size="sm"
						color="white"
						bg="transparent"
						_hover={{ background: 'transparent' }}
						_focus={{ outline: 'none' }}
						_active={{
							outline: 'none',
							background: '#CBD5E0',
							background: 'transparent',
						}}
						onClick={deleteSection}
					/>
				</Flex>
			</Box>

			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={onClose}
				isOpen={isOpen}
				isCentered
				size="md"
			>
				<AlertDialogOverlay />
				<AlertDialogContent>
					<AlertDialogHeader
						color="gray.600"
						fontSize="1.1rem"
						fontWeight="700"
						borderBottomWidth="1px"
					>
						Are you sure?
					</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogFooter>
						<Button
							size="xs"
							px="15px"
							rounded={2}
							ref={cancelRef}
							onClick={onClose}
						>
							No
						</Button>
						<Button
							size="xs"
							px="15px"
							rounded={2}
							colorScheme="red"
							ml={3}
							onClick={() =>
								dispatch({
									type: 'REMOVE_SECTION',
									payload: { index: sectionIndex },
								})
							}
						>
							Yes
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	)
}

export default SectionConfigButton
