import React, { useContext, useEffect, useRef } from 'react'
import { Box, Stack, Text, Flex, Select, 
    Tabs, TabList, TabPanels, Tab, TabPanel,
    FormControl, FormLabel,
    NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Input,
    InputGroup, InputRightAddon, Button, 
    Slider, SliderTrack, SliderFilledTrack, SliderThumb,    
    Modal, ModalOverlay, ModalContent,
    useDisclosure,
    Textarea
} from '@chakra-ui/react'
import debounce from 'lodash.debounce';
import { SketchPicker } from 'react-color';
import { PageBuilderContext } from '../../../context/PageBuilderContext';
import { paddingConfigHandler, textConfigHandler, marginConfigHandler } from '../../../helper/settingsHelper';
import { fonts } from '../../../helper/fonts'

function ParagraphSetting() {
    const [ state, dispatch ] = useContext(PageBuilderContext);
    const { isOpen, onOpen, onClose } = useDisclosure(); 
    const { selected } = state;
    let { style, data, link } = state.section[selected.sectionIndex].columns[selected.columnIndex].components[selected.compIndex];
    
    const paragraphDataInput = useRef();

    const marginHandler = (value, side) => {
        const margin = marginConfigHandler(style.margin, side, value);
        console.log(margin);
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                margin: margin, 
                type:"margin", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const paddingHandler = (value, side) => {
        const payload = paddingConfigHandler(style.padding, side, value);
        console.log(payload);
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                ...payload, 
                type:"padding", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const textHandler = (value, property) => {
        const textStyle = { ...style.text }
        textStyle[property] = value;
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                ...textStyle, 
                type:"text", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const handleFontFamily = (e) => { 
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                fontFamily: e.target.value,
                type:"text.fontFamily", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const dataHandler = debounce((value) => {
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                type:"data", 
                data: value,
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
        paragraphDataInput.current.focus();
        paragraphDataInput.current.setSelectionRange(value.length, value.length);
    }, 500)

    const linkHandler = debounce((value) => {
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                type:"link", 
                url: value,
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }, 400)

          

    useEffect(() => {
        return () => {
            dataHandler.cancel();
            linkHandler.cancel();
        }
    }, []);

    return (
        <>
        <Box>
            <Tabs size="sm" isFitted  variant="unstyled">
                <TabList borderWidth="1px">
                    <Tab
                        fontSize=".75rem" fontWeight="600"
                        _focus={{outline:"none"}} 
                        _selected={{ bg: "gray.200" }}
                    >
                        Design
                    </Tab>
                    <Tab 
                        fontSize=".75rem" fontWeight="600"
                        _focus={{outline:"none"}} 
                        _selected={{ bg: "gray.200" }}
                    >
                        General
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel p={0}>
                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Text:</Text>
                        <Box borderBottomWidth="1px" p="10px">
                            <FormControl key={`${data}-${Math.floor(Math.random() * 1000)}`}>
                                <Textarea 
                                    ref={paragraphDataInput}
                                    size="xs" fontWeight="500" resize="vertical" 
                                    defaultValue={data}
                                    onChange={(e) => dataHandler(e.target.value)}
                                />
                            </FormControl>
                        </Box>

                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Margin:</Text>
                        <Box display="flex" flexWrap="wrap" borderBottomWidth="1px" p="10px">
                            <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pr="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Top</FormLabel>
                                    <NumberInput 
                                        size="xs" 
                                        onChange={(value) => marginHandler(value, "top")} 
                                        value={style.margin.top}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pl="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Right</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        onChange={(value) => marginHandler(value, "right")} 
                                        value={style.margin.right}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" pr="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Left</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        onChange={(value) => marginHandler(value, "left")} 
                                        value={style.margin.left}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput> 
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" pl="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Bottom</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        onChange={(value) => marginHandler(value, "bottom")} 
                                        value={style.margin.bottom}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                        </Box>

                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Padding:</Text>
                        <Box display="flex" flexWrap="wrap" borderBottomWidth="1px" p="10px">
                            <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pr="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Top</FormLabel>
                                    <NumberInput 
                                        size="xs" 
                                        onChange={(value) => paddingHandler(value, "top")} 
                                        value={style.padding.top}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pl="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Right</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        onChange={(value) => paddingHandler(value, "right")} 
                                        value={style.padding.right}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" pr="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Left</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        onChange={(value) => paddingHandler(value, "left")} 
                                        value={style.padding.left}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput> 
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" pl="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Bottom</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        onChange={(value) => paddingHandler(value, "bottom")} 
                                        value={style.padding.bottom}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                        </Box>

                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Text:</Text>
                        <Box borderBottomWidth="1px" p="10px">
                            <Flex mb={2}> 
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Font Family</Text>
                                <Select flex="1 1" size="xs" value={style.fontFamily} onChange={handleFontFamily}>
                                    {
                                        fonts.map(font => (
                                            <option key={font.value} value={font.value}>{font.label}</option>
                                        ))
                                    }
                                </Select>
                                
                            </Flex>
                            <Flex mb={2}> 
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Size (px)</Text>
                                <NumberInput 
                                        flex="1 1"
                                        size="xs" 
                                        max={100} min={5} 
                                        value={style.text.size}
                                        onChange={(value) => textHandler(value, "size")}
                                    >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput> 
                            </Flex>
                            <Flex mb={2}>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Color</Text>
                                <InputGroup flex="1 1" size="xs">
                                    <Input value={style.text.color} onChange={(e) => textHandler(e.target.value, "color")} />
                                    <InputRightAddon id="primaryColor" cursor="pointer" bg={style.text.color} onClick={onOpen} />
                                </InputGroup>
                            </Flex>
                            <Flex mb={2}>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Weight</Text>
                                <Select flex="1 1" size="xs" value={style.text.weight} onChange={(e) => textHandler(e.target.value, "weight")}>
                                    {
                                        Array.from(["200","300","400","500","600","700","800"]).map(item => (
                                            <option key={item} value={item}>{item}</option>
                                        ))
                                    }
                                </Select>
                            </Flex>
                            <Flex mb={2}>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Align</Text>
                                <Select flex="1 1" size="xs" value={style.text.align} onChange={(e) => textHandler(e.target.value, "align")}>
                                    <option value="left">Left</option>
                                    <option value="center">Center</option>
                                    <option value="right">Right</option>
                                </Select>
                            </Flex>
                            <Flex mb={2}>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Transform</Text>
                                <Select flex="1 1" size="xs" value={style.text.transform} onChange={(e) => textHandler(e.target.value, "transform")}>
                                    <option value="none">None</option>
                                    <option value="uppercase">Uppercase</option>
                                    <option value="lowercase">Lowercase</option>
                                    <option value="capitalize">Capitalize</option>
                                </Select>
                            </Flex>
                            <Flex mb={2} mt="10px">
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Line Height</Text>
                                <Box flex="1 1">
                                    <Slider 
                                        size="sm"
                                        aria-label="slider-line-height" 
                                        value={style.text?.lineHeight} 
                                        min={0} max={5}
                                        step={.1}
                                        onChange={(value) => textHandler(value, "lineHeight")}
                                    >
                                        <SliderTrack>
                                            <SliderFilledTrack />
                                        </SliderTrack>
                                        <SliderThumb position="relative">
                                            <Text position="absolute" bottom="10px" fontSize=".65rem">
                                                {style.text?.lineHeight !== 0 ? style.text?.lineHeight : 'Normal'}
                                            </Text>
                                        </SliderThumb>
                                    </Slider>
                                </Box>
                            </Flex>
                            <Flex mb={2} mt="10px">
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Letter Spacing</Text>
                                <Box flex="1 1">
                                    <Slider 
                                        size="sm"
                                        aria-label="slider-letter-spacing" 
                                        value={style.text?.letterSpacing} 
                                        min={0} max={10}
                                        step={1}
                                        onChange={(value) => textHandler(value, "letterSpacing")}
                                    >
                                        <SliderTrack>
                                            <SliderFilledTrack />
                                        </SliderTrack>
                                        <SliderThumb position="relative">
                                            <Text position="absolute" bottom="10px" fontSize=".65rem">
                                                {style.text?.letterSpacing !== 0 ? style.text?.letterSpacing : 'Normal'}
                                            </Text>
                                        </SliderThumb>
                                    </Slider>
                                </Box>
                            </Flex>
                        </Box>
                    </TabPanel>
                    <TabPanel p={0}>
                        <Text fontSize=".75rem" fontWeight="600" p="10px" borderBottomWidth="1px">Link:</Text>
                        <Box borderBottomWidth="1px" p="10px">
                            <FormControl key={link.url}>
                                <Textarea
                                    placeholder="Link url here"
                                    size="sm"
                                    fontSize=".75rem"
                                    resize="vertical"
                                    defaultValue={link.url}
                                    onChange={(e) => linkHandler(e.target.value)}
                                    autoFocus={true}
                                />
                            </FormControl>
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
        
        <Modal 
            isCentered
            onClose={onClose}
            isOpen={isOpen}
            motionPreset="slideInBottom"
            size="xs">

            <ModalOverlay />
            <ModalContent>
                <SketchPicker width="100%" color={ style.text.color } onChange={(color) => textHandler(color.hex, "color")}  /> 
            </ModalContent>
        </Modal>
        </>
    )

}

export default ParagraphSetting;
