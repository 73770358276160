export const LOGIN_REQUESTING = 'LOGIN_REQUESTING'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const USER_REGISTER_REQUESTING = 'USER_REGISTER_REQUESTING'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_ERROR = 'USER_REGISTER_ERROR'

export const LOGOUT_REQUESTING = 'LOGOUT_REQUESTING'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'

export const CLEAR_ERR_MSG = 'CLEAR_ERR_MSG'
export const PROFILE_UPDATE = 'PROFILE_UPDATE'
