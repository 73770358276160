import { useState } from 'react'
import {
	Stack,
	Grid,
	Button,
	Box,
	useToast,
	FormLabel,
	FormControl,
	FormErrorMessage,
	Input,
	InputGroup,
	InputRightElement,
} from '@chakra-ui/react'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { postRequest } from '../../services/base.service'
import { useForm } from 'react-hook-form'
import { toastConfig } from '../../helpers/appConfig'
import { useSelector } from 'react-redux'

const AccountPasswordChange = () => {
	const [showCurrentPass, setCurrentShowPass] = useState(false)
	const [showNewPass, setShowNewPass] = useState(false)
	const [showNewPassConf, setShowNewPassConf] = useState(false)
	const { user } = useSelector((state) => state.auth)

	const toast = useToast()
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
	})

	const [newPassword, newPasswordConfirmation] = watch([
		'new_password',
		'new_password_confirmation',
	])

	const submitHandler = (data) => {
		data._method = 'PUT'
		data.admin = user?.admin

		postRequest('change-password', data)
			.then((res) => toast(toastConfig(res.message, res.status)))
			.catch((err) => {
				toast(
					toastConfig(err.response.data?.errors?.current_password[0], 'error')
				)
			})
	}

	return (
		<Grid
			templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)']}
			gap={6}
		>
			<form onSubmit={handleSubmit(submitHandler)}>
				<Stack spacing="20px">
					{!user?.admin && (
						<FormControl
							id="current_password"
							isInvalid={errors?.current_password}
						>
							<FormLabel>Current Password</FormLabel>
							<InputGroup size="md">
								<Input
									{...register('current_password', {
										required: 'Current password is required',
									})}
									borderColor="secondary.200"
									_hover={{
										borderColor: 'secondary.300',
									}}
									_focus={{
										borderColor: 'secondary.400',
									}}
									_placeholder={{
										color: 'secondary.400',
									}}
									type={showCurrentPass ? 'text' : 'password'}
									rounded="full"
									placeholder="Current password"
								/>
								<InputRightElement>
									<Button
										size="sm"
										bg="transparent"
										_focus={{ boxShadow: 'none' }}
										onClick={() => setCurrentShowPass(!showCurrentPass)}
									>
										{showCurrentPass ? <FiEyeOff /> : <FiEye />}
									</Button>
								</InputRightElement>
							</InputGroup>
							<FormErrorMessage>
								{' '}
								{errors?.current_password?.message}{' '}
							</FormErrorMessage>
						</FormControl>
					)}

					<FormControl id="newPassword" isInvalid={errors?.new_password}>
						<FormLabel>New Password</FormLabel>
						<InputGroup size="md">
							<Input
								{...register('new_password', {
									required: 'New password is required',
								})}
								borderColor="secondary.200"
								_hover={{
									borderColor: 'secondary.300',
								}}
								_focus={{
									borderColor: 'secondary.400',
								}}
								_placeholder={{
									color: 'secondary.400',
								}}
								type={showNewPass ? 'text' : 'password'}
								rounded="full"
								placeholder="New password"
							/>
							<InputRightElement>
								<Button
									size="sm"
									bg="transparent"
									_focus={{ boxShadow: 'none' }}
									onClick={() => setShowNewPass(!showNewPass)}
								>
									{showNewPass ? <FiEyeOff /> : <FiEye />}
								</Button>
							</InputRightElement>
						</InputGroup>
						<FormErrorMessage>
							{' '}
							{errors?.new_password?.message}{' '}
						</FormErrorMessage>
					</FormControl>

					<FormControl
						id="confirmPassword"
						isInvalid={errors?.new_password_confirmation}
					>
						<FormLabel>Confirm Password</FormLabel>
						<InputGroup size="md">
							<Input
								{...register('new_password_confirmation', {
									required: 'Confirm password is required',
									validate: (value) =>
										value === newPassword || "Password doesn't match",
								})}
								borderColor="secondary.200"
								_hover={{
									borderColor: 'secondary.300',
								}}
								_focus={{
									borderColor: 'secondary.400',
								}}
								_placeholder={{
									color: 'secondary.400',
								}}
								type={showNewPassConf ? 'text' : 'password'}
								rounded="full"
								placeholder="Confirm password"
							/>
							<InputRightElement>
								<Button
									size="sm"
									bg="transparent"
									_focus={{ boxShadow: 'none' }}
									onClick={() => setShowNewPassConf(!showNewPassConf)}
								>
									{showNewPassConf ? <FiEyeOff /> : <FiEye />}
								</Button>
							</InputRightElement>
						</InputGroup>
						<FormErrorMessage>
							{' '}
							{errors?.new_password_confirmation?.message}{' '}
						</FormErrorMessage>
					</FormControl>

					<Box pt="2">
						<Button type="submit" variant="primary">
							Update Password
						</Button>
					</Box>
				</Stack>
			</form>
		</Grid>
	)
}

export default AccountPasswordChange
