import { useEffect, useState, useContext } from "react";
import {
  Flex,
  Box,
  Container,
  Grid,
  Spinner,
  Center,
  chakra,
  Modal,
  ModalOverlay,
  useToast,
  ModalContent,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  ModalHeader,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { toastConfig } from "../helpers/appConfig";
import { getRequest, postRequest } from "../services/base.service";
import { profileUpdate } from "../redux";
import { PageBuilderContext } from "../PageBuilder/context/PageBuilderContext";
import PricingCard from "./PricingCard";
import AddPricing from "./AddPricing";
import CouponCard from "./CouponCard";
import AddCoupon from "./AddCoupon";
import AddFreePlan from "./AddFreePlan";
import PricingModalSetting from "./PricingModalSetting";

const PricingModal = ({
  isOpen,
  onClose,
  bigWindow,
  browseImage,
  icon,
  onFormDataChange
}) => {
  const user = useSelector((state) => state.auth.user);
  const [initLoading, setInitLoading] = useState(false);
  const [stripeStatus, setStripeStatus] = useState(false);
  const [pricingList, setPricingList] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [pricesLoaded, setPricesLoaded] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isShowingLogo, setIsShowingLogo] = useState(user?.is_show_logo);
  const [state] = useContext(PageBuilderContext);

  const toast = useToast();
  const dispatch = useDispatch();

  const getStripeStatus = () => {
    setInitLoading(true);

    getRequest("stripe-status").then((res) => {
      const { status } = res.result;
      setStripeStatus(status);
      if (status) {
        getPricingList(
          `?sort=id,desc&?select=id,pricing_type,price,trial_day,annual_saving`
        );
        getCouponList(
          `?sort=id,desc&?select=id,stripe_id,amount_off,percent_off,duration,duration_in_months,max_redemptions,name,times_redeemed`
        );
      } else {
        getPricingList(
          `?sort=id,desc&?select=id,pricing_type,price,trial_day,annual_saving`
        );
        setInitLoading(false);
      }
    });
  };

  const getPricingList = (queryParams = "") => {
    getRequest(`pricing${queryParams}`)
      .then((res) => {
        setPricingList(res.result);
        console.log(res.result)
      })
      .finally(() => setInitLoading(false))
      .catch((error) => {
        toast(toastConfig(error.message, "error"));
      });
  };

	const getCouponList = (queryParams = '') => {
		getRequest(`coupon${queryParams}`)
			.then((res) => {
        setCouponList(res.result)
      })
      .finally(() => setInitLoading(false))
  };

  const connectStripeHandler = () => {
    postRequest("stripe-connect", { redirect_url: window?.location?.href })
      .then((res) => {
        window.location.assign(res.result?.url);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getStripeStatus();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        pe="0"
        ps="0"
        bg="gray.200"
        rounded="3xl"
        py="0"
        minWidth={["90%", "90%", "80%", "80%", "70%", "65%"]}
      >
        <ModalHeader color="gray.900" fontWeight="extrabold" py="2" ml={bigWindow?  24: 8} pl="0">Forms</ModalHeader>
        <Box flex="1" p={0} mt={6}>
          <Tabs variant="enclosed">
            <TabList ml={[0, 0, 16, 20]}>
              <Tab
                _active={{
                  bg: "white",
                  outline: "none !important",
                }}
                _focus={{
                  outline: "none !important",
                }}
                _selected={{
                  bg: "white",
                  outline: "none !important",
                }}
                fontWeight="black"
              >
                Priced sign-up forms
              </Tab>
              <Tab
                _active={{
                  bg: "white",
                  outline: "none",
                }}
                _focus={{
                  outline: "none !important",
                }}
                _selected={{
                  bg: "white",
                  outline: "none",
                }}
                fontWeight="black"
              >
                Coupons
              </Tab>
              <Tab
                _active={{
                  bg: "white",
                  outline: "none",
                }}
                _focus={{
                  outline: "none !important",
                }}
                _selected={{
                  bg: "white",
                  outline: "none",
                }}
                fontWeight="black"
              >
                Free sign-up forms
              </Tab>
              <Tab
                _active={{
                  bg: "white",
                  outline: "none",
                }}
                _focus={{
                  outline: "none !important",
                }}
                _selected={{
                  bg: "white",
                  outline: "none",
                }}
                fontWeight="black"
              >
                Settings
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={0}>
                <Container maxW="100%" pe="0" ps="0" roundedBottom="3xl">
                  <Box
                    bg="white"
                    p={["20px", "20px", "25px", "30px"]}
                    boxShadow="md"
                    roundedBottom="3xl"
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems={"center"}
                      mb="6"
                    >
                      {!stripeStatus && (
                        <Flex justifyContent="center">
                          <chakra.span
                            ml="20px"
                            color="gray.600"
                            _hover={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={connectStripeHandler}
                            textDecoration="underline"
                            fontSize="sm"
                          >
                            Connect your Stripe account
                          </chakra.span>
                        </Flex>
                      )}
                      <Box flex="2">
                        {bigWindow && initLoading && (
                          <Center h="200px">
                            <Spinner
                              thickness="6px"
                              speed="0.65s"
                              emptyColor="gray.200"
                              color="blue.500"
                              size="xl"
                            />
                          </Center>
                        )}
                        {bigWindow && !initLoading && (
                          <Grid
                            templateColumns={[
                              "repeat(1, 1fr)",
                              "repeat(2, 1fr)",
                              "repeat(3, 1fr)",
                            ]}
                            gap={6}
                            colStart="3"
                          >
                            {pricingList
                              .filter((p) => p.type !== "free")
                              .map((subscriber, index) => (
                                <PricingCard
                                  updateList={getPricingList}
                                  key={index}
                                  data={subscriber}
                                  status={stripeStatus}
                                />
                              ))}
                          </Grid>
                        )}
                      </Box>
                      <Flex alignSelf="flex-start" basis="1" ml={5}>
                        <AddPricing updateList={getPricingList} />
                      </Flex>
                    </Flex>
                    {!bigWindow && initLoading && (
                      <Center h="200px">
                        <Spinner
                          thickness="6px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="blue.500"
                          size="xl"
                        />
                      </Center>
                    )}
                    {!bigWindow && !initLoading && (
                      <Grid
                        templateColumns={[
                          "repeat(1, 1fr)",
                          "repeat(2, 1fr)",
                          "repeat(3, 1fr)",
                        ]}
                        gap={6}
                        colStart="3"
                      >
                        {pricingList
                          .filter((p) => p.type !== "free")
                          .map((subscriber, index) => (
                            <PricingCard
                              updateList={getPricingList}
                              key={index}
                              data={subscriber}
                              status={stripeStatus}
                            />
                          ))}
                      </Grid>
                    )}
                  </Box>
                </Container>
              </TabPanel>
              <TabPanel p={0}>
                <Container
                  maxW="100%"
                  sx={{
                    paddingInlineStart: "0rem !important",
                    paddingInlineEnd: "0rem !important",
                  }}
                >
                  <Box
                    bg="white"
                    p={["20px", "20px", "25px", "30px"]}
                    roundedBottom="3xl"
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems={"center"}
                      mb="6"
                    >
                      {!stripeStatus && (
                        <Flex justifyContent="center">
                          <chakra.span
                            ml="20px"
                            color="gray.600"
                            _hover={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={connectStripeHandler}
                            textDecoration="underline"
                            fontSize="sm"
                          >
                            Connect your Stripe account
                          </chakra.span>
                        </Flex>
                      )}
                      <Box flex="2">
                        {bigWindow && initLoading && (
                          <Center h="200px">
                            <Spinner
                              thickness="6px"
                              speed="0.65s"
                              emptyColor="gray.200"
                              color="blue.500"
                              size="xl"
                            />
                          </Center>
                        )}
                        {bigWindow && !initLoading && (
                          <Grid
                            templateColumns={[
                              "repeat(1, 1fr)",
                              "repeat(2, 1fr)",
                              "repeat(3, 1fr)",
                            ]}
                            gap={6}
                            colStart="3"
                          >
                            {couponList.map((coupon, index) => (
                              <CouponCard
                                updateList={getCouponList}
                                key={index}
                                data={coupon}
                              />
                            ))}
                          </Grid>
                        )}
                      </Box>
                      <Flex alignSelf="flex-start" basis="1" ml={5}>
                        <AddCoupon updateList={getCouponList} />
                      </Flex>
                    </Flex>
                    {!bigWindow && initLoading && (
                      <Center h="200px">
                        <Spinner
                          thickness="6px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="blue.500"
                          size="xl"
                        />
                      </Center>
                    )}
                    {!bigWindow && !initLoading && (
                      <Grid
                        templateColumns={[
                          "repeat(1, 1fr)",
                          "repeat(2, 1fr)",
                          "repeat(3, 1fr)",
                        ]}
                        gap={6}
                        colStart="3"
                      >
                        {couponList.map((coupon, index) => (
                          <CouponCard
                            updateList={getCouponList}
                            key={index}
                            data={coupon}
                          />
                        ))}
                      </Grid>
                    )}
                  </Box>
                </Container>
              </TabPanel>
              <TabPanel p={0}>
                <Container maxW="100%" pe="0" ps="0" roundedBottom="3xl">
                  <Box
                    bg="white"
                    p={["20px", "20px", "25px", "30px"]}
                    boxShadow="md"
                    roundedBottom="3xl"
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems={"center"}
                      mb="6"
                    >
                      {bigWindow && initLoading && (
                        <Center h="200px">
                          <Spinner
                            thickness="6px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                          />
                        </Center>
                      )}
                      <Box flex="2">
                        {bigWindow && initLoading && (
                          <Center h="200px">
                            <Spinner
                              thickness="6px"
                              speed="0.65s"
                              emptyColor="gray.200"
                              color="blue.500"
                              size="xl"
                            />
                          </Center>
                        )}
                        {bigWindow && !initLoading && (
                          <Grid
                            templateColumns={[
                              "repeat(1, 1fr)",
                              "repeat(1, 1fr)",
                              "repeat(2, 1fr)",
                              "repeat(3, 1fr)",
                              "repeat(3, 1fr)",
                              "repeat(3, 1fr)",
                            ]}
                            gap={6}
                            colStart="3"
                          >
                            {pricingList
                              .filter((p) => p.type === "free")
                              .map((subscriber, index) => (
                                <PricingCard
                                  updateList={getPricingList}
                                  key={index}
                                  data={subscriber}
                                  status={stripeStatus}
                                  type={subscriber.type}
                                />
                              ))}
                          </Grid>
                        )}
                      </Box>
                      <Flex alignSelf="flex-start" basis="1" ml={5}>
                        <AddFreePlan updateList={getPricingList} />
                      </Flex>
                    </Flex>
                    {!bigWindow && initLoading && (
                      <Center h="200px">
                        <Spinner
                          thickness="6px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="blue.500"
                          size="xl"
                        />
                      </Center>
                    )}
                    {!bigWindow && !initLoading && (
                      <Grid
                        templateColumns={[
                          "repeat(1, 1fr)",
                          "repeat(1, 1fr)",
                          "repeat(2, 1fr)",
                          "repeat(3, 1fr)",
                          "repeat(3, 1fr)",
                          "repeat(3, 1fr)",
                        ]}
                        gap={6}
                        colStart="3"
                      >
                        {pricingList
                          .filter((p) => p.type === "free")
                          .map((subscriber, index) => (
                            <PricingCard
                              updateList={getPricingList}
                              key={index}
                              data={subscriber}
                              status={stripeStatus}
                            />
                          ))}
                      </Grid>
                    )}
                  </Box>
                </Container>
              </TabPanel>
              <TabPanel p={0}>
                <PricingModalSetting 
                  bigWindow={bigWindow}
                  icon={icon}
                  user={user}
                  browseImage={browseImage}
                  validationErrors={validationErrors}
                  onFormDataChange={onFormDataChange}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default PricingModal;
