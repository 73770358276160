import { useRef } from 'react'
import {
	Box,
	Text,
	Popover,
	PopoverTrigger,
	IconButton,
	PopoverContent,
	PopoverBody,
	List,
	Heading,
	ListItem,
	ListIcon,
	Button,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import {
	FiMoreVertical,
	FiEdit3,
	FiX,
	FiArrowRight,
	FiCheckCircle,
	FiTrash,
} from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { toastConfig } from '../helpers/appConfig'
import { isPublicDomain } from '../helpers/utils'
import { deleteRequest, getRequest } from '../services/base.service'
import AddCoupon from './AddCoupon'

const CouponCard = ({
	data,
	updateList,
	handleGetStart,
	tempPricingId,
	isGettingStart,
}) => {
	const user = useSelector((state) => state.auth.user)
	const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false)
	const cancelRef = useRef()
	const toast = useToast()
	const [isDeleting, setIsDeleting] = useState(false)

	const onAlertDialogClose = () => setIsAlertDialogOpen(false)

	return (
		<Box
			w="100%"
			p="15px"
			bg="white"
			border="1px"
			shadow="lg"
			borderColor="secondary.200"
			borderRadius="10"
			alignItems="center"
			pos="relative"
		>
			<Text
				mt="1"
				fontSize="3xl"
				textAlign="center"
				fontWeight="semibold"
				color="secondary.500"
			>
				{data.coupon?.name}
			</Text>
			{data?.coupon?.amount_off > 0 && (
			<Text textAlign="center" mt="1" fontSize="sm" color="secondary.600">
				Discount ${data?.coupon.amount_off / 100}
			</Text>
			)}
			{data?.coupon?.percent_off > 0 && (
			<Text textAlign="center" mt="1" fontSize="sm" color="secondary.600">
				Discount {data?.coupon.percent_off} %
			</Text>
			)}
			<Text
				fontSize="small"
				textAlign="center"
				color="secondary.700"
				fontWeight="normal"
			>
				Code {data.code ? data.code : data.stripe_id}
			</Text>

			<AlertDialog
				isOpen={isAlertDialogOpen}
				leastDestructiveRef={cancelRef}
				onClose={onAlertDialogClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Delete Coupon
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onAlertDialogClose}>
								Cancel
							</Button>
							<Button
								colorScheme="red"
								isLoading={isDeleting}
								loadingText={'Deleting...'}
								onClick={() => {
									setIsDeleting(true)
									deleteRequest(`coupon/${data.id}`)
										.then((res) => {
											toast(toastConfig(res.message, res.status))
											updateList()
											onAlertDialogClose()
											setIsDeleting(false)
										})
										.catch((error) => {
											toast(
												toastConfig(
													error.response.data.message,
													error.response.data.status
												)
											)
											setIsDeleting(false)
										})
								}}
								ml={3}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			{!isPublicDomain() && (
				<Popover placement="bottom-end">
					<PopoverTrigger>
						<IconButton
							_hover={{ outline: 'none', boxShadow: 'none' }}
							_focus={{ outline: 'none', boxShadow: 'none' }}
							aria-label="Actions"
							icon={<FiMoreVertical />}
							pos="absolute"
							top="4px"
							p="0"
							right="2px"
						/>
					</PopoverTrigger>
					<PopoverContent
						maxW="150px"
						size="md"
						bg="white"
						style={{ boxShadow: 'none' }}
					>
						<PopoverBody
							boxShadow="base"
							border="1px"
							rounded="md"
							borderColor="secondary.200"
							size="md"
						>
							<List spacing={3}>
								<ListItem
									cursor="pointer"
									_hover={{
										'& > .action-icon': { color: 'primary.500' },
										color: 'primary.500',
									}}
								>
									<AddCoupon updateList={updateList} data={data} />
								</ListItem>
								<ListItem
									onClick={() => setIsAlertDialogOpen(true)}
									display="flex"
									alignItems="center"
									cursor="pointer"
									_hover={{
										'& > .action-icon': { color: 'primary.500' },
										color: 'primary.500',
									}}
								>
									<ListIcon
										as={FiTrash}
										me="2"
										className="action-icon"
										color="secondary.400"
									/>
									Delete
								</ListItem>
							</List>
						</PopoverBody>
					</PopoverContent>
				</Popover>
			)}
		</Box>
	)
}

export default CouponCard
