import { useState, useEffect } from 'react'
import {
	Heading,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const TrainersTable = ({ data, isLoading }) => {
	const [bigWindow, setBigWindow] = useState(true)

	useEffect(() => {
		if (window.innerWidth < 600) {
            setBigWindow(false)
        }
	})

	return (
		<Table bg={'white'} variant={'striped'}>
			<Thead>
				<Tr>
					<Th>Stage</Th>
					<Th>Trainer Info</Th>
					<Th>App Name</Th>
					<Th>Plan</Th>
					<Th>Customers</Th>
					<Th>Gross Revenue</Th>
					<Th isNumeric>Net Revenue</Th>
				</Tr>
			</Thead>

			<Tbody>
				{isLoading ? (
					<Tr>
						<Td colSpan={7} textAlign={'center'}>
							<Text as={'strong'}>Loading...</Text>
						</Td>
					</Tr>
				) : data.length > 0 ? (
					data.map((trainer) => (
						<Tr
							as={Link}
							to={`/admin/trainers/details/${trainer.id}`}
							display={'table-row'}
							_hover={{ bg: 'gray.50' }}
						>
							<Td>{Math.floor(Math.random() * (4 - 1 + 1) + 1)}</Td>
							<Td>
								<Heading fontSize={'1rem'} mb={2} color={'gray.700'}>
									{trainer?.name}
								</Heading>
								<Text>{trainer?.email}</Text>
							</Td>
							<Td>{trainer?.app_name || '-'}</Td>
							<Td>Starter Plan</Td>
							<Td>{trainer?.customers}</Td>
							<Td>${trainer?.gross_revenue?.toFixed(2) || 0}</Td>
							<Td isNumeric>${trainer?.net_revenue?.toFixed(2) || 0}</Td>
						</Tr>
					))
				) : (
					<Tr>
						<Td colSpan={7} textAlign={'center'}>
							<Text as={'strong'}>No trainer found</Text>
						</Td>
					</Tr>
				)}
			</Tbody>
		</Table>
	)
}

export default TrainersTable
