import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	FormControl,
	FormLabel,
	Heading,
	IconButton,
	Input,
	List,
	ListIcon,
	ListItem,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Select,
	Stack,
	Td,
	Text,
	Tr,
	useDisclosure,
	useToast,
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { FiMoreVertical, FiEye, FiPieChart, FiEdit3, FiX } from 'react-icons/fi'
import { toastConfig } from '../helpers/appConfig'
import { postRequest } from '../services/base.service'

const TrainerRow = ({ getList, bigWindow, data, industries }) => {
	console.log(data)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isDeleting, setIsDeleting] = useState(false)
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm()
	const toast = useToast()
	const [submissionErrors, setSubmissionErrors] = useState([])
	const [isOpenDeleteAlert, setIsOpenDeleteAlert] = useState(false)
	const onDeleteAlertClose = () => setIsOpenDeleteAlert(false)
	const cancelRef = useRef()
	const [apiErrors, setApiErrors] = useState({})

	const handleDeleteTrainer = (id) => {
		setIsDeleting(true)
		postRequest(`admin/delete-trainer/${data.id}`)
			.then((res) => {
				setIsDeleting(false)
				toast(toastConfig(res.message, res.status))
				getList()
				onDeleteAlertClose()
			})
			.catch((err) => {
				setIsDeleting(false)
				console.log(err.response.data.errors)
			})
	}

	const handleAccessToTrainerAccount = () => {
		postRequest(`admin/profile-switch`, { trainer_id: data.id })
			.then((res) => {
				const backUp = JSON.parse(localStorage.getItem('user'))
				localStorage.setItem('backUp', JSON.stringify(backUp))
				localStorage.setItem('user', JSON.stringify(res.result))

				window.location.href = '/trainer/dashboard'
			})
			.catch((err) => {})
	}

	const onSubmit = (formData) => {
		setIsSubmitting(true)

		postRequest(`admin/update-trainer/${data.id}`, formData)
			.then((res) => {
				if (res.status !== 'error') {
					toast(toastConfig(res.message, res.status))
					onClose()
					reset()
					setSubmissionErrors([])
					getList()
				} else {
					setSubmissionErrors({ ...submissionErrors, product_id: res.message })
				}
				setIsSubmitting(false)
			})
			.catch((err) => {
				console.log(err.response.data.errors)
				setIsSubmitting(false)
				// setSubmissionErrors(err.response.data.errors)
			})
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Trainer Update</ModalHeader>

					<form onSubmit={handleSubmit(onSubmit)}>
						<ModalBody>
							<Stack spacing="20px">
								<FormControl id="subscriberName" isRequired>
									<FormLabel>Name</FormLabel>
									{/* <Input
										type="hidden"
										defaultValue="put"
										{...register('_method')}
									/> */}
									<Input
										type="text"
										placeholder="Name"
										rounded={'full'}
										defaultValue={data?.name}
										{...register('name', { required: true })}
									/>
									{errors.name && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}
								</FormControl>
								<FormControl id="subscriberEmail" isRequired>
									<FormLabel>Email</FormLabel>
									<Input
										type="email"
										rounded={'full'}
										defaultValue={data?.email}
										placeholder="Email address"
										{...register('email', { required: true })}
									/>

									{errors.email && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}

									{submissionErrors?.email && (
										<Text fontSize="sm" mt="2" color="red.500">
											{submissionErrors.email}
										</Text>
									)}
								</FormControl>

								<FormControl id="subscriberPassword">
									<FormLabel>Password</FormLabel>
									<Input
										type="text"
										rounded={'full'}
										defaultValue={data?.password}
										placeholder="Password"
										{...register('password', { required: false })}
									/>
								</FormControl>

								<FormControl id="businessType">
									<FormLabel>Business Type</FormLabel>

									<Select
										placeholder="Business Type"
										borderColor="secondary.200"
										defaultValue={data?.industry_id}
										_hover={{
											borderColor: 'secondary.300',
										}}
										_focus={{
											borderColor: 'secondary.400',
										}}
										_placeholder={{
											color: 'secondary.400',
										}}
										rounded="full"
										{...register('industry_id', { required: true })}
									>
										{industries.map((industry, index) => (
											<option key={index} value={industry.id}>
												{industry.name}
											</option>
										))}
									</Select>

									{errors.industry_id && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}
								</FormControl>
								<FormControl id="appName" isRequired>
									<FormLabel>App Name</FormLabel>
									<Input
										defaultValue={data?.app_name}
										borderColor="secondary.200"
										_hover={{
											borderColor: 'secondary.300',
										}}
										_focus={{
											borderColor: 'secondary.400',
										}}
										_placeholder={{
											color: 'secondary.400',
										}}
										rounded="full"
										type="text"
										{...register('app_name', { required: true })}
										placeholder="App name"
									/>

									{errors.app_name && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}
								</FormControl>

								<FormControl id="socialPlatform">
									<FormLabel>Your Main Social Media Platform</FormLabel>

									<Select
										defaultValue={data?.social_media}
										placeholder="Select Platform"
										borderColor="secondary.200"
										_hover={{
											borderColor: 'secondary.300',
										}}
										_focus={{
											borderColor: 'secondary.400',
										}}
										_placeholder={{
											color: 'secondary.400',
										}}
										rounded="full"
										{...register('social_media', { required: false })}
									>
										<option value="Instagram">Instagram</option>
										<option value="YouTube">YouTube</option>
										<option value="TikTok">TikTok</option>
										<option value="Other">Other</option>
									</Select>
								</FormControl>

								<FormControl id="handleName">
									<FormLabel>Platform Handle Name</FormLabel>
									<Input
										defaultValue={data?.social_media_handler_name}
										borderColor="secondary.200"
										_hover={{
											borderColor: 'secondary.300',
										}}
										_focus={{
											borderColor: 'secondary.400',
										}}
										_placeholder={{
											color: 'secondary.400',
										}}
										rounded="full"
										type="text"
										{...register('social_media_handler_name', {
											required: false,
										})}
										placeholder="@username"
									/>
								</FormControl>
								<FormControl id="commissionPercentage">
									<FormLabel>Commission (%)</FormLabel>
									<Input
										min={0}
										max={100}
										maxLength={3}
										minLength={1}
										type={'number'}
										step={'.01'}
										borderColor="secondary.200"
										_hover={{
											borderColor: 'secondary.300',
										}}
										_focus={{
											borderColor: 'secondary.400',
										}}
										_placeholder={{
											color: 'secondary.400',
										}}
										rounded="full"
										defaultValue={data?.mgp_commission}
										{...register('mgp_commission', {
											required: false,
										})}
										placeholder="Commission percentage"
									/>
								</FormControl>

								<FormControl id="accountId">
									<FormLabel>Account ID</FormLabel>
									<Input
										defaultValue={data?.stripe_connect?.account_id}
										type={'text'}
										borderColor="secondary.200"
										_hover={{
											borderColor: 'secondary.300',
										}}
										_focus={{
											borderColor: 'secondary.400',
										}}
										_placeholder={{
											color: 'secondary.400',
										}}
										rounded="full"
										{...register('account_id', {
											required: false,
										})}
										placeholder="Account ID"
									/>
								</FormControl>

								<FormControl id="productId">
									<FormLabel>Product ID</FormLabel>
									<Input
										defaultValue={data?.product?.stripe_product_id}
										type={'text'}
										borderColor="secondary.200"
										_hover={{
											borderColor: 'secondary.300',
										}}
										_focus={{
											borderColor: 'secondary.400',
										}}
										_placeholder={{
											color: 'secondary.400',
										}}
										rounded="full"
										{...register('stripe_product_id', {
											required: false,
										})}
										placeholder="Product ID"
									/>
								</FormControl>
							</Stack>
						</ModalBody>

						<ModalFooter>
							<Button variant={'ghost'} mr={3} onClick={onClose}>
								Close
							</Button>
							<Button
								type="submit"
								loadingText={'Updating'}
								isLoading={isSubmitting}
								variant="primary"
							>
								Update Trainer
							</Button>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>

			<AlertDialog
				isOpen={isOpenDeleteAlert}
				leastDestructiveRef={cancelRef}
				onClose={onDeleteAlertClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Delete Trainer
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onDeleteAlertClose}>
								Cancel
							</Button>
							<Button
								loadingText={'Deleting'}
								isLoading={isDeleting}
								colorScheme="red"
								onClick={() => handleDeleteTrainer(data.id)}
								ml={3}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			<Tr
				// as={Link}
				// to={`/admin/trainers/details/${4}`}
				// display={'table-row'}
				_hover={{ bg: 'gray.50' }}
			>
				<Td p={bigWindow ? 4: 3}>{Math.floor(Math.random() * (4 - 1 + 1) + 1)}</Td>
				<Td p={bigWindow ? 4: 3}>
					<Stack
						as={Link}
						to={`/admin/trainers/details/${data?.id}`}
						spacing={2}
					>
						<Heading fontSize={'1rem'} color={'gray.700'}>
							{data?.name}
						</Heading>
						<Text>{data?.email}</Text>
					</Stack>
				</Td>
				<Td p={bigWindow ? 4: 3}>{data?.app_name || ''}</Td>
				<Td p={bigWindow ? 4: 3}>{data?.plan_name || '-'}</Td>
				<Td p={bigWindow ? 4: 3}>{data?.customers}</Td>
				<Td p={bigWindow ? 4: 3}>${data?.gross_revenue || 0}</Td>
				<Td p={bigWindow ? 4: 3} isNumeric>${data?.net_revenue || 0}</Td>
				<Td p={bigWindow ? 4: 3} isNumeric>
					<Popover placement="bottom-end">
						<PopoverTrigger>
							<IconButton
								variant={'ghost'}
								_hover={{ outline: 'none', boxShadow: 'none' }}
								_focus={{ outline: 'none', boxShadow: 'none' }}
								aria-label="Actions"
								icon={<FiMoreVertical />}
								p="0"
								right="2px"
							/>
						</PopoverTrigger>
						<PopoverContent
							maxW="150px"
							size="md"
							bg="white"
							style={{ boxShadow: 'none' }}
						>
							<PopoverBody
								textAlign={'left'}
								boxShadow="base"
								border="1px"
								rounded="md"
								borderColor="secondary.200"
								size="md"
							>
								<List spacing={3}>
									<ListItem
										as={Link}
										to={`/admin/trainers/details/${data.id}`}
										cursor="pointer"
										_hover={{
											'& > .action-icon': { color: 'primary.500' },
											color: 'primary.500',
										}}
									>
										<ListIcon
											as={FiEye}
											me="2"
											className="action-icon"
											color="secondary.400"
										/>
										View
									</ListItem>
									<ListItem
										cursor="pointer"
										onClick={handleAccessToTrainerAccount}
										_hover={{
											'& > .action-icon': { color: 'primary.500' },
											color: 'primary.500',
										}}
									>
										<ListIcon
											as={FiPieChart}
											me="2"
											className="action-icon"
											color="secondary.400"
										/>
										Dashboard
									</ListItem>

									<ListItem
										onClick={() => setIsOpenDeleteAlert(true)}
										cursor="pointer"
										_hover={{
											'& > .action-icon': { color: 'primary.500' },
											color: 'primary.500',
										}}
									>
										<ListIcon
											className="action-icon"
											as={FiX}
											me="2"
											color="secondary.400"
										/>
										Delete
									</ListItem>
								</List>
							</PopoverBody>
						</PopoverContent>
					</Popover>
				</Td>
			</Tr>
		</>
	)
}

export default TrainerRow
