import {
    Tooltip,
    Text,
} from "@chakra-ui/react";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
const Explain = ({ label }) => {
    return (
        <Tooltip
            placement={'right-start'}
            p="3"
            fontSize={'md'}
            rounded="6"
            label={label}
            bg="blue.500"
            color="white"
            minWidth={350}
        >
            <Text as="span" ms="3">
                <QuestionOutlineIcon />
            </Text>
        </Tooltip>
    );
};

export default Explain;
