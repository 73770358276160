import { v4 as uuid } from 'uuid'
import {
	addComponent,
	createSectionLayout,
	paddingMarginUpdater,
	addItemToSlider,
	deleteItemForSlider,
	addItemToTestimonial,
	addItemToMediaObject,
	addFieldToForm,
	removeFromField,
	copyFieldToForm,
	updateFromField,
	reorderFromField,
} from '../helper/reduceHelper'

export const reducer = (state, action) => {
	switch (action.type) {
		case 'FETCH_DATA':
			return {
				...state,
				name: action.payload.name,
				section: action.payload.section,
			}

		case 'SET_NAME':
			return { ...state, name: action.payload.name }

		case 'ADD_SECTION':
			return addSection({ ...state }, action.payload)

		case 'REMOVE_SECTION':
			return removeSection({ ...state }, action.payload)

		case 'SECTION_DRAG_END':
			return sectionReorder({ ...state }, action.payload)

		case 'SELECT_SECTION':
			return selectSection({ ...state }, action.payload)

		case 'SECTION_CONFIG_UPDATE':
			return sectionConfigUpdate({ ...state }, action.payload)

		case 'REMOVE_COLUMN':
			return removeColumn({ ...state }, action.payload)

		case 'SELECT_COLUMN':
			return selectColumn({ ...state }, action.payload)

		case 'COLUMN_CONFIG_UPDATE':
			return columnConfigUpdate({ ...state }, action.payload)

		case 'SHOW_COMPONENTS':
			return { ...state, selected: null }

		case 'DROP_COMPONENT':
			return addComponentInColumn({ ...state }, action.payload)

		case 'SELECT_COMPONENT':
			return selectComponent({ ...state }, action.payload)

		case 'DELETE_COMPONENT':
			return removeComponent({ ...state }, action.payload)

		case 'COMPONENT_CONFIG_UPDATE':
			return componentConfigUpdate({ ...state }, action.payload)

		// This is new optimized
		case 'COMPONENT_SETTING_UPDATE':
			return componentSettingUpdate({ ...state }, action.payload)

		case 'BASE_SLIDER_UPDATE':
			return baseSliderConfigUpdate({ ...state }, action.payload)

		case 'FORM_COMPONENT_UPDATE':
			return formUpdateHandler({ ...state }, action.payload)
		default:
			return state
	}
}

const addSection = (state, payload) => {
	const section = createSectionLayout(payload.layout)
	let newState = { ...state }
	const sections = [...state.section]
	if (section) {
		let id = uuid()
		if (payload.index !== undefined) {
			sections.splice(payload.index + 1, 0, { ...section, id: id })
		} else {
			sections.push({ ...section, id: id })
		}
		newState = { ...state, selected: null, section: sections }
	}
	return newState
}

const removeSection = (state, payload) => {
	state.selected = null
	state.section.splice(payload.index, 1)
	return state
}

const sectionReorder = (state, payload) => {
	const result = payload.result
	const [removed] = state.section.splice(result.source.index, 1)
	state.section.splice(result.destination.index, 0, removed)
	state.selected = null
	return state
}

const selectSection = (state, payload) => {
	const newState = Object.assign({}, state)
	newState.selected = {
		type: 'section',
		sectionIndex: payload.sectionIndex,
	}
	return newState
}

const sectionConfigUpdate = (state, payload) => {
	const { sectionIndex, type } = payload
	const newState = Object.assign({}, state)
	const section = newState.section[sectionIndex]
	let style = Object.assign({}, section.style)
	switch (type) {
		case 'margin':
			style.margin = payload.value
			section.style = style
			break
		case 'padding':
			section.style.padding = paddingMarginUpdater(payload)
			break
		case 'bgColor':
			section.style.bgColor = payload.bgColor
			break
		case 'fullWidth':
			section.fullWidth = payload.fullWidth
			break
		case 'columnGap':
			section.columnGap = payload.columnGap
			break
		case 'imageUpload':
			section.style.bgImage = payload.url
			break
		default:
			break
	}

	return newState
}

const removeColumn = (state, payload) => {
	const { secIndex, colIndex, mergeSide } = payload
	const { columns } = state.section[secIndex]
	state.selected = null

	if (columns.length === 1) return state

	if (columns.length > 1 && colIndex === 0) {
		columns[colIndex + 1].style.width += columns[colIndex].style.width
	}

	if (columns.length > 1 && colIndex === columns.length - 1) {
		columns[colIndex - 1].style.width += columns[colIndex].style.width
	}

	if (columns.length > 2 && colIndex > 0 && colIndex < columns.length - 1) {
		if (mergeSide && mergeSide === 'right') {
			columns[colIndex + 1].style.width += columns[colIndex].style.width
		} else {
			columns[colIndex - 1].style.width += columns[colIndex].style.width
		}
	}
	columns.splice(colIndex, 1)
	return state
}

const selectColumn = (state, payload) => {
	const newState = Object.assign({}, state)
	newState.selected = {
		type: 'column',
		sectionIndex: payload.sectionIndex,
		columnIndex: payload.columnIndex,
	}

	return newState
}

const columnConfigUpdate = (state, payload) => {
	const { sectionIndex, columnIndex, type } = payload
	const newState = Object.assign({}, state)
	const column = newState.section[sectionIndex].columns[columnIndex]
	switch (type) {
		case 'padding':
			column.style.padding = paddingMarginUpdater(payload)
			break
		case 'rounded':
			column.style.rounded = payload.rounded
			break
		case 'bgColor':
			column.style.bgColor = payload.bgColor
			break
		case 'boxShadow':
			column.style.boxShadow = payload.boxShadow
			break
		default:
			break
	}
	newState.section[sectionIndex].columns[columnIndex] = column
	return newState
}

const addComponentInColumn = (state, payload) => {
	const { section } = state
	const componentObject = addComponent(payload.type)
	if (componentObject) {
		const { components } =
			section[payload.sectionIndex].columns[payload.columnIndex]
		components.push(componentObject)
		const compIndex = components.length - 1
		const selected = {
			type: 'component',
			name: payload.type,
			sectionIndex: payload.sectionIndex,
			columnIndex: payload.columnIndex,
			compIndex: compIndex,
		}
		state.selected = selected
	}
	return state
}

const selectComponent = (state, payload) => {
	const newState = Object.assign({}, state)
	newState.selected = {
		type: 'component',
		name: payload.name,
		sectionIndex: payload.sectionIndex,
		columnIndex: payload.columnIndex,
		compIndex: payload.compIndex,
	}

	return newState
}

const removeComponent = (state, payload) => {
	const { sectionIndex, columnIndex, compIndex } = payload
	const newState = Object.assign({}, state)
	newState.selected = null
	newState.section[sectionIndex].columns[columnIndex].components.splice(
		compIndex,
		1
	)
	return newState
}

const componentConfigUpdate = (state, payload) => {
	const newState = Object.assign({}, state)

	const { sectionIndex, columnIndex, compIndex, type } = payload
	const component =
		newState.section[sectionIndex].columns[columnIndex].components[compIndex]

	switch (type) {
		case 'margin':
			component.style.margin = payload.margin
			break

		case 'padding':
			component.style.padding = paddingMarginUpdater(payload)
			break

		case 'justifyContent':
			component.style.justifyContent = payload.justifyContent
			break

		case 'text':
			component.style.text = payload
			break

		case 'text.fontFamily':
			component.style.fontFamily = payload.fontFamily
			break

		case 'bgColor':
			component.style.bgColor = payload.bgColor
			break
		case 'objectFit':
			component.style.objectFit = payload.objectFit
			break
		case 'data':
			component.data = payload.data
			break
		case 'imageUpload':
			component.url = payload.url
			break
		case 'videoUpload':
			component.src = payload.src
			break
		case 'width':
			component.style.width = payload.width
			break
		case 'height':
			component.style.height = payload.height
			break
		case 'border':
			component.style.border = payload.border
			break
		case 'rounded':
			component.style.rounded = payload.rounded
			break
		case 'link':
			component.link.hasLink = payload.url ? true : false
			component.link.url = payload.url
			break
		case 'list.addItem':
			component.listItems = [...component.listItems, payload.item]
			break
		case 'list.deleteItem':
			const listItmes = [...component.listItems]
			listItmes.splice(payload.index, 1)
			component.listItems = listItmes
			break
		case 'list.changeItemText':
			component.listItems[payload.index].text = payload.value
			break
		case 'list.listStyleChange':
			component.listStyle = payload.value
			break
		case 'list.iconChange':
			component.listIcon = payload.value
			break
		case 'accordion.headingStyle':
			component.headingStyle = payload
			break
		case 'accordion.textStyle':
			component.textStyle = payload
			break
		case 'accordion.headingFontFamily':
			component.headingFontFamily = payload.fontFamily
			break
		case 'accordion.textFontFamily':
			component.textFontFamily = payload.fontFamily
			break
		case 'accordion.addItem':
			component.items = [...component.items, payload.item]
			break
		case 'accordion.updateItem':
			component.items = payload.items
			break
		case 'accordion.deleteItem':
			const accordionLists = [...component.items]
			accordionLists.splice(payload.index, 1)
			component.items = accordionLists
			break

		case 'add_slider_item':
			addItemToSlider(component)
			break

		// Slider, Testimonial, Media Object
		case 'delete_slider_item':
			const sliderItems = deleteItemForSlider(
				component.items,
				payload.itemIndex
			)
			component.items = sliderItems
			break

		case 'testimonial.add_item':
			addItemToTestimonial(component)
			break
		case 'testimonial.imageChange':
			addItemToTestimonial(component)
			break

		case 'mediaObject.add_item':
			addItemToMediaObject(component)
			break

		case 'embedCode':
			component.embedCode = payload.embedCode
			break

		default:
			break
	}

	return newState
}

// This is optimized
const componentSettingUpdate = (state, payload) => {
	const newState = Object.assign({}, state)
	const { sectionIndex, columnIndex, compIndex, cssProperty, objectKey } =
		payload
	const component =
		newState.section[sectionIndex].columns[columnIndex].components[compIndex]

	if (cssProperty) {
		// If has cssProperty
		component[objectKey][cssProperty] = payload.value
	} else {
		// If has no cssProperty
		component[objectKey] = payload.value
	}
	return newState
}

const baseSliderConfigUpdate = (state, payload) => {
	const { sectionIndex, columnIndex, compIndex, items } = payload
	const component =
		state.section[sectionIndex].columns[columnIndex].components[compIndex]
	if (items) {
		component.items = items
	} else {
		component[payload.property] = payload.value
	}
	return state
}

const formUpdateHandler = (state, payload) => {
	const { sectionIndex, columnIndex, compIndex } = payload
	const component =
		state.section[sectionIndex].columns[columnIndex].components[compIndex]

	switch (payload.type) {
		case 'formStyle':
			component.formStyle = payload.formStyle
			break

		case 'labelStyle':
			component.labelStyle = payload.labelStyle
			break

		case 'inputStyle':
			component.inputStyle = payload.inputStyle
			break

		case 'buttonStyle':
			component.buttonStyle = payload.buttonStyle
			break

		case 'button':
			component.button = payload.button
			break

		case 'field.add':
			addFieldToForm(component)
			break
		case 'field.copy':
			copyFieldToForm(component, payload.index)
			break
		case 'field.remove':
			removeFromField(component, payload.index)
			break

		case 'field.update':
			updateFromField(component, payload)
			break

		case 'field.reorder':
			reorderFromField(component, payload.sourceIndex, payload.destinationIndex)
			break

		case 'action.update':
			component.actions = payload.actions
			break

		default:
			break
	}

	return state
}
