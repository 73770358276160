import React, { useState, useEffect, useContext, useRef } from 'react'
import {
	Box,
	Text,
	Flex,
	Button,
	Spacer,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Checkbox,
	Radio,
	RadioGroup,
	Stack,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	FormControl,
	Input,
	Select,
	InputGroup,
	InputRightAddon,
	Textarea,
	SimpleGrid,
	Center,
	Modal,
	ModalOverlay,
	ModalContent,
	useDisclosure,
	Image,
} from '@chakra-ui/react'
import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import { PageBuilderContext } from '../../../context/PageBuilderContext'
import debounce from 'lodash.debounce'
import { SketchPicker } from 'react-color'
import { fonts } from '../../../helper/fonts'

function MediaObjectSetting() {
	const [state, dispatch] = useContext(PageBuilderContext)
	const [selectedIndex, setSelectedIndex] = useState(0)
	const [color, setColor] = useState('#FFFFFF')
	const itemType = useRef(null)
	const itemProperty = useRef(null)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const {
		isOpen: isOpenMedia,
		onOpen: onOpenMedia,
		onClose: onCloseMedia,
	} = useDisclosure()
	const { selected } = state
	const { items, heading, subHeading, text, image, style } =
		state.section[selected.sectionIndex].columns[selected.columnIndex]
			.components[selected.compIndex]

	const addItem = () => {
		dispatch({
			type: 'COMPONENT_CONFIG_UPDATE',
			payload: {
				type: 'mediaObject.add_item',
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const deleteItem = () => {
		if (items.length > 1) {
			console.log(selectedIndex)
			dispatch({
				type: 'COMPONENT_CONFIG_UPDATE',
				payload: {
					type: 'delete_slider_item',
					itemIndex: selectedIndex,
					sectionIndex: selected.sectionIndex,
					columnIndex: selected.columnIndex,
					compIndex: selected.compIndex,
				},
			})
			selectSlide(0)
		}
	}

	const selectSlide = (index) => {
		setSelectedIndex(index)
	}

	const componentWidthHandler = debounce((value) => {
		console.log(value)
		dispatch({
			type: 'COMPONENT_CONFIG_UPDATE',
			payload: {
				type: 'width',
				width: value,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 300)

	const componentAlignmentHandler = (value) => {
		dispatch({
			type: 'COMPONENT_CONFIG_UPDATE',
			payload: {
				type: 'justifyContent',
				justifyContent: value,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const itemGapHandler = debounce((value) => {
		const margin = { top: 0, right: 0, bottom: value, left: 0 }
		dispatch({
			type: 'COMPONENT_CONFIG_UPDATE',
			payload: {
				type: 'margin',
				margin: margin,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 300)

	const headingMarginHandler = debounce((value) => {
		const styles = { ...heading }
		styles.margin = { ...styles.margin }
		styles.margin.top = value
		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				value: styles,
				property: 'heading',
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 300)

	const textHandler = debounce((value, type) => {
		const newItem = Object.assign({}, items[selectedIndex])
		const itemList = [...items]

		newItem[type].data = value
		itemList[selectedIndex] = newItem

		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				items: itemList,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 1000)

	const handleFontFamily = (value, type) => {
		let styles = {}
		switch (type) {
			case 'heading':
				styles = { ...heading }
				styles.fontFamily = value
				break

			case 'subHeading':
				styles = { ...subHeading }
				styles.fontFamily = value
				break
			case 'text':
				styles = { ...text }
				styles.fontFamily = value
				break
			default:
				break
		}

		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				value: styles,
				property: type,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const handleFontStyle = debounce((value, type, property) => {
		let fontStyles = {}
		let element = {}
		switch (type) {
			case 'heading':
				fontStyles = { ...heading.font }
				fontStyles[property] = value
				element = { ...heading }
				element.font = fontStyles
				break

			case 'subHeading':
				fontStyles = { ...subHeading.font }
				fontStyles[property] = value
				element = { ...subHeading }
				element.font = fontStyles
				break
			case 'text':
				fontStyles = { ...text.font }
				fontStyles[property] = value
				element = { ...text }
				element.font = fontStyles
				break
			default:
				break
		}

		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				value: element,
				property: type,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 300)

	const handleVisibility = (value, type) => {
		let element = {}
		switch (type) {
			case 'heading':
				element = { ...heading }
				element.visibility = value
				break

			case 'subHeading':
				element = { ...subHeading }
				element.visibility = value
				break
			case 'text':
				element = { ...text }
				element.visibility = value
				break
			default:
				break
		}

		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				value: element,
				property: type,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const borderHandler = debounce((value, property) => {
		const styles = { ...image.border }
		const imageObject = Object.assign({}, image)
		styles[property] = value
		imageObject.border = styles

		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				value: imageObject,
				property: 'image',
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 500)

	const imageWidthHandler = debounce((value) => {
		let imageObject = Object.assign({}, image)
		imageObject = { ...imageObject, width: value, height: value }

		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				value: imageObject,
				property: 'image',
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 300)

	const imageAlignmentHandler = (value) => {
		let imageObject = Object.assign({}, image)
		imageObject = { ...imageObject, alignment: value }

		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				value: imageObject,
				property: 'image',
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const borderRoundHandler = debounce((value, property) => {
		const styles = { ...image.rounded }
		const imageObject = Object.assign({}, image)
		styles[property] = value
		imageObject.rounded = styles

		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				value: imageObject,
				property: 'image',
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 500)

	const imageHandler = (image) => {
		const newItems = [...items]
		newItems[selectedIndex].image = {
			path: image.filePath,
			altText: image.altText,
		}
		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				items: newItems,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const openColorPicker = (type, property) => {
		itemType.current = type
		itemProperty.current = property
		onOpen()
	}

	useEffect(() => {
		if (itemType.current && itemProperty.current) {
			switch (itemProperty.current) {
				case 'font':
					handleFontStyle(color, itemType.current, 'color')
					break

				case 'border':
					borderHandler(color, 'color')
					break

				default:
					break
			}
		}
	}, [color])

	useEffect(() => {
		return () => {
			textHandler.cancel()
			handleFontStyle.cancel()
			borderHandler.cancel()
			borderRoundHandler.cancel()
			componentWidthHandler.cancel()
			itemGapHandler.cancel()
			imageWidthHandler.cancel()
			headingMarginHandler.cancel()
		}
	}, [])

	return (
		<>
			<Box>
				<Tabs size="sm" isFitted variant="unstyled">
					<TabList borderWidth="1px">
						<Tab
							fontSize=".65rem"
							fontWeight="600"
							_focus={{ outline: 'none' }}
							_selected={{ bg: 'gray.200' }}
						>
							Items
						</Tab>
						<Tab
							fontSize=".65rem"
							fontWeight="600"
							_focus={{ outline: 'none' }}
							_selected={{ bg: 'gray.200' }}
						>
							Styles
						</Tab>
					</TabList>
					<TabPanels>
						{/* Item settings */}
						<TabPanel p={0}>
							<Text
								fontSize=".70rem"
								fontWeight="600"
								p="10px"
								borderBottomWidth="1px"
							>
								Items:
							</Text>
							<SimpleGrid columns={[4]} spacing="5px" px={2} py={3}>
								{items.map((item, index) => (
									<Center
										key={item.id}
										fontSize=".65rem"
										fontWeight="500"
										h="50px"
										bg={index === selectedIndex ? 'gray.300' : 'white'}
										_hover={{
											backgroundColor: 'gray.300',
										}}
										borderWidth={1}
										rounded={4}
										cursor="pointer"
										onClick={() => selectSlide(index)}
									>
										Item-{index + 1}
									</Center>
								))}

								<Center
									h="50px"
									bg="gray.200"
									rounded={4}
									cursor="pointer"
									onClick={addItem}
								>
									<AddIcon />
								</Center>
							</SimpleGrid>
							<Box p="10px" borderBottomWidth="1px">
								<Button
									fontSize="14px"
									fontWeight="500"
									textTransform="uppercase"
									isFullWidth
									onClick={deleteItem}
								>
									Delete Selected Item
								</Button>
							</Box>
							{selectedIndex !== null ? (
								<Box>
									<Text
										fontSize=".70rem"
										fontWeight="600"
										p="10px"
										borderBottomWidth="1px"
									>
										Item Content
									</Text>
									<Box p={2}>
										<Text
											fontSize=".70rem"
											fontWeight="500"
											mb={2}
											borderBottomWidth="1px"
										>
											Image:
										</Text>
										<Box borderBottomWidth="1px">
											{
												<Box position="relative">
													<Image
														src={
															items[selectedIndex]?.image?.path
																? items[selectedIndex]?.image?.path
																: 'https://picsum.photos/id/1005/200/200'
														}
														width="100%"
														maxWidth="350px"
													/>
													<Button
														size="xs"
														rounded={0}
														position="absolute"
														top={0}
														right={0}
														onClick={() => imageHandler('')}
													>
														<DeleteIcon />
													</Button>
												</Box>
											}
											<Box lineHeight="20px" border="1px dashed #BDBDBD">
												<Button
													size="sm"
													onClick={() => onOpenMedia()}
													isFullWidth
													rounded={0}
												>
													{items[selectedIndex].image?.path
														? 'Change'
														: 'Set Image'}
												</Button>
											</Box>
										</Box>

										<Text fontSize=".70rem" fontWeight="600" pt={4} pb={1}>
											Header Text:
										</Text>
										<Box key={items[selectedIndex].heading?.data}>
											<Input
												size="xs"
												defaultValue={items[selectedIndex].heading?.data}
												onChange={(e) => textHandler(e.target.value, 'heading')}
											/>
										</Box>

										<Text fontSize=".70rem" fontWeight="600" pt={4} pb={1}>
											Sub Header Text:
										</Text>
										<Box key={items[selectedIndex].subHeading?.data}>
											<Input
												size="xs"
												defaultValue={items[selectedIndex].subHeading?.data}
												onChange={(e) =>
													textHandler(e.target.value, 'subHeading')
												}
											/>
										</Box>

										<Text fontSize=".70rem" fontWeight="600" pt={4} pb={1}>
											Description:
										</Text>
										<Box key={items[selectedIndex].text?.data}>
											<Textarea
												size="xs"
												defaultValue={items[selectedIndex].text?.data}
												onChange={(e) => textHandler(e.target.value, 'text')}
											/>
										</Box>
									</Box>
								</Box>
							) : null}
						</TabPanel>

						{/* Media Object Common settings */}
						<TabPanel p={0}>
							<Text fontSize=".8rem" fontWeight="600" px={2}>
								Component Style
							</Text>
							<Box p="10px" borderBottomWidth="1px" display="flex">
								<Text fontSize=".7rem" fontWeight="500" flex="1 1">
									Width
								</Text>
								<FormControl flex="1 1" key={`componentWidth${style.width}`}>
									<NumberInput
										size="xs"
										min={0}
										max={100}
										defaultValue={style.width}
										onChange={componentWidthHandler}
									>
										<NumberInputField />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</FormControl>
							</Box>

							<Box p="10px" borderBottomWidth="1px" display="flex">
								<Text fontSize=".7rem" fontWeight="500" flex="1 1">
									AlignItem
								</Text>
								<FormControl
									flex="1 1"
									key={`componentAlignment-${style.justifyContent}`}
								>
									<RadioGroup
										defaultValue={style.justifyContent}
										onChange={componentAlignmentHandler}
									>
										<Stack direction="row">
											<Radio size="sm" value="flex-start">
												<Text fontSize=".7rem" fontWeight="500">
													Left
												</Text>
											</Radio>
											<Radio size="sm" value="center">
												<Text fontSize=".7rem" fontWeight="500">
													Center
												</Text>
											</Radio>
											<Radio size="sm" value="flex-end">
												<Text fontSize=".7rem" fontWeight="500">
													Right
												</Text>
											</Radio>
										</Stack>
									</RadioGroup>
								</FormControl>
							</Box>

							<Box p="10px" borderBottomWidth="1px" display="flex">
								<Text fontSize=".7rem" fontWeight="500" flex="1 1">
									Row Gap
								</Text>
								<FormControl
									flex="1 1"
									key={`mediaRowGap-${style.margin.bottom}`}
								>
									<NumberInput
										size="xs"
										min={0}
										defaultValue={style.margin.bottom}
										onChange={itemGapHandler}
									>
										<NumberInputField />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</FormControl>
							</Box>

							<Text fontSize=".8rem" fontWeight="600" px={2}>
								Image Style
							</Text>
							<Box p="10px" borderBottomWidth="1px" display="flex">
								<Text fontSize=".7rem" fontWeight="500" flex="1 1">
									Width
								</Text>
								<FormControl flex="1 1" key={`imageWidth-${image.width}`}>
									<NumberInput
										size="xs"
										min={0}
										defaultValue={image.width}
										onChange={imageWidthHandler}
									>
										<NumberInputField />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</FormControl>
							</Box>
							<Box p="10px" borderBottomWidth="1px" display="flex">
								<Text fontSize=".7rem" fontWeight="500" flex="1 1">
									Border Width
								</Text>
								<FormControl flex="1 1" key={`borderWidth-${image.border.top}`}>
									<NumberInput
										size="xs"
										min={0}
										defaultValue={image.border.top}
										onChange={(value) => borderHandler(value, 'top')}
									>
										<NumberInputField />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</FormControl>
							</Box>
							<Box p="10px" borderBottomWidth="1px" display="flex">
								<Text fontSize=".70rem" fontWeight="500" flex="1 1">
									Border Color
								</Text>
								<InputGroup
									flex="1 1"
									size="xs"
									key={`image-${image.border.color}`}
								>
									<Input
										defaultValue={image.border.color}
										onChange={(e) => borderHandler(e.target.value, 'color')}
									/>
									<InputRightAddon
										bg={image.border.color}
										cursor="pointer"
										onClick={() => openColorPicker('image', 'border')}
									/>
								</InputGroup>
							</Box>

							<Box p="10px" borderBottomWidth="1px" display="flex">
								<Text fontSize=".70rem" fontWeight="500" flex="1 1">
									Border Radius
								</Text>
								<FormControl
									flex="1 1"
									key={`borderRadius${image.rounded.topLeft}`}
								>
									<NumberInput
										size="xs"
										min={0}
										defaultValue={image.rounded.topLeft}
										onChange={(value) => borderRoundHandler(value, 'topLeft')}
									>
										<NumberInputField />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</FormControl>
							</Box>

							<Box p="10px" borderBottomWidth="1px" display="flex">
								<Text fontSize=".70rem" fontWeight="500" flex="1 1">
									Alignment
								</Text>
								<FormControl flex="1 1">
									<Select
										size="xs"
										value={image.alignment}
										onChange={(e) => imageAlignmentHandler(e.target.value)}
									>
										<option value="left">Left</option>
										<option value="right">Right</option>
									</Select>
								</FormControl>
							</Box>

							<Text fontSize=".8rem" fontWeight="600" px={2}>
								Header Style
							</Text>

							<Box borderBottomWidth="1px" p="10px">
								<Flex mb={2}>
									<Text width="40%" fontSize=".65rem" fontWeight="500" mr={2}>
										Margin Top
									</Text>
									<FormControl flex="1 1" key={heading.margin.top}>
										<NumberInput
											size="xs"
											max={100}
											defaultValue={heading.margin.top}
											onChange={headingMarginHandler}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</FormControl>
								</Flex>
								<Flex mb={2}>
									<Text width="40%" fontSize=".65rem" fontWeight="500" mr={2}>
										Font Family
									</Text>
									<Select
										flex="1 1"
										size="xs"
										value={heading.fontFamily}
										onChange={(e) =>
											handleFontFamily(e.target.value, 'heading')
										}
									>
										{fonts.map((font) => (
											<option key={font.value} value={font.value}>
												{font.label}
											</option>
										))}
									</Select>
								</Flex>
								<Flex mb={2}>
									<Text width="40%" fontSize=".65rem" fontWeight="500" mr={2}>
										Size (px)
									</Text>
									<FormControl flex="1 1" key={heading.font.size}>
										<NumberInput
											size="xs"
											max={100}
											min={5}
											defaultValue={heading.font.size}
											onChange={(value) =>
												handleFontStyle(value, 'heading', 'size')
											}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</FormControl>
								</Flex>
								<Flex mb={2}>
									<Text width="40%" fontSize=".65rem" fontWeight="500" mr={2}>
										Color
									</Text>
									<InputGroup
										flex="1 1"
										size="xs"
										key={`heading-${heading.font.color}`}
									>
										<Input
											defaultValue={heading.font.color}
											onChange={(e) =>
												handleFontStyle(e.target.value, 'heading', 'color')
											}
										/>
										<InputRightAddon
											bg={heading.font.color}
											cursor="pointer"
											onClick={() => openColorPicker('heading', 'font')}
										/>
									</InputGroup>
								</Flex>
								<Flex mb={2}>
									<Text width="40%" fontSize=".65rem" fontWeight="500" mr={2}>
										Weight
									</Text>
									<Select
										flex="1 1"
										size="xs"
										value={heading.font.weight}
										onChange={(e) =>
											handleFontStyle(e.target.value, 'heading', 'weight')
										}
									>
										{Array.from([
											'200',
											'300',
											'400',
											'500',
											'600',
											'700',
											'800',
										]).map((item) => (
											<option key={item} value={item}>
												{item}
											</option>
										))}
									</Select>
								</Flex>
								<Flex mb={2}>
									<Text width="40%" fontSize=".65rem" fontWeight="500" mr={2}>
										Transform
									</Text>
									<Select
										flex="1 1"
										size="xs"
										value={heading.font.transform}
										onChange={(e) =>
											handleFontStyle(e.target.value, 'heading', 'transform')
										}
									>
										<option value="none">None</option>
										<option value="uppercase">Uppercase</option>
										<option value="lowercase">Lowercase</option>
										<option value="capitalize">Capitalize</option>
									</Select>
								</Flex>

								<Flex mb={2}>
									<Text width="40%" fontSize=".65rem" fontWeight="500" mr={2}>
										Visibility
									</Text>
									<Checkbox
										size="sm"
										isChecked={heading.visibility}
										onChange={(e) =>
											handleVisibility(e.target.checked, 'heading')
										}
									>
										<Text
											fontSize=".70rem"
											fontWeight="500"
										>{`${heading.visibility}`}</Text>
									</Checkbox>
								</Flex>
							</Box>

							<Text fontSize=".8rem" fontWeight="600" px={2}>
								Sub Header Style
							</Text>
							<Box borderBottomWidth="1px" p="10px">
								<Flex mb={2}>
									<Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>
										Font Family
									</Text>
									<Select
										flex="1 1"
										size="xs"
										value={subHeading.fontFamily}
										onChange={(e) =>
											handleFontFamily(e.target.value, 'subHeading')
										}
									>
										{fonts.map((font) => (
											<option key={font.value} value={font.value}>
												{font.label}
											</option>
										))}
									</Select>
								</Flex>

								<Flex mb={2}>
									<Text width="40%" fontSize=".65rem" fontWeight="500" mr={2}>
										Size (px)
									</Text>
									<FormControl flex="1 1" key={subHeading.font.size}>
										<NumberInput
											size="xs"
											max={100}
											min={5}
											defaultValue={subHeading.font.size}
											onChange={(value) =>
												handleFontStyle(value, 'subHeading', 'size')
											}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</FormControl>
								</Flex>
								<Flex mb={2}>
									<Text width="40%" fontSize=".65rem" fontWeight="500" mr={2}>
										Color
									</Text>
									<InputGroup
										flex="1 1"
										size="xs"
										key={`subHeading-${subHeading.font.color}`}
									>
										<Input
											defaultValue={subHeading.font.color}
											onChange={(e) =>
												handleFontStyle(e.target.value, 'subHeading', 'color')
											}
										/>
										<InputRightAddon
											bg={subHeading.font.color}
											cursor="pointer"
											onClick={() => openColorPicker('subHeading', 'font')}
										/>
									</InputGroup>
								</Flex>
								<Flex mb={2}>
									<Text width="40%" fontSize=".65rem" fontWeight="500" mr={2}>
										Weight
									</Text>
									<Select
										flex="1 1"
										size="xs"
										value={subHeading.font.weight}
										onChange={(e) =>
											handleFontStyle(e.target.value, 'subHeading', 'weight')
										}
									>
										{Array.from([
											'200',
											'300',
											'400',
											'500',
											'600',
											'700',
											'800',
										]).map((item) => (
											<option key={item} value={item}>
												{item}
											</option>
										))}
									</Select>
								</Flex>
								<Flex mb={2}>
									<Text width="40%" fontSize=".65rem" fontWeight="500" mr={2}>
										Transform
									</Text>
									<Select
										flex="1 1"
										size="xs"
										value={subHeading.font.transform}
										onChange={(e) =>
											handleFontStyle(e.target.value, 'subHeading', 'transform')
										}
									>
										<option value="none">None</option>
										<option value="uppercase">Uppercase</option>
										<option value="lowercase">Lowercase</option>
										<option value="capitalize">Capitalize</option>
									</Select>
								</Flex>

								<Flex mb={2}>
									<Text width="40%" fontSize=".65rem" fontWeight="500" mr={2}>
										Visibility
									</Text>
									<Checkbox
										size="sm"
										isChecked={subHeading.visibility}
										onChange={(e) =>
											handleVisibility(e.target.checked, 'subHeading')
										}
									>
										<Text
											fontSize=".70rem"
											fontWeight="500"
										>{`${subHeading.visibility}`}</Text>
									</Checkbox>
								</Flex>
							</Box>

							<Text fontSize=".8rem" fontWeight="600" px={2}>
								Description Text Style
							</Text>
							<Box borderBottomWidth="1px" p="10px">
								<Flex mb={2}>
									<Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>
										Font Family
									</Text>
									<Select
										flex="1 1"
										size="xs"
										value={text.fontFamily}
										onChange={(e) => handleFontFamily(e.target.value, 'text')}
									>
										{fonts.map((font) => (
											<option key={font.value} value={font.value}>
												{font.label}
											</option>
										))}
									</Select>
								</Flex>
								<Flex mb={2}>
									<Text width="40%" fontSize=".65rem" fontWeight="500" mr={2}>
										Size (px)
									</Text>
									<FormControl flex="1 1" key={text.font.size}>
										<NumberInput
											size="xs"
											max={100}
											min={5}
											defaultValue={text.font.size}
											onChange={(value) =>
												handleFontStyle(value, 'text', 'size')
											}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</FormControl>
								</Flex>
								<Flex mb={2}>
									<Text width="40%" fontSize=".65rem" fontWeight="500" mr={2}>
										Color
									</Text>
									<InputGroup flex="1 1" size="xs" key={`${text.font.color}`}>
										<Input
											defaultValue={text.font.color}
											onChange={(e) =>
												handleFontStyle(e.target.value, 'text', 'color')
											}
										/>
										<InputRightAddon
											bg={text.font.color}
											cursor="pointer"
											onClick={() => openColorPicker('text', 'font')}
										/>
									</InputGroup>
								</Flex>
								<Flex mb={2}>
									<Text width="40%" fontSize=".65rem" fontWeight="500" mr={2}>
										Weight
									</Text>
									<Select
										flex="1 1"
										size="xs"
										value={text.font.weight}
										onChange={(e) =>
											handleFontStyle(e.target.value, 'text', 'weight')
										}
									>
										{Array.from([
											'200',
											'300',
											'400',
											'500',
											'600',
											'700',
											'800',
										]).map((item) => (
											<option key={item} value={item}>
												{item}
											</option>
										))}
									</Select>
								</Flex>
								<Flex mb={2}>
									<Text width="40%" fontSize=".65rem" fontWeight="500" mr={2}>
										Transform
									</Text>
									<Select
										flex="1 1"
										size="xs"
										value={text.font.transform}
										onChange={(e) =>
											handleFontStyle(e.target.value, 'text', 'transform')
										}
									>
										<option value="none">None</option>
										<option value="uppercase">Uppercase</option>
										<option value="lowercase">Lowercase</option>
										<option value="capitalize">Capitalize</option>
									</Select>
								</Flex>

								<Flex mb={2}>
									<Text width="40%" fontSize=".65rem" fontWeight="500" mr={2}>
										Visibility
									</Text>
									<Checkbox
										size="sm"
										isChecked={text.visibility}
										onChange={(e) => handleVisibility(e.target.checked, 'text')}
									>
										<Text
											fontSize=".70rem"
											fontWeight="500"
										>{`${text.visibility}`}</Text>
									</Checkbox>
								</Flex>
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>

			<Modal
				isCentered
				onClose={onClose}
				isOpen={isOpen}
				motionPreset="slideInBottom"
				size="xs"
			>
				<ModalOverlay />
				<ModalContent>
					<SketchPicker
						width="100%"
						color={color}
						onChangeComplete={(color) => setColor(color.hex)}
					/>
				</ModalContent>
			</Modal>

			<Modal
				isCentered
				onClose={onCloseMedia}
				isOpen={isOpenMedia}
				motionPreset="slideInBottom"
				size="xs"
			>
				<ModalOverlay />
				<ModalContent>Image upload modal</ModalContent>
			</Modal>
		</>
	)
}

export default MediaObjectSetting
