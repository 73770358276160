import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from 'recharts'

const OverviewChart = ({ bigWindow, data, dataKey }) => {
	return (
		<ResponsiveContainer width="100%" height="100%">
			<LineChart
				width={bigWindow ? 500 : 380}
				height={300}
				data={data}
				margin={{
					top: 0,
					right: 0,
					left: 0,
					bottom: 0,
				}}
			>
				
				<XAxis dataKey="name" />
				{bigWindow && 
					<YAxis />
				}
				<Tooltip />
				<Line
					type="monotone"
					dataKey={dataKey}
					stroke="#8884d8"
					activeDot={{ r: 8 }}
					dot={bigWindow ? true : false}
				/>
			</LineChart>
		</ResponsiveContainer>
	)
}

export default OverviewChart
