import React, { useContext, useEffect, useState } from 'react'
import {
	Box,
	Stack,
	Text,
	Flex,
	Textarea,
	Image,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	FormControl,
	FormLabel,
	Radio,
	RadioGroup,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Input,
	InputGroup,
	InputRightAddon,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	Button,
	Icon,
} from '@chakra-ui/react'
import { SketchPicker } from 'react-color'
import Dropzone from 'react-dropzone'
import { PageBuilderContext } from '../../../context/PageBuilderContext'
import debounce from 'lodash.debounce'
import {
	borderConfigHandler,
	paddingConfigHandler,
	marginConfigHandler,
	roundedConfigHandler,
} from '../../../helper/settingsHelper'
import { postRequest } from '../../../../services/base.service'

function ImageSetting() {
	const [state, dispatch] = useContext(PageBuilderContext)
	const { isOpen, onClose } = useDisclosure()
	const {
		isOpen: isOpenMedia,
		onOpen: onOpenMedia,
		onClose: onCloseMedia,
	} = useDisclosure()
	const { selected } = state
	let { style, link, url } =
		state.section[selected.sectionIndex].columns[selected.columnIndex]
			.components[selected.compIndex]

	const [isFileUploading, setIsFileUploading] = useState(false)
	const [validationErrors, setValidationErrors] = useState({})

	const paddingHandler = (value, side) => {
		const payload = paddingConfigHandler(style.padding, side, value)
		dispatch({
			type: 'COMPONENT_CONFIG_UPDATE',
			payload: {
				...payload,
				type: 'padding',
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const marginHandler = (value, side) => {
		const margin = marginConfigHandler(style.margin, side, value)
		console.log(margin)
		dispatch({
			type: 'COMPONENT_CONFIG_UPDATE',
			payload: {
				margin: margin,
				type: 'margin',
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const imageHandler = (imageFiles) => {
		setIsFileUploading(true)
		const user = JSON.parse(localStorage.getItem('user'))

		if (imageFiles.length && user) {
			const [file] = imageFiles
			const fileName =
				file.name.substr(0, file.name.lastIndexOf('.')) || file.name
			const formData = new FormData()
			formData.append('user_id', user.id)
			formData.append('file', file)
			formData.append('file_name', fileName)
			formData.append('media_type', 'image')

			postRequest('media', formData)
				.then((response) => {
					const { alt_text, file_path } = response.result
					const imageObject = {
						path: file_path,
						altText: alt_text,
					}
					dispatch({
						type: 'COMPONENT_CONFIG_UPDATE',
						payload: {
							url: imageObject,
							type: 'imageUpload',
							sectionIndex: selected.sectionIndex,
							columnIndex: selected.columnIndex,
							compIndex: selected.compIndex,
						},
					})
					setIsFileUploading(false)
				})
				.catch((error) => {
					setValidationErrors(error.response.data.errors)
					setIsFileUploading(false)
				})
		}
	}

	const widthHandler = (e) => {
		dispatch({
			type: 'COMPONENT_CONFIG_UPDATE',
			payload: {
				width: e.target.value,
				type: 'width',
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const heightHandler = (e) => {
		dispatch({
			type: 'COMPONENT_CONFIG_UPDATE',
			payload: {
				height: e.target.value,
				type: 'height',
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const alignmentHandler = (value) => {
		dispatch({
			type: 'COMPONENT_CONFIG_UPDATE',
			payload: {
				justifyContent: value,
				type: 'justifyContent',
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const borderHandler = (value, side) => {
		const border = borderConfigHandler(style.border, side, value)
		dispatch({
			type: 'COMPONENT_CONFIG_UPDATE',
			payload: {
				border: border,
				type: 'border',
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const roundingHandler = (e) => {
		const rounded = roundedConfigHandler(e.target.value)
		dispatch({
			type: 'COMPONENT_CONFIG_UPDATE',
			payload: {
				rounded: rounded,
				type: 'rounded',
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const linkHandler = debounce((value) => {
		dispatch({
			type: 'COMPONENT_CONFIG_UPDATE',
			payload: {
				type: 'link',
				url: value,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 400)

	useEffect(() => {
		return () => {
			linkHandler.cancel()
		}
	}, [])

	return (
		<>
			<Box>
				<Tabs size="sm" isFitted variant="unstyled">
					<TabList borderWidth="1px">
						<Tab
							fontSize=".75rem"
							fontWeight="600"
							_focus={{ outline: 'none' }}
							_selected={{ bg: 'gray.200' }}
						>
							Design
						</Tab>
						<Tab
							fontSize=".75rem"
							fontWeight="600"
							_focus={{ outline: 'none' }}
							_selected={{ bg: 'gray.200' }}
						>
							General
						</Tab>
					</TabList>

					<TabPanels>
						<TabPanel p={0}>
							<Text
								fontSize=".8rem"
								fontWeight="600"
								p="10px"
								borderBottomWidth="1px"
							>
								Image:
							</Text>
							<Box borderBottomWidth="1px" p="10px" position="relative">
								{url?.path ? (
									<>
										<Image src={url.path} />
									</>
								) : (
									<Image src="https://via.placeholder.com/1200x400/CBD5E0/718096/?text=Image" />
								)}
								<Dropzone
									maxFiles={1}
									onDrop={(acceptedFiles) => imageHandler(acceptedFiles)}
								>
									{({ getRootProps, getInputProps }) => (
										<Box
											{...getRootProps()}
											px={4}
											py={2}
											borderWidth="1px"
											borderStyle="dashed"
										>
											<Input {...getInputProps()} />
											<Text textAlign="center" cursor="pointer">
												{isFileUploading ? 'Uploading...' : 'Change Image'}
											</Text>
										</Box>
									)}
								</Dropzone>
								{validationErrors?.file && (
									<Box mt="3" color="red.500">
										{validationErrors.file[0]}
									</Box>
								)}
							</Box>

							<Text
								fontSize=".8rem"
								fontWeight="600"
								p="10px"
								borderBottomWidth="1px"
							>
								Margin:
							</Text>
							<Box
								display="flex"
								flexWrap="wrap"
								borderBottomWidth="1px"
								p="10px"
							>
								<FormControl
									flex="1 1"
									flexBasis="auto"
									width="50%"
									mb={4}
									pr="5px"
								>
									<Stack direction="row">
										<FormLabel fontSize=".75rem" fontWeight="600">
											Top{' '}
										</FormLabel>
										<NumberInput
											size="xs"
											defaultValue={style.margin.top}
											onChange={(value) => marginHandler(value, 'top')}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</Stack>
								</FormControl>
								<FormControl
									flex="1 1"
									flexBasis="auto"
									width="50%"
									mb={4}
									pl="5px"
								>
									<Stack direction="row">
										<FormLabel fontSize=".75rem" fontWeight="600">
											Right
										</FormLabel>
										<NumberInput
											size="xs"
											onChange={(value) => marginHandler(value, 'right')}
											value={style.margin.right}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</Stack>
								</FormControl>
								<FormControl flex="1 1" flexBasis="auto" width="50%" pr="5px">
									<Stack direction="row">
										<FormLabel fontSize=".75rem" fontWeight="600">
											Left
										</FormLabel>
										<NumberInput
											size="xs"
											onChange={(value) => marginHandler(value, 'left')}
											value={style.margin.left}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</Stack>
								</FormControl>
								<FormControl flex="1 1" flexBasis="auto" width="50%" pl="5px">
									<Stack direction="row">
										<FormLabel fontSize=".75rem" fontWeight="600">
											Bottom
										</FormLabel>
										<NumberInput
											size="xs"
											onChange={(value) => marginHandler(value, 'bottom')}
											value={style.margin.bottom}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</Stack>
								</FormControl>
							</Box>

							<Text
								fontSize=".8rem"
								fontWeight="600"
								p="10px"
								borderBottomWidth="1px"
							>
								Padding:
							</Text>
							<Box
								display="flex"
								flexWrap="wrap"
								borderBottomWidth="1px"
								p="10px"
							>
								<FormControl
									flex="1 1"
									flexBasis="auto"
									width="50%"
									mb={4}
									pr="5px"
								>
									<Stack direction="row">
										<FormLabel fontSize=".75rem" fontWeight="600">
											Top
										</FormLabel>
										<NumberInput
											size="xs"
											max={100}
											min={0}
											defaultValue={style.padding.top}
											onChange={(value) => paddingHandler(value, 'top')}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</Stack>
								</FormControl>
								<FormControl
									flex="1 1"
									flexBasis="auto"
									width="50%"
									mb={4}
									pl="5px"
								>
									<Stack direction="row">
										<FormLabel fontSize=".75rem" fontWeight="600">
											Right
										</FormLabel>
										<NumberInput
											size="xs"
											max={100}
											min={0}
											onChange={(value) => paddingHandler(value, 'right')}
											value={style.padding.right}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</Stack>
								</FormControl>
								<FormControl flex="1 1" flexBasis="auto" width="50%" pr="5px">
									<Stack direction="row">
										<FormLabel fontSize=".75rem" fontWeight="600">
											Left
										</FormLabel>
										<NumberInput
											size="xs"
											max={100}
											min={0}
											onChange={(value) => paddingHandler(value, 'left')}
											value={style.padding.left}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</Stack>
								</FormControl>
								<FormControl flex="1 1" flexBasis="auto" width="50%" pl="5px">
									<Stack direction="row">
										<FormLabel fontSize=".75rem" fontWeight="600">
											Bottom
										</FormLabel>
										<NumberInput
											size="xs"
											max={100}
											min={0}
											onChange={(value) => paddingHandler(value, 'bottom')}
											value={style.padding.bottom}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</Stack>
								</FormControl>
							</Box>

							<Box
								display="flex"
								flexWrap="wrap"
								borderBottomWidth="1px"
								p="10px"
							>
								<Text fontSize=".8rem" fontWeight="600" mr="10px">
									Alignment:
								</Text>
								<RadioGroup
									flex="1 1"
									value={style.justifyContent}
									onChange={alignmentHandler}
								>
									<Stack direction="row">
										<Radio size="sm" value="flex-start">
											Left
										</Radio>
										<Radio size="sm" value="center">
											Center
										</Radio>
										<Radio size="sm" value="flex-end">
											Right
										</Radio>
									</Stack>
								</RadioGroup>
							</Box>

							<Text
								fontSize=".8rem"
								fontWeight="600"
								p="10px"
								borderBottomWidth="1px"
							>
								Width & Height:
							</Text>
							<Box
								display="flex"
								flexWrap="wrap"
								borderBottomWidth="1px"
								p="10px"
							>
								<Flex mb={2}>
									<Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>
										Width
									</Text>
									<InputGroup flex="1 1" size="xs">
										<Input value={style.width} onChange={widthHandler} />
										<InputRightAddon
											id="width"
											cursor="pointer"
											children="(%, px, auto)"
										/>
									</InputGroup>
								</Flex>
								<Flex>
									<Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>
										Height
									</Text>
									<InputGroup flex="1 1" size="xs">
										<Input value={style.height} onChange={heightHandler} />
										<InputRightAddon
											id="primaryColor"
											cursor="pointer"
											children="(%, px, auto)"
										/>
									</InputGroup>
								</Flex>
							</Box>

							<Text
								fontSize=".8rem"
								fontWeight="600"
								p="10px"
								borderBottomWidth="1px"
							>
								Border:
							</Text>
							<Box>
								<Box
									display="flex"
									flexWrap="wrap"
									borderBottomWidth="1px"
									p="10px"
								>
									<FormControl
										flex="1 1"
										flexBasis="auto"
										width="50%"
										mb={4}
										pr="5px"
									>
										<Stack direction="row">
											<FormLabel fontSize=".75rem" fontWeight="600">
												Top
											</FormLabel>
											<NumberInput
												size="xs"
												max={50}
												min={0}
												value={style.border.top}
												onChange={(value) => borderHandler(value, 'top')}
											>
												<NumberInputField />
												<NumberInputStepper>
													<NumberIncrementStepper />
													<NumberDecrementStepper />
												</NumberInputStepper>
											</NumberInput>
										</Stack>
									</FormControl>
									<FormControl
										flex="1 1"
										flexBasis="auto"
										width="50%"
										mb={4}
										pr="5px"
									>
										<Stack direction="row">
											<FormLabel fontSize=".75rem" fontWeight="600">
												Right
											</FormLabel>
											<NumberInput
												size="xs"
												max={50}
												min={0}
												value={style.border.right}
												onChange={(value) => borderHandler(value, 'right')}
											>
												<NumberInputField />
												<NumberInputStepper>
													<NumberIncrementStepper />
													<NumberDecrementStepper />
												</NumberInputStepper>
											</NumberInput>
										</Stack>
									</FormControl>
									<FormControl
										flex="1 1"
										flexBasis="auto"
										width="50%"
										mb={4}
										pr="5px"
									>
										<Stack direction="row">
											<FormLabel fontSize=".75rem" fontWeight="600">
												Left
											</FormLabel>
											<NumberInput
												size="xs"
												max={50}
												min={0}
												value={style.border.left}
												onChange={(value) => borderHandler(value, 'left')}
											>
												<NumberInputField />
												<NumberInputStepper>
													<NumberIncrementStepper />
													<NumberDecrementStepper />
												</NumberInputStepper>
											</NumberInput>
										</Stack>
									</FormControl>
									<FormControl
										flex="1 1"
										flexBasis="auto"
										width="50%"
										mb={4}
										pr="5px"
									>
										<Stack direction="row">
											<FormLabel fontSize=".75rem" fontWeight="600">
												Bottom
											</FormLabel>
											<NumberInput
												size="xs"
												max={50}
												min={0}
												value={style.border.bottom}
												onChange={(value) => borderHandler(value, 'bottom')}
											>
												<NumberInputField />
												<NumberInputStepper>
													<NumberIncrementStepper />
													<NumberDecrementStepper />
												</NumberInputStepper>
											</NumberInput>
										</Stack>
									</FormControl>
								</Box>
							</Box>

							<Box
								display="flex"
								flexWrap="wrap"
								borderBottomWidth="1px"
								p="10px"
							>
								<Text width="40%" fontSize=".75rem" fontWeight="600" mr={2}>
									Border Radius
								</Text>
								<InputGroup flex="1 1" size="xs">
									<Input
										value={style.rounded.topLeft}
										onChange={roundingHandler}
										type="text"
									/>
									<InputRightAddon
										id="primaryColor"
										cursor="pointer"
										children="px or %"
									/>
								</InputGroup>
							</Box>
						</TabPanel>
						<TabPanel p={0}>
							<Text
								fontSize=".75rem"
								fontWeight="600"
								p="10px"
								borderBottomWidth="1px"
							>
								Link:
							</Text>
							<Box borderBottomWidth="1px" p="10px">
								<FormControl key={link.url}>
									<Textarea
										placeholder="Link url here"
										size="sm"
										fontSize=".75rem"
										resize="vertical"
										defaultValue={link.url}
										onChange={(e) => linkHandler(e.target.value)}
										autoFocus={true}
									/>
								</FormControl>
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
			<Modal
				isCentered
				onClose={onClose}
				isOpen={isOpen}
				motionPreset="slideInBottom"
				size="xs"
			>
				<ModalOverlay />
				<ModalContent>
					<SketchPicker width="100%" />
				</ModalContent>
			</Modal>
			<Modal
				isCentered
				onClose={onCloseMedia}
				isOpen={isOpenMedia}
				motionPreset="slideInBottom"
				size="xs"
			>
				<ModalOverlay />
				<ModalContent>Image upload here</ModalContent>
			</Modal>
		</>
	)
}

export default ImageSetting
