import {
	SimpleGrid,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	FormControl,
	Input,
	useDisclosure,
	Button,
	Text,
	Stack,
	Box,
	FormLabel,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import PricingCard from '../components/PricingCard'
import { getRequest, postRequest } from '../services/base.service'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { subdomain } from '../helpers/appConfig'
import { profileUpdate } from '../redux'

const PricingRender = () => {
	const [tempPricingId, setTempPricingId] = useState(null)
	const [pricingList, setPricingList] = useState([])
	const [isRegistering, setIsRegistering] = useState(false)
	const [isGettingStart, setIsGettingStart] = useState(false)
	const [validationErrors, setValidationErrors] = useState(null)

	const { isOpen, onOpen, onClose } = useDisclosure()
	const auth = useSelector((state) => state.auth)

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()
	const dispatch = useDispatch()

	useEffect(() => {
		getRequest(`public_pricing`, { params: { domain: subdomain } }).then(
			(res) => {
				console.log(res.result)
				setPricingList(res.result)
			}
		)
	}, [])

	const handleGetStart = (id) => {
		setTempPricingId(id)

		if (!auth.user) {
			onOpen()
		} else {
			requestPayment(id)
		}
	}

	const handleRegister = (data) => {
		setIsRegistering(true)

		postRequest(`trainee/register`, { ...data, subdomain })
			.then((res) => {
				dispatch(profileUpdate(res.result))
				setIsRegistering(false)
				requestPayment(tempPricingId, data.coupon)
			})
			.catch((error) => {
				console.log(error)
				setValidationErrors(error.response?.data?.errors)
				setIsRegistering(false)
			})
	}

	const requestPayment = (id, coupon) => {
		setIsGettingStart(true)

		postRequest('stripe-payment-link', {
			pricing_id: id,
			redirect_url: window.location.origin + '/payment-success',
			coupon: coupon,
		})
			.then((res) => {
				setIsGettingStart(false)
				sessionStorage.setItem('sessionToken', res.result.session_token)
				window.location.href = res.result.url
			})
			.catch((error) => {
				setIsGettingStart(false)
				console.error('ERROR', error)
			})
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent bg="white" color="secondary.800">
					<ModalHeader>Subscriber Details</ModalHeader>
					<form
						onSubmit={handleSubmit((data) => {
							handleRegister({ ...data, password: 'password' })
						})}
					>
						<ModalBody>
							<Stack spacing="15px">
								<Box>
									<FormControl id="userName">
										<FormLabel>Name</FormLabel>
										<Input
											type="text"
											_placeholder={{ color: 'secondary.600' }}
											_hover={{ borderColor: 'secondary.300' }}
											rounded="full"
											border="1px"
											borderColor="secondary.200"
											autoComplete="off"
											size="md"
											{...register('name', { required: true })}
											placeholder="Enter your name"
										/>
									</FormControl>

									{errors.name && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}

									{validationErrors?.name && (
										<Text fontSize="sm" mt="2" color="red.500">
											{validationErrors?.password[0]}
										</Text>
									)}
								</Box>

								<Box>
									<FormControl id="userEmail">
										<FormLabel>Email</FormLabel>
										<Input
											type="email"
											_placeholder={{ color: 'secondary.600' }}
											_hover={{ borderColor: 'secondary.300' }}
											rounded="full"
											border="1px"
											borderColor="secondary.200"
											autoComplete="off"
											size="md"
											{...register('email', { required: true })}
											placeholder="Email address"
										/>
									</FormControl>

									{errors.email && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}

									{validationErrors?.email && (
										<Text fontSize="sm" mt="2" color="red.500">
											{validationErrors?.email[0]}
										</Text>
									)}
								</Box>

								<Box>
									<FormControl id="userCoupon">
										<FormLabel>Coupon (optional)</FormLabel>
										<Input
											type="text"
											_placeholder={{ color: 'secondary.600' }}
											_hover={{ borderColor: 'secondary.300' }}
											rounded="full"
											border="1px"
											borderColor="secondary.200"
											autoComplete="off"
											size="md"
											{...register('coupon', { required: false })}
											placeholder="coupon code"
										/>
									</FormControl>
								</Box>
							</Stack>
						</ModalBody>

						<ModalFooter>
							<Button mr={3} onClick={onClose}>
								Close
							</Button>
							<Button
								isLoading={isRegistering}
								type="submit"
								loadingText="Registering..."
								variant="primary"
							>
								Continue
							</Button>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>

			<SimpleGrid columns="1" spacing="20px">
				{pricingList.map((price, index) => (
					<PricingCard
						isGettingStart={isGettingStart}
						tempPricingId={tempPricingId}
						handleGetStart={handleGetStart}
						key={index}
						data={price}
					/>
				))}
			</SimpleGrid>
		</>
	)
}

export default PricingRender
