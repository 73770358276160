import { useState, useEffect } from 'react'
import {
	Flex,
	Box,
	Container,
	Grid,
	InputGroup,
	InputLeftElement,
	Input,
	Spinner,
	Center,
} from '@chakra-ui/react'
import UserCard from '../components/UserCard'
import {
	FiArrowLeft,
	FiArrowRight,
	FiChevronLeft,
	FiChevronRight,
	FiSearch,
} from 'react-icons/fi'
import { RiArrowDropDownFill } from 'react-icons/ri'
import AddSubscriber from '../components/AddSubscriber'
import { getRequest } from '../services/base.service'
import { connect } from 'react-redux'
import { changePageTitle } from '../redux'
import { isPublicDomain } from '../helpers/utils'
import DataNotFound from '../components/DataNotFound'
import ReactPaginate from 'react-paginate'
import Paginate from '../components/Paginate'

const Subscribers = ({ changePageTitle }) => {
	const [initLoading, setInitLoading] = useState(false)
	const [subscribers, setSubscribers] = useState([])
	const [pageTitle] = useState('Subscribers')
	const [pageCount, setPageCount] = useState(10)
	const [currentPage, setCurrentPage] = useState(1)
	const [bigWindow, setBigWindow] = useState(true)

	useEffect(() => {
		if (window.innerWidth < 600) {
            setBigWindow(false)
        }
	}, [])

	useEffect(() => {
		setInitLoading(true)
		updateList()
		changePageTitle(pageTitle)
	}, [pageCount])

	const updateList = (page = currentPage) => {
		getRequest('subscribers?sort=id,desc', { params: { page } })
			.then((response) => {
				const { result } = response
				setSubscribers(result.data)
				setPageCount(Math.ceil(result?.total / result?.per_page))
				setCurrentPage(page)
				setInitLoading(false)
			})
			.catch((err) => {
				setInitLoading(false)
			})
	}

	const handleChange = (event) => {
		getRequest(
			`subscribers?sort=id,desc&like[0]=name,${event.target.value}&like[1]=email,${event.target.value}`
		)
			.then((response) => {
				setSubscribers(response.result.data)
			})
			.catch((err) => {})
	}

	// `&name,${event.target.value}&email,${event.target.value}`

	return (
		<>
		{!bigWindow && 
			<Flex justifyContent='center' mt={6} mb={10}>
				<InputGroup w='60%'>
					<InputLeftElement
						pointerEvents="none"
						children={<FiSearch />}
					/>
					<Input
						type="search"
						borderColor="gray.700"
						bg='white'
						_hover={{ borderColor: 'secondary.500' }}
						_focus={{ borderColor: 'secondary.500' }}
						_placeholder={{ color: 'secondary.500' }}
						rounded="full"
						placeholder="Search..."
						onChange={handleChange}
					/>
				</InputGroup>
			</Flex>
		}
		<Box flex="1" p={['0px', '25px', '30px', '30px']} pb={!bigWindow && '124px'} mt={['20px','0','0']}>
			<Container maxW="container.lg">
				<Box bg="white" p="30px" borderRadius={['0','15','15']} boxShadow="md">
					{/* Filter bar */}
					<Flex justifyContent="space-between" mb="6">
						{bigWindow && 
							<Flex>
								<InputGroup flex="2">
									<InputLeftElement
										pointerEvents="none"
										children={<FiSearch />}
									/>
									<Input
										type="search"
										borderColor="secondary.400"
										_hover={{ borderColor: 'secondary.500' }}
										_focus={{ borderColor: 'secondary.500' }}
										_placeholder={{ color: 'secondary.400' }}
										rounded="full"
										placeholder="Search..."
										// value={searchTerm}
										onChange={handleChange}
									/>
								</InputGroup>
							</Flex>
						}
					</Flex>

					{/* Subscribers list */}
					{initLoading ? (
						<Center h="200px">
							<Spinner
								thickness="6px"
								speed="0.65s"
								emptyColor="gray.200"
								color="blue.500"
								size="xl"
							/>
						</Center>
					) : subscribers?.length > 0 ? (
						<Grid
							templateColumns={[
								'repeat(1, 1fr)',
								'repeat(1, 1fr)',
								'repeat(2, 1fr)',
							]}
							gap={6}
						>
							{subscribers.map((subscriber, index) => (
								<UserCard
									key={index}
									updateList={updateList}
									data={subscriber}
									bigWindow={bigWindow}
								/>
							))}
						</Grid>
					) : (
						<DataNotFound status="warning" message="No subscribers exist" />
					)}

					<Box mt="6">
						<Paginate onPageChange={updateList} pageCount={pageCount} />
					</Box>
				</Box>
			</Container>
		</Box>
		</>
	)
}

const mapDispatchToProps = (dispatch) => {
	return {
		changePageTitle: (title) => dispatch(changePageTitle(title)),
	}
}

export default connect(null, mapDispatchToProps)(Subscribers)
