export const fonts = [
    {
        label: 'Poppins',
        url: '',
        value: 'default'
    },

    {
        label: 'Roboto',
        value: 'Roboto',
        url: "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap",
    },

    {
        label: 'Open Sans',
        value: 'Open Sans',
        url: "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap",
    },

    {
        label: 'Lato',
        value: 'Lato',
        url: "https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap"
    },
    {
        label: 'Quicksand',
        value: 'Quicksand',
        url: "https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap"
    },

    {
        label: 'Oswald',
        value: 'Oswald',
        url: "https://fonts.googleapis.com/css2?family=Oswald:wght@500;600;700&display=swap"
    },

    {
        label: 'Roboto Slab',
        value: 'Roboto Slab',
        url: "https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700&display=swap" 
    },

    {
        label: 'PlayFair Display',
        value: 'PlayFair Display',
        url: "https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap"
    },

]