import {
  Box,
  Input,
  Textarea,
  Text,
  Tooltip,
  Icon,
  Image,
  Button,
  Divider
} from "@chakra-ui/react";
import { FiUpload } from "react-icons/fi";
import { BsQuestionCircle } from "react-icons/bs";

import Explain from "./Explain";

const PricingModalSetting = ({
  bigWindow,
  icon,
  user,
  browseImage,
  validationErrors,
  onFormDataChange
}) => {
  return (
    <Box
      py={20}
      px={ bigWindow ? 40: 20}
      bg="white"
    >
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexDirection={bigWindow ? "row" : "column"}
      >
        <Box>
          <Text as={"strong"} mb={3} display={"block"} fontSize={".85rem"}>
            {bigWindow ? "App Icon" : "Form app icon"}
            <Tooltip
              placement={"right-start"}
              p="3"
              fontSize={"md"}
              rounded="6"
              hasArrow
              label="This is the icon shown on the sign-up form"
              bg="blue.500"
              color="blue.100"
            >
              <Text as="span" ms="3">
                <Icon as={BsQuestionCircle} />
              </Text>
            </Tooltip>
          </Text>

          <Box>
            {icon && (
              <Image
                bg="gray.200"
                border="1px"
                mb="3"
                p="2"
                rounded="10px"
                borderColor="gray.300"
                objectFit="contain"
                h="100"
                w="100%"
                src={icon ?? user.icon}
              />
            )}

            {bigWindow ? (
              <Button
                as="label"
                variant="outline"
                colorScheme="cyan"
                cursor="pointer"
                w="100%"
                leftIcon={<FiUpload />}
                htmlFor="uploadIcon"
              >
                <Input
                  accept="image/*"
                  display="none"
                  name="icon"
                  onChange={(e) => browseImage(e, "icon")}
                  id="uploadIcon"
                  type="file"
                />
                {icon ? "Change Icon" : "Browse Icon"}
              </Button>
            ) : (
              <Button
                as="label"
                variant="outline"
                w="100%"
                mb={2}
                color="primary.500"
                rounded="md"
                border="2px"
                borderColor="primary.500"
                fontSize="md"
                fontWeight="normal"
                h="2.8em"
                htmlFor="uploadIcon"
              >
                <Input
                  accept="image/*"
                  display="none"
                  name="icon"
                  onChange={(e) => browseImage(e, "icon")}
                  id="uploadIcon"
                  type="file"
                />
                Upload
              </Button>
            )}

            {validationErrors?.icon && (
              <Text fontSize="sm" mt="2" color="red.500">
                {validationErrors.icon[0]}
              </Text>
            )}
          </Box>
        </Box>

        <Box>
          <Text as={"strong"} mb={3} display={"block"} fontSize={".85rem"}>
            {bigWindow ? "iOS App Link" : "IOS app link"}
            <Explain label="Once your app is lauched, paste your Apple app's link into this field to redirect users to your app once they pay" />
          </Text>
          <Input
            rounded="full"
            defaultValue={user.ios_applink}
            border="1px"
            borderColor="secondary.200"
            type="text"
            name="ios_applink"
            onChange={(e) => onFormDataChange(e.target.value, 'ios_applink')}
          />

          {validationErrors?.ios_applink && (
            <Text fontSize="sm" mt="2" color="red.500">
              {validationErrors.ios_applink[0]}
            </Text>
          )}
        </Box>

        <Box>
          <Text as={"strong"} mb={3} display={"block"} fontSize={".85rem"}>
            {bigWindow ? "Android App Link" : "Android app link"}
            <Explain label="Once your app is lauched, paste your Android app's link into this field to redirect users to your app once they pay" />
          </Text>
          <Input
            rounded="full"
            defaultValue={user.android_applink}
            border="1px"
            borderColor="secondary.200"
            type="text"
            name="android_applink"
            onChange={(e) => onFormDataChange(e.target.value, 'android_applink')}

          />

          {validationErrors?.android_applink && (
            <Text fontSize="sm" mt="2" color="red.500">
              {validationErrors.android_applink[0]}
            </Text>
          )}
        </Box>

        {bigWindow && (
          <Box>
            <Text as={"strong"} mb={3} display={"block"} fontSize={".85rem"}>
              Terms of Service URL
              <Explain label="This is our default Terms of Service for your app. Feel free to to leave it or update it to your own " />
            </Text>
            <Input
              rounded="full"
              defaultValue={user.terms_of_service}
              border="1px"
              borderColor="secondary.200"
              type="text"
              name="terms_of_service"
              onChange={(e) => onFormDataChange(e.target.value, 'terms_of_service')}
            />

            {validationErrors?.terms_of_service && (
              <Text fontSize="sm" mt="2" color="red.500">
                {validationErrors.terms_of_service[0]}
              </Text>
            )}
          </Box>
        )}
      </Box>
      <Divider my={4} />
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexDirection={bigWindow ? "row" : "column"}
      >
        <Box>
          <Text as={"strong"} mb={3} display={"block"} fontSize={".85rem"}>
            Facebook Pixel
            <Explain label="A piece of code that helps you understand your customer's actions. Get this from Facebook's website." />
          </Text>
          <Textarea
            rounded='md'
            defaultValue={user.tracking}
            border='1px'
            borderColor='secondary.200'
            name='tracking'
            onChange={(e) => onFormDataChange(e.target.value, 'tracking')}
          />

          {validationErrors?.tracking && (
            <Text fontSize="sm" mt="2" color="red.500">
              {validationErrors.tracking[0]}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PricingModalSetting;
