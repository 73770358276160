import React, { useContext } from 'react'
import { Box, Stack, Text, Flex,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    FormControl, FormLabel,
    Radio, RadioGroup,
    NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Input,
    InputGroup, InputRightAddon,
    Modal, ModalOverlay, ModalContent,
    useDisclosure
} from '@chakra-ui/react'
import { SketchPicker } from 'react-color';
import { PageBuilderContext } from '../../context/PageBuilderContext';
import { paddingConfigHandler, roundedConfigHandler } from '../../helper/settingsHelper';

function ColumnSetting() {
    const [ state, dispatch ] = useContext(PageBuilderContext);
    const { isOpen, onOpen, onClose } = useDisclosure(); 
    const { selected } = state;
    let { style } = state.section[selected.sectionIndex].columns[selected.columnIndex];

    const paddingHandler = (value, side) => {
        const payload = paddingConfigHandler(style.padding, side, value);
        dispatch({
            type:'COLUMN_CONFIG_UPDATE', 
            payload:{
                ...payload, 
                type:"padding", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex
            }
        });
    }

    const bgColorHandler = (value) => {
        dispatch({
            type:'COLUMN_CONFIG_UPDATE', 
            payload:{
                bgColor: value,
                type:"bgColor", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex
            }
        });
    }

    const roundingHandler = (value) => {
        const rounded = roundedConfigHandler(value);
        dispatch({
            type:'COLUMN_CONFIG_UPDATE', 
            payload:{
                rounded: rounded,
                type:"rounded", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex
            }
        });
    }

    const boxShadowHandler = (value) => {
        dispatch({
            type:'COLUMN_CONFIG_UPDATE', 
            payload:{
                boxShadow: value,
                type:"boxShadow", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex
            }
        });
    }

    return (
        <>
        <Box>
            <Tabs size="sm" isFitted  variant="unstyled">
                <TabList borderWidth="1px">
                    <Tab
                        fontSize=".75rem" fontWeight="600"
                        _focus={{outline:"none"}} 
                        _selected={{ bg: "gray.200" }}
                    >
                        Design
                    </Tab>
                    <Tab 
                        fontSize=".75rem" fontWeight="600"
                        _focus={{outline:"none"}} 
                        _selected={{ bg: "gray.200" }}
                    >
                        General
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel p={0}>
                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Padding:</Text>
                        <Box display="flex" flexWrap="wrap" borderBottomWidth="1px" p="10px">
                            <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pr="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Top</FormLabel>
                                    <NumberInput 
                                        size="xs" 
                                        max={100} min={0} 
                                        onChange={(value) => paddingHandler(value, "top")} 
                                        value={style.padding.top}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pl="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Right</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        max={100} min={0} 
                                        onChange={(value) => paddingHandler(value, "right")} 
                                        value={style.padding.right}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" pr="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Left</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        max={100} min={0} 
                                        onChange={(value) => paddingHandler(value, "left")} 
                                        value={style.padding.left}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput> 
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" pl="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Bottom</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        max={100} min={0} 
                                        onChange={(value) => paddingHandler(value, "bottom")} 
                                        value={style.padding.bottom}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                        </Box>

                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Background:</Text>
                        <Box borderBottomWidth="1px" p="10px">
                            <Flex mb={2}>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Color</Text>
                                <InputGroup flex="1 1" size="xs">
                                    <Input value={style.bgColor}  onChange={(e) => bgColorHandler(e.target.value)} />
                                    <InputRightAddon id="columnBgColor" cursor="pointer" bg={style.bgColor}  onClick={onOpen} />
                                </InputGroup>
                            </Flex>
                        </Box>

                        <Box display="flex" flexWrap="wrap" borderBottomWidth="1px" p="10px">
                            <Text width="40%" fontSize=".8rem" fontWeight="600" mr={2}>Rounded</Text>
                            <InputGroup flex="1 1" size="xs">
                                <NumberInput 
                                    size="xs"
                                    max={100} min={0} 
                                    onChange={(value) => roundingHandler(value)} 
                                    value={style?.rounded?.topLeft}
                                >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                                <InputRightAddon id="primaryColor" cursor="pointer" children="px" />
                            </InputGroup>
                        </Box>

                        <Box borderBottomWidth="1px" p="10px">
                            <Text width="40%" fontSize=".8rem" fontWeight="600" mr={2}>Box Shadow</Text>
                            <RadioGroup value={style.boxShadow} onChange={boxShadowHandler}>
                                <Flex flexWrap="wrap">
                                    <Radio value="none" size="sm" my={1} mx={1}>None</Radio>
                                    <Radio value="xs" size="sm" my={1} mx={1}>Very Small</Radio>
                                    <Radio value="sm" size="sm" my={1} mx={1}>Small</Radio>
                                    <Radio value="base" size="sm" my={1} mx={1}>Normal</Radio>
                                    <Radio value="md" size="sm" my={1} mx={1}>Medium</Radio>
                                    <Radio value="lg" size="sm" my={1} mx={1}>Large</Radio>
                                    <Radio value="xl" size="sm" my={1} mx={1}>XL</Radio>
                                    <Radio value="2xl" size="sm" my={1} mx={1}>2-XL</Radio>
                                </Flex>
                            </RadioGroup>
                        </Box>
            
                    </TabPanel>
                    <TabPanel p={0}>
                 
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
        <Modal 
            isCentered
            onClose={onClose}
            isOpen={isOpen}
            motionPreset="slideInBottom"
            size="xs">

            <ModalOverlay />
            <ModalContent>
                <SketchPicker width="100%" color={style.bgColor} onChange={(color) => bgColorHandler(color.hex)}  /> 
            </ModalContent>
        </Modal>
        </>
    )
}

export default ColumnSetting
