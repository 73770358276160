import React, { createContext, useReducer } from 'react'
import store from './store'
import { reducer } from './reducer'

export const PageBuilderContext = createContext()

function PageBuilderProvider(props) {
	const [state, dispatch] = useReducer(reducer, store)

	return (
		<PageBuilderContext.Provider value={[state, dispatch]}>
			{props.children}
		</PageBuilderContext.Provider>
	)
}

export default PageBuilderProvider
