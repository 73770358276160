import { Button, Box, Text, Modal, ModalOverlay, 
    ModalContent
} from '@chakra-ui/react'
import { MdArrowForwardIos } from 'react-icons/md'

const ComingSoonModal = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay 
                bg='whiteAlpha.800' 
                backdropFilter='blur(4px)'
            />
            <ModalContent bg='transparent' boxShadow='none' mt='50%'>
                <Box>
                    <Text textAlign='center' fontSize='4xl' fontWeight='black'>
                        Coming Soon.
                    </Text>
                    <Text textAlign='center' fontSize='2xl' fontWeight='black'>
                        Visit Stripe.com
                    </Text>
                    <Text textAlign='center' fontSize='2xl' fontWeight='black'>
                        to view earnings
                    </Text>
                </Box>
                <Box display='flex' justifyContent='center' mt='20px'>
                    <Button
                        variant='outline'
                        colorScheme='primary.600'
                        rounded='md'
                        display='flex'
                        justifyContent='center'
                        w='50%'
                        border='2px solid'
                        borderColor='primary.500'
                        color='primary.500'
                        bg='white'
                        py={6}
                        _focus={{
                            outline: 'none'
                        }}
                        onClick={() => window.location.href = 'https://dashboard.stripe.com'}
                    >
                        <Text
                            ml='auto'
                            fontSize='xl'
                        >
                            Stripe
                        </Text>
                        <Text 
                            as='span'
                            ml='auto'
                        >
                            <MdArrowForwardIos size='0.9em' />
                        </Text>
                    </Button>
                </Box>
            </ModalContent>
        </Modal>
    )
}

export default ComingSoonModal