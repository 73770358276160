export const styleHelper = () => {
	return {
		padding: padding,
		margin: margin,
		bgColor: 'transparent',
		text: text,
		border: border,
		rounded: rounded,
	}
}

const padding = (top = 0, right = 0, bottom = 0, left = 0) => {
	return {
		top: top,
		right: right,
		bottom: bottom,
		left: left,
	}
}

const margin = (top = 0, right = 0, bottom = 0, left = 0) => {
	return {
		top: top,
		right: right,
		bottom: bottom,
		left: left,
	}
}

const text = (
	size = '16',
	weight = '400',
	color = '#212121',
	align = 'left',
	transform = 'none',
	lineHeight = 0,
	letterSpacing = 0
) => {
	return {
		size: size,
		color: color,
		weight: weight,
		align: align,
		transform: transform,
		lineHeight: lineHeight,
		letterSpacing: letterSpacing,
	}
}

const border = (width = 1, style = 'solid', color = '#666666') => {
	return {
		top: width,
		right: width,
		bottom: width,
		left: width,
		color: color,
		style: style,
	}
}

const rounded = (
	topLeft = 4,
	topRight = 4,
	bottomRight = 4,
	bottomLeft = 4
) => {
	return {
		topLeft: topLeft,
		topRight: topRight,
		bottomLeft: bottomLeft,
		bottomRight: bottomRight,
	}
}
