import { postRequest } from '../../services/base.service'

import {
	LOGIN_ERROR,
	LOGIN_REQUESTING,
	LOGIN_SUCCESS,
	USER_REGISTER_REQUESTING,
	USER_REGISTER_SUCCESS,
	USER_REGISTER_ERROR,
	LOGOUT_ERROR,
	LOGOUT_REQUESTING,
	LOGOUT_SUCCESS,
	CLEAR_ERR_MSG,
	PROFILE_UPDATE,
} from './actionTypes'

/**
 * User login
 */
export const login = (data, callback = () => null) => {
	return (dispatch) => {
		dispatch(loginRequesting())
		postRequest(`login`, data)
			.then((res) => {
				if (res.status === 'success') {
					localStorage.setItem('user', JSON.stringify(res.result))
					dispatch(loginSuccess(res.result, res.message))
				}
				callback(res.message, res.status)
			})
			.catch((error) => {
				dispatch(
					loginError(error.response?.data?.errors )
				)
			})
	}
}

export const loginRequesting = () => {
	return {
		type: LOGIN_REQUESTING,
	}
}

export const loginSuccess = (payload, successMessage) => {
	return {
		type: LOGIN_SUCCESS,
		payload,
		message: successMessage,
	}
}

export const loginError = (err) => {
	return {
		type: LOGIN_ERROR,
		errors: err,
	}
}

/**
 * User register
 */
export const userRegister = (data, callback) => {
	return (dispatch) => {
		dispatch(userRegisterRequesting())

		postRequest('register', data)
			.then((res) => {
				if (res.status === 'success') {
					localStorage.setItem('user', JSON.stringify(res.result))
					dispatch(userRegisterSuccess(res.result, res.message))
				}
				callback(res.message, res.status)
			})
			.catch((error) => {
				console.log(error.response)
				dispatch(
					userRegisterError(
						error.response?.data?.errors
					)
				)
			})
	}
}

/**
 *  Partial User register
 */
 export const partUserRegister = (data, callback) => {
	return (dispatch) => {
		dispatch(userRegisterRequesting())

		postRequest('second-step', data)
			.then((res) => {
				if (res.status === 'success') {
					localStorage.setItem('user', JSON.stringify(res.result))
					dispatch(userRegisterSuccess(res.result, res.message))
				}
				callback(res.message, res.status)
			})
			.catch((error) => {
				console.log(error.response)
				dispatch(
					userRegisterError(
						error.response?.data?.errors
					)
				)
			})
	}
}

const userRegisterRequesting = () => {
	return {
		type: USER_REGISTER_REQUESTING,
	}
}

const userRegisterSuccess = (payload, message) => {
	return {
		type: USER_REGISTER_SUCCESS,
		message: message,
		payload,
	}
}

const userRegisterError = (err) => {
	return {
		type: USER_REGISTER_ERROR,
		errors: err,
	}
}

/**
 * User logout
 */
export const logout = (callback) => {
	return (dispatch) => {
		dispatch(logoutRequesting())
		postRequest(`logout`)
			.then((res) => {
				if (res.status === 'success') {
					dispatch(logoutSuccess(res.message))
					// window.location.href = '/'
					localStorage.removeItem('user')
					callback(res.message, res.status)
				} else {
					callback(res.message, 'error')
				}
			})
			.catch((error) => {
				dispatch(logoutError('Something went wrong'))
			})
	}
}

export const logoutRequesting = () => {
	return {
		type: LOGOUT_REQUESTING,
	}
}

export const logoutSuccess = (msg) => {
	return {
		type: LOGOUT_SUCCESS,
		payload: msg,
	}
}

export const logoutError = (err) => {
	return {
		type: LOGOUT_ERROR,
		payload: err,
	}
}

/**
 * Clear error messages
 */
export const clearErrMsg = () => {
	return {
		type: CLEAR_ERR_MSG,
	}
}

/**
 * PROFILE
 */
export const profileUpdate = (data) => {
	localStorage.setItem('user', JSON.stringify(data))
	return {
		type: PROFILE_UPDATE,
		payload: data,
	}
}
