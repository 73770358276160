import { Navigate } from 'react-router-dom'
import { isPublicDomain } from '../helpers/utils'

const IsLandingPage = ({ children }) => {
	if (!isPublicDomain()) {
		return <Navigate to="/login" />
	}

	return children
}

export default IsLandingPage
