export const toastConfig = (message, status, duration = 2000) => {
	return {
		title: message,
		position: 'top-right',
		status: status,
		variant: 'subtle',
		isClosable: true,
		duration: duration,
	}
}

export const subdomain =
	process.env.NODE_ENV === 'development'
		? process.env.REACT_APP_DOMAIN_SLUG
		: window.location.host.split('.')[0]


export const mainDomain = process.env.REACT_APP_MAIN_DOMAIN
