import { CHANGE_PAGE_TITLE, SET_LANDING_TRAINER_INFO } from './actionTypes'

const initialState = {
	pageTitle: 'Gameplan Apps',
	landingTrainerInfo: JSON.parse(localStorage.getItem('trainer')) || {
		business_name: '',
		logo: '',
		is_show_logo: '',
	},
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		/**
		 * Change page title
		 */
		case CHANGE_PAGE_TITLE:
			return {
				...state,
				pageTitle: action.title,
			}

		case SET_LANDING_TRAINER_INFO:
			return {
				...state,
				landingTrainerInfo: action.payload,
			}

		default:
			return state
	}
}

export default reducer
