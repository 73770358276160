import { useColorMode } from '@chakra-ui/color-mode'
import { useEffect } from 'react'
import AppRoutes from './routes'

const App = () => {
	const { toggleColorMode } = useColorMode()

	useEffect(() => {
		toggleColorMode()
	}, [])

	return <AppRoutes />
}

export default App
