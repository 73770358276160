import {
	FaHeading,
	FaParagraph,
	FaSquare,
	FaImage,
	FaVideo,
	FaSuitcase,
	FaList,
	FaDivide,
	FaDollarSign,
} from 'react-icons/fa'
import { FiCode } from 'react-icons/fi'

const componentsFormat = [
	{
		name: 'Heading',
		type: 'heading',
		icon: FaHeading,
	},
	{
		name: 'Paragraph',
		type: 'paragraph',
		icon: FaParagraph,
	},
	{
		name: 'Button',
		type: 'button',
		icon: FaSquare,
	},
	{
		name: 'Image',
		type: 'image',
		icon: FaImage,
	},
	{
		name: 'Video',
		type: 'video',
		icon: FaVideo,
	},
	// {
	// 	name: 'Slider',
	// 	type: 'slider',
	// 	icon: FaSuitcase,
	// },
	{
		name: 'List',
		type: 'list',
		icon: FaList,
	},
	{
		name: 'Media Object',
		type: 'mediaObject',
		icon: FaImage,
	},
	{
		name: 'Divider',
		type: 'divider',
		icon: FaDivide,
	},
	{
		name: 'Pricing',
		type: 'pricing',
		icon: FaDollarSign,
	},
	{
		name: 'Embed Code',
		type: 'embedCode',
		icon: FiCode,
	},
]

export default componentsFormat
