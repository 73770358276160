import React, {useState} from 'react'
import {Button, FormControl, Flex, Input, Box, Heading, 
    Text, List, ListItem, ListIcon, Stack, useToast
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { MdArrowForwardIos, MdCheckCircle} from 'react-icons/md'
import { profileUpdate } from '../redux'
import { toastConfig } from '../helpers/appConfig'
import {  CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { postRequest } from '../services/base.service'
import { useEffect } from 'react'

const CheckoutForm = ({name, email, data}) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [promoSubmitting, setPromoSubmitting] = useState(false)
    const [promo, setPromo] = useState('')
    const [promoObj, setPromoObj] = useState({})
    const [promoError, setPromoError] = useState('')
    const [price, setPrice] = useState(data.price_amount)
    const [promoClaimed, setPromoClaimed] = useState(false)
    const [promoUsed, setPromoUsed] = useState(false)
    const stripe = useStripe()

    const elements = useElements()
    const {
		formState: { errors },
	} = useForm()
    const toast = useToast()
    const dispatch = useDispatch()

    const points = [
        'New features every month',
        'Auto code maintenance',
        'Charge for recurring revenue',
        'Post to your your community',
        'Send push notifications',
        'Fully customized',
        'Update app anytime'
    ]

    async function handlePromoCode(e) {
        setPromoSubmitting(true)

        postRequest(`get-promo`, {
            'promo-code': promo
        })
			.then(({ status, message, result }) => {
                if (status === 'error') {
                    setPromoError(message)
                    setPromoSubmitting(false)
                    toast(toastConfig(message, status, 6000))
                    return
                }
                
                setPromoSubmitting(false)
                setPromoObj(result)

                if (!promoUsed){
                   setPromoClaimed(true)
                }
			})
            .catch(error => {
                setPromoSubmitting(false)
                const message = Object.keys(error.response.data.errors).length > 0 
                    ? Object.values(error.response.data.errors)[0]
                    : error.response.data.message
                toast(toastConfig(message, 'error', 6000))
            })

    }
    
    async function handleSubmit(e) {
        if (elements == null) {
            return
        }
    
        setIsSubmitting(true)

        const {paymentMethod, error} = await stripe.createPaymentMethod({
                type: 'card', 
                card: elements.getElement(CardElement),
                billing_details: {
                    name: name,
                    email: email,
                }
            })
        
        if (error) {
            setIsSubmitting(false)
            toast(toastConfig(error.message, 'error', 6000))
            return
        }

        postRequest(`create-subscription`, {
            ...data, 
            name: name,
            email: email,
            promo_code: promo,
            payment_method: paymentMethod.id
        })
			.then(({status, message, result}) => {
				setIsSubmitting(false)
                toast(toastConfig(message, status, 6000))
                const localUserData = JSON.parse(localStorage.getItem('user'))
				dispatch(
					profileUpdate({
						...localUserData,
						business_name: result?.business_name,
						app_name: result?.app_name,
                        mobile_template: result?.mobile_template,
                        industry_id: result?.industry_id
					})
				)

                const protocol = process.env.NODE_ENV === 'production' ? 'https://' : 'http://'
                const url = protocol + process.env.REACT_APP_DOMAIN + '/trainer/webapp-builder?m=hello'
				window.location.replace(url)
			})
            .catch(error => {
                setIsSubmitting(false)
                const message = Object.keys(error.response.data.errors).length > 0 
                    ? Object.values(error.response.data.errors)[0]
                    : error.response.data.message
                toast(toastConfig(message, 'error', 6000))
            })
    }

    useEffect(() => {
        if (!promoUsed && Object.keys(promoObj).length > 0) {
            if (promoObj.coupon?.percent_off > 0) {
                const newPrice = price * ((100 - promoObj.coupon.percent_off) / 100 )
                setPrice(newPrice.toFixed(2))
            } 
            else if (promoObj.coupon?.amount_off > 0) {
                const newPrice = price - promoObj.coupon.amount_off
                setPrice(newPrice.toFixed(2))
            }
            setPromoUsed(true)
        }
    }, [promoObj, promoClaimed])

    return (
        <Stack as='form' spacing='3' direction={['column', 'column', 'row', 'row', 'row', 'row']}>
            <Box 
                flex='1' 
                bg='white'
                border='1px'
                borderColor='secondary.200'
                borderRadius='10'
                boxShadow='lg'
                p={6}
            >
                <Heading as='h3' textAlign='left' color='black' fontSize={['lg','lg','md','md']} mb='1'>
                    Business Annual Plan
                </Heading>
                <Text textAlign='left' color='black' fontSize={['0.88rem','0.88rem','0.7rem','0.7rem']} fontWeight='black' mb='5'>
                    {`Custom mobile apps with full payment functionality $${price}/${data.price_recurring}. `}
                    {promoUsed && 
                        <Box as='span' color='green.400'>
                            {`Congrats! You've earned ${promoObj.coupon?.percent_off ? promoObj.coupon?.percent_off + '%' : '$' + promoObj.coupon?.amount_off} off of your plan.`}
                        </Box>
                    }
                    
                </Text>
                
                <FormControl isInvalid={promoError} display='flex'>
                    <Input 
                        flex='1.2' 
                        id='promo-code' 
                        type='text' 
                        fontSize='sm'
                        placeholder='Discount code' 
                        onChange={e => setPromo(e.currentTarget.value)}
                    />
                    <Button 
                        flex='0.8'
                        isLoading={promoSubmitting}
                        loadingText='Applying'
                        rounded='sm'
                        bg='gray.500'
                        ml='2'
                        borderRadius='lg'
                        color='white'
                        _hover={{
                            bg: 'gray.600'
                        }}
                        onClick={handlePromoCode}
                        disabled={!data.price_id}
                    >
                        Apply
                    </Button>
                </FormControl>
                <Box mt='2' mb='6' border='1px solid #ccc' borderRadius='md' py='3' px='2'>
                    <CardElement options={{
                        style: {
                            base: {
                                fontWeight: '500',
                                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                                fontSize: '14px',
                                fontSmoothing: 'antialiased',
                                padding: '2px',
                                '::placeholder': {
                                    color: '#b4b9bf',
                                }
                            },
                        },
                    }}/>
                </Box>
                <Button
                    isLoading={isSubmitting}
                    loadingText='Subscribing'
                    variant="primary"
                    rounded='md'
                    w="100%"
                    onClick={handleSubmit}
                    disabled={!stripe || !elements}
                >
                    <Text
                        ml='auto'
                    >
                        Start building
                    </Text>
                    <Text 
                        as='span'
                        ml='auto'
                    >
                        <MdArrowForwardIos size='0.9em' />
                    </Text>
                </Button>
                <Flex justifyContent='center'>
                    <Text as='small' color='black' fontSize='0.7rem' textAlign='center'>{`60 day money-back guarantee & $0 setup fees`}</Text>
                </Flex>      
            </Box>
            <Box 
                flex='1' 
                bg='white'
                p={6}
                pr={0}
            >
                <List spacing={1}>
                    {
                        points.map((point, id) => (
                            <ListItem key={id}>
                                <ListIcon as={MdCheckCircle} color='green.500' sx={{ fontSize: '1.2rem' }} />
                                <Box as='span' color='black' fontWeight='bold' fontSize='1.1rem'>{point}</Box>
                            </ListItem>
                        ))
                    }
                </List> 
            </Box>   
        </Stack>
    )
}

export default CheckoutForm
