const mapDate = (date) => {
    let day = new Date(date).getDate() 
    day = day > 9 ? day : `0${day}` 

    let month = new Date(date).getMonth() + 1 // january gives 0 
    
    switch(month) { 
        case 1: { month = 'January'; break; } 
        case 2: { month = 'February'; break; } 
        case 3: { month = 'March'; break; } 
        case 4: { month = 'April'; break; } 
        case 5: { month = 'May'; break; } 
        case 6: { month = 'June'; break; } 
        case 7: { month = 'July'; break; } 
        case 8: { month = 'August'; break; } 
        case 9: { month = 'September'; break; } 
        case 10: { month = 'October'; break; } 
        case 11: { month = 'November'; break; } 
        case 12: { month = 'December'; break; } 
    } 
    
    let year = new Date(date).getFullYear() 
    year = year > 9 ? year : `0${year}` 

    return { day, month, year } 
} 

export default mapDate; 