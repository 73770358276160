import { useState, useEffect } from 'react'
import {
	Flex,
	Box,
	Container,
	InputGroup,
	InputLeftElement,
	Input,
	Spinner,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	Text,
} from '@chakra-ui/react'
import { FiSearch } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { changePageTitle } from '../redux'
import { getRequest } from '../services/base.service'
import Paginate from '../components/Paginate'
import AddTrainer from '../components/AddTrainer'
import TrainerRow from '../components/TrainerRow'

const Trainers = () => {
	const [trainers, setTrainers] = useState([])
	const [bigWindow, setBigWindow] = useState(true)
	const [initLoading, setInitLoading] = useState(false)
	const dispatch = useDispatch()
	const [pageCount, setPageCount] = useState(0)
	const [currentPage, setCurrentPage] = useState(1)
	const [industries, setIndustries] = useState([])
	const [searchTimeout, setSearchTimeout] = useState(null)

	useEffect(() => {
		if (window.innerWidth < 600) {
            setBigWindow(false)
        }
		
		dispatch(changePageTitle('Trainers'))
	}, [])

	useEffect(() => {
		setInitLoading(true)
		getList()
		fetchIndustries()
	}, [])

	const fetchIndustries = () => {
		getRequest(`industries`)
			.then((res) => {
				setIndustries(res.result)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const getList = (page = currentPage) => {
		getRequest('admin/trainers', {
			params: {
				page,
				limit: 15,
			},
		})
			.then(({ result }) => {
				setTrainers(result.data)
				setPageCount(Math.ceil(result?.total / result?.per_page))
				setCurrentPage(page)
				setInitLoading(false)
			})
			.catch((err) => setInitLoading(false))
	}

	const handleChange = (event) => {
		clearTimeout(searchTimeout)

		setSearchTimeout(
			setTimeout(function () {
				getRequest(`admin/trainers`, {
					params: {
						search_string: event.target.value,
						limit: 15,
					},
				})
					.then((response) => {
						const { result } = response
						setTrainers(result.data)
						setPageCount(Math.ceil(result?.total / result?.per_page))
						setCurrentPage(1)
					})
					.catch((err) => {})
			}, 300)
		)
	}

	return (
		<>
		{!bigWindow && 
			<Flex justifyContent='space-between' w='100vw' mt={6} mb={10} px={4}>
				<InputGroup w='50%'>
					<Input
						type="search"
						borderColor="secondary.400"
						_hover={{ borderColor: 'secondary.500' }}
						_focus={{ borderColor: 'secondary.500' }}
						_placeholder={{ color: 'secondary.400' }}
						rounded="full"
						placeholder="Search..."
						bg='white'
						py={6}
						onChange={handleChange}
					/>
				</InputGroup>
					
				<AddTrainer industries={industries} getList={getList} />
			</Flex>
		}
		<Box flex="1" p={['0px', '25px', '30px', '30px']} pb={!bigWindow && '124px'} mt={['20px','0','0']}>
			<Container maxW={['container.lg','container.xl','container.xl']}>
				<Box bg="white" p={['10px','30px','30px']} borderRadius={['0','15','15']} boxShadow="md">
					{bigWindow && 
						<Flex justifyContent="space-between" mb="6">
							<Flex>
								<InputGroup flex="2">
									<InputLeftElement
										pointerEvents="none"
										children={<FiSearch />}
									/>
									<Input
										type="search"
										borderColor="secondary.400"
										_hover={{ borderColor: 'secondary.500' }}
										_focus={{ borderColor: 'secondary.500' }}
										_placeholder={{ color: 'secondary.400' }}
										rounded="full"
										placeholder="Search..."
										// value={searchTerm}
										onChange={handleChange}
									/>
								</InputGroup>

								{/* <Select
									flex="1"
									ms="4"
									icon={<RiArrowDropDownFill />}
									borderColor="secondary.400"
									_hover={{ borderColor: 'secondary.500' }}
									_focus={{ borderColor: 'secondary.500' }}
									_placeholder={{ color: 'secondary.400' }}
									rounded="full"
									placeholder="Sort by"
								>
									<option value="1">Most Recent</option>
								</Select> */}
							</Flex>

							{/* Add Subscriber */}
							<AddTrainer industries={industries} getList={getList} />
						</Flex>
					}

					<Table bg={'white'} variant={'striped'}>
						<Thead>
							<Tr>
								<Th p={bigWindow ? 4: 3}>Stage</Th>
								<Th p={bigWindow ? 4: 3}>Trainer Info</Th>
								<Th p={bigWindow ? 4: 3}>App Name</Th>
								<Th p={bigWindow ? 4: 3}>Plan</Th>
								<Th p={bigWindow ? 4: 3}>Customers</Th>
								<Th p={bigWindow ? 4: 3}>Gross Revenue</Th>
								<Th p={bigWindow ? 4: 3} isNumeric>Net Revenue</Th>
								<Th p={bigWindow ? 4: 3} isNumeric />
							</Tr>
						</Thead>
						<Tbody>
							{initLoading ? (
								<Tr>
									<Td colSpan={8} textAlign={'center'}>
										<Spinner
											thickness="2px"
											speed="0.65s"
											emptyColor="gray.200"
											color="blue.500"
										/>
										<Text>Loading...</Text>
									</Td>
								</Tr>
							) : trainers.length > 0 ? (
								trainers?.map((trainer, index) => (
									<TrainerRow
										industries={industries}
										key={index}
										getList={getList}
										data={trainer}
										bigWindow={bigWindow}
									/>
								))
							) : (
								<Tr>
									<Td colSpan={8} fontWeight={'bold'} textAlign={'center'}>
										No trainer found
									</Td>
								</Tr>
							)}
						</Tbody>
					</Table>

					<Box mt="6">
						<Paginate onPageChange={getList} pageCount={pageCount} />
					</Box>
				</Box>
			</Container>
		</Box>
		</>
	)
}
export default Trainers
