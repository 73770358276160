import React, { useContext, useState, useEffect } from 'react'
import {
	Box,
	Stack,
	Text,
	Flex,
	Button,
	Image,
	Badge,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	FormControl,
	FormLabel,
	Input,
	Checkbox,
	Switch,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	InputGroup,
	InputRightAddon,
	Modal,
	ModalOverlay,
	ModalContent,
	useDisclosure,
	Spacer,
	Icon,
	useToast
} from '@chakra-ui/react'
import { FaRegTrashAlt } from 'react-icons/fa'
import { SketchPicker } from 'react-color'
import Dropzone from 'react-dropzone'
import { PageBuilderContext } from '../../context/PageBuilderContext'
import { paddingConfigHandler } from '../../helper/settingsHelper'
import { toastConfig } from '../../../helpers/appConfig'
import debounce from 'lodash.debounce'
import { postRequest } from '../../../services/base.service'

function SectionSetting() {
	const [state, dispatch] = useContext(PageBuilderContext)
	const { selected } = state
	let { style, fullWidth, columnGap } = state.section[selected.sectionIndex]
	const { isOpen, onOpen, onClose } = useDisclosure()
	const {
		isOpen: isOpenMedia,
		onOpen: onOpenMedia,
		onClose: onCloseMedia,
	} = useDisclosure()
	const toast = useToast()
	const [inputLink, setInputLink] = useState({
		padding: true,
		margin: true,
	})

	const marginHandler = debounce((value, side) => {
		let margin = { ...style.margin }
		if (inputLink.margin) {
			margin = {
				...margin,
				top: value,
				right: value,
				bottom: value,
				left: value,
			}
		} else {
			margin[side] = value
		}

		dispatch({
			type: 'SECTION_CONFIG_UPDATE',
			payload: {
				value: margin,
				type: 'margin',
				sectionIndex: selected.sectionIndex,
			},
		})
	}, 400)

	const paddingHandler = debounce((value, side) => {
		const payload = paddingConfigHandler(style.padding, side, value)
		let padding = { ...style.padding }
		if (inputLink.padding) {
			padding = {
				...padding,
				top: value,
				right: value,
				bottom: value,
				left: value,
			}
		} else {
			padding[side] = value
		}
		console.log(payload, padding)
		dispatch({
			type: 'SECTION_CONFIG_UPDATE',
			payload: {
				...padding,
				type: 'padding',
				sectionIndex: selected.sectionIndex,
			},
		})
	}, 400)

	const bgColorHandler = (value) => {
		dispatch({
			type: 'SECTION_CONFIG_UPDATE',
			payload: {
				bgColor: value,
				type: 'bgColor',
				sectionIndex: selected.sectionIndex,
			},
		})
	}

	const fullWidthHandler = (e) => {
		dispatch({
			type: 'SECTION_CONFIG_UPDATE',
			payload: {
				fullWidth: e.target.checked,
				type: 'fullWidth',
				sectionIndex: selected.sectionIndex,
			},
		})
	}

	const columnGapHandler = (value) => {
		dispatch({
			type: 'SECTION_CONFIG_UPDATE',
			payload: {
				columnGap: value,
				type: 'columnGap',
				sectionIndex: selected.sectionIndex,
			},
		})
	}

	const imageHandler = (imageFiles) => {
		const user = JSON.parse(localStorage.getItem('user'));
		if (imageFiles.length && user) {
            const [ file ] = imageFiles;
            const fileName = file.name.substr(0, file.name.lastIndexOf('.')) || file.name;
            const formData = new FormData();
            formData.append('user_id', user.id);
            formData.append('file', file);
            formData.append('file_name', fileName);
            formData.append('media_type', 'image');

            postRequest('media', formData)
            .then(response => {
                const { alt_text, file_path } = response.result;
                const imageObject = {
                    path: file_path,
                    altText: alt_text
                }

                dispatch({
				    type:'SECTION_CONFIG_UPDATE',
				    payload:{
				        url: imageObject,
				        type:"imageUpload",
				        sectionIndex: selected.sectionIndex,
				    }
				});

				toast(toastConfig('File uploaded', 'success'))

            })
			.catch(error => {
				console.log(error)
				const message = Object.keys(error.response.data.errors).length > 0 
                    ? Object.values(error.response.data.errors)[0]
                    : error.response.data.message
                toast(toastConfig(message, 'error', 6000))
		});
        } 
		
	}

	const imageDeleteHandler = () => {
		const imageObject = {
			path: '',
			altText: '',
		}
		dispatch({
			type: 'SECTION_CONFIG_UPDATE',
			payload: {
				url: imageObject,
				type: 'imageUpload',
				sectionIndex: selected.sectionIndex,
			},
		})
	}

	useEffect(() => {
		return () => {
			marginHandler.cancel()
			paddingHandler.cancel()
		}
	}, [])

	return (
		<>
			<Box>
				<Tabs size="sm" isFitted variant="unstyled">
					<TabList borderWidth="1px">
						<Tab
							fontSize=".75rem"
							fontWeight="600"
							_focus={{ outline: 'none' }}
							_selected={{ bg: 'gray.200' }}
						>
							Design
						</Tab>
					</TabList>
					<TabPanels>
						<TabPanel p={0}>
							<Text
								fontSize=".8rem"
								fontWeight="600"
								p="10px"
								borderBottomWidth="1px"
							>
								Image:
							</Text>
							<Box borderBottomWidth="1px" p="10px" position="relative">
								{style.bgImage?.path ? (
									<>
										<Image src={style.bgImage.path} />
										<Icon
											as={FaRegTrashAlt}
											color="gray.500"
											cursor="pointer"
											_hover={{ color: 'gray.700' }}
											bg="gray.200"
											pt="4px"
											pb="5px"
											w="25px"
											h="25px"
											position="absolute"
											top="10px"
											right="10px"
											onClick={imageDeleteHandler}
										/>
									</>
								) : null}
								<Dropzone maxFiles={1} onDrop={acceptedFiles => imageHandler(acceptedFiles)}>
									{({getRootProps, getInputProps}) => (
									
										<Box {...getRootProps()} px={4} py={2} borderWidth="1px" borderStyle="dashed">
											<Input {...getInputProps()} />
											<Text textAlign="center" cursor="pointer">Set Image</Text>
										</Box>
								
									)}
								</Dropzone>
							</Box>
							{/* Margin */}
							<Box display="flex" px="10px" mt="5px">
								<Text fontSize=".8rem" fontWeight="600">
									Margin:
								</Text>
								<Spacer />
								<Box
									fontSize=".7rem"
									mr="10px"
									fontWeight="600"
									alignSelf="center"
								>
									{inputLink.margin ? (
										<Badge size="xs" variant="outline" colorScheme="teal">
											Link
										</Badge>
									) : (
										<Badge size="xs" variant="outline" colorScheme="red">
											Unlink
										</Badge>
									)}
								</Box>
								<FormControl width="max-content" alignSelf="center">
									<Switch
										size="sm"
										colorScheme="teal"
										value={inputLink.margin}
										isChecked={inputLink.margin}
										onChange={(e) =>
											setInputLink({ ...inputLink, margin: e.target.checked })
										}
									/>
								</FormControl>
							</Box>
							<Box
								display="flex"
								flexWrap="wrap"
								justifyContent="space-between"
								p="10px"
							>
								<FormControl
									flexBasis="auto"
									width="24.5%"
									key={`MarginTop-${style?.margin?.top}`}
								>
									<FormLabel fontSize=".75rem" mb={0} fontWeight="500">
										Top
									</FormLabel>
									<Stack direction="row">
										<NumberInput
											size="xs"
											defaultValue={style?.margin?.top}
											onChange={(value) => marginHandler(value, 'top')}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</Stack>
								</FormControl>
								<FormControl
									flexBasis="auto"
									width="24.5%"
									key={`MarginRight-${style?.margin?.right}`}
								>
									<FormLabel fontSize=".75rem" mb={0} fontWeight="500">
										Right
									</FormLabel>
									<Stack direction="row">
										<NumberInput
											size="xs"
											defaultValue={style?.margin?.right}
											onChange={(value) => marginHandler(value, 'right')}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</Stack>
								</FormControl>
								<FormControl
									flexBasis="auto"
									width="24.5%"
									key={`MarginBottom-${style?.margin?.bottom}`}
								>
									<FormLabel fontSize=".75rem" mb={0} fontWeight="500">
										Bottom
									</FormLabel>
									<Stack direction="row">
										<NumberInput
											size="xs"
											defaultValue={style?.margin?.bottom}
											onChange={(value) => marginHandler(value, 'bottom')}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</Stack>
								</FormControl>
								<FormControl
									flexBasis="auto"
									width="24.5%"
									key={`MarginLeft-${style?.margin?.left}`}
								>
									<FormLabel fontSize=".75rem" mb={0} fontWeight="500">
										Left
									</FormLabel>
									<Stack direction="row">
										<NumberInput
											size="xs"
											defaultValue={style?.margin?.left}
											onChange={(value) => marginHandler(value, 'left')}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</Stack>
								</FormControl>
							</Box>

							{/* Padding */}
							<Box display="flex" px="10px" mt="5px">
								<Text fontSize=".8rem" fontWeight="600">
									Padding:
								</Text>
								<Spacer />
								<Box
									fontSize=".7rem"
									mr="10px"
									fontWeight="600"
									alignSelf="center"
								>
									{inputLink.padding ? (
										<Badge size="xs" variant="outline" colorScheme="teal">
											Link
										</Badge>
									) : (
										<Badge size="xs" variant="outline" colorScheme="red">
											Unlink
										</Badge>
									)}
								</Box>
								<FormControl width="max-content" alignSelf="center">
									<Switch
										size="sm"
										colorScheme="teal"
										value={inputLink.padding}
										isChecked={inputLink.padding}
										onChange={(e) =>
											setInputLink({ ...inputLink, padding: e.target.checked })
										}
									/>
								</FormControl>
							</Box>
							<Box
								display="flex"
								flexWrap="wrap"
								justifyContent="space-between"
								p="10px"
							>
								<FormControl
									flexBasis="auto"
									width="24.5%"
									key={`PaddingTop-${style?.padding?.top}`}
								>
									<FormLabel fontSize=".75rem" mb={0} fontWeight="500">
										Top
									</FormLabel>
									<Stack direction="row">
										<NumberInput
											size="xs"
											defaultValue={style?.padding?.top}
											onChange={(value) => paddingHandler(value, 'top')}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</Stack>
								</FormControl>
								<FormControl
									flexBasis="auto"
									width="24.5%"
									key={`PaddingRight-${style?.padding?.right}`}
								>
									<FormLabel fontSize=".75rem" mb={0} fontWeight="500">
										Right
									</FormLabel>
									<Stack direction="row">
										<NumberInput
											size="xs"
											defaultValue={style?.padding?.right}
											onChange={(value) => paddingHandler(value, 'right')}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</Stack>
								</FormControl>
								<FormControl
									flexBasis="auto"
									width="24.5%"
									key={`PaddingBottom-${style?.padding?.bottom}`}
								>
									<FormLabel fontSize=".75rem" mb={0} fontWeight="500">
										Bottom
									</FormLabel>
									<Stack direction="row">
										<NumberInput
											size="xs"
											defaultValue={style?.padding?.bottom}
											onChange={(value) => paddingHandler(value, 'bottom')}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</Stack>
								</FormControl>
								<FormControl
									flexBasis="auto"
									width="24.5%"
									key={`PaddingLeft-${style?.padding?.left}`}
								>
									<FormLabel fontSize=".75rem" mb={0} fontWeight="500">
										Left
									</FormLabel>
									<Stack direction="row">
										<NumberInput
											size="xs"
											defaultValue={style?.padding?.left}
											onChange={(value) => paddingHandler(value, 'left')}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
									</Stack>
								</FormControl>
							</Box>

							<Text
								fontSize=".75rem"
								fontWeight="600"
								p="10px"
								borderBottomWidth="1px"
							>
								Background:
							</Text>
							<Box borderBottomWidth="1px" p="10px">
								<Flex mb={2}>
									<Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>
										Color
									</Text>
									<InputGroup flex="1 1" size="xs">
										<Input
											value={style.bgColor}
											onChange={(e) => bgColorHandler(e.target.value)}
										/>
										<InputRightAddon
											id="primaryColor"
											cursor="pointer"
											bg={style.bgColor}
											onClick={onOpen}
										/>
									</InputGroup>
								</Flex>
							</Box>

							<Box borderBottomWidth="1px" p="10px">
								<Flex mb={2}>
									<Text width="40%" fontSize=".8rem" fontWeight="600" mr={2}>
										Column Gap
									</Text>
									<InputGroup size="xs" flex="1 1">
										<NumberInput
											size="xs"
											max={15}
											min={0}
											defaultValue={0}
											value={columnGap}
											onChange={columnGapHandler}
										>
											<NumberInputField />
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
										<InputRightAddon
											id="columnGap"
											cursor="pointer"
											children="px"
										/>
									</InputGroup>
								</Flex>
							</Box>

							<Box p="10px" borderBottomWidth="1px" display="flex">
								<Text fontSize=".8rem" mr={2} fontWeight="600">
									No Wrap
								</Text>
								<Spacer />
								<Checkbox
									size="md"
									value={fullWidth}
									onChange={fullWidthHandler}
									isChecked={fullWidth}
								></Checkbox>
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
			<Modal
				isCentered
				onClose={onClose}
				isOpen={isOpen}
				motionPreset="slideInBottom"
				size="xs"
			>
				<ModalOverlay />
				<ModalContent>
					<SketchPicker
						width="100%"
						color={style.bgColor}
						onChangeComplete={(color) => bgColorHandler(color.hex)}
					/>
				</ModalContent>
			</Modal>

			<Modal
				isCentered
				onClose={onCloseMedia}
				isOpen={isOpenMedia}
				motionPreset="slideInBottom"
				size="xs"
			>
				<ModalOverlay />
				<ModalContent>
					<SketchPicker
						width="100%"
						color={style.bgColor}
						onChangeComplete={(color) => bgColorHandler(color.hex)}
					/>
				</ModalContent>
			</Modal>
		</>
	)
}

export default SectionSetting
