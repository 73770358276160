import { Flex, Box, Center, Text, Link } from '@chakra-ui/react'
import { Link as RRLink } from 'react-router-dom'
import { isPublicDomain } from '../../helpers/utils'

const NotFound = () => {
	return (
		<Flex h="100vh" bg="secondary.900" color="white">
			<Center flex="1">
				<Box>
					<Text fontSize="4xl" d="block">
						404 | Not Found
					</Text>
					{!isPublicDomain() && (
						<Text style={{ display: 'block' }}>
							<Link as={RRLink} to="/">
								Go Back
							</Link>
						</Text>
					)}
				</Box>
			</Center>
		</Flex>
	)
}

export default NotFound
