import {
	Box,
	Button,
	Center,
	Flex,
	Heading,
	Stack,
	Text,
} from '@chakra-ui/react'
import { FiEdit3, FiTrash } from 'react-icons/fi'
import MGPPricingForm from './MGPPricingFrom'

const MGPPricingCard = () => {
	return (
		<Box
			borderWidth={'1px'}
			rounded={10}
			borderStyle={'solid'}
			borderColor={'gray.200'}
		>
			<Box
				textAlign={'center'}
				px={5}
				borderBottomWidth={'1px'}
				borderBottomStyle={'solid'}
				borderBottomColor={'gray.200'}
				py={5}
			>
				<Heading as="h4" mb={2} color={'gray.800'} fontSize={'2rem'}>
					Business Plan
				</Heading>
				<Text mt={2}>Higher Startup, lower fee</Text>
				<Text mt={2}>Best for businesses already make money</Text>
			</Box>
			<Stack p={5} spacing={4}>
				<Flex alignItems={'center'} justifyContent={'space-between'}>
					<Text fontSize={'1.25rem'} color={'gray.700'}>
						Startup:
					</Text>
					<Text
						fontSize={'1.75rem'}
						color={'primary.500'}
						as={'strong'}
						lineHeight={1}
					>
						$999
					</Text>
				</Flex>
				<Flex alignItems={'center'} justifyContent={'space-between'}>
					<Text fontSize={'1.25rem'} color={'gray.700'}>
						Monthly:
					</Text>

					<Box>
						<Text
							fontSize={'1.75rem'}
							color={'primary.500'}
							as={'strong'}
							lineHeight={1}
						>
							$89
						</Text>
					</Box>
				</Flex>
				<Flex alignItems={'center'} justifyContent={'space-between'}>
					<Text fontSize={'1.25rem'} color={'gray.700'}>
						Management Fee:
					</Text>
					<Text
						fontSize={'1.75rem'}
						color={'primary.500'}
						as={'strong'}
						lineHeight={1}
					>
						12%
					</Text>
				</Flex>
				<Center pt={2}>
					<MGPPricingForm
						data={{
							plan_name: 'Business Plan',
							description:
								'Higher Startup, lower fee. Best for businesses already make money',
							startup: 999,
							monthly: 89,
							management_fee: 12,
						}}
					/>
					<Button
						colorScheme={'red'}
						variant={'outline'}
						leftIcon={<FiTrash />}
						ms={2}
						size={'sm'}
					>
						Delete
					</Button>
				</Center>
			</Stack>
		</Box>
	)
}

export default MGPPricingCard
