import { Box } from '@chakra-ui/react'

const EmbedCode = ({ component }) => {
	return (
		<Box
			dangerouslySetInnerHTML={{
				__html: component?.embedCode || 'Embed code',
			}}
		/>
	)
}

export default EmbedCode
