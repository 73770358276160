import { Container, Box, Heading } from '@chakra-ui/react'
import { useSelector } from 'react-redux'

const AccountEndUserProfile = () => {
	const user = useSelector((state) => state.auth.user)
	return (
		<Box pt="30px" pb="30px" bg="primary.100" h="calc(100vh - 65px)">
			<Container maxW="container.lg">
				<Box p="30px" bg="white">
					<Heading as="h4">Hello, {user.name}!!</Heading>
				</Box>
			</Container>
		</Box>
	)
}

export default AccountEndUserProfile
