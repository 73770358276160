import { Global } from '@emotion/react'

const NewFonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Neue Haas Grotesk Display Pro Black';
        font-style: normal;
        font-weight: 900;
        src: url('./app-theme/components/fonts/neue-haas-grotesk-display-pro-cufonfonts/NeueHaasDisplayBlack.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Neue Haas Grotesk Display Pro Black Italic';
        font-style: italic;
        font-weight: 900;
        src: url('./app-theme/components/fonts/neue-haas-grotesk-display-pro-cufonfonts/NeueHaasDisplayBlackItalic.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Neue Haas Grotesk Display Pro Bold';
        font-style: normal;
        font-weight: 700;
        src: url('./app-theme/components/fonts/neue-haas-grotesk-display-pro-cufonfonts/NeueHaasDisplayBold.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Neue Haas Grotesk Display Pro Bold Italic';
        font-style: italic;
        font-weight: 700;
        src: url('./app-theme/components/fonts/neue-haas-grotesk-display-pro-cufonfonts/NeueHaasDisplayBoldItalic.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Neue Haas Grotesk Display Pro Medium';
        font-style: normal;
        font-weight: 500;
        src: url('./app-theme/components/fonts/neue-haas-grotesk-display-pro-cufonfonts/NeueHaasDisplayMedium.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Neue Haas Grotesk Display Pro Medium Italic';
        font-style: italic;
        font-weight: 500;
        src: url('./app-theme/components/fonts/neue-haas-grotesk-display-pro-cufonfonts/NeueHaasDisplayMediumItalic.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Neue Haas Grotesk Display Pro Normal';
        font-style: normal;
        font-weight: 400;
        src: url('./app-theme/components/fonts/neue-haas-grotesk-display-pro-cufonfontsNeueHaasDisplayRoman.ttf') format('truetype');
      }
      @font-face {
        font-family: 'Neue Haas Grotesk Display Pro Normal Italic';
        font-style: italic;
        font-weight: 400;
        src: url('./app-theme/components/fonts/neue-haas-grotesk-display-pro-cufonfonts/NeueHaasDisplayRomanItalic.ttf') format('truetype');
      }
      `}
  />
)

export default NewFonts