import {
	AspectRatio,
	Box,
	Button,
	Container,
	Image,
	Input,
	Stack,
	Text,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { changePageTitle } from '../redux'
import { FiUploadCloud } from 'react-icons/fi'

const AppImages = () => {
	const dispatch = useDispatch()
	const [logo, setLogo] = useState(null)
	const [banner, setBanner] = useState(null)

	useEffect(() => {
		dispatch(changePageTitle('App Logo & Banner'))
	}, [])

	return (
		<Box flex="1" p={['20px 0', '20px 0', '20px', '30px']}>
			<Container
				maxW={['100%', 'container.sm', 'container.md', 'container.lg']}
				mb="6"
			>
				<Box
					bg="white"
					p={['20px', '20px', '25px', '30px']}
					borderRadius="15"
					boxShadow="md"
				>
					<Stack direction={'row'} spacing={6}>
						<Box>
							<Text fontWeight={'semibold'} color={'gray.800'} mb={2}>
								App Logo
							</Text>
							<Box
								w={'200px'}
								bg={'#E9EEF1'}
								p={3}
								pos={'relative'}
								borderWidth={'1px'}
								borderStyle={'solid'}
								borderColor={'gray.200'}
								h={'200px'}
							>
								<Image
									src={logo || '/default_image_sq.png'}
									w={'100%'}
									h={'100%'}
									objectFit={'contain'}
									alt={'Logo'}
								/>
								<Button
									pos={'absolute'}
									bottom={3}
									right={3}
									cursor={'pointer'}
									variant={'primary'}
									size={'sm'}
									leftIcon={<FiUploadCloud />}
									as={'label'}
									for={'appLogo'}
								>
									{logo ? 'Change' : 'Browse'}
								</Button>
								<Input
									id={'appLogo'}
									w={0}
									p={0}
									accept={'image/*'}
									onChange={(e) => {
										setLogo(URL.createObjectURL(e.target.files[0]))
									}}
									border={'none'}
									fontSize={'0'}
									visibility={'hidden'}
									type={'file'}
								/>
							</Box>
						</Box>

						<Box>
							<Text fontWeight={'semibold'} color={'gray.800'} mb={2}>
								App Banner
							</Text>
							<Box
								minW={'400px'}
								bg={'#E9EEF1'}
								p={3}
								pos={'relative'}
								borderWidth={'1px'}
								borderStyle={'solid'}
								borderColor={'gray.200'}
								h={'200px'}
							>
								<Image
									src={banner || '/default_image_sq.png'}
									w={'100%'}
									h={'100%'}
									objectFit={'contain'}
									alt={'Logo'}
								/>
								<Button
									pos={'absolute'}
									bottom={3}
									right={3}
									cursor={'pointer'}
									variant={'primary'}
									size={'sm'}
									leftIcon={<FiUploadCloud />}
									as={'label'}
									for={'appBanner'}
								>
									{logo ? 'Change' : 'Browse'}
								</Button>
								<Input
									id={'appBanner'}
									w={0}
									p={0}
									accept={'image/*'}
									onChange={(e) => {
										setBanner(URL.createObjectURL(e.target.files[0]))
									}}
									border={'none'}
									fontSize={'0'}
									visibility={'hidden'}
									type={'file'}
								/>
							</Box>
						</Box>
					</Stack>
				</Box>
			</Container>
		</Box>
	)
}

export default AppImages
