import { SimpleGrid, Box } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import PricingCard from '../../components/PricingCard'
import { getRequest } from '../../services/base.service'

const Pricing = () => {
	const [pricingList, setPricingList] = useState([])
	const [stripeStatus, setStripeStatus] = useState(false)

	const getStripeStatus = () => {
		getRequest('stripe-status').then((res) => {
			const { status } = res.result
			setStripeStatus(status)
		})
	}

	useEffect(() => {
		getStripeStatus()

		getPricingList(
			`?sort=id,desc&?select=id,pricing_type,price,trial_day,annual_saving`
		)
	}, [])

	const getPricingList = (queryParams = '') => {
		getRequest(`pricing${queryParams}`)
		.then((res) => {
			if (res.result.length === 0) {
				setPricingList([
					{
						created_by: 1,
						id: 1,
						price: process.env.REACT_APP_DEFAULT_PRICE_YEAR,
						pricing_type: "year",
						stripe_link_url: "http://9391-74-101-16-56.ngrok.io/975/websignup/form?trl=0&trlid=156&pid=price_1LLEMw2QNmFPFj4PxEcBOHJK",
						stripe_price_id: "price_1",
						title: "Monthly",
						trial_day: 0
					},
					{
						created_by: 1,
						id: 2,
						price: process.env.REACT_APP_DEFAULT_PRICE_MONTH,
						pricing_type: "month",
						stripe_link_url: "http://9391-74-101-16-56.ngrok.io/975/websignup/form?trl=0&trlid=156&pid=price_1LLEMw2QNmFPFj4PxEcBOHJK",
						stripe_price_id: "price_2",
						title: "Monthly",
						trial_day: 0
					},
				])
			}
			else {
				setPricingList(res.result)
				console.log(res.result)
			}
		})

		.catch(error => {
			setPricingList([
				{
					created_by: 1,
					id: 1,
					price: process.env.REACT_APP_DEFAULT_PRICE_YEAR,
					pricing_type: "year",
					stripe_link_url: "http://9391-74-101-16-56.ngrok.io/975/websignup/form?trl=0&trlid=156&pid=price_1LLEMw2QNmFPFj4PxEcBOHJK",
					stripe_price_id: "price_1",
					title: "Monthly",
					trial_day: 0
				},
				{
					created_by: 1,
					id: 2,
					price: process.env.REACT_APP_DEFAULT_PRICE_MONTH,
					pricing_type: "month",
					stripe_link_url: "http://9391-74-101-16-56.ngrok.io/975/websignup/form?trl=0&trlid=156&pid=price_1LLEMw2QNmFPFj4PxEcBOHJK",
					stripe_price_id: "price_2",
					title: "Monthly",
					trial_day: 0
				},
			])
		})

	}

	return (
		<SimpleGrid columns="1" spacing="20px">
			{pricingList.map((price, index) => (
				<PricingCard updateList={getPricingList} data={price} status={stripeStatus} />
			))}
		</SimpleGrid>
	)
}

export default Pricing
