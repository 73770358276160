import { useState, useEffect } from 'react'
import { Flex, Center, Text, Box, Link, Image, Stack,
	useToast,
} from '@chakra-ui/react'
import { Link as RRLink, useNavigate, useLocation } from 'react-router-dom'
import { FiUsers, FiLogOut } from 'react-icons/fi'
import { HiOutlineCog } from 'react-icons/hi'
import { ImNewspaper } from 'react-icons/im'
import { IoPeopleCircleOutline, IoHammerOutline } from 'react-icons/io5'
import { TbDeviceAnalytics } from 'react-icons/tb'
import { connect, useSelector } from 'react-redux'
import { logout } from '../redux'
import { toastConfig } from '../helpers/appConfig'

const LeftSidebar = ({ auth, bigWindow, dashClicked, welcome, comingSoon, handleLogout }) => {
	const [newsSelected, setNewsSelected] = useState(false)
	const [dashboardSelected, setDashboardSelected] = useState(false)
	const [builderSelected, setBuilderSelected] = useState(false)
	const [customersSelected, setCustomersSelected] = useState(false)
	const [profileSelected, setProfileSelected] = useState(false)
	const [trainersSelected, setTrainersSelected] = useState(false)
	const [checkboxSelected, setCheckBoxSelected] = useState(false)
	
	const toast = useToast()
	const navigate = useNavigate()
	const location = useLocation()
	const { user } = useSelector((state) => state.auth)
	
	function handleExitUser() {
		const backUp = JSON.parse(localStorage.getItem('backUp'))
		localStorage.setItem('user', JSON.stringify(backUp))
		localStorage.removeItem('backUp')
		window.location.href = '/admin/trainers'
	}

	useEffect(() => {
		setBuilderSelected(false)
		setTrainersSelected(false)
		setProfileSelected(false)
		setNewsSelected(false)
		setDashboardSelected(false)
		setCustomersSelected(false)
		setCheckBoxSelected(false)

		if (location.pathname === '/admin/trainers') {
			setTrainersSelected(true)
		}
		else if (location.pathname === '/admin/dashboard' || location.pathname === '/trainer/dashboard') {
			setDashboardSelected(true)
		}
		else if (location.pathname === '/trainer/page-builder' || location.pathname === '/trainer/webapp-builder' || location.pathname === '/trainer/app-accounts') {
			setBuilderSelected(true)
		}
		else if (location.pathname === '/trainer/account/profile') {
			setProfileSelected(true)
		}
		else if (location.pathname === '/trainer/news') {
			setNewsSelected(true)
		}
		else if (location.pathname === '/trainer/customers') {
			setCustomersSelected(true)
		}
		else if (location.pathname === '/trainer/checklist') {
			setCheckBoxSelected(true)
		}
	}, [location.pathname])

	return (
		<>
		{bigWindow ? (
			<Box
				w={['50px', '80px', '80px']}
				color="white"
				bg="primary.500"
				pos="fixed"
				left="0"
				top={auth.user.role === "admin" && bigWindow && '60px'}
				zIndex='0'
			>
				<Flex h="100vh" direction="column" zIndex={1}>
					<Stack mt={`10px`} spacing={'20px'}>
						{auth?.user?.role !== 'admin' && (
							<>
								{auth.user.role === 'trainer' && (
									<>
									<Flex 
										justifyContent='center'
										py={2}
										bg={builderSelected ? '#5a55ed' : ''}
										borderLeft={builderSelected ? 'white solid 0.215em' : ''}
									>
										<Link
											_hover={{
												'& > .toolTipText': {
													transform: 'translateY(-50%) scaleX(1)',
													marginLeft: '28px',
												},
											}}
											_focus={{
												outline: 'none'
											}}
											pos={'relative'}
											display="block"
											fontSize="2xl"
											as={RRLink}
											to={`/trainer/webapp-builder`}
										>
											<IoHammerOutline />
											<Text
												className="toolTipText"
												pos={'absolute'}
												top={'50%'}
												left={'100%'}
												transformOrigin={'0 0'}
												transform={'translateY(-50%) scaleX(0)'}
												marginLeft={'28px'}
												transition={'transform ease-in-out .15s'}
												ps={3}
												pe={3}
												pt={2}
												pb={2}
												whiteSpace={'nowrap'}
												color="primary.500"
												bg={'white'}
												fontSize={'14px'}
												letterSpacing={'0.08rem'}
												textTransform={'uppercase'}
												boxShadow={'md'}
												rounded={5}
												zIndex='99'
											>
												Build
											</Text>
										</Link>
									</Flex>
									
									<Flex 
										justifyContent='center'
										py={2}
										bg={customersSelected ? '#5a55ed' : ''}
										borderLeft={customersSelected ? 'white solid 0.215em' : ''}
									>
										<Link
											_hover={{
												'& > .toolTipText': {
													transform: 'translateY(-50%) scaleX(1)',
													marginLeft: '28px',
												},
											}}
											_focus={{
												outline: 'none'
											}}
											pos={'relative'}
											display="block"
											fontSize="2xl"
											as={RRLink}
											to={`/trainer/customers`}
										>
											<IoPeopleCircleOutline />
											<Text
												className="toolTipText"
												pos={'absolute'}
												top={'50%'}
												left={'100%'}
												transformOrigin={'0 0'}
												transform={'translateY(-50%) scaleX(0)'}
												marginLeft={'28px'}
												transition={'transform ease-in-out .15s'}
												ps={3}
												pe={3}
												pt={2}
												pb={2}
												whiteSpace={'nowrap'}
												color="primary.500"
												bg={'white'}
												fontSize={'14px'}
												letterSpacing={'0.08rem'}
												textTransform={'uppercase'}
												boxShadow={'md'}
												rounded={5}
												zIndex='99'
											>
												Customers
											</Text>
										</Link>
									</Flex>
									</>
								)}
								<Flex 
									justifyContent='center'
									py={2}
									bg={newsSelected ? '#5a55ed' : ''}
									borderLeft={newsSelected ? 'white solid 0.215em' : ''}
								>
									<Link
										pos={'relative'}
										_hover={{
											'& > .toolTipText': {
												transform: 'translateY(-50%) scaleX(1)',
												marginLeft: '28px',
											},
										}}
										_focus={{
											outline: 'none'
										}}
										display="block"
										fontSize="2xl"
										as={RRLink}
										to={`/trainer/news`}
									>
										<ImNewspaper />
										<Text
											className="toolTipText"
											pos={'absolute'}
											top={'50%'}
											left={'100%'}
											transformOrigin={'0 0'}
											transform={'translateY(-50%) scaleX(0)'}
											marginLeft={'28px'}
											transition={'transform ease-in-out .15s'}
											ps={3}
											pe={3}
											pt={2}
											pb={2}
											whiteSpace={'nowrap'}
											color="primary.500"
											bg={'white'}
											fontSize={'14px'}
											letterSpacing={'0.08rem'}
											textTransform={'uppercase'}
											boxShadow={'md'}
											rounded={5}
										>
											News &amp; Events
										</Text>
									</Link>
								</Flex>
							</>
						)}

						{/*<Flex 
							justifyContent='center'
							py={2}
							bg={dashboardSelected ? '#5a55ed' : ''}
							borderLeft={dashboardSelected ? 'white solid 0.215em' : ''}
						>
							<Link
								pos={'relative'}
								_hover={{
									'& > .toolTipText': {
										transform: 'translateY(-50%) scaleX(1)',
										marginLeft: '28px',
									},
								}}
								_focus={{
									outline: 'none'
								}}
								display="block"
								fontSize="2xl"
								as={RRLink}
								to={
									auth.user.role === 'admin'
										? `/admin/dashboard`
										: `#`
								}
							>
								<TbDeviceAnalytics />
								{auth.user.role === 'trainer' ? (
									<Box
										as='button'
										className="toolTipText"
										pos={'absolute'}
										top={'50%'}
										left={'120%'}
										transformOrigin={'0 0'}
										transform={'translateY(-50%) scaleX(0)'}
										marginLeft={'28px'}
										transition={'transform ease-in-out .15s'}
										p={4}
										color='white'
										bg='primary.500'
										fontSize={'12px'}
										letterSpacing={'0.08rem'}
										boxShadow={'md'}
										rounded={5}
										w='1200%'
										display='flex'
										justifyContent='center'
										onClick={() => window.location.href = 'https://dashboard.stripe.com'}
									>
										<Text>Go to <span><u>Stripe.com</u></span> to view your sales</Text>
									</Box>
								) : (
									<Text
										className="toolTipText"
										pos={'absolute'}
										top={'50%'}
										left={'100%'}
										transformOrigin={'0 0'}
										transform={'translateY(-50%) scaleX(0)'}
										marginLeft={'28px'}
										transition={'transform ease-in-out .15s'}
										ps={3}
										pe={3}
										pt={2}
										pb={2}
										color="primary.500"
										bg={'white'}
										fontSize={'14px'}
										letterSpacing={'0.08rem'}
										textTransform={'uppercase'}
										boxShadow={'md'}
										rounded={5}
									>
										Dashboard
									</Text>
								)}
								
							</Link>
						</Flex>*/}
						{auth?.user?.role === 'admin' && (
							<Flex 
								justifyContent='center'
								py={2}
								bg={trainersSelected ? '#5a55ed' : ''}
								borderLeft={trainersSelected ? 'white solid 0.215em' : ''}
							>
								<Link
									pos={'relative'}
									_hover={{
										'& > .toolTipText': {
											transform: 'translateY(-50%) scaleX(1)',
											marginLeft: '28px',
										},
									}}
									_focus={{
										outline: 'none'
									}}
									display="block"
									fontSize="2xl"
									as={RRLink}
									to={`/admin/trainers`}
								>
									<FiUsers />
									<Text
										className="toolTipText"
										pos={'absolute'}
										top={'50%'}
										left={'100%'}
										transformOrigin={'0 0'}
										transform={'translateY(-50%) scaleX(0)'}
										marginLeft={'28px'}
										transition={'transform ease-in-out .15s'}
										ps={3}
										pe={3}
										pt={2}
										pb={2}
										color="primary.500"
										bg={'white'}
										fontSize={'14px'}
										letterSpacing={'0.08rem'}
										textTransform={'uppercase'}
										boxShadow={'md'}
										rounded={5}
									>
										Trainers
									</Text>
								</Link>
							</Flex>
						)
						}
					</Stack>
				</Flex>
			</Box>
		) : (
			<Box
				w='100%'
				h='64px'
				color="white"
				bg="primary.500"
				pos="fixed"
				bottom='0'
				zIndex={welcome || comingSoon ? '-1' : '9999'}
			>
				<Flex 
					justifyContent={auth?.user?.role !== 'admin' ? 'space-between' : 'space-evenly'} 
					px={4} 
					w='100%' 
					h='100%'
				>
					{auth?.user?.role !== 'admin' && (
						<Link
							h='100%'
							px={2}
							pos={'relative'}
							display='flex'
							alignItems='center'
							fontSize="2em"
							bg={newsSelected ? '#5a55ed' : ''}
							borderBottom={newsSelected ? 'white solid 0.115em' : ''}
							as={RRLink}
							to={`/trainer/news`}
							_focus={{
								outline: 'none',
								bg: '#5a55ed'
							}}
						>
							<ImNewspaper />
						</Link>
					)}

					{auth.user.role === 'trainer' && (
						<>
							<Link
								h='100%'
								px={2}
								pos={'relative'}
								display='flex'
								alignItems='center'
								fontSize="2em"
								bg={builderSelected ? '#5a55ed' : ''}
								borderBottom={builderSelected ? 'white solid 0.115em' : ''}
								as={RRLink}
								to={`/trainer/page-builder`}
								_focus={{
									outline: 'none',
									bg: '#5a55ed'
								}}
							>
								<IoHammerOutline />
							</Link>

							<Link
								h='100%'
								px={2}
								pos={'relative'}
								display='flex'
								alignItems='center'
								fontSize="2em"
								bg={customersSelected ? '#5a55ed' : ''}
								borderBottom={customersSelected ? 'white solid 0.115em' : ''}
								as={RRLink}
								to={`/trainer/customers`}
								_focus={{
									outline: 'none',
									bg: '#5a55ed'
								}}
							>
								<IoPeopleCircleOutline />
							</Link>

							<Link
								h='100%'
								px={2}
								pos={'relative'}
								display='flex'
								alignItems='center'
								fontSize="2em"
								bg={checkboxSelected ? '#5a55ed' : ''}
								borderBottom={checkboxSelected ? 'white solid 0.115em' : ''}
								as={RRLink}
								to={`/trainer/checklist`}
								_focus={{
									outline: 'none',
									bg: '#5a55ed'
								}}
							>
								<img src='/images/clipboard.png' width={32} alt='clipboard'/>
							</Link>

							<Link
								h='100%'
								px={2}
								pos={'relative'}
								display='flex'
								alignItems='center'
								fontSize="2em"
								bg={profileSelected ? '#5a55ed' : ''}
								borderBottom={profileSelected ? 'white solid 0.115em' : ''}
								as={RRLink}
								to={`/trainer/account/profile`}
								_focus={{
									outline: 'none',
									bg: '#5a55ed'
								}}
							>
								<HiOutlineCog />
							</Link>
						</>
					)}

					{auth.user.role === 'admin' && (
						<>
							<Link
								h='100%'
								px={2}
								pos={'relative'}
								display='flex'
								alignItems='center'
								fontSize="2em"
								bg={trainersSelected ? '#5a55ed' : ''}
								borderBottom={trainersSelected ? 'white solid 0.115em' : ''}
								as={RRLink}
								to={`/admin/trainers`}
								_focus={{
									outline: 'none',
									bg: '#5a55ed'
								}}
							>
								<FiUsers />
							</Link>

							<Link
								h='100%'
								px={2}
								pos={'relative'}
								display='flex'
								alignItems='center'
								fontSize="2em"
								bg={dashboardSelected ? '#5a55ed' : ''}
								borderBottom={dashboardSelected ? 'white solid 0.115em' : ''}
								as={RRLink}
								to={'/admin/dashboard'}
								_focus={{
									outline: 'none',
									bg: '#5a55ed'
								}}
								>
								<TbDeviceAnalytics />
							</Link>

							{/*<Link
								h='100%'
								px={2}
								pos={'relative'}
								display='flex'
								alignItems='center'
								fontSize="2em"
								as={RRLink}
								to={`/admin/subscribers`}
								_focus={{
								outline: 'none',
								bg: '#5a55ed'
							}}
							>
								<AiOutlineUsergroupAdd />
							</Link>*/}

							<Text
								h='100%'
								px={2}
								pos={'relative'}
								display='flex'
								alignItems='center'
								fontSize="2em"
								cursor="pointer"
								onClick={() =>
									user?.admin
										? handleExitUser()
										: handleLogout((message, status) => {
												toast(toastConfig(message, status))
												navigate('/')
										})
								}
								as={'span'}
								_focus={{
									outline: 'none',
									bg: '#5a55ed'
								}}
							>
								<FiLogOut />
							</Text>
						</>
					)} 
				</Flex>
			</Box>
		)}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		handleLogout: (callback) => dispatch(logout(callback)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar)
