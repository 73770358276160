import { useState, useEffect } from 'react'
import { Box, Container, Flex, Grid, Heading, Text, 
	Button, useDisclosure
} from '@chakra-ui/react'
import { changePageTitle } from '../redux'
import { useDispatch } from 'react-redux'
import OverviewChart from '../components/OverviewChart'
import CardInfo from '../components/CardInfo'
import { getRequest } from '../services/base.service'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import { GoCalendar } from 'react-icons/go'
import StatsCard from '../components/StatsCard'
import { BsArrowRight } from 'react-icons/bs'
import Checklist from '../components/Checklist'
import { Link, useSearchParams } from 'react-router-dom'
import TrainersTable from '../components/TrainersTable'
import ComingSoonModal from '../components/ComingSoonModal'

const Dashboard = () => {
	const [daysChoice, setDaysChoice] = useState('30_DAYS')
	const [isShowingData, setIsShowingData] = useState('USER')
	const [trainerLimit] = useState(10)
	const [isLoadingStats, setIsLoadingStats] = useState(true)
	const [bigWindow, setBigWindow] = useState(true)
	const [newUsersData, setNewUsersData] = useState([])
	const [newSubscribersData, setNewSubscribersData] = useState([])
	const [revenueData, setRevenueData] = useState([])
	const {isOpen, onOpen, onClose} = useDisclosure()

	const [totalUserRevenue, setTotalUserRevenue] = useState({
		total_user: 0,
		total_revenue: 0,
		total_trainee: 0,
		total_trainer: 0,
		total_trainee: 0,
	})
	const [subscriberOverview, setSubscriberOverview] = useState({
		total_subscriber: 0,
		active_subscriber: 0,
		expired_subscriber: 0,
	})

	const [dashboardStats, setDashboardStats] = useState(null)

	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)

	const dispatch = useDispatch()

	useEffect(() => {
		if (daysChoice !== 'CUSTOM_DATE_RANGE') {
			setStartDate(null)
			setEndDate(null)
		}
	}, [daysChoice])

	useEffect(() => {
		if (window.innerWidth < 600) {
            setBigWindow(false)
        }

		dispatch(changePageTitle(bigWindow ? 'Dashboard' : 'Earnings'))
	}, [])

	const user = JSON.parse(localStorage.getItem('user'))

	const fetchDashboardData = (start = startDate, end = endDate) => {
		let api = ''
		if (daysChoice === '30_DAYS' && isShowingData === 'SUBSCRIBER') {
			api = `admin/dashboard/new-registered-trainee`
		} else if (daysChoice === '7_DAYS' && isShowingData === 'SUBSCRIBER') {
			api = `admin/dashboard/new-registered-trainee?days=7`
		} else if (daysChoice === '1_DAY' && isShowingData === 'SUBSCRIBER') {
			api = `admin/dashboard/new-registered-trainee?days=1` 
		} else if (
			daysChoice === 'CUSTOM_DATE_RANGE' &&
			isShowingData === 'SUBSCRIBER'
		) {
			api = `admin/dashboard/new-registered-trainee?start_date=${start}&end_date=${end}`
		} else if (daysChoice === '30_DAYS' && isShowingData === 'USER') {
			if (user.role === 'admin') {
				api = `admin/dashboard/new-registered-trainer`
			} else {
				api = `dashboard/user/new-register`
			}
		} else if (daysChoice === '7_DAYS' && isShowingData === 'USER') {
			if (user.role === 'admin') {
				api = `admin/dashboard/new-registered-trainer?days=7`
			} else {
				api = `dashboard/user/new-register?days=7`
			}
		} else if (daysChoice === '1_DAY' && isShowingData === 'USER') {
			if (user.role === 'admin') {
				api = `admin/dashboard/new-registered-trainer?days=1`
			} else {
				api = `dashboard/user/new-register?days=1`
			}
		} else if (daysChoice === 'CUSTOM_DATE_RANGE' && isShowingData === 'USER') {
			if (user.role === 'admin') {
				api = `admin/dashboard/new-registered-trainer?start_date=${start}&end_date=${end}`
			} else {
				api = `dashboard/user/new-register?start_date=${start}&end_date=${end}`
			}
		} else if (daysChoice === '30_DAYS' && isShowingData === 'REVENUE') {
			if (user.role === 'admin') {
				api = `admin/dashboard/revenue`
			} else {
				api = `dashboard/user/revenue`
			}
		} else if (daysChoice === '7_DAYS' && isShowingData === 'REVENUE') {
			if (user.role === 'admin') {
				api = `admin/dashboard/revenue?days=7`
			} else {
				api = `dashboard/user/revenue?days=7`
			}
		} else if (daysChoice === '1_DAY' && isShowingData === 'REVENUE') {
			if (user.role === 'admin') {
				api = `admin/dashboard/revenue?days=1`
			} else {
				api = `dashboard/user/revenue?days=1`
			}
		} else if (
			daysChoice === 'CUSTOM_DATE_RANGE' &&
			isShowingData === 'REVENUE'
		) {
			if (user.role === 'admin') {
				api = `admin/dashboard/revenue?start_date=${start}&end_date=${end}`
			} else {
				api = `dashboard/user/revenue?start_date=${start}&end_date=${end}`
			}
		}

		if (daysChoice === 'CUSTOM_DATE_RANGE') {
			fetchDashboardStats({ start_date: start, end_date: end })
		} else if (daysChoice === '7_DAYS') {
			fetchDashboardStats({ days: 7 })
		} else if (daysChoice === '1_DAY') {
			fetchDashboardStats({ days: 1 })
		} else {
			fetchDashboardStats()
		}

		getRequest(api)
			.then((res) => {
				if (isShowingData === 'USER') {
					setNewUsersData(res.result)
				} else if (isShowingData === 'SUBSCRIBER') {
					setNewSubscribersData(res.result)
				} else {
					setRevenueData(res.result)
				}
			})
			.catch((err) => console.log(err))
	}

	const fetchRevenueData = (start = startDate, end = endDate) => {
		let api = 'dashboard/user/total-user-revenue'
		if (user.role === 'admin') {
			api = 'admin/dashboard/total-user-revenue'
		}

		if (daysChoice === '7_DAYS') {
			api = 'dashboard/user/total-user-revenue?days=7'
			if (user.role === 'admin') {
				api = 'admin/dashboard/total-user-revenue?days=7'
			}
		} else if (daysChoice === '1_DAY') {
			api = 'dashboard/user/total-user-revenue?days=1'
			if (user.role === 'admin') {
				api = 'admin/dashboard/total-user-revenue?days=1'
			}
		} else if (daysChoice === 'CUSTOM_DATE_RANGE') {
			api = `dashboard/user/total-user-revenue?start_date=${start}&end_date=${end}`
			if (user.role === 'admin') {
				api = `admin/dashboard/total-user-revenue?start_date=${start}&end_date=${end}`
			}
		}

		getRequest(api)
			.then((res) => {
				setTotalUserRevenue(res?.result)
			})
			.catch((err) => console.log(err))
	}

	const fetchTotalSubscriber = (start = startDate, end = endDate) => {
		let api = 'dashboard/user/total-subscriber'
		if (user.role === 'admin') {
			api = 'admin/dashboard/total-subscriber'
		}

		if (daysChoice === '7_DAYS') {
			api = 'dashboard/user/total-subscriber?days=7'
			if (user.role === 'admin') {
				api = 'admin/dashboard/total-subscriber?days=7'
			}
		} else if (daysChoice === '1_DAY') {
			api = 'dashboard/user/total-subscriber?days=1'
			if (user.role === 'admin') {
				api = 'admin/dashboard/total-subscriber?days=1'
			}
		} else if (daysChoice === 'CUSTOM_DATE_RANGE') {
			api = `dashboard/user/total-subscriber?start_date=${start}&end_date=${end}`
			if (user.role === 'admin') {
				api = `admin/dashboard/total-subscriber?start_date=${start}&end_date=${end}`
			}
		}

		getRequest(api)
			.then((res) => {
				setSubscriberOverview(res?.result)
			})
			.catch((err) => console.log(err))
	}

	const fetchDashboardStats = (params = {}) => {
		if (user.role !== 'admin') return
		getRequest(`admin/dashboard-stats`, {
			params: { ...params, limit: trainerLimit },
		})
			.then((res) => {
				setDashboardStats(res.result)
				setIsLoadingStats(false)
			})
			.catch((error) => {
				console.error(error)
				setIsLoadingStats(false)
			})
	}

	useEffect(() => {
		fetchDashboardData()
	}, [daysChoice, isShowingData])

	useEffect(() => {
		fetchRevenueData()
	}, [daysChoice])

	useEffect(() => {
		fetchTotalSubscriber()
	}, [daysChoice])

	useEffect(() => {
		fetchDashboardStats()
	}, [])

	console.log([totalUserRevenue, revenueData])

	return (
		<Box flex="1" pb="30px" overflowY={'auto'}>
			<Box
				p={['20px 0', '20px 0', '20px', '30px']}
				pb={!bigWindow && '120px'}
				bg={['','','white','white','white','white']}
				maxW="100%"
				mb="30px"
			>
				<Container
					maxW={['100%', 'container.sm', 'container.md', 'container.lg']}
				>
					<Box
						display={['block', 'block', 'flex', 'flex']}
						justifyContent="space-between"
						alignItems="center"
					>
						{bigWindow ? (
							<Flex justifyContent={'center'} mb={[3, 3, 0]}>
								<Text
									as="button"
									fontWeight={daysChoice === '30_DAYS' ? 'semibold' : 'normal'}
									color={daysChoice === '30_DAYS' && 'primary.500'}
									me="4"
									fontSize="sm"
									letterSpacing=".03rem"
									textTransform="uppercase"
									onClick={() => setDaysChoice('30_DAYS')}
								>
									Last 30 days
								</Text>
								<Text
									as="button"
									me="4"
									fontWeight={daysChoice === '7_DAYS' ? 'semibold' : 'normal'}
									color={daysChoice === '7_DAYS' && 'primary.500'}
									letterSpacing=".03rem"
									fontSize="sm"
									textTransform="uppercase"
									onClick={() => setDaysChoice('7_DAYS')}
								>
									Last 7 days
								</Text>

								<DateRangePicker
									onCallback={(start, end) => {
										setStartDate(start.format('YYYY-MM-DD'))
										setEndDate(end.format('YYYY-MM-DD'))
										if (daysChoice !== 'CUSTOM_DATE_RANGE') {
											setDaysChoice('CUSTOM_DATE_RANGE')
										} else {
											fetchDashboardData(
												start.format('YYYY-MM-DD'),
												end.format('YYYY-MM-DD')
											)
											fetchRevenueData(
												start.format('YYYY-MM-DD'),
												end.format('YYYY-MM-DD')
											)
											fetchTotalSubscriber(
												start.format('YYYY-MM-DD'),
												end.format('YYYY-MM-DD')
											)
										}
									}}
									initialSettings={{
										startDate: new Date(),
										endDate: new Date(),
									}}
								>
									<Text
										fontWeight={
											daysChoice === 'CUSTOM_DATE_RANGE' ? 'semibold' : 'normal'
										}
										color={daysChoice === 'CUSTOM_DATE_RANGE' && 'primary.500'}
										letterSpacing=".03rem"
										fontSize="sm"
										textTransform="uppercase"
										as="button"
										display={'flex'}
										alignItems={'center'}
									>
										<span me="3">
											<GoCalendar />
										</span>
										&nbsp; &nbsp;
										<span>
											{startDate && endDate
												? `${startDate} ~ ${endDate}`
												: 'Select date range'}
										</span>
									</Text>
								</DateRangePicker>
							</Flex>
						) : (
							<Flex justifyContent={'center'} mb={[3, 3, 0]} w='100%'>
								<Text
									as="button"
									w='25%'
									me="2"
									color={daysChoice === '1_DAY' ? 'white' : 'black'}
									letterSpacing=".03rem"
									fontSize="sm"
									bg={daysChoice === '1_DAY' ? 'primary.500' : 'white'}
									rounded='md'
									boxShadow={daysChoice === '1_DAY' ? 'xs' : 'md'}
									p={1}
									onClick={() => setDaysChoice('1_DAY')}
								>
									Today
								</Text>

								<Text
									as="button"
									w='25%'
									me="2"
									color={daysChoice === '7_DAYS' ? 'white' : 'black'}
									letterSpacing=".03rem"
									fontSize="sm"
									bg={daysChoice === '7_DAYS' ? 'primary.500' : 'white'}
									rounded='md'
									boxShadow={daysChoice === '7_DAYS' ? 'xs' : 'md'}
									p={1}
									onClick={() => setDaysChoice('7_DAYS')}
								>
									Last 7 days
								</Text>

								<Text
									as="button"
									w='25%'
									color={daysChoice === '30_DAYS' ? 'white' : 'black'}
									me="2"
									fontSize="sm"
									letterSpacing=".03rem"
									bg={daysChoice === '30_DAYS' ? 'primary.500' : 'white'}
									rounded='md'
									boxShadow={daysChoice === '30_DAYS' ? 'xs' : 'md'}
									p={1}
									onClick={() => setDaysChoice('30_DAYS')}
								>
									Last 30 days
								</Text>

								<DateRangePicker
									onCallback={(start, end) => {
										setStartDate(start.format('YYYY-MM-DD'))
										setEndDate(end.format('YYYY-MM-DD'))
										if (daysChoice !== 'CUSTOM_DATE_RANGE') {
											setDaysChoice('CUSTOM_DATE_RANGE')
										} else {
											fetchDashboardData(
												start.format('YYYY-MM-DD'),
												end.format('YYYY-MM-DD')
											)
											fetchRevenueData(
												start.format('YYYY-MM-DD'),
												end.format('YYYY-MM-DD')
											)
											fetchTotalSubscriber(
												start.format('YYYY-MM-DD'),
												end.format('YYYY-MM-DD')
											)
										}
									}}
									initialSettings={{
										startDate: new Date(),
										endDate: new Date(),
									}}
								>
									<Text
										color='black'
										w='25%'
										letterSpacing=".03rem"
										fontSize="sm"
										as="button"
										display={'flex'}
										alignItems={'center'}
										bg='white'
										rounded='md'
										boxShadow='md'
										p={1}
									>
										{startDate && endDate
											? `${startDate} ~ ${endDate}`
											: 'Custom date range'
										}
									</Text>
								</DateRangePicker>
							</Flex>
						)}
						
						{bigWindow && 
							<Flex>
								<Box
									w={['80px', '80px', '120px', '160px']}
									textAlign="left"
									as="button"
									border={`1px`}
									ps="4"
									pe="4"
									pt="2"
									pb="2"
									mr={[1, 2]}
									rounded="5"
									onClick={() => setIsShowingData('USER')}
									borderColor={
										isShowingData === 'USER' ? 'primary.500' : 'secondary.200'
									}
									bg={isShowingData === 'USER' ? 'primary.500' : 'white'}
									color={isShowingData === 'USER' ? 'primary.100' : ''}
									flex="1"
								>
									<Text
										textTransform="uppercase"
										fontSize="xs"
										mb="1"
										opacity=".8"
									>
										{user?.role === 'admin' ? 'New Trainer' : 'New Subscribers'}
									</Text>
									<Text fontWeight="semibold">
										{user?.role === 'admin'
											? totalUserRevenue?.total_trainer
											: totalUserRevenue?.total_user}
									</Text>
								</Box>

								<Box
									w={['80px', '80px', '120px', '160px']}
									textAlign="left"
									as="button"
									ms="3"
									border={`1px`}
									ps="4"
									pe="4"
									pt="2"
									pb="2"
									onClick={() => setIsShowingData('REVENUE')}
									borderColor={
										isShowingData === 'REVENUE' ? 'primary.500' : 'secondary.200'
									}
									bg={isShowingData === 'REVENUE' ? 'primary.500' : 'white'}
									color={isShowingData === 'REVENUE' ? 'primary.100' : ''}
									rounded="5"
									flex="1"
								>
									<Text
										textTransform="uppercase"
										opacity=".8"
										fontSize="xs"
										mb="1"
									>
										Revenue
									</Text>
									<Text fontWeight="semibold">
										${totalUserRevenue?.total_revenue?.toFixed(2)}
									</Text>
								</Box>
							</Flex>
						}
						
					</Box>

					{bigWindow ? (
						<Box h="250px" mt="30px">
							<OverviewChart
								bigWindow={bigWindow}
								dataKey={
									isShowingData === 'REVENUE'
										? 'Revenue'
										: isShowingData === 'USER'
										? user.role === 'admin'
											? 'New Trainer'
											: 'New User'
										: 'New Subscriber'
								}
								data={
									isShowingData === 'REVENUE'
										? revenueData
										: isShowingData === 'USER'
										? newUsersData
										: newSubscribersData
								}
							/>
						</Box>
					) : (
						<Box px={4} mt={['30px','60px','30px']}>
							<Box 
								bg={['white','','']}
								rounded='md'
								boxShadow='md'
								my={4}
								px={2}
								py={4}
							>
								<Flex
									justifyContent='space-between'
								>
									<Box>
										<Flex>
											<Text 
												fontSize='sm'
												fontWeight='bold'
												mr='5px'
											>
												Gross volume
											</Text>
											<Box 
												bg='green.100' 
												color='green.600' 
												fontSize='xs'
												px={1}
												py={0}
												rounded='md'
											>
												+0%
											</Box>
										</Flex>
										<Text fontSize='lg'>
											${totalUserRevenue?.total_revenue.toFixed(2)}
										</Text>
									</Box>
									<Text>$0.00</Text>
								</Flex>
								<Box h='150px'>
									<OverviewChart
										bigWindow={bigWindow}
										dataKey={
											isShowingData === 'REVENUE'
												? 'Revenue'
												: isShowingData === 'USER'
												? user.role === 'admin'
													? 'New Trainer'
													: 'New User'
												: 'New Subscriber'
										}
										data={
											isShowingData === 'REVENUE'
												? revenueData
												: isShowingData === 'USER'
												? newUsersData
												: newSubscribersData
										}
									/>
								</Box>
							</Box>

							<Box 
								as='button'
								h='20%'
								w='100%'
								bg={['white','','']}
								rounded='md'
								boxShadow='md'
								my={2}
								px={2}
								py={4}
								onClick={onOpen}
							>
								<Flex justifyContent='center' fontSize='3xl' mt={4} color='black'>
									${user.role === 'admin' 
										? dashboardStats?.total_revenue?.toFixed(2)
										: totalUserRevenue?.total_revenue.toFixed(2)
									}
								</Flex>
								<Text textAlign='center'>Revenue</Text>
							</Box>

							<Box 
								as='button'
								h='20%'
								w='100%'
								bg={['white','','']}
								rounded='md'
								boxShadow='md'
								my={2}
								px={2}
								py={4}
								onClick={onOpen}
							>
								<Flex justifyContent='center' fontSize='3xl' mt={4} color='black'>
									{user.role === 'admin' 
										? totalUserRevenue?.total_trainer
										: totalUserRevenue?.total_user ?? 0
									}
								</Flex>
								<Text textAlign='center'>
									{user.role === 'admin' 
										? 'New Trainers'
										: 'New Subscribers'
									}
								</Text>
							</Box>

							<Box 
								as='button'
								h='20%'
								w='100%'
								bg={['white','','']}
								rounded='md'
								boxShadow='md'
								my={2}
								px={2}
								py={4}
								onClick={onOpen}
							>
								<Flex justifyContent='center' fontSize='3xl' mt={4} color='black'>
									{user.role === 'admin' 
										? dashboardStats?.active_clients
										: subscriberOverview?.active_subscriber ?? 0
									}
								</Flex>
								<Text textAlign='center'>
									{user.role === 'admin' 
										? 'Active Trainers'
										: 'Active Subscribers'
									}
								</Text>
							</Box>
							
							<ComingSoonModal onClose={onClose} isOpen={isOpen} />
						</Box>
					)}

					{user.role !== 'admin' && bigWindow && (
						<Grid
							templateColumns={[
								`repeat(${user.role === 'admin' ? 1 : 1}, 1fr)`,
								`repeat(${user.role === 'admin' ? 1 : 1}, 1fr)`,
								`repeat(${user.role === 'admin' ? 3 : 2}, 1fr)`,
							]}
							mt="30px"
							gap={6}
						>
							<Box w="100%">
								<CardInfo
									data={{
										value: totalUserRevenue?.total_user,
										label: 'New Subscribers',
									}}
								/>
							</Box>

							<Box w="100%" bg="white">
								<CardInfo
									data={{
										value: `$${totalUserRevenue?.total_revenue.toFixed(2)}`,
										label: 'Revenue',
									}}
								/>
							</Box>
						</Grid>
					)}
				</Container>
			</Box>

			{user.role !== 'admin' && bigWindow && (
				<>
					<Container
						mt={'10'}
						maxW={['100%', 'container.sm', 'container.md', 'container.lg']}
					>
						<Box mb="30px">
							<Heading as="h2" fontSize="1.35rem" color="secondary.500">
								Subscriber Overview
							</Heading>
							{/*
					<Text mt="2">
						{daysChoice === '30_DAYS' ? 'Last 30 days' : 'Last 7 days'}
					</Text>
				*/}
						</Box>

						<Grid
							templateColumns={[
								`repeat(1, 1fr)`,
								`repeat(2, 1fr)`,
								`repeat(3, 1fr)`,
							]}
							mt="30px"
							gap={6}
						>
							<Box w="100%" bg="white" shadow="md" rounded="10px" p="4">
								<Text fontSize="2rem" mb="2">
									<Text as="span" fontWeight="semibold" color="secondary.800">
										{subscriberOverview?.total_subscriber}
									</Text>
									{/* <Text as="small" color="green.500" ml="2" fontSize="1rem">
								+5%
							</Text> */}
								</Text>

								<Text>Total Subscribers</Text>
							</Box>

							<Box w="100%" bg="white" shadow="md" rounded="10px" p="4">
								<Text fontSize="2rem" mb="2">
									<Text as="span" fontWeight="semibold" color="secondary.800">
										{subscriberOverview?.active_subscriber}
									</Text>
									{/* <Text as="small" color="green.500" ml="2" fontSize="1rem">
								+5%
							</Text> */}
								</Text>

								<Text>Active Subscribers</Text>
							</Box>

							<Box w="100%" bg="white" shadow="md" rounded="10px" p="4">
								<Text fontSize="2rem" mb="2">
									<Text as="span" fontWeight="semibold" color="secondary.800">
										{subscriberOverview?.expired_subscriber}
									</Text>
									{/* <Text as="small" color="green.500" ml="2" fontSize="1rem">
								+5%
							</Text> */}
								</Text>

								<Text>Expired Subscribers</Text>
							</Box>
						</Grid>
					</Container>
					{/*<Checklist />*/}
				</>
			)}

			{/* Super Admin */}
			{user.role === 'admin' && bigWindow && (
				<>
					<Container
						mt={4}
						pb={5}
						maxW={['100%', 'container.sm', 'container.md', 'container.lg']}
					>
						<Box mb="30px">
							<Heading as="h2" fontSize="1.35rem" color="secondary.500">
								Trainer Overview
							</Heading>
						</Box>

						<Grid
							templateColumns={[
								`repeat(1, 1fr)`,
								`repeat(2, 1fr)`,
								`repeat(2, 1fr)`,
								`repeat(4, 1fr)`,
							]}
							mt="30px"
							mb={'30px'}
							gap={6}
						>
							<StatsCard
								data={{
									label: 'Active Clients',
									value: dashboardStats?.active_clients || 0,
								}}
							/>
							<StatsCard
								data={{
									label: 'Total Revenue',
									value: `$${dashboardStats?.total_revenue?.toFixed(2) || 0}`,
								}}
							/>

							<StatsCard
								data={{
									label: 'Net Revenue',
									value: `$${dashboardStats?.net_revenue?.toFixed(2) || 0}`,
								}}
							/>

							<StatsCard
								data={{
									label: 'Active Subscribers',
									value: dashboardStats?.active_subscribers || 0,
								}}
							/>
						</Grid>

						<Flex
							alignItems={'center'}
							justifyContent={'space-between'}
							mb="30px"
							mt={'40px'}
						>
							<Heading as="h2" fontSize="1.35rem" color="secondary.500">
								New Trainers
							</Heading>

							<Button
								as={Link}
								to={'/admin/trainers'}
								size={'sm'}
								variant={'primary'}
								rightIcon={<BsArrowRight />}
							>
								View All
							</Button>
						</Flex>

						<TrainersTable
							isLoading={isLoadingStats}
							data={dashboardStats?.trainers || []}
						/>
					</Container>
				</>
			)}
		</Box>
	)
}

export default Dashboard
