import React from 'react'
import { Box, Text, Flex, Image } from '@chakra-ui/react'

function MediaObject({ component }) {
	return (
		<Box
			display="flex"
			px="2px"
			py="2px"
			justifyContent={component.style.justifyContent}
		>
			<Box width={`${component.style.width}%`}>
				{component.items.map((item, index) => (
					<Box
						key={item.id}
						width="100%"
						mb={
							component.items.length !== index + 1
								? `${component.style.margin.bottom}px`
								: '0'
						}
					>
						<Flex>
							{component.image.alignment === 'left' ? (
								<Box
									flex="0 0"
									flexBasis="auto"
									rounded={`${component.image.rounded.topLeft}%`}
									p={component.image.rounded.topLeft ? 1 : 0}
									borderWidth={`${component.image.border.top}px`}
									borderColor={component.image.border.color}
									alignSelf="flex-start"
								>
									<Image
										display="block"
										width={`${component.image.width}px`}
										height={`${component.image.height}px`}
										objectFit="cover"
										rounded={`${component.image.rounded.topLeft}%`}
										src={
											item.image?.path
												? item.image?.path
												: 'https://picsum.photos/id/1005/200/200'
										}
									/>
								</Box>
							) : null}

							<Box px={3} flex="1 1" textAlign={component.image.alignment}>
								{component.heading.visibility ? (
									<Text
										mt={`${component.heading.margin.top}px`}
										fontFamily={component.heading.fontFamily}
										fontSize={`${component.heading.font.size}px`}
										fontWeight={component.heading.font.weight}
										textTransform={component.heading.font.transform}
										color={component.heading.font.color}
									>
										{item.heading?.data}
									</Text>
								) : null}
								{component.subHeading.visibility ? (
									<Text
										fontFamily={component.subHeading.fontFamily}
										fontSize={`${component.subHeading.font.size}px`}
										fontWeight={component.subHeading.font.weight}
										textTransform={component.subHeading.font.transform}
										color={component.subHeading.font.color}
									>
										{item.subHeading?.data}
									</Text>
								) : null}
								{component.text.visibility ? (
									<Text
										fontFamily={component.text.fontFamily}
										fontSize={`${component.text.font.size}px`}
										fontWeight={component.text.font.weight}
										textTransform={component.text.font.transform}
										color={component.text.font.color}
										lineHeight={`${component.text.font.size * 1.5}px`}
									>
										{item.text?.data}
									</Text>
								) : null}
							</Box>

							{component.image.alignment === 'right' ? (
								<Box
									flex="0 0"
									flexBasis="auto"
									rounded={`${component.image.rounded.topLeft}%`}
									p={component.image.rounded.topLeft ? 1 : 0}
									borderWidth={`${component.image.border.top}px`}
									borderColor={component.image.border.color}
									alignSelf="flex-start"
								>
									<Image
										display="block"
										width={`${component.image.width}px`}
										height={`${component.image.height}px`}
										objectFit="cover"
										rounded={`${component.image.rounded.topLeft}%`}
										src={
											item.image?.path
												? item.image?.path
												: 'https://picsum.photos/id/1005/200/200'
										}
									/>
								</Box>
							) : null}
						</Flex>
					</Box>
				))}
			</Box>
		</Box>
	)
}

export default MediaObject
