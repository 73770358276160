import React, { useContext, useEffect } from 'react'
import { Box, Stack, Text, Flex,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    FormControl, FormLabel, Radio, RadioGroup,
    NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Input,
    Select, InputGroup, InputRightAddon, Textarea, 
    Modal, ModalOverlay, ModalContent,
    useDisclosure
} from '@chakra-ui/react'
import debounce from 'lodash.debounce';
import { SketchPicker } from 'react-color';
import { PageBuilderContext } from '../../../context/PageBuilderContext';
import { paddingConfigHandler, textConfigHandler, borderConfigHandler, roundedConfigHandler, marginConfigHandler
} from '../../../helper/settingsHelper';
import { fonts } from '../../../helper/fonts'

function ButtonSetting() {
    const [ state, dispatch ] = useContext(PageBuilderContext);
    const { isOpen, onOpen, onClose } = useDisclosure(); 
    const { isOpen: bgIsOpen, onOpen: bgOnOpen, onClose: bgOnClose } = useDisclosure(); 
    const { selected } = state;
    let { style, data, link } = state.section[selected.sectionIndex].columns[selected.columnIndex].components[selected.compIndex];

    const marginHandler = (value, side) => {
        const margin = marginConfigHandler(style.margin, side, value);
        console.log(margin);
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                margin: margin, 
                type:"margin", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }
 
    const paddingHandler = (value, side) => {
        const payload = paddingConfigHandler(style.padding, side, value);
        console.log(payload);
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                ...payload, 
                type:"padding", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const textHandler = debounce((value, property) => {
        const payload = textConfigHandler(style.text, property, value);
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                ...payload, 
                type:"text", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }, 400)

    const handleFontFamily = (e) => {
        
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                fontFamily: e.target.value,
                type:"text.fontFamily", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const bgColorHandler = (value) => {
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                bgColor: value, 
                type:"bgColor", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const dataHandler = debounce((e) => {
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                type:"data", 
                data: e.target.value,
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    },400)

    const widthHandler = (e) => {
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                width: e.target.value, 
                type:"width", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const borderHandler = (value, side) => {
        const border = borderConfigHandler(style.border, side, value);
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                border: border,
                type:"border", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const alignmentHandler = (value) => {
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                justifyContent: value, 
                type:"justifyContent", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const roundingHandler = (value) => {
        const rounded = roundedConfigHandler(value);
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                rounded: rounded,
                type:"rounded", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const linkHandler = debounce((value) => {
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                type:"link", 
                url: value,
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }, 400)

   
    const heightHandler = (e) => {
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                height: e.target.value, 
                type:"height", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    useEffect(() => {
        return () => {
            dataHandler.cancel();
            textHandler.cancel();
            linkHandler.cancel();
        }
    }, []);

    return (
        <>
        <Box>
            <Tabs size="sm" isFitted  variant="unstyled">
                <TabList borderWidth="1px">
                    <Tab
                        fontSize=".75rem" fontWeight="600"
                        _focus={{outline:"none"}} 
                        _selected={{ bg: "gray.200" }}
                    >
                        Design
                    </Tab>
                    <Tab 
                        fontSize=".75rem" fontWeight="600"
                        _focus={{outline:"none"}} 
                        _selected={{ bg: "gray.200" }}
                    >
                        General
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel p={0}>

                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Text:</Text>
                        <Box borderBottomWidth="1px" p="10px">
                            <Textarea 
                                size="xs" fontWeight="500" resize="vertical" 
                                defaultValue={data}
                                onChange={dataHandler}
                            />
                        </Box>

                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Margin:</Text>
                        <Box display="flex" flexWrap="wrap" borderBottomWidth="1px" p="10px">
                            <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pr="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Top</FormLabel>
                                    <NumberInput 
                                        size="xs" 
                                        onChange={(value) => marginHandler(value, "top")} 
                                        value={style.margin.top}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pl="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Right</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        onChange={(value) => marginHandler(value, "right")} 
                                        value={style.margin.right}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" pr="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Left</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        onChange={(value) => marginHandler(value, "left")} 
                                        value={style.margin.left}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput> 
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" pl="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Bottom</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        onChange={(value) => marginHandler(value, "bottom")} 
                                        value={style.margin.bottom}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                        </Box>

                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Padding:</Text>
                        <Box display="flex" flexWrap="wrap" borderBottomWidth="1px" p="10px">
                            <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pr="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Top</FormLabel>
                                    <NumberInput 
                                        size="xs" 
                                        onChange={(value) => paddingHandler(value, "top")} 
                                        value={style.padding.top}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pl="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Right</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        onChange={(value) => paddingHandler(value, "right")} 
                                        value={style.padding.right}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" pr="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Left</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        onChange={(value) => paddingHandler(value, "left")} 
                                        value={style.padding.left}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput> 
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" pl="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Bottom</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        onChange={(value) => paddingHandler(value, "bottom")} 
                                        value={style.padding.bottom}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                        </Box>

                        <Box display="flex" borderBottomWidth="1px" p="10px">
                            <Text fontSize=".8rem" fontWeight="600" mr="10px">Alignment:</Text>
                            <RadioGroup flex="1 1" value={style.justifyContent} onChange={alignmentHandler}>
                                <Stack direction="row">
                                    <Radio size="sm" value="flex-start">Left</Radio>
                                    <Radio size="sm" value="center">Center</Radio>
                                    <Radio size="sm" value="flex-end">Right</Radio>
                                </Stack>
                            </RadioGroup>
                        </Box>

                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Width & Height:</Text>
                        <Box display="flex" flexWrap="wrap" borderBottomWidth="1px" p="10px">
                            <Flex mb={2}>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Width</Text>
                                <InputGroup flex="1 1" size="xs">
                                    <Input value={style.width} onChange={widthHandler} />
                                    <InputRightAddon id="width" cursor="pointer" children="(%, px, auto)" />
                                </InputGroup>
                            </Flex>
                            <Flex>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Height</Text>
                                <InputGroup flex="1 1" size="xs">
                                    <Input value={style.height} onChange={heightHandler} />
                                    <InputRightAddon id="primaryColor" cursor="pointer" children="(%, px, auto)" />
                                </InputGroup>
                            </Flex>
                        </Box>
                        
                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Button Text:</Text>
                        <Box borderBottomWidth="1px" p="10px">
                            <Flex mb={2}> 
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Font Family</Text>
                                <Select flex="1 1" size="xs" value={style.fontFamily} onChange={handleFontFamily}>
                                    {
                                        fonts.map(font => (
                                            <option key={font.value} value={font.value}>{font.label}</option>
                                        ))
                                    }
                                </Select>
                                
                            </Flex>
                            <Flex mb={2}> 
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Size (px)</Text>
                                <NumberInput 
                                        flex="1 1"
                                        size="xs" 
                                        max={50} min={5} 
                                        defaultValue={style.text.size}
                                        key={style.text.size + (Math.random() * 1000) }
                                        onChange={(value) => textHandler(value, "size")}
                                    >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput> 
                            </Flex>
                            <Flex mb={2}>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Color</Text>
                                <InputGroup flex="1 1" size="xs">
                                    <Input value={style.text.color} onChange={(e) => textHandler(e.target.value, "color")} />
                                    <InputRightAddon id="primaryColor" cursor="pointer" bg={style.text.color} onClick={onOpen} />
                                </InputGroup>
                            </Flex>
                            <Flex mb={2}>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Weight</Text>
                                <Select flex="1 1" size="xs" value={style.text.weight} onChange={(e) => textHandler(e.target.value, "weight")}>
                                    {
                                        Array.from(["200","300","400","500","600","700","800"]).map(item => (
                                            <option key={item} value={item}>{item}</option>
                                        ))
                                    }
                                </Select>
                            </Flex>
                            <Flex mb={2}>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Align</Text>
                                <Select flex="1 1" size="xs" value={style.text.align} onChange={(e) => textHandler(e.target.value, "align")}>
                                    <option value="flex-start">Left</option>
                                    <option value="center">Center</option>
                                    <option value="flex-end">Right</option>
                                </Select>
                            </Flex>
                            <Flex mb={2}>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Transform</Text>
                                <Select flex="1 1" size="xs" value={style.text.transform} onChange={(e) => textHandler(e.target.value, "transform")}>
                                    <option value="none">None</option>
                                    <option value="uppercase">Uppercase</option>
                                    <option value="lowercase">Lowercase</option>
                                    <option value="capitalize">Capitalize</option>
                                </Select>
                            </Flex>
                        </Box>

                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Border:</Text>
                        <Box>
                            <Box display="flex" flexWrap="wrap" borderBottomWidth="1px" p="10px">
                                <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pr="5px">
                                    <Stack direction="row">
                                        <FormLabel fontSize=".75rem" fontWeight="600">Top</FormLabel>
                                        <NumberInput 
                                            size="xs" 
                                            max={50} min={0} 
                                            onChange={(value) => borderHandler(value,'top')} 
                                            value={style.border.top}
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>  
                                    </Stack>
                                </FormControl>
                                <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pr="5px">
                                    <Stack direction="row">
                                        <FormLabel fontSize=".75rem" fontWeight="600">Right</FormLabel>
                                        <NumberInput 
                                            size="xs" 
                                            max={50} min={0} 
                                            onChange={(value) => borderHandler(value, 'right')}
                                            value={style.border.right}
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>  
                                    </Stack>
                                </FormControl>
                                <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pr="5px">
                                    <Stack direction="row">
                                        <FormLabel fontSize=".75rem" fontWeight="600">Left</FormLabel>
                                        <NumberInput 
                                            size="xs" 
                                            max={50} min={0} 
                                            onChange={(value) => borderHandler(value,'left')}
                                            value={style.border.left}
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>  
                                    </Stack>
                                </FormControl>
                                <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pr="5px">
                                    <Stack direction="row">
                                        <FormLabel fontSize=".75rem" fontWeight="600">Bottom</FormLabel>
                                        <NumberInput 
                                            size="xs" 
                                            max={50} min={0} 
                                            onChange={(value) => borderHandler(value,'bottom')}
                                            value={style.border.bottom}
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>  
                                    </Stack>
                                </FormControl>
                            </Box>
                            
                            <Box display="flex" flexWrap="wrap" borderBottomWidth="1px" p="10px">
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Rounded</Text>
                                <NumberInput 
                                    flex="1 1" size="xs" 
                                    max={50} min={0} 
                                    onChange={roundingHandler}
                                    value={style.rounded.topLeft}
                                >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput> 
                            </Box>
                        </Box>
                        
                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Background:</Text>
                        <Box borderBottomWidth="1px" p="10px">
                            <Flex mb={2}>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Color</Text>
                                <InputGroup flex="1 1" size="xs">
                                    <Input value={style.bgColor} onChange={(e) => bgColorHandler(e.target.value)} />
                                    <InputRightAddon id="primaryColor" cursor="pointer" bg={style.bgColor} onClick={bgOnOpen} />
                                </InputGroup>
                            </Flex>
                        </Box>
                    </TabPanel>
                    <TabPanel p={0}>
                        <Text fontSize=".75rem" fontWeight="600" p="10px" borderBottomWidth="1px">Link:</Text>
                        <Box borderBottomWidth="1px" p="10px">
                            <FormControl key={link.url}>
                                <Textarea
                                    placeholder="Link url here"
                                    size="sm"
                                    fontSize=".75rem"
                                    resize="vertical"
                                    defaultValue={link.url}
                                    onChange={(e) => linkHandler(e.target.value)}
                                    autoFocus={true}
                                />
                            </FormControl>
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
        <Modal 
            isCentered
            onClose={onClose}
            isOpen={isOpen}
            motionPreset="slideInBottom"
            size="xs">

            <ModalOverlay />
            <ModalContent>
                <SketchPicker width="100%" color={ style.text.color } onChange={(color) => textHandler(color.hex, "color")}  /> 
            </ModalContent>
        </Modal>
        <Modal 
            isCentered
            onClose={bgOnClose}
            isOpen={bgIsOpen}
            motionPreset="slideInBottom"
            size="xs">
            <ModalOverlay />
            <ModalContent>
                <SketchPicker width="100%" color={ style.bgColor } onChangeComplete={(color) => bgColorHandler(color.hex)}  /> 
            </ModalContent>
        </Modal>
        </>
    )
}

export default ButtonSetting;
