import {
	Avatar,
	Box,
	Stack,
	Flex,
	Grid,
	Text,
	Input,
	Button,
	useToast,
	FormLabel,
	FormControl,
	FormErrorMessage,
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { postRequest } from '../../services/base.service'
import { toastConfig } from '../../helpers/appConfig'
import { profileUpdate } from '../../redux/auth/authActions'
import { logout } from '../../redux'

const AccountProfile = ({ auth, handleLogout }) => {
	const [bigWindow, setBigWindow] = useState(true)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [localUserData, setLocalUserData] = useState(JSON.parse(localStorage.getItem('user')))
	const [validationErrors, setValidationErrors] = useState({})
	const [ logo, setLogo ] = useState(auth.user.logo || null)
	const [ logoObj, setLogoObj ] = useState()
	const toast = useToast()
	const navigate = useNavigate()

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
	})

	const dispatch = useDispatch()

	const browseImage = (e, name) => {
		if (e.target.files.length > 0) {
			const objURL = URL.createObjectURL(e.target.files[0])
			setLogo(objURL)
			setLogoObj(e.target.files[0])
		}
	}

	useEffect(() => {
		if (window.innerWidth < 600) {
				setBigWindow(false)
		}
	}, [])

	function submitHandler(e) {
		e.preventDefault()

		setIsSubmitting(true)
		const formData = new FormData(e.target)
		formData.append('name', e.target.name.value)
		formData.append('email', e.target.email.value)
		formData.append('logo', logoObj)

		postRequest('accounts', formData)
			.then((res) => {
				toast(toastConfig(res.message, res.status))

				const localUserData = JSON.parse(localStorage.getItem('user'))
				localUserData.name = res.result?.name || ''
				localUserData.email = res.result?.email || ''
				localUserData.business_name = res.result?.business_name || ''
				localUserData.logo = res.result?.logo || ''
				dispatch(profileUpdate(localUserData))
				setIsSubmitting(false)
			})
			.catch((err) => {
				setIsSubmitting(false)
				// console.log(err.response);
				setValidationErrors(err.response.data.errors)
			})
	}

	function handleExitUser() {
		const backUp = JSON.parse(localStorage.getItem('backUp'))
		localStorage.setItem('user', JSON.stringify(backUp))
		localStorage.removeItem('backUp')
		window.location.href = '/admin/trainers'
	}

	console.log(JSON.parse(localStorage.getItem('user')))

	return (
		<>		
		{localUserData.role && localUserData.role !== 'admin' && (
			<Grid
			templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)']}
			gap={6}
		>
			<form onSubmit={submitHandler}>
				<Stack spacing="20px">
					<FormControl id="profileName" isInvalid={errors?.name}>
						<FormLabel>Name</FormLabel>
						<Input
							{...register('name', { required: 'Name is required' })}
							borderColor="secondary.200"
							_hover={{
								borderColor: 'secondary.300',
							}}
							_focus={{
								borderColor: errors?.name ? 'none' : 'secondary.400',
							}}
							_placeholder={{
								color: 'secondary.400',
							}}
							type="text"
							rounded="full"
							placeholder="Name"
							defaultValue={auth.user.name}
						/>
						<FormErrorMessage> {errors?.name?.message} </FormErrorMessage>
						{validationErrors?.name && (
							<Text mt={2} color="red">
								{validationErrors.name}
							</Text>
						)}
					</FormControl>

					<FormControl id="profileEmail" isInvalid={errors?.email}>
						<FormLabel>Email</FormLabel>
						<Input
							{...register('email', { required: 'Email is required' })}
							borderColor="secondary.200"
							_hover={{
								borderColor: 'secondary.300',
							}}
							_focus={{
								borderColor: errors?.email ? 'none' : 'secondary.400',
							}}
							_placeholder={{
								color: 'secondary.400',
							}}
							type="email"
							rounded="full"
							placeholder="Email address"
							defaultValue={auth.user.email}
						/>
						<FormErrorMessage> {errors?.email?.message} </FormErrorMessage>
						{validationErrors?.email && (
							<Text mt={2} color="red">
								{validationErrors.email}
							</Text>
						)}
					</FormControl>

					<FormControl id="appLogo" isInvalid={errors?.email}>
						<Flex justifyContent='space-between' mt={6}>
							<Avatar name={auth.user.name} size='lg' src={logo ?? auth.user.logo} />
							<Button
								as='label'
								w="100%"
								my={2}
								ml={8}
								color='white'
								bg='primary.500'
								rounded='2xl'
								border='2px'
								borderColor='primary.500'
								fontSize='md'
								fontWeight='normal'
								h='2.8em'
								_focus={{
									bg: 'primary.500',
									color: 'primary.500'
								}}
								htmlFor='uploadLogo'
							>
								<Input
									accept="image/*"
									display="none"
									name="logo"
									onChange={(e) => browseImage(e, 'logo')}
									id="uploadLogo"
									type="file"
								/>
								Upload new image
							</Button>
						</Flex>
					</FormControl>

					<Box pt="2">
						<Flex justifyContent="space-between" alignItems="center">
							<Button
								isLoading={isSubmitting}
								loadingText="Updating..."
								type="submit"
								variant="primary"
								w={['100%','100%','50%','50%','50%','50%']}
								mr="2"
							>
								Save
							</Button>
						{
							bigWindow && (			
									<Button
										type="submit"
										colorScheme='red'
										rounded='md'
										height="3.2em"
										w='50%'
										fontWeight='bold'
										onClick={() =>
											auth.user?.admin
												? handleExitUser()
												: handleLogout((message, status) => {
														toast(toastConfig(message, status))
														navigate('/')
												})
										}
									>
										Sign Out
									</Button>
						)
						}
						</Flex>
					</Box>
				</Stack>
			</form>
		</Grid>
		)}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		handleLogout: (callback) => dispatch(logout(callback)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountProfile)
