import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Flex, Box, useDisclosure,
} from '@chakra-ui/react'
import { Outlet, useSearchParams } from 'react-router-dom'
import Header from './Header'
import LeftSidebar from './LeftSidebar'
import '../Custom.css'
import Checkbox from '../components/Checkbox/Checkbox'
import ComingSoonModal from '../components/ComingSoonModal'

const AppLayout = () => {
	const user = useSelector((state) => state.auth.user)
	const location = useLocation();
	const { isOpen: isWelcomeOpen, onOpen: onWelcomeOpen, onClose: onWelcomeClose } = useDisclosure()
	const [showChatBox, setShowChatBox] = useState(false)
	const { isOpen: isComingSoonOpen, onOpen: onComingSoonOpen, onClose: onComingSoonClose } = useDisclosure()
	const [searchParams, setSearchParams] = useSearchParams()
	const [bigWindow, setBigWindow] = useState(true)
	const [welcome, setWelcome] = useState(false)
	const [comingSoon, setComingSoon] = useState(false)

	function loadWelcome() {
	
	}

	function closeWelcome() {
		onWelcomeClose()
		setWelcome(false)
		searchParams.delete('m')
		setSearchParams(searchParams)
	}

	function dashClicked() {
		setComingSoon(true)
		onComingSoonOpen()
	}

	function closeComingSoon() {
		onComingSoonClose()
		setComingSoon(false)
	}

	useEffect(() => {

		if (window.innerWidth < 600) {
			setBigWindow(false)
		}
	}, [])

	return (
		<>
			{comingSoon && (
				<ComingSoonModal isOpen={isComingSoonOpen} onClose={closeComingSoon} />
			)}

			<Flex 
				overflowY={(user.role === 'trainer') ? 'hidden' : ''}
				h={bigWindow ? '100vh' : '100%'} 
				bg={'#F5F5F5'} 
				overflowX="hidden" 
				flexDirection='column'
			>

				<Header bigWindow={bigWindow} setShowChatBox={setShowChatBox} />
				<Flex
					mt={user.role === 'admin' && bigWindow ? '60px' : '0px'}
					ps={['0', '0', '50px', '50px', '80px', '80px']}
					direction="column"
					flex="1"
					bg="secondary.100"
					color="secondary.700"
					>
					{bigWindow && <LeftSidebar bigWindow={bigWindow} />}
					<Box 
						h={bigWindow ? "calc(100vh - 70px)" : '100%'} 
						style={{overflowY: location.pathname.includes('customers') && 'auto'}}
					>
						<Outlet />
					</Box>
				</Flex>
			</Flex>

			<Checkbox
				setShowChatBox={setShowChatBox}
				showChatBox={showChatBox}
			/>

			{!bigWindow && 
				<LeftSidebar 
					bigWindow={bigWindow} 
					dashClicked={dashClicked} 
					welcome={welcome} 
					comingSoon={comingSoon}
				/>
			}
		</>


	)
}

export default AppLayout
