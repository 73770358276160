import React, { useContext } from 'react'
import { Flex, Stack, Icon, useDisclosure } from '@chakra-ui/react'
import { PageBuilderContext } from '../context/PageBuilderContext'

function LayoutButton(props) {
	const [state, dispatch] = useContext(PageBuilderContext)

	// console.log(props);

	const addSection = (layout) => {
		dispatch({
			type: 'ADD_SECTION',
			payload: { layout: layout, index: props.index },
		})
		props.onClose();
	}

	return (
		<Flex flexWrap="wrap" justifyContent="center">
			{/* Two column */}
			<Stack direction="row" mr={4} my={2} cursor="pointer" rounded={4}>
				<Icon
					viewBox="0 0 40 40"
					color="gray.400"
					onClick={() => addSection('two-column')}
				>
					<rect x="0" y="0" width="20" height="40" fill="currentColor" />
					<rect x="22" y="0" width="10" height="40" fill="currentColor" />
					<rect x="30" y="0" width="10" height="40" fill="currentColor" />
				</Icon>
			</Stack>

			{/* Three column */}
			<Stack
				direction="row"
				mr={4}
				my={2}
				cursor="pointer"
				rounded={4}
				onClick={() => addSection('three-column')}
			>
				<Icon viewBox="0 0 160 160" color="gray.400">
					<rect x="0" y="0" width="50" height="160" fill="currentColor" />
					<rect x="55" y="0" width="50" height="160" fill="currentColor" />
					<rect x="110" y="0" width="50" height="160" fill="currentColor" />
				</Icon>
			</Stack>

			{/* Single column */}
			<Stack
				direction="row"
				mr={4}
				my={2}
				cursor="pointer"
				rounded={4}
				onClick={() => addSection('single-column')}
			>
				<Icon viewBox="0 0 220 140" color="gray.400">
					<rect x="0" y="0" width="220" height="140" fill="currentColor" />
				</Icon>
			</Stack>

			{/* Three column 1:2 */}
			{/* <Stack
				direction="row"
				mr={4}
				my={2}
				cursor="pointer"
				rounded={4}
				onClick={() => addSection('one-by-two-column')}
			>
				<Icon viewBox="0 0 40 40" color="gray.400">
					<rect x="0" y="0" width="20" height="40" fill="currentColor" />
					<rect x="21" y="0" width="10" height="40" fill="currentColor" />
					<rect x="32" y="0" width="10" height="40" fill="currentColor" />
				</Icon>
			</Stack> */}

			{/* Three column 2:1 */}
			{/* <Stack
				direction="row"
				mr={4}
				my={2}
				cursor="pointer"
				rounded={4}
				onClick={() => addSection('two-by-one-column')}
			>
				<Icon viewBox="0 0 40 40" color="gray.400">
					<rect x="0" y="0" width="10" height="40" fill="currentColor" />
					<rect x="11" y="0" width="10" height="40" fill="currentColor" />
					<rect x="22" y="0" width="20" height="40" fill="currentColor" />
				</Icon>
			</Stack> */}

			{/* Two column 2:3 = 40%-60% */}
			{/* <Stack
				direction="row"
				mr={4}
				my={2}
				cursor="pointer"
				rounded={4}
				onClick={() => addSection('2-3-column')}
			>
				<Icon viewBox="0 0 40 40" color="gray.400">
					<rect x="0" y="0" width="16" height="40" fill="currentColor" />
					<rect x="17" y="0" width="40" height="40" fill="currentColor" />
				</Icon>
			</Stack> */}

			{/* Two column 3:2 = 60%-40% */}
			{/* <Stack
				direction="row"
				mr={4}
				my={2}
				cursor="pointer"
				rounded={4}
				onClick={() => addSection('3-2-column')}
			>
				<Icon viewBox="0 0 40 40" color="gray.400">
					<rect x="0" y="0" width="23" height="40" fill="currentColor" />
					<rect x="24" y="0" width="16" height="40" fill="currentColor" />
				</Icon>
			</Stack> */}

			{/* Four column */}
			{/* <Stack
				direction="row"
				mr={4}
				my={2}
				cursor="pointer"
				rounded={4}
				onClick={() => addSection('four-column')}
			>
				<Icon viewBox="0 0 40 40" color="gray.400">
					<rect x="0" y="0" width="9" height="40" fill="currentColor" />
					<rect x="10" y="0" width="9" height="40" fill="currentColor" />
					<rect x="20" y="0" width="9" height="40" fill="currentColor" />
					<rect x="30" y="0" width="9" height="40" fill="currentColor" />
				</Icon>
			</Stack> */}

			{/* One by three column */}
			{/* <Stack
				direction="row"
				mr={4}
				my={2}
				cursor="pointer"
				rounded={4}
				onClick={() => addSection('one-by-three-column')}
			>
				<Icon viewBox="0 0 40 40" color="gray.400">
					<rect x="0" y="0" width="10" height="40" fill="currentColor" />
					<rect x="12" y="0" width="10" height="40" fill="currentColor" />
					<rect x="20" y="0" width="20" height="40" fill="currentColor" />
				</Icon>
			</Stack> */}

			{/* Three by one column */}
			{/* <Stack
				direction="row"
				mr={4}
				my={2}
				cursor="pointer"
				rounded={4}
				onClick={() => addSection('three-by-one-column')}
			>
				<Icon viewBox="0 0 40 40" color="gray.400">
					<rect x="0" y="0" width="20" height="40" fill="currentColor" />
					<rect x="20" y="0" width="10" height="40" fill="currentColor" />
					<rect x="32" y="0" width="10" height="40" fill="currentColor" />
				</Icon>
			</Stack> */}

			{/* 4-2-2-2 column */}
			{/* <Stack
				direction="row"
				mr={4}
				my={2}
				cursor="pointer"
				rounded={4}
				onClick={() => addSection('4-2-2-2-column')}
			>
				<Icon viewBox="0 0 40 40" color="gray.400">
					<rect x="0" y="0" width="15" height="40" fill="currentColor" />
					<rect x="17" y="0" width="6" height="40" fill="currentColor" />
					<rect x="24" y="0" width="6" height="40" fill="currentColor" />
					<rect x="32" y="0" width="6" height="40" fill="currentColor" />
				</Icon>
			</Stack>

			<Stack
				direction="row"
				mr={4}
				my={2}
				cursor="pointer"
				rounded={4}
				onClick={() => addSection('one-two-one-column')}
			>
				<Icon viewBox="0 0 40 40" color="gray.400">
					<rect x="0" y="0" width="9" height="40" fill="currentColor" />
					<rect x="10" y="0" width="10" height="40" fill="currentColor" />
					<rect x="20" y="0" width="11" height="40" fill="currentColor" />
					<rect x="32" y="0" width="9" height="40" fill="currentColor" />
				</Icon>
			</Stack> */}
		</Flex>
	)
}

export default LayoutButton
