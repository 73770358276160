import { useEffect, useState } from 'react'
import {
	Flex,
	Box,
	Container,
	Grid,
	Heading,
	Spinner,
	Center,
	Alert,
	AlertIcon,
	chakra,
} from '@chakra-ui/react'
import PricingCard from '../components/PricingCard'
import AddPricing from '../components/AddPricing'
import { changePageTitle } from '../redux'
import { connect } from 'react-redux'
import { getRequest, postRequest } from '../services/base.service'
import CouponCard from '../components/CouponCard'
import AddCoupon from '../components/AddCoupon'
import { subdomain } from '../helpers/appConfig'

const Pricing = ({ changePageTitle }) => {
	const [initLoading, setInitLoading] = useState(false)
	const [stripeStatus, setStripeStatus] = useState(false)
	const [pricingList, setPricingList] = useState([])
	const [pageTitle] = useState('Pricing')
	const [couponList, setCouponList] = useState([])

	useEffect(() => {
		console.log(subdomain)
		changePageTitle(pageTitle)
		getStripeStatus()
	}, [])

	const getStripeStatus = () => {
		setInitLoading(true)

		getRequest('stripe-status').then((res) => {
			const { status } = res.result
			setStripeStatus(status)
			if (status) {
				getPricingList(
					`?sort=id,desc&?select=id,pricing_type,price,trial_day,annual_saving`
				)
				getCouponList(
					`?sort=id,desc&?select=id,stripe_id,amount_off,percent_off,duration,duration_in_months,max_redemptions,name,times_redeemed`
				)
			} else {
				setInitLoading(false)
			}
		})
	}

	const getPricingList = (queryParams = '') => {
		getRequest(`pricing${queryParams}`)
			.then((res) => {
				setPricingList(res.result)
				console.log(res.result)
			})
			.finally(() => setInitLoading(false))
	}

	const getCouponList = (queryParams = '') => {
		getRequest(`coupon${queryParams}`)
			.then((res) => {
				setCouponList(res.result)
			})
			.finally(() => setInitLoading(false))
	}

	const connectStripeHandler = () => {
		postRequest('stripe-connect', { redirect_url: window?.location?.href })
			.then((res) => {
				window.location.assign(res.result?.url)
			})
			.catch((err) => console.log(err))
	}

	return (
		<Box flex="1" p={['20px 0', '20px 0', '20px', '30px']}>
			<Container
				maxW={['100%', 'container.sm', 'container.md', 'container.lg']}
				mb="6"
			>
				<Box
					bg="white"
					p={['20px', '20px', '25px', '30px']}
					borderRadius="15"
					boxShadow="md"
				>
					{/* Card header */}
					<Flex justifyContent="space-between" alignItems={'center'} mb="6">
						<Heading as="h3" size="md">
							Pricing List
						</Heading>

						<AddPricing updateList={getPricingList} />
					</Flex>
					{/* end::Card header */}

					{/* Pricing list */}
					{initLoading ? (
						<Center h="200px">
							<Spinner
								thickness="6px"
								speed="0.65s"
								emptyColor="gray.200"
								color="blue.500"
								size="xl"
							/>
						</Center>
					) : stripeStatus ? (
						<Grid
							templateColumns={[
								'repeat(1, 1fr)',
								'repeat(2, 1fr)',
								'repeat(3, 1fr)',
							]}
							gap={6}
						>
							{pricingList.map((subscriber, index) => (
								<PricingCard
									updateList={getPricingList}
									key={index}
									data={subscriber}
								/>
							))}
						</Grid>
					) : (
						<Alert status="warning" mt="50px" boxShadow="sm" rounded="sm">
							<AlertIcon />
							Account Not Connected.
							<chakra.span
								ml="8px"
								color="gray.700"
								_hover={{
									cursor: 'pointer',
									textDecoration: 'underline',
								}}
								onClick={connectStripeHandler}
							>
								Connect
							</chakra.span>
						</Alert>
					)}
					{/* end::Pricing list */}
				</Box>
			</Container>

			<Container
				maxW={['100%', 'container.sm', 'container.md', 'container.lg']}
				mb="6"
			>
				<Box
					bg="white"
					p={['20px', '20px', '25px', '30px']}
					borderRadius="15"
					boxShadow="md"
				>
					{/* Card header */}
					<Flex justifyContent="space-between" alignItems={'center'} mb="6">
						<Heading as="h3" size="md">
							Coupons List
						</Heading>

						<AddCoupon updateList={getCouponList} />
					</Flex>
					{/* end::Card header */}

					{/* Pricing list */}
					{initLoading ? (
						<Center h="200px">
							<Spinner
								thickness="6px"
								speed="0.65s"
								emptyColor="gray.200"
								color="blue.500"
								size="xl"
							/>
						</Center>
					) : stripeStatus ? (
						<Grid
							templateColumns={[
								'repeat(1, 1fr)',
								'repeat(2, 1fr)',
								'repeat(3, 1fr)',
							]}
							gap={6}
						>
							{couponList.map((coupon, index) => (
								<CouponCard
									updateList={getCouponList}
									key={index}
									data={coupon}
								/>
							))}
						</Grid>
					) : (
						<Alert status="warning" mt="50px" boxShadow="sm" rounded="sm">
							<AlertIcon />
							Account Not Connected.
							<chakra.span
								ml="8px"
								color="gray.700"
								_hover={{
									cursor: 'pointer',
									textDecoration: 'underline',
								}}
								onClick={connectStripeHandler}
							>
								Connect
							</chakra.span>
						</Alert>
					)}
					{/* end::Pricing list */}
				</Box>
			</Container>
		</Box>
	)
}

const mapDispatchToProps = (dispatch) => {
	return {
		changePageTitle: (title) => dispatch(changePageTitle(title)),
	}
}

export default connect(null, mapDispatchToProps)(Pricing)
