import react from 'react';
import { 
    Alert, 
    AlertIcon, 
    AlertTitle 
} from '@chakra-ui/react'

const DataNotFound = (props) => {
    return (
        <Alert
            status={props.status}
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="300px"
            rounded="md" 
            boxShadow="sm"
        > 
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
                {props.message}
            </AlertTitle>
        </Alert>
    )
};

export default DataNotFound;