import React, { useEffect, useState } from 'react'
import { Container, Box } from '@chakra-ui/react'
import { getRequest } from '../services/base.service'
import Column from '../PageRender/Column'
import { useDispatch, useSelector } from 'react-redux'
import { setLandingTrainerInfo } from '../redux'
import NotFound from './errors/NotFound'
import LandingPageHeader from '../layouts/LandingPageHeader'
import { subdomain } from '../helpers/appConfig'

const TrainerLandingPage = () => {
	const [pageContent, setPageContent] = useState(null)
	const dispatch = useDispatch()
	const [isInvalidDomain, setIsInvalidDomain] = useState(false)
	const { landingTrainerInfo } = useSelector((state) => state.helpers)

	useEffect(() => {
		// user
		console.log(subdomain)
		if (subdomain !== 'dashboard'){
			getRequest(`pages/${subdomain}`)
			.then((response) => {
				const { result } = response
				const trainer = {
					business_name: result.trainer?.business_name,
					logo: result.trainer?.logo,
					is_show_logo: result.trainer?.is_show_logo,
					redirect_url: result?.trainer?.redirect_url || null,
				}

				if (result) {
					dispatch(setLandingTrainerInfo(trainer))
					setPageContent(result.content.section)
				}
			})
			.catch((error) => {
				setIsInvalidDomain(true)
			})
		}
	}, [])

	if (isInvalidDomain) return <NotFound />

	return (
		<>
			{landingTrainerInfo?.is_show_logo && landingTrainerInfo?.logo && (
				<LandingPageHeader />
			)}

			{pageContent
				? pageContent?.map((section, index) => {
						return (
							<Box bg="#d9e7f4">
								<Container key={index} p="0" maxW="container.sm" bg="#FFF">
									<Box
										bg={section.style.bgColor}
										backgroundImage={section.style.bgImage?.path}
										backgroundPosition="center"
										backgroundRepeat="no-repeat"
										backgroundSize="cover"
										mt={`${section.style?.margin?.top}px`}
										mb={`${section.style?.margin?.bottom}px`}
										ml={`${section.style?.margin?.left}px`}
										mr={`${section.style?.margin?.right}px`}
										pt={[`${section?.style?.padding?.top}px`]}
										pb={[`${section?.style?.padding?.bottom}px`]}
										pl={[`${section?.style?.padding?.left}px`]}
										pr={[`${section?.style?.padding?.right}px`]}
									>
										<Column
											cols={section.columns}
											columnGap={section.columnGap}
										/>
									</Box>
								</Container>
							</Box>
						)
				  })
				: null}
		</>
	)
}

export default TrainerLandingPage
