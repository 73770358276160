import { useState, useEffect } from 'react'
import { Box, Image, Heading, Text, Stack, FormControl, FormLabel,
	Input, Button, Center, Checkbox, Flex, VStack
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import './auth.css'
import NavButtons from '../NavButtons'
import { MdArrowForwardIos } from 'react-icons/md'

const StepFeature = ({ value, onChange, prevStep, nextStep }) => {
    const [features, setFeatures] = useState([])
    const [bigWindow, setBigWindow] = useState(true)
    const [industry, setIndustry] = useState(3)

    useEffect(() => {
        onChange({
            ...value,
            industry_id: 3
        })
    }, [])
    
     function gobackward() {
        prevStep()
    }

    function goforward() {
        nextStep()
    }

    const handleChange = (val) => {
        const selectedFeatures = features
        const index= selectedFeatures.indexOf(val)
        if (index !== -1) {
            selectedFeatures.splice(index, 1)
        } else {
            selectedFeatures.push(val)
        }
        setFeatures(selectedFeatures)
    }

     useEffect(() => {
        if (window.innerWidth < 650) {
            setBigWindow(false)
        }
    }, [])

    const CheckboxInput = ({value, bgColor, isChecked, handleChange, url, title, description}) => {
        function handleCheckboxChange(e) {
            const {id} = e.currentTarget
            handleChange(id)
        }
        return (
            <Flex flexDirection='row' position='relative' maxWidth={['150px', '200px']} boxShadow="md" m="1">
                <Checkbox
                    position='absolute'
                    size="sm"
                    top={["2", "2.5"]}
                    left={["2.5","3"]}
                    id={value}
                    defaultChecked={isChecked}
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
                <FormLabel 
                    htmlFor={value}
                    sx={{
                        cursor: 'pointer'
                    }}
                >
                    <Box>
                        <VStack width={'100%'}>
                            <Image src={url} width='full' />
                            <Text fontWeight="extrabold" color='gray.900'>{title}</Text>
                            <Text textAlign="center" fontSize="0.9rem">{description}</Text>
                        </VStack>
                    </Box>

                </FormLabel>
            </Flex>
        )
    }

    const images = [
        {
            url: "/images/features/payments.svg",
            title: "Payments",
            description: "Charge for access to your digital content",
            value: 1
        },
        {
            url: "/images/features/chat.svg",
            title: "Chat",
            description: "Direct message with members of your app",
            value: 2
        },
         {
            url: "/images/features/community.svg",
            title: "Community",
            description: "Engage with your members with your in-app feed",
            value: 3
        },
        {
            url: "/images/features/push notifications.svg",
            title: "Push notifications",
            description: "Send notifications to all your members. Instantly.",
            value: 4
        },
        {
            url: "/images/features/website embed.svg",
            title: "Website embed",
            description: "Embed websites for quick access within your app",
            value: 5
        },
        {
            url: "/images/features/link store.svg",
            title: "Link store",
            description: "Sell physical products by linking them in your link store",
            value: 6
        },
       
    ]

    return (
		<Box display={'flex'} h="100vh" bg="secondary.100" color="secondary.700" alignItems={bigWindow ? '' : 'center'}>
			<Box
				flex="1"
				display={['flex', 'flex', 'flex', 'flex']}
				justifyContent="center"
				alignItems="center"
				sx={{
                    background: `transparent linear-gradient(137deg, #5E59FF 0%, #59FEF4 100%) 0% 0% no-repeat padding-box;`
                }}
				overflowX="hidden"
				p={['0', '0', '0', '20px']}
			>
				<Box display={['none', 'none', 'block', 'block']}>
					<div class="custom-shape-divider-bottom-1656696063">
						<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
							<path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
						</svg>
					</div>
					<div class="custom-shape-divider-bottom-1656695281">
						<svg data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
							<path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
						</svg>
					</div>
				</Box>
				<Box
					w="100%"
					maxW={['100%', '100%', '100%', '900px']}
					bg="white"
                    boxShadow='lg'
					overflowX="hidden"
					maxH="100%"
                    h={['100%', '100%', 'auto', 'auto']}
					rounded={['0', '0', 'xl', 'xl']}
					p={["5px", "40px"]}
					zIndex='2'
				>
                    <Center>
                        <Heading mt={['10%', '10%', '0', '0']} as="h3" fontSize="2xl" letterSpacing='wide' fontWeight='black' color="secondary.500" mb="1">
                            Pick Your Features.
                        </Heading>
                    </Center>
                    <Flex wrap='wrap' flexDirection={"row"} mx='auto' mt="5" justifyContent='center' p="2">
                        {
                            images.map((image, index) => (
                                <CheckboxInput 
                                    value={index + 1}
                                    isChecked={features.includes(image.value)}
                                    handleChange={handleChange}
                                    title={image.title}
                                    description={image.description}
                                    url={image.url}
                                    bgColor={image.bgColor}
                                />
                            ))
                        }
                    </Flex>
                    {
                        bigWindow ?
                        <Center>
                            <Button 
                                mt={2} 
                                variant='primary' 
                                cursor='pointer'
                                onClick={nextStep}
                                width="300px"
                            >
                                <Text ml="auto">Pick Your Template</Text>
                                <Text
                                    as="span"
                                    ml="auto"
                                >
                                    <MdArrowForwardIos size="0.9em" />
                                </Text>
                            </Button>
                        </Center>
                        :
                        <NavButtons forward={goforward} backward={gobackward} width={'100%'} data={value} />
                    }
				</Box>
			</Box>

            <Box
				display={['none', 'None', 'none', 'block']}
				flex="1"
				bg="primary.100"
				pos="relative"
			>
				<Image
					h="100vh"
					w="100%"
					objectFit="cover"
                    objectPosition='35% 0'
					src="https://i.imgur.com/erpuzav.jpg"
				/>

				<Box
					pos="absolute"
					top="0"
					left="0"
					w="100%"
					h="100%"
					bg="rgba(0, 0, 0, .05)"
				></Box>

                <Box
					pos="absolute"
					top="0%"
					w="100%"
					h="100%"
					bg="rgba(0, 0, 0, .05)"
				>
                    <Image
                        h="70vh"
                        src="https://i.imgur.com/9iDJGLN.png"
                    />
                </Box>
			</Box>
		</Box>
	)
}

export default StepFeature
