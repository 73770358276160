import {
	Box,
	Button,
	Center,
	Container,
	Flex,
	Grid,
	GridItem,
	Heading,
	Stack,
	Text,
} from '@chakra-ui/react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changePageTitle } from '../redux'
import { getRequest, postRequest } from '../services/base.service'

const PricingPlans = () => {
	const dispatch = useDispatch()
	const { user } = useSelector((state) => state.auth)
	const [isGetting, setIsGetting] = useState(false)
	const [planType, setPlanType] = useState(null)
	const [plans, setPlans] = useState(null)

	useEffect(() => {
		dispatch(changePageTitle('Pricing Plans'));
		fetchPlansDetails()
		console.log(user)
	}, [])

	const fetchPlansDetails = (params = {}) => {
		getRequest(`getPricingPlans`, { ...params }).then((res) => {
			console.log(res.result)
			setPlans(res.result)
		})
	}

	const handleGetStartedPlan = (plan) => {
		setIsGetting(true)
		setPlanType(plan)
		axios
			.post(`${process.env.REACT_APP_API_BASE_URL}/getTrainerPaymentLink`, {
				plan,
				trainer_id: user.id,
				redirect_url: window.location.origin,
			})
			.then((res) => {
				setIsGetting(false)
				setPlanType(null)
				const { result } = res.data
				window.location.href = result.url
			})
			.catch((err) => {
				setIsGetting(false)
				setPlanType(null)
			})
	}


	return (
		<Box flex="1" p="30">
			<Container maxW="container.lg">
				<Box bg="white" p="30px" borderRadius="15" boxShadow="md">
					{/* Filter bar */}
					<Center mb="10">
						<Heading
							as="h2"
							textAlign={'center'}
							fontSize="2rem"
							color="gray.800"
						>
							Pricing Plans
						</Heading>
					</Center>

					<Center justifyContent={'center'}>
						<GridItem colSpan={4}>
							<Grid gap={6} templateColumns={'repeat(2, 1fr)'}>
								<GridItem>
									<Box
										bg={'white'}
										rounded={10}
										borderWidth={'1px'}
										borderStyle={'solid'}
										borderColor={'gray.200'}
									>
										<Box
											textAlign={'center'}
											px={5}
											borderBottomWidth={'1px'}
											borderBottomStyle={'solid'}
											borderBottomColor={'gray.200'}
											py={5}
										>
											<Heading
												as="h4"
												mb={2}
												color={'gray.800'}
												fontSize={'2rem'}
											>
												Business Plan
											</Heading>
											<Text mt={2}>Higher Startup, lower fee</Text>
											<Text mt={2}>Best for businesses already make money</Text>
										</Box>
										<Stack p={5} spacing={4}>
											<Flex
												alignItems={'center'}
												justifyContent={'space-between'}
											>
												<Text fontSize={'1.25rem'} color={'gray.700'}>
													Startup:
												</Text>
												<Text
													fontSize={'1.75rem'}
													color={'primary.500'}
													as={'strong'}
													lineHeight={1}
												>
													${plans?.business}
												</Text>
											</Flex>
											<Flex
												alignItems={'center'}
												justifyContent={'space-between'}
											>
												<Text fontSize={'1.25rem'} color={'gray.700'}>
													Monthly:
												</Text>

												<Box>
													<Text
														fontSize={'1.75rem'}
														color={'primary.500'}
														as={'strong'}
														lineHeight={1}
													>
														${plans?.monthly}
													</Text>
												</Box>
											</Flex>
											<Flex
												alignItems={'center'}
												justifyContent={'space-between'}
											>
												<Text fontSize={'1.25rem'} color={'gray.700'}>
													Management Fee:
												</Text>
												<Text
													fontSize={'1.75rem'}
													color={'primary.500'}
													as={'strong'}
													lineHeight={1}
												>
													{plans?.commission_business}%
												</Text>
											</Flex>
										</Stack>

										<Box px={5} py={5}>
											<Button
												variant={'primary'}
												onClick={() => handleGetStartedPlan('business')}
												w={'full'}
												isLoading={isGetting && planType === 'business'}
												loadingText={'Getting started...'}
												size={'lg'}
											>
												Get Started
											</Button>
										</Box>
									</Box>
								</GridItem>

								<GridItem>
									<Box
										bg={'white'}
										rounded={10}
										borderWidth={'1px'}
										borderStyle={'solid'}
										borderColor={'gray.300'}
									>
										<Box
											textAlign={'center'}
											px={5}
											borderBottomWidth={'1px'}
											borderBottomStyle={'solid'}
											borderBottomColor={'gray.200'}
											py={5}
										>
											<Heading
												as="h4"
												mb={2}
												color={'gray.800'}
												fontSize={'2rem'}
											>
												Starter Plan
											</Heading>
											<Text mt={2}>Lower Startup, higher fee</Text>
											<Text mt={2}>Best for businesses just starting out</Text>
										</Box>
										<Stack p={5} spacing={4}>
											<Flex
												alignItems={'center'}
												justifyContent={'space-between'}
											>
												<Text fontSize={'1.25rem'} color={'gray.700'}>
													Startup:
												</Text>
												<Text
													fontSize={'1.75rem'}
													color={'primary.500'}
													as={'strong'}
													lineHeight={1}
												>
													${plans?.starter}
												</Text>
											</Flex>
											<Flex
												alignItems={'center'}
												justifyContent={'space-between'}
											>
												<Text fontSize={'1.25rem'} color={'gray.700'}>
													Monthly:
												</Text>

												<Box>
													<Text
														fontSize={'1.75rem'}
														color={'primary.500'}
														as={'strong'}
														lineHeight={1}
													>
														${plans?.monthly}
													</Text>
												</Box>
											</Flex>
											<Flex
												alignItems={'center'}
												justifyContent={'space-between'}
											>
												<Text fontSize={'1.25rem'} color={'gray.700'}>
													Management Fee:
												</Text>
												<Text
													fontSize={'1.75rem'}
													color={'primary.500'}
													as={'strong'}
													lineHeight={1}
												>
													{plans?.commission_starter}%
												</Text>
											</Flex>
										</Stack>

										<Box px={5} py={5}>
											{/* TODO: have to remove link */}
											<Button
												variant={'primary'}
												onClick={() => handleGetStartedPlan('starter')}
												isLoading={isGetting && planType === 'starter'}
												loadingText={'Getting started...'}
												w={'full'}
												size={'lg'}
											>
												Get Started
											</Button>
										</Box>
									</Box>
								</GridItem>
							</Grid>
						</GridItem>
					</Center>
				</Box>
			</Container>
		</Box>
	)
}

export default PricingPlans
