import { useState, useEffect } from 'react'
import {Box, Center, Alert, AlertIcon, AlertTitle, AlertDescription, 
	Tabs, TabList, TabPanels, Tab, TabPanel, useDisclosure
} from '@chakra-ui/react'
import PageBuilder from '../PageBuilder'
import { changePageTitle } from '../redux'
import { connect } from 'react-redux'
import SalesPageVideoModal from '../components/SalesPageVideoModal'

const AppBuilder = ({ changePageTitle }) => {
	const [pageTitle] = useState('Build')
	const { isOpen, onOpen, onClose } = useDisclosure()

	useEffect(() => {
		changePageTitle(pageTitle)
	})

	useEffect(() => {
		if (localStorage.getItem('salesPageOpen')) {
			return
		}
		onOpen()
		localStorage.setItem('salesPageOpen', true)
	}, [])

	return (
		<Box flex="1">
			{/*<Center display={['block', 'block', 'block', 'none']} p="5">
				<Alert
					status="warning"
					rounded={10}
					variant="subtle"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					textAlign="center"
					height="200px"
					width='70%'
				>
					{/* <AlertIcon boxSize="40px" mr={0} /> 
					<AlertTitle mt={4} mb={1} fontSize="lg">
						Better on desktop!
					</AlertTitle>
					<AlertDescription maxWidth="sm">
						Switch to a desktop computer to view your page builder
					</AlertDescription>
				</Alert>
			</Center>
			<Box display={['none', 'none', 'none', 'block']}>*/}
			<Box display='block'>
				<PageBuilder />
			</Box>
			<SalesPageVideoModal isOpen={isOpen} onClose={onClose} />
		</Box>
	)
}

const mapDispatchToProps = (dispatch) => {
	return {
		changePageTitle: (title) => dispatch(changePageTitle(title)),
	}
}

export default connect(null, mapDispatchToProps)(AppBuilder)
