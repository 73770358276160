import { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Avatar,
  HStack,
  Button,
  Tabs,
  Tab,
  Box,
  Image,
} from "@chakra-ui/react";
import { Container } from "@chakra-ui/react";
import { connect } from "react-redux";
import {
  Link,
  Link as RRLink,
  useNavigate,
  useLocation,
} from "react-router-dom";

const Header = ({ auth, bigWindow, helpers, setShowChatBox }) => {
  const [appSelected, setAppSelected] = useState(false);
  const [pageSelected, setPageSelected] = useState(false);
  const [appAccountSelected, setAppAccountSelected] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleExitUser = () => {
    const backUp = JSON.parse(localStorage.getItem("backUp"));
    localStorage.setItem("user", JSON.stringify(backUp));
    localStorage.removeItem("backUp");
    window.location.href = "/admin/trainers";
  };

  function selectAppTab() {
    if (pageSelected || appAccountSelected) {
      navigate("/trainer/webapp-builder");
    }
  }

  function selectPageTab() {
    if (appSelected || appAccountSelected) {
      navigate("/trainer/page-builder");
    }
  }

  function selectAppAccountTab() {
    if (appSelected || pageSelected) {
      navigate("/trainer/app-accounts");
    }
  }

  useEffect(() => {
    setPageSelected(false);
    setAppAccountSelected(false);
    setAppSelected(false);

    if (location.pathname === "/trainer/page-builder") {
      setPageSelected(true);
    } else if (location.pathname.includes("/trainer/webapp-builder")) {
      setAppSelected(true);
    } else {
      setAppAccountSelected(true);
    }
  }, [location.pathname]);
  
  return (
    <>
      {location.pathname === "/trainer/page-builder" ||
      location.pathname.includes("/trainer/webapp-builder") ||
      ((location.pathname.includes("/trainer/news") ||
        location.pathname === "/trainer/app-accounts" ||
        location.pathname === "/trainer/customers") &&
        bigWindow) ? (
        <Flex
          minHeight={'65px'}
          backgroundColor={'white'}
          pos={auth.user.role === "admin" && bigWindow && 'fixed'}
          alignItems="center"
          pe={bigWindow ? "30" : ""}
        >
          <Container display={bigWindow ? "" : "flex flex-col"} maxW="100%">
            {!bigWindow && (
              <Box display="flex" pb={2}>
                <Text
                  display="inline-block"
                  alignSelf="flex-end"
                  mr={4}
                  as={RRLink}
                  mt={
                    (location.pathname === "/trainer/page-builder" 
                    || location.pathname === "/trainer/webapp-builder" ) 
                    ? '8px' : ''
                  }
                  to={
                    auth.user.role === "admin" ? "/" : "/trainer/page-builder"
                  }
                >
                  <Image h="50px" src="/logo.svg" />
                </Text>
                {(location.pathname === "/trainer/page-builder" 
                  || location.pathname === "/trainer/webapp-builder") && 
                  (
                  <Text
                    fontSize={
                      location.pathname === "/trainer/dashboard"
                        ? ["2xl", "20px", "3xl"]
                        : ["3xl", "20px", "3xl"]
                    }
                    alignItems="center"
                    as={Flex}
                    mt="6px"
                    fontWeight="bold"
                    color="secondary.900"
                  >
                    {helpers.pageTitle}
                  </Text>
                )}
              </Box>
            )}
            <Flex justifyContent="between">
              {bigWindow && (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  as={RRLink}
                  to={
                    auth.user.role === "admin" ? "/" : "/trainer/page-builder"
                  }
                  my="10px"
                >
                  <Image h="30px" mr={2} src="/main logo.png" />
                </Box>
              )}

              {
                (location.pathname === "/trainer/page-builder" || location.pathname.includes("/trainer/webapp-builder")) &&
                (<Tabs
                  mt="12px"
                  ml="auto"
                  display="flex"
                  direction="ltr"
                  orientation="horizontal"
                  variant="enclosed"
                  pt="12px"
                >
                  <Tab
                    fontSize={["md", "md", "md", "md", "md", "lg"]}
                    fontWeight="bold"
                    onClick={selectAppTab}
                    bg={appSelected ? "#6B4779" : ""}
                    _focus={{
                      outline: "none",
                      boxShadow: "md",
                    }}
                    color={appSelected ? "white !important" : ""}
                  >
                    App Builder (Beta)
                  </Tab>
                  <Tab
                    fontSize={["md", "md", "md", "md", "md", "lg"]}
                    onClick={selectPageTab}
                    fontWeight="bold"
                    bg={pageSelected ? "white" : ""}
                    _focus={{
                      outline: "none",
                      boxShadow: "md",
                    }}
                  >
                    Sales Page
                  </Tab>
                  {bigWindow && (
                    <Tab
                      fontSize={["md", "md", "md", "md", "md", "lg"]}
                      fontWeight="bold"
                      onClick={selectAppAccountTab}
                      bg={appAccountSelected ? "white" : ""}
                      _focus={{
                        outline: "none",
                        boxShadow: "md",
                      }}
                    >
                      App Accounts
                    </Tab>
                  )}
                </Tabs>)
              }

              <Box ml="auto" mt="3">
                {auth.user.role !== "admin" ? (
                  <HStack spacing={4}>
                    {
                    bigWindow && 
                      (location.pathname === "/trainer/page-builder" ||
                        location.pathname === "/trainer/webapp-builder"
                      )
                      && (
                      <Flex
                        alignItems="center"
                      >
                        <Box pr="5">
                          {bigWindow  ?
                            (
                              <Flex
                                flexDirection={location.pathname === "/trainer/webapp-builder" ? 'row' : 'row-reverse'}
                              >
                                <Button
                                  colorScheme="primary"
                                  variant="outline"
                                  mr={5}
                                  onClick={handleExitUser}
                                  order="0"
                                >
                                  Exit Trainer Mode
                                </Button>
                                <Button
                                  bg="#669933"
                                  color="white"
                                  _hover={{
                                    opacity: 0.7

                                  }}
                                  borderTopLeftRadius="5px"
                                  borderTopRightRadius="5px"
                                  borderBottomRightRadius="5px"
                                  borderBottomLeftRadius="5px"
                                  onClick={() => {
                                    setShowChatBox(true)
                                  }}
                                  visibility={location.pathname === "/trainer/webapp-builder" ? 'visible' : 'hidden'}
                                >
                                  LAUNCH MY APP
                                </Button>
                              </Flex>
                          ) : (
                            <></>
                          )}
                        </Box>
                        <Flex
                          as={Link}
                          to={`/trainer/account/profile`}
                          alignItems="center"
                        >
                          <Avatar
                            name={auth.user.name}
                            src={auth.user.logo}
                            boxSize="40px"
                          />
                          <Text
                            ms="3"
                            display={["none", "none", "block", "block"]}
                          >
                            {auth.user.name}
                          </Text>
                        </Flex>
                      </Flex>
                    )}
                  </HStack>
                ) : (
                  <Flex alignItems="center">
                    <Avatar
                      name={auth.user.name}
                      src={auth.user.logo}
                      boxSize={["30px", "30px", "40px", "40px"]}
                    />

                    <Text ms="3" display={["none", "none", "block", "block"]}>
                      {auth.user.name}
                    </Text>
                  </Flex>
                )}
              </Box>
            </Flex>
          </Container>
        </Flex>
      ) : (
        <Flex
          minHeight={'65px'}
          backgroundColor={auth.user.role === "admin" && 'white'}
          pos={auth.user.role === "admin" && bigWindow && 'fixed'}
          zIndex={auth.user.role === "admin" && 999}
          width={auth.user.role === "admin" && 'full'}
          alignItems="center"
          px="4"
          justifyContent={bigWindow ? 'space-between' : ''}
        >
          {!bigWindow && (
            <Text
              mr={4}
              as={RRLink}
              to={auth.user.role === "admin" ? "/" : "/trainer/page-builder"}
            >
              <Image w="50px" h="50px" src="/logo.svg" />
            </Text>
          )}
          {bigWindow && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              as={RRLink}
              my="10px"
              to={auth.user.role === "admin" ? "/" : "/trainer/page-builder"}
            >
              <Image h="30px" mr={2} src="/main logo.png" />
            </Box>
          )}
            <Flex>
              {!bigWindow && (
                <Text
                  fontSize={
                    location.pathname === "/trainer/dashboard"
                      ? ["2xl", "20px", "3xl"]
                      : ["3xl", "20px", "3xl"]
                  }
                  alignItems="center"
                  as={Flex}
                  fontWeight="bold"
                  color="secondary.900"
                >
                  {helpers.pageTitle}
                </Text>
              )}

              {auth.user.role === "admin" ? (
                <HStack spacing={4}>
                  {(
                    <>
                      {bigWindow ?
                        (
                          <>
                            <Button
                              colorScheme="primary"
                              variant="outline"
                              mr={3}
                              onClick={handleExitUser}
                            >
                              Exit Trainer Mode
                            </Button>
                            <Button
                              bg="#669933"
                              color="white"
                              _hover={{
                                opacity: 0.7

                              }}
                              borderTopLeftRadius="5px"
                              borderTopRightRadius="5px"
                              borderBottomRightRadius="5px"
                              borderBottomLeftRadius="5px"
                              onClick={(e) => {
                                e.stopPropagation(); 
                                setShowChatBox(true)
                              }}
                            >
                              LAUNCH MY APP
                            </Button>
                          </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  {bigWindow && (
                    <Flex
                      as={Link}
                      to={`/trainer/account/profile`}
                      alignItems="center"
                      pe="30"
                    >
                      <Avatar
                        name={auth.user.name}
                        src={auth.user.logo}
                        boxSize="40px"
                      />

                      <Text ms="3" display={["none", "none", "block", "block"]}>
                        {auth.user.name}
                      </Text>
                    </Flex>
                  )}
                </HStack>
              ) : (
                <Box mt="-2px">
                  {bigWindow && (
                    <Flex  as={Link}
                      to={`/trainer/account/profile`}
                      alignItems="center"
                      pe="30"
                    >
                      <Avatar
                        name={auth.user.name}
                        src={auth.user.logo}
                        boxSize="40px"
                      />

                      <Text ms="3" display={["none", "none", "block", "block"]}>
                        {auth.user.name}
                      </Text>
                    </Flex>
                  )}
                </Box>
              )}
            </Flex>
        </Flex>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    helpers: state.helpers,
  };
};

export default connect(mapStateToProps)(Header);
