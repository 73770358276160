import { useState, useEffect } from 'react'
import { Flex, Box, Container, Avatar, Text, Stack, 
	Button, useToast
} from '@chakra-ui/react'
import { changePageTitle, logout } from '../redux'
import { connect, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import AppLink from '../routes/AppLink'
import { toastConfig } from '../helpers/appConfig'

const AppAccountLayout = ({ auth, changePageTitle, handleLogout }) => {
	const [pageTitle] = useState('Setting')
	const [bigWindow, setBigWindow] = useState(true)
	const { user } = useSelector((state) => state.auth)
	const toast = useToast()
	const navigate = useNavigate()

	function handleExitUser() {
		const backUp = JSON.parse(localStorage.getItem('backUp'))
		localStorage.setItem('user', JSON.stringify(backUp))
		localStorage.removeItem('backUp')
		window.location.href = '/admin/trainers'
	}

	useEffect(() => {
        if (window.innerWidth < 600) {
            setBigWindow(false)
        }

		changePageTitle(pageTitle)
	}, [])

	return (
		<Box flex="1" pb={!bigWindow && '124px'}>
			<Box pt="30px" pe="30px" ps="30px" bg="white" mb="30px">
				<Container maxW="container.lg">
					<Flex alignItems="center">
						<Avatar boxSize="80px" name={auth.user.name} src={auth.user.logo} />
						<Box flex="1" ps="20px">
							<Text
								fontSize="2xl"
								color={'secondary.500'}
								fontWeight="semibold"
							>
								{auth.user.name}
							</Text>
							<Text mt="2">{auth.user.email}</Text>
						</Box>
					</Flex>

					<Box pt="30px">
						<Stack direction={`row`}>
							<Text
								as={AppLink}
								to="/trainer/account/profile"
								ps="2"
								pe="2"
								pb="2"
								borderBottom="2px"
								borderColor="transparent"
								activeStyle={{ borderColor: '#5e59ff' }}
							>
								Account Information
							</Text>

							<Text
								as={AppLink}
								to="/trainer/account/payment-settings"
								ps="2"
								pe="2"
								pb="2"
								borderBottom="2px"
								borderColor="transparent"
								activeStyle={{ borderColor: '#5e59ff' }}
							>
								Payment Settings
							</Text>

							<Text
								as={AppLink}
								to="/trainer/account/change-password"
								ps="2"
								pe="2"
								pb="2"
								borderBottom="2px"
								borderColor="transparent"
								activeStyle={{ borderColor: '#5e59ff' }}
							>
								Change Password
							</Text>
						</Stack>
					</Box>
				</Container>
			</Box>

			<Box>
				<Container maxW="container.lg">
					<Box bg="white" p="30px" borderRadius="15" boxShadow="md">
						<Outlet />
					</Box>
					{!bigWindow && (
						<Flex justifyContent='center'>
							<Button
								mt={5}
								type="submit"
								colorScheme='red'
								rounded='md'
								w='75%'
								fontWeight='bold'
								onClick={() =>
									user?.admin
										? handleExitUser()
										: handleLogout((message, status) => {
												toast(toastConfig(message, status))
												navigate('/')
										})
								}
							>
								Sign Out
							</Button>
						</Flex>
					)}
				</Container>
			</Box>
		</Box>
	)
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		changePageTitle: (title) => dispatch(changePageTitle(title)),
		handleLogout: (callback) => dispatch(logout(callback))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AppAccountLayout)
