import React, { useContext } from 'react'
import {
	Badge,
	Box,
	Button,
	Flex,
	Spacer,
	Text,
	IconButton,
} from '@chakra-ui/react'
import { FaTh } from 'react-icons/fa'
import { BsGrid } from 'react-icons/bs'
import { PageBuilderContext } from '../context/PageBuilderContext'
import SectionSetting from './settingPanel/SectionSetting'
import ColumnSetting from './settingPanel/ColumnSetting'
import ComponentList from './ComponentList'
import HeadingSetting from './settingPanel/basic/HeadingSetting'
import ParagraphSetting from './settingPanel/basic/ParagraphSetting'
import ImageSetting from './settingPanel/basic/ImageSetting'
import ButtonSetting from './settingPanel/basic/ButtonSetting'
import SliderSetting from './settingPanel/compound/SliderSetting'
import VideoSetting from './settingPanel/basic/VideoSetting'
import DividerSetting from './settingPanel/basic/DividerSetting'
import ListSetting from './settingPanel/basic/ListSetting'
import MediaObjectSetting from './settingPanel/compound/MediaObjectSetting'
import EmbedCodeSettings from './settingPanel/EmbedCodeSettings'

function Settings() {
	const [state, dispatch] = useContext(PageBuilderContext)
	let settingElement = null

	const backToComponent = () => {
		dispatch({ type: 'SHOW_COMPONENTS' })
	}
	const componentSetting = () => {
		switch (state.selected.name) {
			case 'heading':
				return <HeadingSetting />
			case 'paragraph':
				return <ParagraphSetting />
			case 'image':
				return <ImageSetting />
			case 'video':
				return <VideoSetting />
			case 'button':
				return <ButtonSetting />
			case 'slider':
				return <SliderSetting />
			case 'divider':
				return <DividerSetting />
			case 'list':
				return <ListSetting />
			case 'mediaObject':
				return <MediaObjectSetting />
			case 'pricing':
				return <div>Pricing Settings</div>
			case 'embedCode':
				return <EmbedCodeSettings />
			default:
				return null
		}
	}

	if (state.selected) {
		switch (state.selected.type) {
			case 'section':
				settingElement = (
					<Box>
						<Flex alignItems="center" justifyContent={'space-between'}>
							<Text
								py={4}
								fontWeight="semibold"
								fontSize="lg"
								color="secondary.800"
								letterSpacing="0.03rem"
							>
								SECTION
							</Text>

							<IconButton
								size="sm"
								bg={'secondary.100'}
								color="secondary.800"
								onClick={backToComponent}
								icon={<BsGrid />}
							/>
						</Flex>
						<SectionSetting />
					</Box>
				)
				break

			case 'column':
				settingElement = (
					<Box>
						<Flex alignItems="center" justifyContent={'space-between'}>
							<Text
								py={4}
								fontWeight="semibold"
								fontSize="lg"
								color="secondary.800"
								letterSpacing="0.03rem"
							>
								COLUMN
							</Text>

							<IconButton
								size="sm"
								bg={'secondary.100'}
								color="secondary.800"
								onClick={backToComponent}
								icon={<BsGrid />}
							/>
						</Flex>

						<ColumnSetting />
					</Box>
				)
				break
			case 'component':
				settingElement = (
					<Box>
						<Flex alignItems="center" justifyContent={'space-between'}>
							<Text
								py={4}
								fontWeight="semibold"
								fontSize="lg"
								color="secondary.800"
								letterSpacing="0.03rem"
							>
								{state.selected.name.toUpperCase()}
							</Text>

							<IconButton
								size="sm"
								bg={'secondary.100'}
								color="secondary.800"
								onClick={backToComponent}
								icon={<BsGrid />}
							/>
						</Flex>

						{componentSetting()}
					</Box>
				)
				break
			default:
		}
	} else {
		settingElement = <ComponentList />
	}

	return settingElement
}

export default Settings
