export const paddingConfigHandler = (padding, updateSide, updateValue) => {
    const value = parseInt(updateValue);
    const style = {
        top: padding.top,
        right: padding.right,
        bottom: padding.bottom,
        left: padding.left,
    }
    switch (updateSide) {
        case "top":
            style.top = value;
            break;
        case "right":
            style.right = value;
            break;
        case "bottom":
            style.bottom = value;
            break;
        case "left":
            style.left = value;
            break;    
        default:
            break;
    }

    return style;
}

export const marginConfigHandler = (margin, updateSide, updateValue) => {
    const value = parseInt(updateValue);
    const style = {
        top: margin.top,
        right: margin.right,
        bottom: margin.bottom,
        left: margin.left,
    }
    switch (updateSide) {
        case "top":
            style.top = value;
            break;
        case "right":
            style.right = value;
            break;
        case "bottom":
            style.bottom = value;
            break;
        case "left":
            style.left = value;
            break;    
        default:
            break;
    }

    return style;
}

export const borderConfigHandler = (border, updateSide, updateValue) => {
    const value = parseInt(updateValue);
    switch (updateSide) {
        case "top":
            border.top = value;
            break;
        case "right":
            border.right = value;
            break;
        case "bottom":
            border.bottom = value;
            break;
        case "left":
            border.left = value;
            break;    
        default:
            break;
    }

    return border;
}

export const textConfigHandler = (text, property, value) => {
    const style = {
        size: text.size,
        color: text.color,
        weight: text.weight,
        align: text.align,
        transform: text.transform,
        lineHeight: text.lineHeight ? text.lineHeight : 'normal'
    }
    switch (property) {
        case "size":
            style.size = value;
            break;
        case "color":
            style.color = value;
            break;
        case "weight":
            style.weight = value;
            break;
        case "align":
            style.align = value;
            break;   
        case "transform":
            style.transform = value;
            break;    
        case "lineHeight":
            style.lineHeight = value;
            break;      
        default:
            break;
    }

    return style;
}

export const roundedConfigHandler = (value) => {
    return {
        topLeft: value,
        topRight: value,
        bottomLeft: value,
        bottomRight: value,
    }
}

export const formFiledType = [
    { name: 'Text', value: 'text' },
    { name: 'Email', value: 'email' },
    { name: 'Number', value: 'number' },
    { name: 'Checkbox', value: 'checkbox' },
    { name: 'Radio', value: 'radio' },
    { name: 'Select', value: 'select' },
    { name: 'Textarea', value: 'textarea' },
    { name: 'File', value: 'file' }
]