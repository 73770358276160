import { connect } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { getRequest, postRequest } from '../services/base.service'

const AuthRequire = ({ children, auth }) => {
	let location = useLocation()

	if (!auth.user) {
		return <Navigate to="/login" state={{ from: location }} />
	}

	if (auth.user) {
		if (auth.user?.role === 'trainer') {
			if (!auth.user?.app_name) {
				if (!auth.user?.business_name) {
					return <Navigate to="/signup/2" state={{ from: location }} />
				}	
			}
		}
		if ( auth.user?.role === 'admin' ) return children
		if ( auth.user?.admin ) return children
	}

	getRequest(`trainerPaidSubscription`, {}).then((res) => {
		if(res.status == "success") {
			localStorage.setItem('trainerSubscription', JSON.stringify(res.result))
			//return <Navigate to="/trainer/pricing-plans" state={{ from: location }} />
		}

	})

		/*
	  let trainerSubscription = JSON.parse(localStorage.getItem('trainerSubscription'))
		if ( !trainerSubscription?.status ) {
			if( location.pathname != '/trainer/pricing-plans' && location.pathname != '/register/business-info' ) {
				return <Navigate to="/trainer/pricing-plans" state={{ from: location }} />
			}
		}
		*/
	return children
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	}
}



export default connect(mapStateToProps)(AuthRequire)
