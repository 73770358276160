import React from 'react'
import {
	Flex,
	Box,
	Image,
	Text,
	Button,
	Link,
	Divider,
	List,
	ListItem,
	ListIcon,
	OrderedList,
	UnorderedList,
} from '@chakra-ui/react'
import {
	FaCheckCircle,
	FaCheckSquare,
	FaArrowAltCircleRight,
	FaArrowRight,
} from 'react-icons/fa'
import tinycolor from 'tinycolor2'
import MediaObject from './mediaObject/MediaObject'
import PricingRender from './PricingRender'
import Video from '../PageBuilder/components/compound/Video'
import EmbedCode from '../PageBuilder/components/EmbedCode'

const ComponentViewer = ({ components }) => {
	const listIcons = {
		FaCheckCircle: FaCheckCircle,
		FaCheckSquare: FaCheckSquare,
		FaArrowAltCircleRight: FaArrowAltCircleRight,
		FaArrowRight: FaArrowRight,
	}

	const getElement = (component) => {
		let element = null
		switch (component.type) {
			case 'heading':
				element = (
					<Text
						pt={`${component.style.padding.top}px`}
						pr={`${component.style.padding.right}px`}
						pb={`${component.style.padding.bottom}px`}
						pl={`${component.style.padding.left}px`}
						mt={`${component.style.margin.top}px`}
						mr={`${component.style.margin.right}px`}
						mb={`${component.style.margin.bottom}px`}
						ml={`${component.style.margin.left}px`}
						color={component.style.text.color}
						fontSize={`${component.style.text.size}px`}
						fontWeight={component.style.text.weight}
						textAlign={component.style.text.align}
						textTransform={component.style.text.transform}
						fontFamily={
							component.style.fontFamily === 'default'
								? 'Poppins'
								: component.style.fontFamily
						}
						as="h2"
						whiteSpace="pre-wrap"
						lineHeight={
							component.style.text?.lineHeight
								? component.style.text?.lineHeight
								: 'normal'
						}
						letterSpacing={
							component.style.text?.letterSpacing
								? `${component.style.text?.letterSpacing}px`
								: 'normal'
						}
					>
						{component.link.url ? (
							<Link
								href={component.link.url}
								isExternal
								textDecoration="underline"
							>
								{component.data}
							</Link>
						) : (
							component.data
						)}
					</Text>
				)
				break

			case 'paragraph':
				element = (
					<Text
						pt={`${component.style.padding.top}px`}
						pr={`${component.style.padding.right}px`}
						pb={`${component.style.padding.bottom}px`}
						pl={`${component.style.padding.left}px`}
						mt={`${component.style.margin.top}px`}
						mr={`${component.style.margin.right}px`}
						mb={`${component.style.margin.bottom}px`}
						ml={`${component.style.margin.left}px`}
						color={component.style.text.color}
						fontSize={`${component.style.text.size}px`}
						fontWeight={component.style.text.weight}
						textAlign={component.style.text.align}
						textTransform={component.style.text.transform}
						fontFamily={
							component.style.fontFamily === 'default'
								? 'Poppins'
								: component.style.fontFamily
						}
						lineHeight={
							component.style.text?.lineHeight
								? component.style.text?.lineHeight
								: 'normal'
						}
						letterSpacing={
							component.style.text?.letterSpacing
								? `${component.style.text?.letterSpacing}px`
								: 'normal'
						}
					>
						{component.link.url ? (
							<Link
								href={component.link.url}
								isExternal
								textDecoration="underline"
							>
								{component.data}
							</Link>
						) : (
							component.data
						)}
					</Text>
				)
				break

			case 'image':
				element = component.link.hasLink ? (
					<Link href={component.link.url} isExternal>
						<Box display="flex" justifyContent={component.style.justifyContent}>
							<Image
								src={
									component.url?.path
										? component.url?.path
										: 'https://via.placeholder.com/1200x400/CBD5E0/718096/?text=Image'
								}
								maxWidth="100%"
								borderTop={`${component.style.border.top}px solid #ced8e2`}
								borderRight={`${component.style.border.right}px solid #ced8e2`}
								borderBottom={`${component.style.border.bottom}px solid #ced8e2`}
								borderLeft={`${component.style.border.left}px solid #ced8e2`}
								pt={`${component.style.padding.top}px`}
								pr={`${component.style.padding.right}px`}
								pb={`${component.style.padding.bottom}px`}
								pl={`${component.style.padding.left}px`}
								mt={`${component.style.margin.top}px`}
								mr={`${component.style.margin.right}px`}
								mb={`${component.style.margin.bottom}px`}
								ml={`${component.style.margin.left}px`}
								width={component.style.width}
								height={component.style.height}
								rounded={component.style.rounded.topLeft}
								objectFit="cover"
							/>
						</Box>
					</Link>
				) : (
					<Box display="flex" justifyContent={component.style.justifyContent}>
						<Image
							src={
								component.url?.path
									? component.url?.path
									: 'https://via.placeholder.com/1200x400/CBD5E0/718096/?text=Image'
							}
							maxWidth="100%"
							borderTop={`${component.style.border.top}px solid #ced8e2`}
							borderRight={`${component.style.border.right}px solid #ced8e2`}
							borderBottom={`${component.style.border.bottom}px solid #ced8e2`}
							borderLeft={`${component.style.border.left}px solid #ced8e2`}
							pt={`${component.style.padding.top}px`}
							pr={`${component.style.padding.right}px`}
							pb={`${component.style.padding.bottom}px`}
							pl={`${component.style.padding.left}px`}
							mt={`${component.style.margin.top}px`}
							mr={`${component.style.margin.right}px`}
							mb={`${component.style.margin.bottom}px`}
							ml={`${component.style.margin.left}px`}
							width={component.style.width}
							height={component.style.height}
							rounded={component.style.rounded.topLeft}
							objectFit="cover"
							loading="lazy"
						/>
					</Box>
				)
				break
			case 'video':
				element = <Video component={component} />
				break
			case 'button':
				element = (
					<Box
						display="flex"
						justifyContent={component.style.justifyContent}
						mt={`${component.style.margin.top}px`}
						mb={`${component.style.margin.bottom}px`}
						ml={`${component.style.margin.left}px`}
						mr={`${component.style.margin.right}px`}
					>
						<Button
							maxWidth="100%"
							whiteSpace="normal"
							width={component.style.width}
							height={component.style.height}
							borderTop={`${component.style.border.top}px solid #90A3AE`}
							borderRight={`${component.style.border.right}px solid #90A3AE`}
							borderBottom={`${component.style.border.bottom}px solid #90A3AE`}
							borderLeft={`${component.style.border.left}px solid #90A3AE`}
							pt={[
								`${component.style.padding.top * 0.7}px`,
								`${component.style.padding.top * 0.75}px`,
								`${component.style.padding.top}px`,
							]}
							pb={[
								`${component.style.padding.bottom * 0.7}px`,
								`${component.style.padding.bottom * 0.75}px`,
								`${component.style.padding.bottom}px`,
							]}
							pl={[
								`${component.style.padding.left * 0.7}px`,
								`${component.style.padding.left * 0.75}px`,
								`${component.style.padding.left}px`,
							]}
							pr={[
								`${component.style.padding.right * 0.7}px`,
								`${component.style.padding.right * 0.75}px`,
								`${component.style.padding.right}px`,
							]}
							color={component.style.text.color}
							bg={component.style.bgColor}
							fontSize={`${component.style.text.size}px`}
							fontWeight={component.style.text.weight}
							textTransform={component.style.text.transform}
							justifyContent={component.style.text.align}
							rounded={`${component.style.rounded.topLeft}`}
							_hover={{
								bg: tinycolor(component.style.bgColor).lighten().toString(),
							}}
						>
							{component.link.url ? (
								<Link
									href={component.link.url}
									isExternal
									textDecoration="underline"
								>
									{component.data}
								</Link>
							) : (
								component.data
							)}
						</Button>
					</Box>
				)
				break

			case 'mediaObject':
				element = <MediaObject component={component} />
				break

			case 'divider':
				element = (
					<Box display="flex" justifyContent={component.style.justifyContent}>
						<Divider
							mt={`${component.style.margin.top}px`}
							mr={`${component.style.margin.right}px`}
							mb={`${component.style.margin.bottom}px`}
							ml={`${component.style.margin.left}px`}
							width={component.style.width}
							borderColor={component.style.border.color}
							borderTopWidth={component.style.border.top}
							borderBottomWidth="0"
							borderStyle={component.style.border.style}
							rounded={component.style.rounded.topLeft}
						/>
					</Box>
				)
				break

			case 'list':
				const listItems = component.listItems.map((list) => {
					return (
						<ListItem key={list.id} fontSize={component.style.text.size}>
							<Text>{list.text}</Text>
						</ListItem>
					)
				})
				element = (
					<Box display="flex" justifyContent={component.style.justifyContent}>
						<Box
							pt={`${component.style.padding.top}px`}
							pr={`${component.style.padding.right}px`}
							pb={`${component.style.padding.bottom}px`}
							pl={`${component.style.padding.left}px`}
							mt={`${component.style.margin.top}px`}
							mr={`${component.style.margin.right}px`}
							mb={`${component.style.margin.bottom}px`}
							ml={`${component.style.margin.left}px`}
							color={component.style.text.color}
							fontSize={`${component.style.text.size}px`}
							fontWeight={component.style.text.weight}
							fontFamily={
								component.style.fontFamily === 'default'
									? 'Poppins'
									: component.style.fontFamily
							}
							textAlign={component.style.text.align}
							textTransform={component.style.text.transform}
							maxWidth="100%"
							width={component.style.width}
							rounded={component.style.rounded.topLeft}
							borderTop={`${component.style.border.top}px solid #90A3AE`}
							borderRight={`${component.style.border.right}px solid #90A3AE`}
							borderBottom={`${component.style.border.bottom}px solid #90A3AE`}
							borderLeft={`${component.style.border.left}px solid #90A3AE`}
						>
							{component.listStyle === 'unordered' ? (
								<UnorderedList spacing={3}>{listItems}</UnorderedList>
							) : component.listStyle === 'ordered' ? (
								<OrderedList spacing={3}>{listItems}</OrderedList>
							) : (
								<List spacing={3}>
									{component.listItems.map((list) => (
										<ListItem
											key={list.id}
											display="flex"
											fontSize={`${component.style.text.size}px`}
										>
											<Flex alignItems="flex-start">
												<Text>
													{component.listIcon !== 'none' ? (
														<ListIcon
															mt="-4px"
															verticalAlign="middle"
															as={listIcons[component.listIcon]}
															w={Math.ceil(component.style.text.size / 4)}
															h={Math.ceil(component.style.text.size / 4)}
														/>
													) : null}
												</Text>
												<Text>{list.text}</Text>
											</Flex>
										</ListItem>
									))}
								</List>
							)}
						</Box>
					</Box>
				)
				break

			case 'pricing':
				element = <PricingRender />
				break

			case 'embedCode':
				element = <EmbedCode component={component} />
				break

			default:
				break
		}

		return element
	}

	return components.map((component, index) => (
		<Box key={`${component.id}-${index}`}>{getElement(component)}</Box>
	))
}

export default ComponentViewer
