import {
	Avatar,
	Box,
	Button,
	Center,
	Container,
	Divider,
	Grid,
	GridItem,
	Heading,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	Stack,
	Select,
	FormControl,
	useToast,
	useDisclosure,
	Popover,
	Input,
	FormLabel,
	PopoverTrigger,
	PopoverContent,
	Flex,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { changePageTitle } from '../redux'
import { useDispatch } from 'react-redux'
import { FiEdit3, FiCopy, FiExternalLink } from 'react-icons/fi'
import { getRequest, postRequest } from '../services/base.service'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { toastConfig } from '../helpers/appConfig'
import { useForm } from 'react-hook-form'

const TrainerDetails = () => {
	const [trainer, setTrainer] = useState(null)
	const [industries, setIndustries] = useState([])
	const { id } = useParams()
	const toast = useToast()
	const [fieldDefault, setFieldDefault] = useState({
		business_type: null
	})
	//Error
	const [fieldError, setFieldError] = useState({
		business_type: null
	})
	const [productIDError, setProductIDError] = useState(null)
	const [commissionError, setCommissionError] = useState(null)
	const [appNameError, setAppNameError] = useState(null)
	const [appIdError, setAppIdError] = useState(null)
	const [accountIdError, setAccountIdError] = useState(null)
	const [websiteError, setWebsiteError] = useState(null)
	const [nameError, setNameError] = useState(null)
	const [emailError, setEmailError] = useState(null)
	const [passwordError, setPasswordError] = useState(null)
	const [socialMediaError, setSocialMediaError] = useState(null)
	const [socialHandleError, setSocialHandleError] = useState(null)
	const [domainError, setDomainError] = useState(null)
	const [iosAppLinkError, setIosAppLinkError] = useState(null)
	const [androidAppLinkError, setAndroidAppLinkError] = useState(null)
	const [webAppLinkError, setWebAppLinkError] = useState(null)
	//Updating
	const [fieldUpdating, setFieldUpdating] = useState({
		business_type: false
	})
	const [isProductIDUpdating, setIsProductIDUpdating] = useState(false)
	const [commissionUpdating, setCommissionUpdating] = useState(false)
	const [appNameUpdating, setAppNameUpdating] = useState(false)
	const [appIdUpdating, setAppIdUpdating] = useState(false)
	const [accountIdUpdating, setAccountIdUpdating] = useState(false)
	const [websiteUpdating, setWebsiteUpdating] = useState(false)
	const [nameUpdating, setNameUpdating] = useState(false)
	const [emailUpdating, setEmailUpdating] = useState(false)
	const [passwordUpdating, setPasswordUpdating] = useState(false)
	const [socialMediaUpdating, setSocialMediaUpdating] = useState(false)
	const [socialHandleUpdating, setSocialHandleUpdating] = useState(false)
	const [domainUpdating, setDomainUpdating] = useState(false)
	const [iosAppLinkUpdating, setIosAppLinkUpdating] = useState(false)
	const [androidAppLinkUpdating, setAndroidAppLinkUpdating] = useState(false)
	const [webAppLinkUpdating, setWebAppLinkUpdating] = useState(false)
	//Open/Close
	const { onOpen: onProductOpen, onClose: onProductClose, isOpen: isProductOpen } = useDisclosure()
	const { onOpen: onCommissionOpen, onClose: onCommissionClose, isOpen: isCommissionOpen } = useDisclosure()
	const { onOpen: onAppNameOpen, onClose: onAppNameClose, isOpen: isAppNameOpen } = useDisclosure()
	const { onOpen: onAppIdOpen, onClose: onAppIdClose, isOpen: isAppIdOpen } = useDisclosure()
	const { onOpen: onAccountIdOpen, onClose: onAccountIdClose, isOpen: isAccountIdOpen } = useDisclosure()
	const { onOpen: onWebsiteOpen, onClose: onWebsiteClose, isOpen: isWebsiteOpen } = useDisclosure()
	const { onOpen: onNameOpen, onClose: onNameClose, isOpen: isNameOpen } = useDisclosure()
	const { onOpen: onEmailOpen, onClose: onEmailClose, isOpen: isEmailOpen } = useDisclosure()
	const { onOpen: onPasswordOpen, onClose: onPasswordClose, isOpen: isPasswordOpen } = useDisclosure()
	const { onOpen: onSocialMediaOpen, onClose: onSocialMediaClose, isOpen: isSocialMediaOpen } = useDisclosure()
	const { onOpen: onSocialHandleOpen, onClose: onSocialHandleClose, isOpen: isSocialHandleOpen } = useDisclosure()
	const { onOpen: onDomainOpen, onClose: onDomainClose, isOpen: isDomainOpen } = useDisclosure()
	const { onOpen: onIosAppLinkOpen, onClose: onIosAppLinkClose, isOpen: isIosAppLinkOpen } = useDisclosure()
	const { onOpen: onAndroidAppLinkOpen, onClose: onAndroidAppLinkClose, isOpen: isAndroidAppLinkOpen } = useDisclosure()
	const { onOpen: onWebAppLinkOpen, onClose: onWebAppLinkClose, isOpen: isWebAppLinkOpen } = useDisclosure()
	const { onOpen: onBusinessTypeOpen, onClose: onBusinessTypeClose, isOpen: isBusinessTypeOpen } = useDisclosure()
	
	const dispatch = useDispatch()

	const {
		register,
		resetField,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm()

	useEffect(() => {
		dispatch(changePageTitle('Trainer Details'))
		fetchIndustries()
		fetchTrainerDetails(id)
	}, [id])

	const fetchTrainerDetails = (id, params = {}) => {
		getRequest(`admin/trainer/${id}`, { ...params }).then((res) => {
			console.log(res.result)
			setTrainer(res.result)
		})
	}

	const fetchIndustries = () => {
		getRequest(`industries`)
			.then((res) => {
				setIndustries(res.result)
			})
			.catch((error) => {
				console.log(error)
			})
	}
	
	const handleAccessToTrainerAccount = () => {
		postRequest(`admin/profile-switch`, { trainer_id: id })
			.then((res) => {
				const backUp = JSON.parse(localStorage.getItem('user'))
				localStorage.setItem('backUp', JSON.stringify(backUp))
				localStorage.setItem('user', JSON.stringify(res.result))

				window.location.href = '/trainer/webapp-builder'
			})
			.catch((err) => {})
	}

	const copyText = (text) => {
		navigator.clipboard.writeText(text).then(
			function () {
				toast(toastConfig('Copied', 'success'))
			},
			function (err) {
				toast(toastConfig('Sorry! Could not copy text.', 'error'))
			}
		)
	}

	const handlePasswordUpdate = (values) => {
		setPasswordUpdating(true)
		postRequest(`admin/this-update-trainer/${id}`, {
			password: values.password
		})
			.then((res) => {
				values.password = ''
				if (res.status !== 'error') {
					values.password = ''
					fetchTrainerDetails(id)
					resetField('password')
					toast(toastConfig(res.message, 'success'))
					onPasswordClose()
				} else {
					setPasswordError(res.message)
				}
				setPasswordUpdating(false)
				reset()
			})
			.catch((err) => {
				resetField('password')
				setPasswordUpdating(false)
			})
	}

	let charges = [];
	for (let i = 0; i < trainer?.charges.length; i++) {
		if(trainer?.charges[i].amount_captured <= 0) continue;
		charges.push(
			<Tr key={i}>
				<Td>{moment(trainer?.charges[i].stripe_charge_created).format('MMM D, YYYY h:mm:ss a')}</Td>
				<Td textAlign={'center'}>${( (trainer?.charges[i].amount_captured - trainer?.charges[i].application_fee_amount) / 100).toFixed(2)}</Td>
				<Td isNumeric>
					<Text color={'green'}>Success</Text>
				</Td>
			</Tr>
		);
	}
	
	return (
		<Box flex="1" p={['0','30','30','30']} pb={['120px','30px','30px','30px']}>
			<Container maxW="container.lg">
				<Box
					bg="white"
					p="30px"
					borderRadius="15"
					boxShadow="md"
					pos={'relative'}
				>
					<Center>
						<Box textAlign={'center'}>
							<Avatar display={'inline-block'} size={'lg'} name={'John Doe'} />
							<Heading color={'gray.800'} as="h4" mt={4} fontSize={'1.75rem'}>
								{trainer?.name}
							</Heading>
							<Text mt={2}>{trainer?.email}</Text>
							<Text mt={2}>
								<strong>Joining Date:</strong>{' '}
								{moment(trainer?.created_at).format('ll')}
							</Text>

							<Box textAlign={'center'} mt={4}>
								<Button
									onClick={handleAccessToTrainerAccount}
									// as={Link}
									// to={'/'}
									// variant={'link'}
									color={'blue'}
									leftIcon={<FiExternalLink />}
									size={'sm'}
									disabled={!trainer?.app_name && !trainer?.firebase_app_id}
								>
									Go to Dashboard
								</Button>
							</Box>
						</Box>
					</Center>

					<Divider my={7} />

					<Heading mb='5' fontSize={['1.5rem','1.15rem','1.15rem','1.15rem']} color={'gray.800'}>
						Business
					</Heading>

					<Grid 
						gap={6} 
						templateColumns={['repeat(1, 1fr)','repeat(3, 1fr)','repeat(3, 1fr)','repeat(3, 1fr)']}
					>
						<GridItem>
							<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
								App ID
							</Text>
							<Box mt="2" color={'gray.800'}>
								{trainer?.firebase_app_id ? `${trainer.firebase_app_id}` : '-'}
								<Popover
									isOpen={isAppIdOpen}
									onOpen={onAppIdOpen}
									onClose={onAppIdClose}
									placement="right"
									closeOnBlur={false}
								>
									<PopoverTrigger>
										<Button
											ml={3}
											color={'primary.500'}
											as={'button'}
											variant={'link'}
										>
											<FiEdit3 />
										</Button>
									</PopoverTrigger>

									<PopoverContent p="5">
										<Stack
											as={'form'}
											onSubmit={handleSubmit(e => {
												setAppIdUpdating(true)
												postRequest(`admin/this-update-trainer/${id}`, {
													firebase_app_id: e.firebase_app_id
												})
													.then((res) => {
														if (res.status !== 'error') {
															fetchTrainerDetails(id)
															onAppIdClose()
															reset()
															toast(toastConfig(res.message, 'success'))
														} else {
															setAppIdError(res.message)
														}
														setAppIdUpdating(false)
													})
													.catch((err) => {
														setAppIdUpdating(false)
													})
											})}
											spacing={5}
										>
											<FormControl>
												<FormLabel>App ID</FormLabel>
												<Input
													defaultValue={
														trainer?.firebase_app_id
															? trainer.firebase_app_id
															: ''
													}
													borderColor={'gray.300'}
													{...register('firebase_app_id', { required: false })}
													rounded={'full'}
													placeholder={'Enter App ID'}
												/>
												{errors.firebase_app_id && (
													<Text fontSize="sm" mt="2" color="red.500">
														This field is required
													</Text>
												)}
												{appIdError && (
													<Text fontSize="sm" mt="2" color="red.500">
														{appIdError}
													</Text>
												)}
											</FormControl>

											<Flex spacing={5} justifyContent={'flex-end'}>
												<Button
													type={'button'}
													onClick={() => {
														onAppIdClose()
														setAppIdError(null)
													}}
													variant={'ghost'}
												>
													Close
												</Button>
												<Button
													type={'submit'}
													isLoading={appIdUpdating}
													loadingText={'Updating...'}
													variant={'primary'}
												>
													Update
												</Button>
											</Flex>
										</Stack>
									</PopoverContent>
								</Popover>

								{trainer && (
									<Text
										ml={3}
										color={'gray.600'}
										_hover={{
											color: 'primary.500',
										}}
										onClick={() =>
											copyText(trainer.firebase_app_id)
										}
										as={'button'}
									>
										<FiCopy />
									</Text>
								)}
							</Box>
						</GridItem>
						<GridItem>
							<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
								Connected Account ID
							</Text>
							<Box mt="2" color={'gray.800'}>
								{trainer?.cstripe && trainer.cstripe?.account_id 
									? `${trainer.cstripe?.account_id}` : '-'}
								<Popover
									isOpen={isAccountIdOpen}
									onOpen={onAccountIdOpen}
									onClose={onAccountIdClose}
									placement="right"
									closeOnBlur={false}
								>
									<PopoverTrigger>
										<Button
											ml={3}
											color={'primary.500'}
											as={'button'}
											variant={'link'}
										>
											<FiEdit3 />
										</Button>
									</PopoverTrigger>

									<PopoverContent p="5">
										<Stack
											as={'form'}
											onSubmit={handleSubmit(e => {
												setAccountIdUpdating(true)
												postRequest(`admin/this-update-trainer/${id}`, {
													account_id: e.account_id
												})
													.then((res) => {
														if (res.status !== 'error') {
															fetchTrainerDetails(id)
															onAccountIdClose()
															reset()
															toast(toastConfig(res.message, 'success'))
														} else {
															setAccountIdError(res.message)
														}
														setAccountIdUpdating(false)
													})
													.catch((err) => {
														setAccountIdUpdating(false)
													})
											})}
											spacing={5}
										>
											<FormControl>
												<FormLabel>Account ID</FormLabel>
												<Input
													defaultValue={
														trainer?.cstripe?.account_id
															? trainer.cstripe?.account_id
															: ''
													}
													borderColor={'gray.300'}
													{...register('account_id', { required: false })}
													rounded={'full'}
													placeholder={'Enter Account ID'}
												/>
												{errors.account_id && (
													<Text fontSize="sm" mt="2" color="red.500">
														This field is required
													</Text>
												)}
												{accountIdError && (
													<Text fontSize="sm" mt="2" color="red.500">
														{accountIdError}
													</Text>
												)}
											</FormControl>

											<Flex spacing={5} justifyContent={'flex-end'}>
												<Button
													type={'button'}
													onClick={() => {
														onAccountIdClose()
														setAccountIdError(null)
													}}
													variant={'ghost'}
												>
													Close
												</Button>
												<Button
													type={'submit'}
													isLoading={accountIdUpdating}
													loadingText={'Updating...'}
													variant={'primary'}
												>
													Update
												</Button>
											</Flex>
										</Stack>
									</PopoverContent>
								</Popover>

								{trainer && (
									<Text
										ml={3}
										color={'gray.600'}
										_hover={{
											color: 'primary.500',
										}}
										onClick={() =>
											copyText(trainer.cstripe?.account_id)
										}
										as={'button'}
									>
										<FiCopy />
									</Text>
								)}
							</Box>
						</GridItem>
						<GridItem>
							<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
								Website
							</Text>
							<Box mt="2" color={'gray.800'}>
								{trainer?.website ? `${trainer.website}` : '-'}
								<Popover
									isOpen={isWebsiteOpen}
									onOpen={onWebsiteOpen}
									onClose={onWebsiteClose}
									placement="right"
									closeOnBlur={false}
								>
									<PopoverTrigger>
										<Button
											ml={3}
											color={'primary.500'}
											as={'button'}
											variant={'link'}
										>
											<FiEdit3 />
										</Button>
									</PopoverTrigger>

									<PopoverContent p="5">
										<Stack
											as={'form'}
											onSubmit={handleSubmit(e => {
												setWebsiteUpdating(true)
												postRequest(`admin/this-update-trainer/${id}`, {
													website: e.website
												})
													.then((res) => {
														if (res.status !== 'error') {
															fetchTrainerDetails(id)
															onWebsiteClose()
															reset()
															toast(toastConfig(res.message, 'success'))
														} else {
															setWebsiteError(res.message)
														}
														setWebsiteUpdating(false)
													})
													.catch((err) => {
														setWebsiteUpdating(false)
													})
											})}
											spacing={5}
										>
											<FormControl>
												<FormLabel>Website</FormLabel>
												<Input
													defaultValue={
														trainer?.website
															? trainer.website
															: ''
													}
													borderColor={'gray.300'}
													{...register('website', { required: false })}
													rounded={'full'}
													placeholder={'Enter Website'}
												/>
												{errors.website && (
													<Text fontSize="sm" mt="2" color="red.500">
														This field is required
													</Text>
												)}
												{websiteError && (
													<Text fontSize="sm" mt="2" color="red.500">
														{websiteError}
													</Text>
												)}
											</FormControl>

											<Flex spacing={5} justifyContent={'flex-end'}>
												<Button
													type={'button'}
													onClick={() => {
														onWebsiteClose()
														setWebsiteError(null)
													}}
													variant={'ghost'}
												>
													Close
												</Button>
												<Button
													type={'submit'}
													isLoading={websiteUpdating}
													loadingText={'Updating...'}
													variant={'primary'}
												>
													Update
												</Button>
											</Flex>
										</Stack>
									</PopoverContent>
								</Popover>

								{trainer && (
									<Text
										ml={3}
										color={'gray.600'}
										_hover={{
											color: 'primary.500',
										}}
										onClick={() =>
											copyText(trainer.website)
										}
										as={'button'}
									>
										<FiCopy />
									</Text>
								)}
							</Box>
						</GridItem>

						<GridItem>
							<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
								Stripe Product ID(s)
							</Text>
							<Box mt="2" color={'gray.800'}>
								{trainer?.product ? trainer?.product?.stripe_product_id : '-'}
								<Popover
									isOpen={isProductOpen}
									onOpen={onProductOpen}
									onClose={onProductClose}
									placement="right"
									closeOnBlur={false}
								>
									<PopoverTrigger>
										<Button
											ml={3}
											color={'primary.500'}
											as={'button'}
											variant={'link'}
										>
											<FiEdit3 />
										</Button>
									</PopoverTrigger>

									<PopoverContent p="5">
										<Stack
											as={'form'}
											onSubmit={handleSubmit(e => {
												setIsProductIDUpdating(true)
												postRequest(`admin/trainer-product-id/${id}`, {
													product_id: e.product_id
												})
													.then((res) => {
														if (res.status !== 'error') {
															fetchTrainerDetails(id)
															onProductClose()
															reset()
															toast(toastConfig(res.message, 'success'))
														} else {
															setProductIDError(res.message)
														}
														setIsProductIDUpdating(false)
													})
													.catch((err) => {
														setIsProductIDUpdating(false)
													})
											})}
											spacing={5}
										>
											<FormControl>
												<FormLabel>Stripe Product ID</FormLabel>
												<Input
													defaultValue={
														trainer?.product
															? trainer?.product?.stripe_product_id
															: ''
													}
													borderColor={'gray.300'}
													{...register('product_id', { required: false })}
													rounded={'full'}
													placeholder={'Enter stripe product ID'}
												/>
												{errors.product_id && (
													<Text fontSize="sm" mt="2" color="red.500">
														This field is required
													</Text>
												)}
												{productIDError && (
													<Text fontSize="sm" mt="2" color="red.500">
														{productIDError}
													</Text>
												)}
											</FormControl>

											<Flex spacing={5} justifyContent={'flex-end'}>
												<Button
													type={'button'}
													onClick={() => {
														onProductClose()
														setProductIDError(null)
													}}
													variant={'ghost'}
												>
													Close
												</Button>
												<Button
													type={'submit'}
													isLoading={isProductIDUpdating}
													loadingText={'Updating...'}
													variant={'primary'}
												>
													Update
												</Button>
											</Flex>
										</Stack>
									</PopoverContent>
								</Popover>

								{trainer?.product && (
									<Text
										ml={3}
										color={'gray.600'}
										_hover={{
											color: 'primary.500',
										}}
										onClick={() =>
											copyText(trainer?.product?.stripe_product_id)
										}
										as={'button'}
									>
										<FiCopy />
									</Text>
								)}
							</Box>
						</GridItem>

						<GridItem>
							<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
								Commission we keep
							</Text>
							<Box mt="2" color={'gray.800'}>
								{trainer?.mgp_commission ? `${trainer.mgp_commission}%` : '-'}
								<Popover
									isOpen={isCommissionOpen}
									onOpen={onCommissionOpen}
									onClose={onCommissionClose}
									placement="right"
									closeOnBlur={false}
								>
									<PopoverTrigger>
										<Button
											ml={3}
											color={'primary.500'}
											as={'button'}
											variant={'link'}
										>
											<FiEdit3 />
										</Button>
									</PopoverTrigger>

									<PopoverContent p="5">
										<Stack
											as={'form'}
											onSubmit={handleSubmit(e => {
												setCommissionUpdating(true)
												postRequest(`admin/update-trainer-mgp-commission/${id}`, {
													mgp_commission: e.mgp_commission
												})
													.then((res) => {
														if (res.status !== 'error') {
															fetchTrainerDetails(id)
															onCommissionClose()
															reset()
															toast(toastConfig(res.message, 'success'))
														} else {
															setCommissionError(res.message)
														}
														setCommissionUpdating(false)
													})
													.catch((err) => {
														setCommissionUpdating(false)
													})
											})}
											spacing={5}
										>
											<FormControl>
												<FormLabel>GamePlan's Commission</FormLabel>
												<Input
													defaultValue={
														trainer?.mgp_commission
															? trainer.mgp_commission
															: ''
													}
													borderColor={'gray.300'}
													{...register('mgp_commission', { required: false })}
													rounded={'full'}
													placeholder={'Enter GamePlan\'s commission'}
												/>
												{errors.mgp_commission && (
													<Text fontSize="sm" mt="2" color="red.500">
														This field is required
													</Text>
												)}
												{commissionError && (
													<Text fontSize="sm" mt="2" color="red.500">
														{commissionError}
													</Text>
												)}
											</FormControl>

											<Flex spacing={5} justifyContent={'flex-end'}>
												<Button
													type={'button'}
													onClick={() => {
														onCommissionClose()
														setCommissionError(null)
													}}
													variant={'ghost'}
												>
													Close
												</Button>
												<Button
													type={'submit'}
													isLoading={commissionUpdating}
													loadingText={'Updating...'}
													variant={'primary'}
												>
													Update
												</Button>
											</Flex>
										</Stack>
									</PopoverContent>
								</Popover>

								{trainer && (
									<Text
										ml={3}
										color={'gray.600'}
										_hover={{
											color: 'primary.500',
										}}
										onClick={() =>
											copyText(trainer.mgp_commission)
										}
										as={'button'}
									>
										<FiCopy />
									</Text>
								)}
							</Box>
						</GridItem>

						<GridItem>
							<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
								App name
							</Text>
							<Box mt="2" color={'gray.800'}>
								{trainer?.app_name ? `${trainer.app_name}` : '-'}
								<Popover
									isOpen={isAppNameOpen}
									onOpen={onAppNameOpen}
									onClose={onAppNameClose}
									placement="right"
									closeOnBlur={false}
								>
									<PopoverTrigger>
										<Button
											ml={3}
											color={'primary.500'}
											as={'button'}
											variant={'link'}
										>
											<FiEdit3 />
										</Button>
									</PopoverTrigger>

									<PopoverContent p="5">
										<Stack
											as={'form'}
											onSubmit={handleSubmit(e => {
												setAppNameUpdating(true)
												postRequest(`admin/this-update-trainer/${id}`, {
													app_name: e.app_name
												})
													.then((res) => {
														if (res.status !== 'error') {
															fetchTrainerDetails(id)
															onAppNameClose()
															reset()
															toast(toastConfig(res.message, 'success'))
														} else {
															setAppNameError(res.message)
														}
														setAppNameUpdating(false)
													})
													.catch((err) => {
														setAppNameUpdating(false)
													})
											})}
											spacing={5}
										>
											<FormControl>
												<FormLabel>App Name</FormLabel>
												<Input
													defaultValue={
														trainer?.app_name
															? trainer.app_name
															: ''
													}
													borderColor={'gray.300'}
													{...register('app_name', { required: false })}
													rounded={'full'}
													placeholder={'Enter App Name'}
												/>
												{errors.app_name && (
													<Text fontSize="sm" mt="2" color="red.500">
														This field is required
													</Text>
												)}
												{appNameError && (
													<Text fontSize="sm" mt="2" color="red.500">
														{appNameError}
													</Text>
												)}
											</FormControl>

											<Flex spacing={5} justifyContent={'flex-end'}>
												<Button
													type={'button'}
													onClick={() => {
														onAppNameClose()
														setAppNameError(null)
													}}
													variant={'ghost'}
												>
													Close
												</Button>
												<Button
													type={'submit'}
													isLoading={appNameUpdating}
													loadingText={'Updating...'}
													variant={'primary'}
												>
													Update
												</Button>
											</Flex>
										</Stack>
									</PopoverContent>
								</Popover>
							</Box>
						</GridItem>
					</Grid>

					<Divider my={7} />

					<Heading mb='5' fontSize={['1.5rem','1.15rem','1.15rem','1.15rem']} color={'gray.800'}>
						Account
					</Heading>

					<Grid 
						gap={6} 
						templateColumns={['repeat(1, 1fr)','repeat(3, 1fr)','repeat(3, 1fr)','repeat(3, 1fr)']}
					>
						<GridItem>
							<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
								Name
							</Text>
							<Box mt="2" color={'gray.800'}>
								{trainer?.name ? `${trainer.name}` : '-'}
								<Popover
									isOpen={isNameOpen}
									onOpen={onNameOpen}
									onClose={onNameClose}
									placement="right"
									closeOnBlur={false}
								>
									<PopoverTrigger>
										<Button
											ml={3}
											color={'primary.500'}
											as={'button'}
											variant={'link'}
										>
											<FiEdit3 />
										</Button>
									</PopoverTrigger>

									<PopoverContent p="5">
										<Stack
											as={'form'}
											onSubmit={handleSubmit(e => {
												console.log(e)
												setNameUpdating(true)
												postRequest(`admin/this-update-trainer/${id}`, {
													name: e.name
												})
													.then((res) => {
														if (res.status !== 'error') {
															fetchTrainerDetails(id)
															onNameClose()
															reset()
															toast(toastConfig(res.message, 'success'))
														} else {
															setNameError(res.message)
														}
														setNameUpdating(false)
													})
													.catch((err) => {
														setNameUpdating(false)
													})
											})}
											spacing={5}
										>
											<FormControl>
												<FormLabel>Name</FormLabel>
												<Input
													defaultValue={
														trainer?.name
															? trainer.name
															: ''
													}
													borderColor={'gray.300'}
													{...register('name', { required: false })}
													rounded={'full'}
													placeholder={'Enter App ID'}
												/>
												{errors.name && (
													<Text fontSize="sm" mt="2" color="red.500">
														This field is required
													</Text>
												)}
												{nameError && (
													<Text fontSize="sm" mt="2" color="red.500">
														{nameError}
													</Text>
												)}
											</FormControl>

											<Flex spacing={5} justifyContent={'flex-end'}>
												<Button
													type={'button'}
													onClick={() => {
														onNameClose()
														setNameError(null)
													}}
													variant={'ghost'}
												>
													Close
												</Button>
												<Button
													type={'submit'}
													isLoading={nameUpdating}
													loadingText={'Updating...'}
													variant={'primary'}
												>
													Update
												</Button>
											</Flex>
										</Stack>
									</PopoverContent>
								</Popover>
							</Box>
						</GridItem>
						<GridItem>
							<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
								Email
							</Text>
							<Box mt="2" color={'gray.800'}>
								{trainer?.email ? `${trainer.email}` : '-'}
								<Popover
									isOpen={isEmailOpen}
									onOpen={onEmailOpen}
									onClose={onEmailClose}
									placement="right"
									closeOnBlur={false}
								>
									<PopoverTrigger>
										<Button
											ml={3}
											color={'primary.500'}
											as={'button'}
											variant={'link'}
										>
											<FiEdit3 />
										</Button>
									</PopoverTrigger>

									<PopoverContent p="5">
										<Stack
											as={'form'}
											onSubmit={handleSubmit((e) => {
												setEmailUpdating(true)
												postRequest(`admin/this-update-trainer/${id}`, {
													email: e.email
												})
													.then((res) => {
														if (res.status !== 'error') {
															fetchTrainerDetails(id)
															onEmailClose()
															reset()
															toast(toastConfig(res.message, 'success'))
														} else {
															setEmailError(res.message)
														}
														setEmailUpdating(false)
													})
													.catch((err) => {
														setEmailUpdating(false)
													})
											})}
											spacing={5}
										>
											<FormControl>
												<FormLabel>Email</FormLabel>
												<Input
													defaultValue={
														trainer?.email
															? trainer.email
															: ''
													}
													borderColor={'gray.300'}
													{...register('email', { required: false })}
													rounded={'full'}
													placeholder={'Enter Email'}
												/>
												{errors.email && (
													<Text fontSize="sm" mt="2" color="red.500">
														This field is required
													</Text>
												)}
												{emailError && (
													<Text fontSize="sm" mt="2" color="red.500">
														{emailError}
													</Text>
												)}
											</FormControl>

											<Flex spacing={5} justifyContent={'flex-end'}>
												<Button
													type={'button'}
													onClick={() => {
														onEmailClose()
														setEmailError(null)
													}}
													variant={'ghost'}
												>
													Close
												</Button>
												<Button
													type={'submit'}
													isLoading={emailUpdating}
													loadingText={'Updating...'}
													variant={'primary'}
												>
													Update
												</Button>
											</Flex>
										</Stack>
									</PopoverContent>
								</Popover>
							</Box>
						</GridItem>
						<GridItem>
							<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
								Password
							</Text>
							<Box mt="2" color={'gray.800'}>
								*************
								<Popover
									isOpen={isPasswordOpen}
									onOpen={onPasswordOpen}
									onClose={onPasswordClose}
									placement="right"
									closeOnBlur={false}
								>
									<PopoverTrigger>
										<Button
											ml={3}
											color={'primary.500'}
											as={'button'}
											variant={'link'}
										>
											<FiEdit3 />
										</Button>
									</PopoverTrigger>

									<PopoverContent p="5">
										<Stack
											as={'form'}
											onSubmit={handleSubmit(handlePasswordUpdate)}
											spacing={5}
										>
											<FormControl>
												<FormLabel>Password</FormLabel>
												<Input
													borderColor={'gray.300'}
													{...register('password', { required: true })}
													rounded={'full'}
													id="password"
													placeholder={'Enter Password'}
												/>
												{errors.password && (
													<Text fontSize="sm" mt="2" color="red.500">
														This field is required
													</Text>
												)}
												{passwordError && (
													<Text fontSize="sm" mt="2" color="red.500">
														{passwordError}
													</Text>
												)}
											</FormControl>

											<Flex spacing={5} justifyContent={'flex-end'}>
												<Button
													type={'button'}
													onClick={() => {
														onPasswordClose()
														setPasswordError(null)
													}}
													variant={'ghost'}
												>
													Close
												</Button>
												<Button
													type={'submit'}
													isLoading={passwordUpdating}
													loadingText={'Updating...'}
													variant={'primary'}
												>
													Update
												</Button>
											</Flex>
										</Stack>
									</PopoverContent>
								</Popover>
							</Box>
						</GridItem>

						<GridItem>
							<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
								Business type
							</Text>
							<Box mt="2" color={'gray.800'}>
								{industries && trainer?.industry_id 
										? industries.filter(industry => industry.id === trainer?.industry_id)[0].name
										: '-'}
								<Popover
									isOpen={isBusinessTypeOpen}
									onOpen={onBusinessTypeOpen}
									onClose={onBusinessTypeClose}
									placement="right"
									closeOnBlur={false}
								>
									<PopoverTrigger>
										<Button
											ml={3}
											color={'primary.500'}
											as={'button'}
											variant={'link'}
										>
											<FiEdit3 />
										</Button>
									</PopoverTrigger>

									<PopoverContent p="5">
										<Stack 
											as={'form'}
											onSubmit={handleSubmit(e => {
												setFieldUpdating({business_type: true})
												postRequest(`admin/this-update-trainer/${id}`, {
													industry_id: e.industry_id
												})
													.then((res) => {
														if (res.status !== 'error') {
															fetchTrainerDetails(id)
															onBusinessTypeClose()
															reset()
															toast(toastConfig(res.message, 'success'))
														} else {
															setFieldError({business_type: res.message})
														}
														setFieldUpdating({business_type: false})
													})
													.catch((err) => {
														setFieldUpdating({business_type: false})
													})
											})}
											spacing={5}
										>
											<FormControl>
												<FormLabel>Business Type</FormLabel>
												<Select
													value={fieldDefault.business_type ?? trainer?.industry_id ?? null}
													borderColor="secondary.200"
													size="sm"
													_hover={{
														borderColor: 'secondary.300',
													}}
													_focus={{
														borderColor: 'secondary.400',
													}}
													_placeholder={{
														color: 'secondary.400',
													}}
													rounded="full"
													{...register('industry_id', { required: false })}
													onChange={e => setFieldDefault({business_type: e.target.value})}
												>
													{industries.map((industry) => (	
														<option 
															key={industry.id} 
															value={industry.id}
														>
															{industry.name}
														</option>
													))}
												</Select>
												
											</FormControl>

											<Flex spacing={5} justifyContent={'flex-end'}>
												<Button
													type={'button'}
													onClick={() => {
														onBusinessTypeClose()
														setFieldError({business_type: null})
													}}
													variant={'ghost'}
												>
													Close
												</Button>
												<Button
													type={'submit'}
													isLoading={fieldUpdating.business_type}
													loadingText={'Updating...'}
													variant={'primary'}
												>
													Update
												</Button>
											</Flex>
										</Stack>
									</PopoverContent>
								</Popover>
							</Box>
						</GridItem>

						<GridItem>
							<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
								Social media platform
							</Text>
							<Box mt="2" color={'gray.800'}>
								{trainer?.social_media ? `${trainer.social_media}` : '-'}
								<Popover
									isOpen={isSocialMediaOpen}
									onOpen={onSocialMediaOpen}
									onClose={onSocialMediaClose}
									placement="right"
									closeOnBlur={false}
								>
									<PopoverTrigger>
										<Button
											ml={3}
											color={'primary.500'}
											as={'button'}
											variant={'link'}
										>
											<FiEdit3 />
										</Button>
									</PopoverTrigger>

									<PopoverContent p="5">
										<Stack
											as={'form'}
											onSubmit={handleSubmit(e => {
												setSocialMediaUpdating(true)
												postRequest(`admin/this-update-trainer/${id}`, {
													social_media: e.social_media
												})
													.then((res) => {
														if (res.status !== 'error') {
															fetchTrainerDetails(id)
															onSocialMediaClose()
															reset()
															toast(toastConfig(res.message, 'success'))
														} else {
															setSocialMediaError(res.message)
														}
														setSocialMediaUpdating(false)
													})
													.catch((err) => {
														setSocialMediaUpdating(false)
													})
											})}
											spacing={5}
										>
											<FormControl>
												<FormLabel>Social media platform</FormLabel>
												<Input
													defaultValue={
														trainer?.social_media
															? trainer.social_media
															: ''
													}
													borderColor={'gray.300'}
													{...register('social_media', { required: false })}
													rounded={'full'}
													placeholder={'Enter Social Media'}
												/>
												{errors.social_media && (
													<Text fontSize="sm" mt="2" color="red.500">
														This field is required
													</Text>
												)}
												{socialMediaError && (
													<Text fontSize="sm" mt="2" color="red.500">
														{socialMediaError}
													</Text>
												)}
											</FormControl>

											<Flex spacing={5} justifyContent={'flex-end'}>
												<Button
													type={'button'}
													onClick={() => {
														onSocialMediaClose()
														setSocialMediaError(null)
													}}
													variant={'ghost'}
												>
													Close
												</Button>
												<Button
													type={'submit'}
													isLoading={socialMediaUpdating}
													loadingText={'Updating...'}
													variant={'primary'}
												>
													Update
												</Button>
											</Flex>
										</Stack>
									</PopoverContent>
								</Popover>
							</Box>
						</GridItem>

						<GridItem>
							<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
								Platform handle name
							</Text>
							<Box mt="2" color={'gray.800'}>
								{trainer?.social_media_handler_name ? `${trainer.social_media_handler_name}` : '-'}
								<Popover
									isOpen={isSocialHandleOpen}
									onOpen={onSocialHandleOpen}
									onClose={onSocialHandleClose}
									placement="right"
									closeOnBlur={false}
								>
									<PopoverTrigger>
										<Button
											ml={3}
											color={'primary.500'}
											as={'button'}
											variant={'link'}
										>
											<FiEdit3 />
										</Button>
									</PopoverTrigger>

									<PopoverContent p="5">
										<Stack
											as={'form'}
											onSubmit={handleSubmit(e => {
												setSocialHandleUpdating(true)
												postRequest(`admin/this-update-trainer/${id}`, {
													social_media_handler_name: e.social_media_handler_name
												})
													.then((res) => {
														if (res.status !== 'error') {
															fetchTrainerDetails(id)
															onSocialHandleClose()
															reset()
															toast(toastConfig(res.message, 'success'))
														} else {
															setSocialHandleError(res.message)
														}
														setSocialHandleUpdating(false)
													})
													.catch((err) => {
														setSocialHandleUpdating(false)
													})
											})}
											spacing={5}
										>
											<FormControl>
												<FormLabel>Platform handle name</FormLabel>
												<Input
													defaultValue={
														trainer?.social_media_handler_name
															? trainer.social_media_handler_name
															: ''
													}
													borderColor={'gray.300'}
													{...register('social_media_handler_name', { required: false })}
													rounded={'full'}
													placeholder={'Enter Platform Handle'}
												/>
												{errors.social_media && (
													<Text fontSize="sm" mt="2" color="red.500">
														This field is required
													</Text>
												)}
												{socialHandleError && (
													<Text fontSize="sm" mt="2" color="red.500">
														{socialHandleError}
													</Text>
												)}
											</FormControl>

											<Flex spacing={5} justifyContent={'flex-end'}>
												<Button
													type={'button'}
													onClick={() => {
														onSocialHandleClose()
														setSocialHandleError(null)
													}}
													variant={'ghost'}
												>
													Close
												</Button>
												<Button
													type={'submit'}
													isLoading={socialHandleUpdating}
													loadingText={'Updating...'}
													variant={'primary'}
												>
													Update
												</Button>
											</Flex>
										</Stack>
									</PopoverContent>
								</Popover>
							</Box>
						</GridItem>

					</Grid>

					<Divider my={7} />

					<Heading mb='5' fontSize={['1.5rem','1.15rem','1.15rem','1.15rem']} color={'gray.800'}>
						App
					</Heading>

					<Grid 
						gap={6} 
						templateColumns={['repeat(1, 1fr)','repeat(3, 1fr)','repeat(3, 1fr)','repeat(3, 1fr)']}
					>
						<GridItem>
							<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
								Domain
							</Text>
							<Box mt="2" color={'gray.800'}>
								{trainer?.domain ? `${trainer.domain}` : '-'}
								<Popover
									isOpen={isDomainOpen}
									onOpen={onDomainOpen}
									onClose={onDomainClose}
									placement="right"
									closeOnBlur={false}
								>
									<PopoverTrigger>
										<Button
											ml={3}
											color={'primary.500'}
											as={'button'}
											variant={'link'}
										>
											<FiEdit3 />
										</Button>
									</PopoverTrigger>

									<PopoverContent p="5">
										<Stack
											as={'form'}
											onSubmit={handleSubmit(e => {
												setDomainUpdating(true)
												postRequest(`admin/this-update-trainer/${id}`, {
													domain: e.domain
												})
													.then((res) => {
														if (res.status !== 'error') {
															fetchTrainerDetails(id)
															onDomainClose()
															reset()
															toast(toastConfig(res.message, 'success'))
														} else {
															setDomainError(res.message)
														}
														setDomainUpdating(false)
													})
													.catch((err) => {
														setDomainUpdating(false)
													})
											})}
											spacing={5}
										>
											<FormControl>
												<FormLabel>Domain</FormLabel>
												<Input
													defaultValue={
														trainer?.domain
															? trainer.domain
															: ''
													}
													borderColor={'gray.300'}
													{...register('domain', { required: false })}
													rounded={'full'}
													placeholder={'Enter App ID'}
												/>
												{errors.domain && (
													<Text fontSize="sm" mt="2" color="red.500">
														This field is required
													</Text>
												)}
												{domainError && (
													<Text fontSize="sm" mt="2" color="red.500">
														{domainError}
													</Text>
												)}
											</FormControl>

											<Flex spacing={5} justifyContent={'flex-end'}>
												<Button
													type={'button'}
													onClick={() => {
														onDomainClose()
														setDomainError(null)
													}}
													variant={'ghost'}
												>
													Close
												</Button>
												<Button
													type={'submit'}
													isLoading={domainUpdating}
													loadingText={'Updating...'}
													variant={'primary'}
												>
													Update
												</Button>
											</Flex>
										</Stack>
									</PopoverContent>
								</Popover>
							</Box>
						</GridItem>
						<GridItem>
							<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
								iOS App Link
							</Text>
							<Box mt="2" color={'gray.800'}>
								{trainer?.ios_applink ? `${trainer.ios_applink}` : '-'}
								<Popover
									isOpen={isIosAppLinkOpen}
									onOpen={onIosAppLinkOpen}
									onClose={onIosAppLinkClose}
									placement="right"
									closeOnBlur={false}
								>
									<PopoverTrigger>
										<Button
											ml={3}
											color={'primary.500'}
											as={'button'}
											variant={'link'}
										>
											<FiEdit3 />
										</Button>
									</PopoverTrigger>

									<PopoverContent p="5">
										<Stack
											as={'form'}
											onSubmit={handleSubmit(e => {
												setIosAppLinkUpdating(true)
												postRequest(`admin/this-update-trainer/${id}`, {
													ios_applink: e.ios_applink
												})
													.then((res) => {
														if (res.status !== 'error') {
															fetchTrainerDetails(id)
															onIosAppLinkClose()
															reset()
															toast(toastConfig(res.message, 'success'))
														} else {
															setIosAppLinkError(res.message)
														}
														setIosAppLinkUpdating(false)
													})
													.catch((err) => {
														setIosAppLinkUpdating(false)
													})
											})}
											spacing={5}
										>
											<FormControl>
												<FormLabel>iOS App Link</FormLabel>
												<Input
													defaultValue={
														trainer?.ios_applink
															? trainer.ios_applink
															: ''
													}
													borderColor={'gray.300'}
													{...register('ios_applink', { required: false })}
													rounded={'full'}
													placeholder={'Enter iOS App Link'}
												/>
												{errors.ios_applink && (
													<Text fontSize="sm" mt="2" color="red.500">
														This field is required
													</Text>
												)}
												{iosAppLinkError && (
													<Text fontSize="sm" mt="2" color="red.500">
														{iosAppLinkError}
													</Text>
												)}
											</FormControl>

											<Flex spacing={5} justifyContent={'flex-end'}>
												<Button
													type={'button'}
													onClick={() => {
														onIosAppLinkClose()
														setIosAppLinkError(null)
													}}
													variant={'ghost'}
												>
													Close
												</Button>
												<Button
													type={'submit'}
													isLoading={iosAppLinkUpdating}
													loadingText={'Updating...'}
													variant={'primary'}
												>
													Update
												</Button>
											</Flex>
										</Stack>
									</PopoverContent>
								</Popover>
							</Box>
						</GridItem>
						<GridItem>
							<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
								Android App Link
							</Text>
							<Box mt="2" color={'gray.800'}>
								{trainer?.android_applink ? `${trainer.android_applink}` : '-'}
								<Popover
									isOpen={isAndroidAppLinkOpen}
									onOpen={onAndroidAppLinkOpen}
									onClose={onAndroidAppLinkClose}
									placement="right"
									closeOnBlur={false}
								>
									<PopoverTrigger>
										<Button
											ml={3}
											color={'primary.500'}
											as={'button'}
											variant={'link'}
										>
											<FiEdit3 />
										</Button>
									</PopoverTrigger>

									<PopoverContent p="5">
										<Stack
											as={'form'}
											onSubmit={handleSubmit(e => {
												setAndroidAppLinkUpdating(true)
												postRequest(`admin/this-update-trainer/${id}`, {
													android_applink: e.android_applink
												})
													.then((res) => {
														if (res.status !== 'error') {
															fetchTrainerDetails(id)
															onAndroidAppLinkClose()
															reset()
															toast(toastConfig(res.message, 'success'))
														} else {
															setAndroidAppLinkError(res.message)
														}
														setAndroidAppLinkUpdating(false)
													})
													.catch((err) => {
														setAndroidAppLinkUpdating(false)
													})
											})}
											spacing={5}
										>
											<FormControl>
												<FormLabel>Android App Link</FormLabel>
												<Input
													defaultValue={
														trainer?.android_applink
															? trainer.android_applink
															: ''
													}
													borderColor={'gray.300'}
													{...register('android_applink', { required: false })}
													rounded={'full'}
													placeholder={'Enter Android App Link'}
												/>
												{errors.android_applink && (
													<Text fontSize="sm" mt="2" color="red.500">
														This field is required
													</Text>
												)}
												{androidAppLinkError && (
													<Text fontSize="sm" mt="2" color="red.500">
														{androidAppLinkError}
													</Text>
												)}
											</FormControl>

											<Flex spacing={5} justifyContent={'flex-end'}>
												<Button
													type={'button'}
													onClick={() => {
														onAndroidAppLinkClose()
														setAndroidAppLinkError(null)
													}}
													variant={'ghost'}
												>
													Close
												</Button>
												<Button
													type={'submit'}
													isLoading={androidAppLinkUpdating}
													loadingText={'Updating...'}
													variant={'primary'}
												>
													Update
												</Button>
											</Flex>
										</Stack>
									</PopoverContent>
								</Popover>
							</Box>
						</GridItem>

						<GridItem>
							<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
								Web App Link
							</Text>
							<Box mt="2" color={'gray.800'}>
								{trainer?.web_applink ? trainer?.web_applink : '-'}
								<Popover
									isOpen={isWebAppLinkOpen}
									onOpen={onWebAppLinkOpen}
									onClose={onWebAppLinkClose}
									placement="right"
									closeOnBlur={false}
								>
									<PopoverTrigger>
										<Button
											ml={3}
											color={'primary.500'}
											as={'button'}
											variant={'link'}
										>
											<FiEdit3 />
										</Button>
									</PopoverTrigger>

									<PopoverContent p="5">
										<Stack
											as={'form'}
											onSubmit={handleSubmit(e => {
												setWebAppLinkUpdating(true)
												postRequest(`admin/trainer-product-id/${id}`, {
													web_applink: e.web_applink
												})
													.then((res) => {
														if (res.status !== 'error') {
															fetchTrainerDetails(id)
															onWebAppLinkClose()
															reset()
															toast(toastConfig(res.message, 'success'))
														} else {
															setWebAppLinkError(res.message)
														}
														setWebAppLinkUpdating(false)
													})
													.catch((err) => {
														setWebAppLinkUpdating(false)
													})
											})}
											spacing={5}
										>
											<FormControl>
												<FormLabel>Web App Link</FormLabel>
												<Input
													defaultValue={
														trainer?.web_applink
															? trainer?.web_applink
															: ''
													}
													borderColor={'gray.300'}
													{...register('web_applink', { required: false })}
													rounded={'full'}
													placeholder={'Enter web app link'}
												/>
												{errors.web_applink && (
													<Text fontSize="sm" mt="2" color="red.500">
														This field is required
													</Text>
												)}
												{webAppLinkError && (
													<Text fontSize="sm" mt="2" color="red.500">
														{webAppLinkError}
													</Text>
												)}
											</FormControl>

											<Flex spacing={5} justifyContent={'flex-end'}>
												<Button
													type={'button'}
													onClick={() => {
														onWebAppLinkClose()
														setWebAppLinkError(null)
													}}
													variant={'ghost'}
												>
													Close
												</Button>
												<Button
													type={'submit'}
													isLoading={webAppLinkUpdating}
													loadingText={'Updating...'}
													variant={'primary'}
												>
													Update
												</Button>
											</Flex>
										</Stack>
									</PopoverContent>
								</Popover>

								{trainer?.web_applink && (
									<Text
										ml={3}
										color={'gray.600'}
										_hover={{
											color: 'primary.500',
										}}
										onClick={() =>
											copyText(trainer?.web_applink)
										}
										as={'button'}
									>
										<FiCopy />
									</Text>
								)}
							</Box>
						</GridItem>
					</Grid>

					<Divider my={7} />

					<Box>
						<Heading fontSize={['1.5rem','1.15rem','1.15rem','1.15rem']} color={'gray.800'}>
							Active Subscription
						</Heading>

						<Grid 
							mt={5}
							gap={6} 
							templateColumns={['repeat(1, 1fr)','repeat(3, 1fr)','repeat(3, 1fr)','repeat(3, 1fr)']}
						>
							<GridItem>
								<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
									Plan Name
								</Text>
								<Text mt="2" color={'gray.800'}>
									{trainer?.trainer_charges[0]?.plan?.title}
								</Text>
							</GridItem>
							<GridItem>
								<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
									Subscribe Date
								</Text>
								<Text mt="2" color={'gray.800'}>
									{trainer?.trainer_subscription?.created_at && moment(trainer?.trainer_subscription?.created_at).format('MMM D, YYYY h:mm:ss a')}
								</Text>
							</GridItem>
							<GridItem>
								<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
									Charges For
								</Text>
								<Text mt="2" color={'gray.800'}>
									{trainer?.charges_for}
								</Text>
							</GridItem>
							<GridItem>
								<Text fontSize={['0.9rem','0.8rem','0.8rem','0.8rem']} fontWeight={'semibold'}>
									Next Payment Date:
								</Text>
								<Text mt="2" color={'gray.800'}>
									{ trainer?.next_payment_date && moment(trainer?.next_payment_date).format('MMM D, YYYY h:mm:ss a') }
								</Text>
							</GridItem>
						</Grid>
					</Box>

					<Divider my={7} />

					<Box>
						<Heading fontSize={['1.5rem','1.15rem','1.15rem','1.15rem']} color={'gray.800'}>
							Payment History
						</Heading>

						<Table variant={'striped'} mt={5}>
							<Thead>
								<Tr>
									<Th>Date</Th>
									<Th textAlign={'center'}>Total Amount</Th>
									<Th isNumeric>Status</Th>
								</Tr>
							</Thead>

							<Tbody>
								{charges}
							</Tbody>
						</Table>
					</Box>
				</Box>
			</Container>
		</Box>
	)
}

export default TrainerDetails
