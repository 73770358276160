import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
    Box,
	Accordion,
    AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
    useToast
} from '@chakra-ui/react'
import AppModal from '../AppModal'
import { useNavigate  } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toastConfig } from '../../helpers/appConfig'
import { postRequest } from '../../services/base.service'
import { changePageTitle } from '../../redux'

const CheckboxContent = ({changePageTitle}) => {
    const [pageTitle] = useState('App Checklist')
	const [isLunchBtn, setIsLunchBtn] = useState(false)
	const [showAppModal, setShowAppModal] = useState(false)
    const [checkList, setCheckList] = useState({check1:false,check2:false,check3:false,check4:false,check5:false})
    const navigate  = useNavigate()
    const user = useSelector((state) => state.auth.user)
    const toast = useToast()
    function handleLaunch() {
        setShowAppModal(true)

        postRequest(`send-launch-email`, {
            'name': user.name,
            'email': user.email,
            'business_name': user.business_name,
            'app_name': user.app_name
        })
        .then(({status, message, result}) => {
            toast(toastConfig(message, status, 6000))
            navigate('/trainer/page-builder')
        })
            .catch(error => {
                console.log(error)
                /*const message = Object.keys(error.response.data.errors).length > 0 
                    ? Object.values(error.response.data.errors)[0]
                    : error.response.data.message
                toast(toastConfig(message, 'error', 6000))*/
            })
    }

	useEffect(()=> {
        changePageTitle(pageTitle)
        let ShowLunchBtn=true;
        let count=0;
        
        Object.keys(checkList).map((key)=>{
            if(checkList[key]===false){
                ShowLunchBtn=false;
                setIsLunchBtn(false)
                count++;
            }
        })

        if(ShowLunchBtn)
            setIsLunchBtn(true)
	},[checkList])

    const CheckListHandler=(e)=>{
        setCheckList({...checkList,[e.target.name]:e.target.checked})
    }

    const ButtonClickHandler=(url, newUrl)=>{
       if(newUrl)
        window.location.href=url
       else  navigate(url);

    }

  return (
    <div>
        {
            <Box p='5'>
                <div class="app-launch-details">
                    <div class="acordion-r">
                        <Accordion allowMultiple>
                            <AccordionItem>
                                <h2 class="check-box-checked-outer">
                                    <div class="check-box-checked"> 
                                        <input 
                                            className='input-checkbox' 
                                            type="checkbox" 
                                            name="check1" 
                                            id="opt-in1"
                                            onChange={(e)=> CheckListHandler(e)} checked={checkList.check1 ? true : false} 
                                        /> 
                                        <label ></label>
                                    </div>
                                    <AccordionButton class="acc-button">
                                
                                    <Box flex='1' textAlign='left' class="ac-heading">
                                        <label className={`${checkList.check1?'checkbox_checked':''}`} >STEP 1</label>
                                        <p className={`ac-head-details ${checkList.check1?'checkbox_checked':''}`}>Connect your Stripe account</p>
                                        
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <p class={`acc-details ${checkList.check1?'checkbox_checked':''}`} >This is how you get paid from your app. Revenue will automatically pay-out to your bank account.</p>
                                    <div class="app-button">
                                        <button onClick={()=>ButtonClickHandler('/trainer/account/payment-settings')}>Connect account 
                                            <img
                                                src={"/arrow.svg"}
                                                className="img-fluid"
                                            />
                                        </button>
                                    </div>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                    
                </div>

                <div class="app-launch-details">
                    <div class="acordion-r">
                        <Accordion allowMultiple>
                            <AccordionItem>
                                <h2 class="check-box-checked-outer">
                                    <div class="check-box-checked"> 
                                        <input 
                                            className='input-checkbox' 
                                            type="checkbox" 
                                            name="check2" 
                                            id="opt-in2"
                                            onChange={(e)=>CheckListHandler(e)} checked={checkList.check2 ? true : false} 
                                        />
                                        <label ></label>
                                    </div>
                                    <AccordionButton class="acc-button">
                                        <Box flex='1' textAlign='left' class="ac-heading">
                                            <label className={`${checkList.check2?'checkbox_checked':''}`}>STEP 2</label>
                                            <p className={`ac-head-details ${checkList.check2?'checkbox_checked':''}`}>Build your app</p>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <p class={`acc-details ${checkList.check2?'checkbox_checked':''}`} >Build 4-8 weeks of content to get your app
                                        launched. View our 'test apps' to ge a better Idea
                                        of how you want yours to function.</p>
                                    <div class="app-button">
                                        <button onClick={()=>ButtonClickHandler('/trainer/webapp-builder')}>
                                            Build app
                                            <img
                                                src={"/arrow.svg"}
                                                className="img-fluid"
                                            />
                                        </button>
                                    </div>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                    
                </div>

                <div class="app-launch-details">
                    <div class="acordion-r">
                        <Accordion allowMultiple>
                            <AccordionItem>
                                <h2 class="check-box-checked-outer">
                                    <div class="check-box-checked"> 
                                        <input 
                                            className='input-checkbox' 
                                            type="checkbox" 
                                            name="check3" 
                                            id="opt-in3"
                                            onChange={(e)=>CheckListHandler(e)} checked={checkList.check3 ? true : false} 
                                        />
                                        <label ></label>
                                    </div>
                                    <AccordionButton class="acc-button">
                                        <Box flex='1' textAlign='left' class="ac-heading">
                                            <label className={`${checkList.check3?'checkbox_checked':''}`}>STEP 3</label>
                                            <p className={`ac-head-details ${checkList.check3?'checkbox_checked':''}`}>Design your sales page</p>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <p class={`acc-details ${checkList.check3?'checkbox_checked':''}`} >Your sales page is where your users sign up for
                                        app access. Add pictures, colors and pricing with
                                        our page builder
                                    </p>
                                    <div class="app-button">
                                        <button onClick={()=>ButtonClickHandler('/trainer/page-builder')}>
                                            Build page
                                            <img
                                                src={"/arrow.svg"}
                                                className="img-fluid"
                                            />
                                        </button>
                                    </div>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>

                <div class="app-launch-details">
                    <div class="acordion-r">
                        <Accordion allowMultiple>
                            <AccordionItem>
                                <h2 class="check-box-checked-outer">
                                    <div class="check-box-checked"> 
                                        <input 
                                            className='input-checkbox' 
                                            type="checkbox" 
                                            name="check4" 
                                            id="opt-in4"
                                            onChange={(e)=>CheckListHandler(e)} 
                                            checked={checkList.check4 ? true : false} />
                                        <label ></label>
                                    </div>
                                    <AccordionButton class="acc-button">
                                        <Box flex='1' textAlign='left' class="ac-heading">
                                            <label className={`${checkList.check4?'checkbox_checked':''}`}>STEP 4</label>
                                            <p className={`ac-head-details ${checkList.check4?'checkbox_checked':''}`}>Create your Apple Devloper account</p>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <p class={`acc-details ${checkList.check4?'checkbox_checked':''}`} >
                                        This is how you get paid from your app. Revenue
                                        will automatically pay-out to your bank account.
                                    </p>
                                    
                                    <div class="app-button">
                                        <button onClick={()=>ButtonClickHandler('/trainer/app-accounts')}>Start now
                                            <img
                                                src={"/arrow.svg"}
                                                className="img-fluid"
                                            />
                                        </button>
                                    </div>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                    
                </div>
                <div class="app-launch-details">
                    <div class="acordion-r">
                        <Accordion allowMultiple>
                            <AccordionItem>
                                <h2 class="check-box-checked-outer">
                                    <div class="check-box-checked"> 
                                        <input 
                                            className='input-checkbox' 
                                            type="checkbox" 
                                            name="check5" 
                                            id="opt-in5"
                                            onChange={(e)=>CheckListHandler(e)} 
                                            checked={checkList.check5 ? true : false} />
                                        <label ></label>
                                    </div>
                                    <AccordionButton class="acc-button">
                                        <Box flex='1' textAlign='left' class="ac-heading">
                                            <p className={`ac-head-details ${checkList.check5?'checkbox_checked':''}`}>Schedule a launch call</p>
                                        
                                        </Box>
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <p class={`acc-details ${checkList.check5?'checkbox_checked':''}`} >Let's rock and roll. Get on a call with us to
                                        discuss marketing, your questions, and
                                        expectations about your app's launch.
                                    </p>
                                    <div class="app-button">
                                        <button onClick={()=>ButtonClickHandler('https://calendly.com/gameplanapps/app_overview?month=2022-08',true)}>
                                            Start now
                                        <img
                                            src={"/arrow.svg"}
                                            className="img-fluid"
                                            />
                                        </button>
                                    </div>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
                <div class="launch-my-app">
                    <div class="app-button l-button">
                        <button onClick={handleLaunch} disabled={!isLunchBtn?true:false}>LAUNCH MY APP 
                            <img
                                src={"/arrow.svg"}
                                className="img-fluid"
                            />
                        </button>
                    </div>
                </div>
            </Box>
        }
    
   {showAppModal && <AppModal isOpen={showAppModal} onClose={()=>setShowAppModal(false)}/>}

    </div>
   
  )
}

const mapDispatchToProps = (dispatch) => {
	return {
		changePageTitle: (title) => dispatch(changePageTitle(title)),
	}
}

export default connect(null, mapDispatchToProps)(CheckboxContent)
