import {
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalContent,
	Flex,
	Grid,
	GridItem,
	InputGroup,
	InputLeftElement,
	Box,
	Avatar,
	Heading,
	Text,
	Icon,
	IconButton,
	Popover,
	PopoverTrigger,
	PopoverContent,
	useDisclosure,
	PopoverBody,
	FormLabel,
	Badge,
	List,
	ListItem,
	ListIcon,
	Input,
	Stack,
	useToast,
	FormControl,
	Button,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	RadioGroup,
	Radio,
	Checkbox,
	Divider,
} from '@chakra-ui/react'
import {
	FiEdit3,
	FiX,
	FiMoreVertical,
	FiMail,
	FiClock,
	FiCreditCard,
	FiUserX,
} from 'react-icons/fi'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { useState, useRef, useEffect } from 'react'
import { toastConfig } from '../helpers/appConfig'
import {
	postRequest,
	deleteRequest,
	getRequest,
} from '../services/base.service'
import ComingSoonModal from './ComingSoonModal'

const DELETE_USER = 'DELETE_USER'
const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'
const CANCEL_NEXT_PAYMENT = 'CANCEL_NEXT_PAYMENT'
const UPDATE_SUBSCRIBER = 'UPDATE_SUBSCRIBER'
const CHANGE_CARD = 'CHANGE_CARD'

const UserCard = ({ updateList, bigWindow, data }) => {
	const { isOpen: isPopoverOpen, onOpen: onPopoverOpen, onClose: onPopoverClose } = useDisclosure()
	const { isOpen: isMessageOpen, onOpen: onMessageOpen, onClose: onMessageClose } = useDisclosure()
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm()
	const toast = useToast()
	const [submissionErrors, setSubmissionErrors] = useState([])

	const [isOpenDeleteAlert, setIsOpenDeleteAlert] = useState(false)
	const onDeleteAlertClose = () => setIsOpenDeleteAlert(false)
	const cancelRef = useRef()

	const [confirmationType, setConfirmationType] = useState(null)
	const [modalType, setModalType] = useState(null)

	const [isSubmitting, setIsSubmitting] = useState(false)

	const [subscriptionHistory, setSubscriptionHistory] = useState([])
	const [cancellationData, setCancellationData] = useState({
		action: 'cancel_without_refund',
	})

	const handleCancellationInputChange = (name, value) => {
		if (
			name === 'action' &&
			(value === 'cancel_with_refund' || value === 'refund_only')
		) {
			getSubscriptionHistory()
		}

		if (name === 'stripe_charge_id') {
			const stripe_charge_id = cancellationData?.stripe_charge_id || []

			// return
			const isIncluded = stripe_charge_id.includes(value)

			if (isIncluded) {
				stripe_charge_id.splice(stripe_charge_id.indexOf(value), 1)
			} else {
				stripe_charge_id.push(value)
			}

			setCancellationData({ ...cancellationData, [name]: stripe_charge_id })
		} else {
			if (name === 'action' && value === 'refund_only') {
				setCancellationData({
					...cancellationData,
					[name]: 'cancel_with_refund',
					refund_only: 1,
				})
			} else if (name === 'action' && value === 'cancel_with_refund') {
				setCancellationData({
					...cancellationData,
					[name]: 'cancel_with_refund',
					refund_only: 0,
				})
			} else {
				setCancellationData({ ...cancellationData, [name]: value })
			}
		}
	}

	const getSubscriptionHistory = () => {
		getRequest(`subscriber/subscription-history/${data.id}`)
			.then((res) => {
				setSubscriptionHistory(res.result)
			})
			.catch((error) => {
				console.log('Error ', error)
			})
	}

	useEffect(() => {
		console.log(cancellationData)
	}, [cancellationData])

	const handleDeleteSubscriber = (id) => {
		deleteRequest(`subscribers/${data.id}`)
			.then((res) => {
				toast(toastConfig(res.message, res.status))
				updateList()
				setCancellationData({
					action: 'cancel_without_refund',
				})
				onDeleteAlertClose()
			})
			.catch((err) => {
				console.log(err.response.data.errors)
			})
	}

	const onSubmit = (formData) => {
		postRequest(`subscribers/${data.id}`, formData)
			.then((res) => {
				toast(toastConfig(res.message, res.status))
				onPopoverClose()
				reset()
				setSubmissionErrors([])
				updateList()
			})
			.catch((err) => {
				console.log(err.response.data.errors)
				// setSubmissionErrors(err.response.data.errors)
			})
	}

	const handleOpenAlertDialog = (type) => {
		setConfirmationType(type)
		setIsOpenDeleteAlert(true)
	}

	const handleOpenModal = (type) => {
		setModalType(type)
		onPopoverOpen()
	}

	const handleCancelNextPayment = (id) => {
		setIsSubmitting(true)

		postRequest(`cancel-next-payment`, { stripe_subscription_id: id })
			.then((res) => {
				updateList()
				toast(toastConfig(res.message, res.status))
				setIsSubmitting(false)
				setIsOpenDeleteAlert(false)
			})
			.catch((error) => {
				toast(toastConfig(error.message))
				setIsSubmitting(false)
				setIsOpenDeleteAlert(false)
			})
	}

	const handleCancelSubscription = () => {
		setIsSubmitting(true)
		postRequest('cancel-subscription', {
			...cancellationData,
			subscriber_id: data.id,
		})
			.then((res) => {
				toast(toastConfig(res.message, res.status))
				setIsSubmitting(false)
				updateList()
				setIsOpenDeleteAlert(false)
			})
			.catch((error) => {
				toast(toastConfig(error.message, 'error'))
				setIsSubmitting(false)
			})
	}

	const handleChangeCard = (formData) => {
		setIsSubmitting(true)
		postRequest(`change-card`, { ...formData, subscriber_id: data.id })
			.then((res) => {
				toast(toastConfig(res.message, res.status))
				setIsSubmitting(false)
				updateList()
				onPopoverClose()
			})
			.catch((error) => {
				toast(toastConfig(error.message, 'error'))
				setIsSubmitting(false)
			})
	}

	const userStatus = (status) => {
		const statusColor =
			status === 'active' ? 'green' : status === 'canceled' ? 'red' : 'yellow'
		return (
			<Badge ml="3" colorScheme={statusColor}>
				{status}
			</Badge>
		)
	}
	return (
		<>
			<Modal isOpen={isPopoverOpen} onClose={onPopoverClose}>
				<ModalOverlay />
				<ModalContent color="secondary.800" bg="white">
					{modalType === UPDATE_SUBSCRIBER && (
						<>
							<ModalHeader>Update Subscriber</ModalHeader>

							<form onSubmit={handleSubmit(onSubmit)}>
								<ModalBody>
									<Stack spacing="20px">
										<FormControl id="subscriberName" isRequired>
											<FormLabel>Name</FormLabel>
											<Input
												type="hidden"
												defaultValue="put"
												{...register('_method')}
											/>
											<Input
												type="text"
												placeholder="Name"
												_placeholder={{ color: 'secondary.600' }}
												_hover={{ borderColor: 'secondary.300' }}
												rounded="full"
												border="1px"
												borderColor="secondary.200"
												defaultValue={data?.name}
												{...register('name', { required: true })}
											/>
											{errors.name && (
												<Text fontSize="sm" mt="2" color="red.500">
													This field is required
												</Text>
											)}
										</FormControl>

										<FormControl id="subscriberEmail" isRequired>
											<FormLabel>Email</FormLabel>
											<Input
												type="email"
												defaultValue={data?.email}
												_placeholder={{ color: 'secondary.600' }}
												_hover={{ borderColor: 'secondary.300' }}
												rounded="full"
												border="1px"
												borderColor="secondary.200"
												placeholder="Email address"
												{...register('email', { required: true })}
											/>

											{errors.email && (
												<Text fontSize="sm" mt="2" color="red.500">
													This field is required
												</Text>
											)}

											{submissionErrors?.email && (
												<Text fontSize="sm" mt="2" color="red.500">
													{submissionErrors.email}
												</Text>
											)}
										</FormControl>
									</Stack>
								</ModalBody>

								<ModalFooter>
									<Button mr={3} onClick={onPopoverClose}>
										Close
									</Button>
									<Button type="submit" variant="primary">
										Update Subscriber
									</Button>
								</ModalFooter>
							</form>
						</>
					)}

					{modalType === CHANGE_CARD && (
						<Box as="form" onSubmit={handleSubmit(handleChangeCard)}>
							<ModalHeader>Card Details</ModalHeader>
							<ModalBody>
								<Stack spacing="15px">
									<FormControl id="cardNumber" isRequired>
										<FormLabel>Card Number</FormLabel>
										<InputGroup>
											<InputLeftElement
												pointerEvents="none"
												children={<Icon as={FiCreditCard} color="gray.500" />}
											/>
											<Input
												maxLength="16"
												minLength="16"
												type="number"
												rounded="full"
												_placeholder={{ color: 'secondary.600' }}
												_hover={{ borderColor: 'secondary.300' }}
												border="1px"
												borderColor="secondary.200"
												placeholder="Card number"
												{...register('card_number', { required: true })}
											/>
										</InputGroup>

										{/* {errors.name && (
												<Text fontSize="sm" mt="2" color="red.500">
													This field is required
												</Text>
											)} */}
									</FormControl>

									<Grid
										templateColumns="repeat(3, 1fr)"
										templateRows="repeat(1, 1fr)"
										gap={30}
									>
										<GridItem colSpan="2">
											<FormControl id="cardCVC">
												<FormLabel>Expire Date</FormLabel>
												<InputGroup>
													<Input
														type="number"
														roundedLeft="full"
														_placeholder={{ color: 'secondary.600' }}
														_hover={{ borderColor: 'secondary.300' }}
														border="1px"
														maxLength="2"
														minLength="2"
														borderColor="secondary.200"
														placeholder="DD"
														{...register('card_exp_month', {
															required: true,
														})}
													/>
													<Input
														type="number"
														roundedRight="full"
														_placeholder={{ color: 'secondary.600' }}
														_hover={{ borderColor: 'secondary.300' }}
														border="1px"
														borderColor="secondary.200"
														placeholder="YYYY"
														{...register('card_exp_year', { required: true })}
													/>
												</InputGroup>
											</FormControl>
										</GridItem>

										<GridItem colSpan="1">
											<FormControl id="cardExpireDate">
												<FormLabel>CVC</FormLabel>
												<Input
													type="text"
													maxLength="4"
													minLength="3"
													rounded="full"
													_placeholder={{ color: 'secondary.600' }}
													_hover={{ borderColor: 'secondary.300' }}
													border="1px"
													borderColor="secondary.200"
													placeholder="CVC"
													{...register('card_cvc', { required: true })}
												/>
											</FormControl>
										</GridItem>
									</Grid>
								</Stack>
							</ModalBody>

							<ModalFooter>
								<Button mr={3} onClick={onPopoverClose}>
									Close
								</Button>
								<Button
									type="submit"
									isLoading={isSubmitting}
									loadingText={'Submitting...'}
									variant="primary"
								>
									Submit
								</Button>
							</ModalFooter>
						</Box>
					)}
				</ModalContent>
			</Modal>

			<AlertDialog
				isOpen={isOpenDeleteAlert}
				leastDestructiveRef={cancelRef}
				onClose={onDeleteAlertClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent bg="white" color="secondary.800">
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{confirmationType === DELETE_USER && 'Delete Customer'}
							{confirmationType === CANCEL_SUBSCRIPTION &&
								'Cancel Subscription and Refund'}
							{confirmationType === CANCEL_NEXT_PAYMENT &&
								'Please Choose an Actions'}
						</AlertDialogHeader>

						<AlertDialogBody>
							{confirmationType !== CANCEL_SUBSCRIPTION ? (
								`Are you sure? You can't undo this action afterwards.`
							) : (
								<>
									<FormControl>
										<RadioGroup
											onChange={(value) =>
												handleCancellationInputChange('action', value)
											}
											defaultValue={cancellationData?.action}
										>
											<Grid templateColumns="repeat(2, 1fr)" gap={4}>
												<Radio value="cancel_without_refund">
													Cancel without Refund
												</Radio>
												<Radio value="cancel_with_refund">
													Cancel with Refund
												</Radio>
												<Radio value="refund_only">Only Refund</Radio>

											</Grid>
										</RadioGroup>

										{cancellationData?.action === 'cancel_with_refund' && (
											<Box mt="5">
												<Text mb="3">Subscription Payments History</Text>
												<Stack spacing={3}>
													{subscriptionHistory.map((history, index) => {
														if (history.stripe_charge_id) {
															return (
																<Checkbox
																	isDisabled={history?.refund_status}
																	key={index}
																	onChange={(e) =>
																		handleCancellationInputChange(
																			'stripe_charge_id',
																			e.target.value
																		)
																	}
																	value={history.stripe_charge_id}
																>
																	{moment(history.start_date).format('MMMM')} ($
																	{history?.price})
																</Checkbox>
															)
														}
													})}
												</Stack>
												<Divider mt="4" mb="4" />

												{/* <Checkbox
													onChange={(e) => {
														const cloneData = { ...cancellationData }
														console.log(e.target.checked)
														if (e.target.checked) {
															setCancellationData({
																...cancellationData,
																refund_only: 1,
															})
														} else {
															setCancellationData({
																...cancellationData,
																refund_only: 0,
															})
														}

														console.log(cancellationData)
													}}
													colorScheme="cyan"
												>
													Only Refund
												</Checkbox> */}
											</Box>
										)}
									</FormControl>
								</>
							)}
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onDeleteAlertClose}>
								Cancel
							</Button>

							{confirmationType === DELETE_USER && (
								<Button
									colorScheme="red"
									onClick={() => handleDeleteSubscriber(data.id)}
									ml={3}
								>
									Delete
								</Button>
							)}

							{confirmationType === CANCEL_SUBSCRIPTION && (
								<Button
									isLoading={isSubmitting}
									variant="primary"
									loadingText="Confirming..."
									onClick={handleCancelSubscription}
									ml={3}
								>
									&nbsp; Confirm &nbsp;
								</Button>
							)}

							{confirmationType === CANCEL_NEXT_PAYMENT && (
								<Button
									colorScheme="red"
									isLoading={isSubmitting}
									loadingText="Cancelling payment..."
									onClick={() =>
										handleCancelNextPayment(
											data.subscription.stripe_subscription_id
										)
									}
									ml={3}
								>
									Cancel Next Payment
								</Button>
							)}
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			<Flex
				w="100%"
				p="10px"
				bg="white"
				border="1px"
				flexDirection={['column', 'column', 'row', 'row']}
				borderColor="secondary.200"
				borderRadius="10"
				pos="relative"
			>
				<Avatar name={data?.name} boxSize="65px" mb={[3, 3, 0, 0]} />

				<Box flex="1" ps={[0, 0, 4, 4]}>
					<Heading fontSize="md" fontWeight="bold" mb="1" color="secondary.900">
						{data?.name}
						{data.subscription?.status && userStatus(data.subscription?.status)}
					</Heading>

					<List spacing={2} mt="3">
						<ListItem display="flex" color="secondary.600" alignItems="center">
							<ListIcon
								display={['none', 'none', '', '']}
								as={FiMail}
								color="gray.400"
							/>
							Email: {data?.email}
						</ListItem>

						{/* TODO: Next payment */}
						{/* <ListItem display="flex" color="secondary.600" alignItems="center">
							<ListIcon as={FiClock} color="gray.400" />
							Next payment:{' '}
							{data?.subscription ? (
								!data?.subscription?.next_payment_status ? (
									<>
										{moment(data?.subscription?.end_date).format('ll')}
										<Text
											as="button"
											onClick={() => handleOpenAlertDialog(CANCEL_NEXT_PAYMENT)}
											textTransform="uppercase"
											color="red.400"
											fontSize="sm"
											ms="2"
										>
											Cancel
										</Text>
									</>
								) : (
									<Text ml="3" color="red.500" textTransform={'uppercase'}>
										{data?.subscription?.next_payment_status}
									</Text>
								)
							) : (
								'-'
							)}
						</ListItem> */}
						<ListItem display="flex" color="secondary.600" alignItems="center">
							<ListIcon
								display={['none', 'none', '', '']}
								as={FiCreditCard}
								color="gray.400"
							/>
							Card:{' '}
							{data.subscription && !data.subscription?.next_payment_status ? (
								<>
									************{data.subscription?.stripe_last4_digit}{' '}
									<Text
										as="button"
										onClick={() => handleOpenModal(CHANGE_CARD)}
										textTransform="uppercase"
										color="primary.500"
										fontSize="sm"
										ms="2"
									>
										Change
									</Text>
								</>
							) : (
								'-'
							)}
						</ListItem>
					</List>
				</Box>

				<Popover placement="bottom-end">
					{bigWindow ? (
						<PopoverTrigger>
							<IconButton
								_hover={{ outline: 'none', boxShadow: 'none' }}
								_focus={{ outline: 'none', boxShadow: 'none' }}
								aria-label="Actions"
								icon={<FiMoreVertical />}
								pos="absolute"
								top="4px"
								p="0"
								right="2px"
							/>
						</PopoverTrigger>
					) : (
						<IconButton
							_focus={{ outline: 'none', boxShadow: 'none' }}
							aria-label="Actions"
							icon={<FiMoreVertical />}
							pos="absolute"
							top="4px"
							p="0"
							right="2px"
							onClick={onMessageOpen}
						/>
					)}
					
					<ComingSoonModal isOpen={isMessageOpen} onClose={onMessageClose} />
					<PopoverContent
						maxW="200px"
						size="md"
						bg="white"
						style={{ boxShadow: 'none' }}
					>
						<PopoverBody
							boxShadow="base"
							border="1px"
							rounded="md"
							borderColor="secondary.200"
							size="md"
						>
							<List spacing={3}>
								<ListItem
									onClick={() => handleOpenModal(UPDATE_SUBSCRIBER)}
									cursor="pointer"
									_hover={{
										'& > .action-icon': { color: 'primary.500' },
										color: 'primary.500',
									}}
								>
									<ListIcon
										as={FiEdit3}
										me="2"
										className="action-icon"
										color="secondary.400"
									/>
									Edit
								</ListItem>

								{data.subscription?.status !== 'canceled' && (
									<ListItem
										cursor="pointer"
										onClick={() => handleOpenAlertDialog(CANCEL_SUBSCRIPTION)}
										_hover={{
											'& > .action-icon': { color: 'primary.500' },
											color: 'primary.500',
										}}
									>
										<ListIcon
											as={FiUserX}
											me="2"
											className="action-icon"
											color="secondary.400"
										/>
										Cancel
									</ListItem>
								)}

								<ListItem
									onClick={() => handleOpenAlertDialog(DELETE_USER)}
									cursor="pointer"
									_hover={{
										'& > .action-icon': { color: 'primary.500' },
										color: 'primary.500',
									}}
								>
									<ListIcon
										className="action-icon"
										as={FiX}
										me="2"
										color="secondary.400"
									/>
									Delete
								</ListItem>
							</List>
						</PopoverBody>
					</PopoverContent>
				</Popover>
			</Flex>
		</>
	)
}

export default UserCard
