import { useState, useEffect } from 'react'
import { Box, Heading, Text, Stack, Flex, Switch, 
    FormLabel, Input, HStack, VStack, List, ListItem,
    Button, Progress
} from '@chakra-ui/react'
import { BsCheckCircleFill } from 'react-icons/bs'
import { MdArrowForwardIos } from 'react-icons/md'
import { getRequest } from '../../services/base.service'
import NavButtons from '../NavButtons'
import '../../components/auth/auth.css'

const RadioInput = ({ content, monthly, value, isChecked, handleChange, bigWindow }) => {
    function handleRadioChange(e) {
        const {id} = e.currentTarget
        handleChange(id)
    }

    const chooseTitle = (title) => {
        switch(title) {
            case 'Enterprise': 
                return 'Custom app solutions'
            case 'Business':
                return 'Unlimited paid users'
            case 'Basic':
                return 'Unlimited free users'
            default:
                return ''
        }
    }

    return (
        <Box 
            w={['100%','auto','33.3%','33.3%']}
            sx={{
                display: 'inline-block',
            }}
        >
            <Input
                type="radio"
                sx={{
                    display: 'none',
                }}
                name='template'
                id={content.type}
                checked={isChecked}
                defaultValue={value.price_id}
                onChange={handleRadioChange}
            />
            <FormLabel 
                htmlFor={content.type}
                sx={{
                    cursor: 'pointer',
                    marginInlineEnd: '0 !important',
                    WebKitMarginEnd: '0 !important'
                }}
            >
                <Box
                    position='relative'
                    padding='4'
                    w='100%'
                    minH='20em'
                    h={['28em','28em','22em','22em']}
                    sx={{
                        display: 'inline-block',
                        background: isChecked ? '#D3D3D3 0% 0% no-repeat padding-box;' : '#FFF 0% 0% no-repeat padding-box;',
                        boxShadow: '0px 3px 6px #00000029;',
                        border: content.id === 'REACT_APP_BUSINESS_YEAR_ID' || content.id === 'REACT_APP_BUSINESS_MONTH_ID' ? '3px solid rgb(56,161,105);' : '1px solid #CCCCCC;',
                        borderRadius: '5px;',
                        opacity: '1',
                    }}
                >
                    {(content.id === 'REACT_APP_BUSINESS_YEAR_ID' || content.id === 'REACT_APP_BUSINESS_MONTH_ID') && (
                        <Box position='absolute' w='75%' top='-7' left='59%' transform='translateX(-50%)'>
                            <Box
                                display='flex'
                                justifyContent='center'
                                rounded='md'
                                color='white'
                                fontSize='sm'
                                fontWeight="semibold"
                                bg='rgb(56,161,105)' 
                                w='75%'
                                my={2}
                                py="2"
                            >
                                Most popular
                            </Box>
                        </Box>
                    )}
                    <VStack 
                        sx={{  
                            alignItems: 'normal', 
                            WebkitAlignItems: 'normal'
                        }}
                    >
                        <Heading as='h3' textAlign='left' fontSize={['2xl','2xl','lg','lg']} fontWeight='black' color='black' mt="3">
                            {content.title} {content.title !== 'Enterprise' ?  'unlimited' : ''}
                        </Heading>
                        <Text fontSize={['md','md','xs','sm']} color='black' fontWeight='black' sx={{ marginTop: '0 !important', marginBottom: '10px !important' }}>
                            {chooseTitle(content.title)}
                        </Text>
                        
                        { content.title !== 'Enterprise' ?
                            (<Heading as='h3' fontSize={['2xl','2xl','xl','xl']} color='black'>
                                {`$${monthly ? content.price : content.price}`}
                                <Text as='span' fontSize={['md','md','xs','xs']}>/month</Text>
                            </Heading>)
                            :
                            ''
                        }
                        <Text as='small' sx={{ marginTop: '0px !important' }} fontSize={['0.8rem','0.8rem','0.6rem','0.6rem']}>{content.price_subtitle}</Text>
                        {/* {(content.id === 'REACT_APP_BUSINESS_YEAR_ID' || content.id === 'REACT_APP_BUSINESS_MONTH_ID') && (
                            <Box mt='20px !important'>
                                <Text textAlign='center' fontSize={['md','md','sm','sm']} fontWeight='black' color='black'>*Everything from the Basic plan*</Text>
                                <Text textAlign='center' fontSize={['md','md','sm','sm']} fontWeight='black' color='black'>+</Text>
                            </Box>
                        )} */}
                        {(content.id === 'REACT_APP_ENTERPRISE_YEAR_ID' || content.id === 'REACT_APP_ENTERPRISE_MONTH_ID') 
                            ? (
                                <Box mt='1.2rem !important'>
                                    {/* <Text fontSize={['md','md','sm','sm']} color='black'>Set up a demo.</Text> */}
                                    <Button
                                        bg='primary.500'
                                        mt='20px'
                                        display='flex'
                                        justifyContent='center'
                                        w="75%"
                                        color='white'
                                        rounded='md'
                                        _hover={{
                                            bg: 'primary.800',
                                            color: 'white',
                                        }}
                                        _focus={{
                                            bg: 'primary.800',
                                        }}
                                        onClick={() => window.location.href = 'https://calendly.com/gameplanapps/app_overview'}
                                    >
                                        <Text
                                            ml='auto'
                                            fontSize='sm'
                                            fontWeight='black'
                                        >
                                            Schedule a call with us
                                        </Text>
                                        <Text 
                                            as='span'
                                            ml='auto'
                                        >
                                            <MdArrowForwardIos size={bigWindow ? '0.7em' : '0.9em'} />
                                        </Text>
                                    </Button>
                                </Box>
                            ) : (
                                <Box mt={(content.id !== 'REACT_APP_BUSINESS_YEAR_ID' || content.id !== 'REACT_APP_BUSINESS_MONTH_ID') && ('1rem !important')}>
                                    <List spacing='2'>
                                        {
                                            content.points.map((point, id) => (
                                                <ListItem key={id} display='flex' lineHeight={['1.2rem','1rem','0.82rem','0.7rem', '0.7rem','0.7rem']}>
                                                    {
                                                        point !== '<br/>' &&
                                                        <Text color='green.500' mr={1} display={['flex','block','block','block']} alignItems={['center','none','none','none']}>
                                                            <BsCheckCircleFill size={bigWindow ? '0.5em' : '0.8em'}  />
                                                        </Text>
                                                    }
                                                    <Text fontWeight='normal' color='black' fontFamily='listings'fontSize={['1rem','0.9rem','0.7rem','0.75rem', '0.75rem', '0.9rem']} dangerouslySetInnerHTML={{__html: point}}></Text>
                                                </ListItem>
                                            ))
                                        }
                                    </List> 
                                </Box>
                            )}
                    </VStack>
                </Box>
            </FormLabel>
        </Box>
    )
}

const StepFive = ({ value, onChange, prevStep, nextStep }) => {
    const [monthlyPlan, setMonthlyPlan] = useState(false)
    const [plan, setPlan] = useState(value.price_id ?? monthlyPlan ? process.env.REACT_APP_BUSINESS_MONTH_ID : process.env.REACT_APP_BUSINESS_YEAR_ID)
    const [platformPrices, setPlatformPrices] = useState([])
    const [prices, setPrices] = useState([])
    const [bigWindow, setBigWindow] = useState(true)
    const [sentence, setSentence] = useState('')

    function gobackward() {
        prevStep()
    }

    function goforward() {
        nextStep()
    }

    function handleChange(val) {
        setPlan(val)
    }

    useEffect(() => {
        getRequest(`get-platform-prices`)
            .then((res) => {
                setPlatformPrices(res.result.data)
            })
            .catch((error) => {
                console.log('Error ', error)
            })
    }, [])

    useEffect(() => {
        if (window.innerWidth < 600) {
            setBigWindow(false)
        }

        if (platformPrices.length > 0) {
            const defaultPrice = platformPrices.filter(price => price.id === process.env.REACT_APP_BUSINESS_YEAR_ID)[0].unit_amount / 12 / 100
            setPrices(
                [
                    {id: 'REACT_APP_BASIC_YEAR_ID',  type: process.env.REACT_APP_BASIC_YEAR_ID, title: 'Basic', subtitle: 'Custom IOS, Android & web app', price_subtitle: '+15% fee',
                        price: platformPrices.filter(price => price.id === process.env.REACT_APP_BASIC_YEAR_ID)[0].unit_amount / 12 / 100,
                        points: [
                            "IOS, Android, & Web app", "Push notifications", "Community group", "Messaging", "Unlimited content storage"
                        ]
                    },
                    {id: 'REACT_APP_BASIC_MONTH_ID', type: process.env.REACT_APP_BASIC_MONTH_ID, title: 'Basic', subtitle: 'Custom IOS, Android & web app', price_subtitle: '+15% fee',
                        price: platformPrices.filter(price => price.id === process.env.REACT_APP_BASIC_MONTH_ID)[0].unit_amount / 100,
                        points: [
                           "IOS, Android, & Web app", "Push notifications", "Community group", "Messaging", "Unlimited content storage"
                        ]
                    },
                    {id: 'REACT_APP_BUSINESS_YEAR_ID', type: process.env.REACT_APP_BUSINESS_YEAR_ID, title: 'Business', subtitle: 'Custom apps + Marketing', price_subtitle: '+15% fee',
                        price: platformPrices.filter(price => price.id === process.env.REACT_APP_BUSINESS_YEAR_ID)[0].unit_amount / 12 / 100,
                        points: [
                            'IOS, Android, & Web app', 'Push notifications', 'Community group', 'Messaging',
                            'Unlimited content storage', '<br/>', 'Gated content', 'Payment integrations', 'Sign-up forms generator'
                        ]
                    },
                    {id: 'REACT_APP_BUSINESS_MONTH_ID', type: process.env.REACT_APP_BUSINESS_MONTH_ID, title: 'Business', subtitle: 'Custom apps + Marketing', price_subtitle: '+15% fee',
                        price: platformPrices.filter(price => price.id === process.env.REACT_APP_BUSINESS_MONTH_ID)[0].unit_amount / 100,
                        points: [
                            'IOS, Android, & Web app', 'Push notifications', 'Community group', 'Messaging',
                            'Unlimited content storage', '<br/>', 'Gated content', 'Payment integrations', 'Sign-up forms generator'
                        ]
                    },
                    {id: 'REACT_APP_ENTERPRISE_YEAR_ID', type: process.env.REACT_APP_ENTERPRISE_YEAR_ID, title: 'Enterprise', subtitle: 'Custom programming/Multiple apps', price_subtitle: '',
                        price: platformPrices.filter(price => price.id === process.env.REACT_APP_ENTERPRISE_YEAR_ID)[0].unit_amount / 12 / 100,
                        points: []
                    },
                    {id: 'REACT_APP_ENTERPRISE_MONTH_ID', type: process.env.REACT_APP_ENTERPRISE_MONTH_ID, title: 'Enterprise', subtitle: 'Custom programming/Multiple apps', price_subtitle: '',
                        price: platformPrices.filter(price => price.id === process.env.REACT_APP_ENTERPRISE_MONTH_ID)[0].unit_amount / 100,
                        points: []
                    },
               ]
            )

            onChange({
                ...value,
                price_id: process.env.REACT_APP_BASIC_YEAR_ID,
                price_amount: defaultPrice * 12,
                price_recurring: 'yearly',
                mgp_commission: 20
            })
        }
    }, [platformPrices])

    useEffect(() => {
        if (prices.length > 0) {
            const filtered = prices.filter(price => price.type === plan)[0]
            const recurring = monthlyPlan ? 'monthly' : 'yearly'
            const saying = 'You chose the ' +  filtered.title + ' ' + recurring + ' plan'

            setSentence(saying)
            onChange({
                ...value,
                price_id: plan,
                price_amount: monthlyPlan
                    ? filtered.price
                    : filtered.price * 12,
                price_recurring: recurring,
                mgp_commission: filtered.id !== 'REACT_APP_ENTERPRISE_YEAR_ID' || filtered.id !== 'REACT_APP_ENTERPRISE_MONTH_ID'
                    ? 20
                    : 12
            })
        }
    }, [plan])

    return (
		<Box display={'flex'} h="100vh" bg="secondary.100" color="secondary.700">
			<Box
				flex='1.6'
				display={['block', 'block', 'flex', 'flex']}
				justifyContent="center"
				alignItems="center"
				sx={{
                    zIndex: '2',
                    background: 'transparent linear-gradient(137deg, #5E59FF 0%, #59FEF4 100%) 0% 0% no-repeat padding-box;',
                    boxShadow: '4px 3px 14px #0000007D;'
                }}
				overflowX="hidden"
                px={['0', '0', '0', '30px']}
				py={['0', '0', '0', '50px']}
			>
				<Box
					w="100%"
					bg="white"
                    boxShadow='lg'
					overflow="auto"
                    maxH='100%'
                    h={['100%','100%','auto','auto']}
					rounded={['0', '0', 'xl', 'xl']}
					p='20px'
				>
                    <Box py={['50px', '0', '0', '0']}>
                        <VStack alignItems='center' w='full'>
                            <Heading as="h2" textAlign='left' fontSize={['3xl', '3xl', '2xl', '3xl']} fontWeight='black' color="black">
                                Build once, sell forever
                            </Heading>
                            <Text   
                                sx={{
                                    marginTop: '0px !important',
                                    marginBottom: '10px !important'
                                }} 
                                color='primary.500' 
                                fontSize={['lg','lg','md','md']}  
                                fontWeight='bold'  
                                textAlign='center'
                            >
                                *60 day money-back guarantee*
                            </Text>
                            <HStack minWidth='max-content' direction='row' gap='2' zIndex='9999'>
                                <Box>
                                    <Text as='small' color='black' fontSize={['1rem','0.75rem']}>
                                        Annual
                                    </Text>
                                </Box>
                                <Box  display='flex' justifyContent='center'>
                                    <Switch 
                                        _focus={{ outline: 'none' }} 
                                        onChange={() => setMonthlyPlan(!monthlyPlan)} 
                                        sx={{ 
                                            'span.chakra-switch__track:not([data-checked])': { backgroundColor: 'purple.400' } 
                                        }}
                                        colorScheme='gray'
                                        size='md'
                                    />
                                </Box>
                                <Box>
                                    <Text as='small' color='black' fontSize={['1rem','0.75rem']}>Monthly</Text>
                                </Box>
                                
                            </HStack>
                        </VStack>
                        <Box sx={{marginTop: '20px'}}>
                        {platformPrices.length > 0 ? 
                            (
                                <Stack direction={['column', 'column', 'row', 'row']} justifyContent='center' spacing='10px'>
                                    {monthlyPlan  
                                        ? prices.filter((p) => p.id === 'REACT_APP_BASIC_MONTH_ID' || p.id === 'REACT_APP_BUSINESS_MONTH_ID' || p.id === 'REACT_APP_ENTERPRISE_MONTH_ID').map((price, id) => (
                                            <RadioInput 
                                                key={id}
                                                content={price}
                                                monthly={monthlyPlan}
                                                value={value}
                                                isChecked={plan === price.type}
                                                handleChange={handleChange}
                                            />
                                        )) 
                                        : prices.filter((p) => p.id === 'REACT_APP_BASIC_YEAR_ID' || p.id === 'REACT_APP_BUSINESS_YEAR_ID' || p.id === 'REACT_APP_ENTERPRISE_YEAR_ID').map((price, id) => (
                                            <RadioInput 
                                                key={id}
                                                content={price}
                                                monthly={monthlyPlan}
                                                value={value}
                                                isChecked={plan === price.type}
                                                handleChange={handleChange}
                                            />
                                        ))   
                                    }
                                </Stack>
                            )
                            : (
                                <Box display='flex' justifyContent='center' pb={10}>
                                    <Text fontSize='xl'>Loading...</Text>
                                </Box>
                            )
                        }

                            <Box mt='1.2rem'>
                                <Box mt={3} display={['flex', 'flex', 'none', 'none']} justifyContent='center'>
                                    <Text as='small'>
                                        {!plan && 'No plan selected'}
                                        {plan && sentence}
                                    </Text>
                                </Box>
                                <Box display='flex' justifyContent={'center'}>
                                    <NavButtons forward={goforward} backward={gobackward} width={'50%'} data={value} />
                                </Box>
                                <Box display='flex' justifyContent='center' mt={2} pb={['10', '10', '0', '0']}>
                                    <Text as='small'>60 day money-back guarantee & $0 setup</Text>
                                </Box>
                            </Box>
                        </Box>
                    </Box>                    
				</Box>
			</Box>

            <Box
                display={['none', 'None', 'none', 'block']}
				flex='0.9'
				bg="white"
				pos="relative"
                p={8}
			>
                <VStack>
                    <Flex justifyContent='center' w='75%'>
                        <Heading textAlign='center' my='50px' as='h2' fontSize='4xl' fontWeight='black' color='black'>
                            <Box as='span' fontSize='5xl'>Upgrading...</Box>
                            <Progress 
                                value="70" 
                                colorScheme='primary'  
                                isIndeterminate 
                                mt="10"
                                borderRadius='5' 
                            />
                        </Heading>                  
                    </Flex>
                    <Box display={['none', 'none', 'block', 'block']} overflow='hidden'>	
                        <div className='video-sizer'>
                            <video 
                                src='/images/spinningphone_01_1.mp4'
                                muted
                                autoPlay={bigWindow}
                                loop
                            />
                        </div>
                    </Box >
                </VStack>
			</Box>
		</Box>
	)
}

export default StepFive
