import { Text, Box, Icon } from '@chakra-ui/react'
import { ImWarning } from 'react-icons/im'
import { Link } from 'react-router-dom'

const PaymentFailed = () => {
	return (
		<Box pt="100px" h="100vh" bg="primary.100" overflowX="hidden">
			<Box
				w="100%"
				maxW="550px"
				mx="auto"
				p="6"
				h="auto"
				shadow={'sm'}
				rounded="10px"
				textAlign="center"
				bg="white"
			>
				<Icon fontSize="4xl" color="red.500" as={ImWarning} />

				<Text fontSize="2xl" mt="6">
					Payment Failed
				</Text>
				<Text color="gray.500" mt="2">
					Something went wrong
				</Text>
				<Text
					display="block"
					mt="4"
					as={Link}
					to="/"
					style={{ textDecoration: 'underline' }}
				>
					Back to Home
				</Text>
			</Box>
		</Box>
	)
}

export default PaymentFailed
