import { Modal, Flex, ModalOverlay, ModalHeader, ModalBody, ModalFooter,
	ModalContent, useDisclosure, FormControl, FormLabel, Input, Stack, 
	Text, Box, Select, useToast, Collapse
} from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import { FiEdit3, FiPlus } from 'react-icons/fi'
import { useForm } from 'react-hook-form'
import { postRequest } from '../services/base.service'
import { toastConfig } from '../helpers/appConfig'
import { useState, useRef } from 'react'

const AddFreePlan = ({ data, updateList }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [submitting, setSubmitting] = useState(false)
	const {
		register,
		reset,
		formState: { errors },
	} = useForm()
	const toast = useToast()

	const titleRef = useRef('')

	const handleUpdateData = (id, formData) => {
		postRequest(`pricing/${data.id}`, {
			...formData,
			price: 0,
			type: 'free'
		}).then((res) => {
			setSubmitting(false)
			onClose()
			reset()
			toast(toastConfig(res.message, res.status))
			updateList(
				'?sort=id,desc&?select=id,pricing_type,price,trial_day,annual_saving'
			)
		})
	}

	function handlePricingSubmit(e) {
		if (e.key === 'Enter' || e.type === 'click') {
			e.preventDefault()

			setSubmitting(true)

			if (data) {
				handleUpdateData(data.id, {
					title: titleRef.current.value,
				})
			} 
			else {
				postRequest(`pricing`, {
					title: titleRef.current.value,
					price: 0,
					type: 'free'
				})
				.then((res) => {
					setSubmitting(false)
					onClose()
					reset()
					toast(toastConfig(res.message, res.status))
					updateList(
						'?sort=id,desc&?select=id,pricing_type,price,trial_day,annual_saving'
					)
				})
			}	
		}
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent bg="white" color="secondary.800">
					<ModalHeader>{!data ? 'Add Free Plan' : 'Update Free Plan'}</ModalHeader>

					<form>
						<ModalBody>
							<Stack spacing="20px">
								<FormControl id="pricingTitle">
									<FormLabel>Title</FormLabel>
									<Input
										type="text"
										{...register('title', { required: true })}
										defaultValue={data?.title || ''}
										placeholder="Name"
										ref={titleRef}
										onKeyPress={handlePricingSubmit}
									/>
									{errors.title && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}
								</FormControl>
							</Stack>
						</ModalBody>

						<ModalFooter>
							<Button variant={'ghost'} mr={3} onClick={onClose}>
								Close
							</Button>
							<Button 
								type="submit" 
								variant="primary" 
								isLoading={submitting}
                    			loadingText={!data ? 'Adding...' : 'Updating...'}
								onClick={handlePricingSubmit}
							>
								{!data ? 'Add Pricing' : 'Update Pricing'}
							</Button>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>

			{!data ? (
				<Button leftIcon={<FiPlus />} onClick={onOpen} variant="primary">
					Add Free Plan
				</Button>
			) : (
				<Text as={Flex} alignItems="center" onClick={onOpen}>
					<FiEdit3 />{' '}
					<Text as={'span'} ms="3">
						Edit Free Plan
					</Text>
				</Text>
			)}
		</>
	)
}

export default AddFreePlan
