import {
	Button,
	FormControl,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	Box,
	Stack,
} from '@chakra-ui/react'
import { FiSave, FiEdit3 } from 'react-icons/fi'

const MGPPricingForm = ({ data }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<>
			{!data ? (
				<Button size={'sm'} variant={'primary'} onClick={onOpen}>
					+ Create Pricing
				</Button>
			) : (
				<Button
					onClick={onOpen}
					colorScheme={'green'}
					variant={'outline'}
					leftIcon={<FiEdit3 />}
					size={'sm'}
				>
					Edit
				</Button>
			)}

			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent as={'form'}>
					<ModalHeader>
						{!data ? 'Create New Pricing' : 'Edit Pricing'}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack spacing={'5'}>
							<FormControl>
								<Input
									defaultValue={data?.plan_name || ''}
									borderColor={'gray.300'}
									rounded={'full'}
									type={'text'}
									placeholder={'Plan Name'}
								/>
							</FormControl>

							<FormControl>
								<Input
									defaultValue={data?.description || ''}
									borderColor={'gray.300'}
									rounded={'full'}
									type={'text'}
									placeholder={'Description'}
								/>
							</FormControl>

							<FormControl>
								<Input
									defaultValue={data?.startup || ''}
									borderColor={'gray.300'}
									rounded={'full'}
									type={'text'}
									placeholder={'Startup ($)'}
								/>
							</FormControl>

							<FormControl>
								<Input
									defaultValue={data?.monthly || ''}
									borderColor={'gray.300'}
									rounded={'full'}
									type={'text'}
									placeholder={'Monthly fee ($)'}
								/>
							</FormControl>

							<FormControl>
								<Input
									defaultValue={data?.management_fee || ''}
									borderColor={'gray.300'}
									rounded={'full'}
									type={'text'}
									placeholder={'Management fee ($)'}
								/>
							</FormControl>
						</Stack>
					</ModalBody>

					<ModalFooter>
						<Button variant="ghost" mr={3} onClick={onClose}>
							Close
						</Button>
						<Button leftIcon={<FiSave />} variant="primary">
							{!data ? 'Save' : 'Update'}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default MGPPricingForm
