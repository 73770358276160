import { useState, useEffect } from 'react'
import { Box, Image, Heading, Text, FormLabel,
	Input, VStack,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { MdFitnessCenter, MdGroups, MdArrowForwardIos } from 'react-icons/md'
import { TbHeartHandshake } from 'react-icons/tb'
import { GiOpenBook } from 'react-icons/gi'
import NavButtons from '../NavButtons'

const RadioInput = ({ label, value, icon, isChecked, handleChange, subtitle }) => {
    function handleRadioChange(e) {
        const {id} = e.currentTarget
        handleChange(id)
    }

    return (
        <Box 
            sx={{
                display: 'inline-block',
            }}
        >
            <Input
                type="radio"
                sx={{
                display: 'none',
                }}
                name='industry_id'
                id={value}
                checked={isChecked}
                onChange={handleRadioChange}
            />
            <FormLabel 
                htmlFor={value}
                sx={{
                    cursor: 'pointer'
                }}
            >
                <Box
                    width='22rem'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    textColor='#101010'
                    px={3}
                    py={4}
                    sx={{
                        background: isChecked ? '#ABABAB 0% 0% no-repeat padding-box;' : '#F3F3F3 0% 0% no-repeat padding-box;',
                        boxShadow: '0px 3px 6px #00000029;',
                        borderRadius: '5px;'
                    }}
                >
                    {icon}
                    <Box flex flexDirection="column">
                        <Text 
                            ml={4} 
                            fontFamily='listings'
                            letterSpacing='0.61px'
                            fontSize='17px'
                            fontStyle='normal'
                            color={isChecked ? 'black' : 'gray.500' } 
                            fontWeight='600'
                        >{label}</Text>
                        <Text
                            ml={4}
                            fontFamily='listings'
                            letterSpacing='0.61px'
                            fontSize='sm'
                            fontStyle='normal'
                            color={isChecked ? 'black' : 'gray.500' } 
                        >{subtitle}</Text>
                    </Box>
                    <Text 
                        ml='auto'
                        as='span'
                        display='inline-block'
                        float='right'
                    ><MdArrowForwardIos size='0.9em' /></Text>
                    
                </Box>
            </FormLabel>
        </Box>
    )
}

const StepThree = ({ value, onChange, prevStep, nextStep }) => {
    const [industry, setIndustry] = useState(3)
    const [isChecked, setIsChecked] = useState(null)

    const {
		formState: { errors },
	} = useForm()

    function gobackward() {
        prevStep()
    }

    function goforward() {
        nextStep()
    }

    function handleChange(val) {
        setIndustry(val)
    }

    useEffect(() => {
        if (industry === 13) {
            return
        }
        onChange({
            ...value,
            industry_id: industry
        })
    }, [industry])


    return (
		<Box display={'flex'} h="100vh" bg="secondary.100" color="secondary.700">
			<Box
				flex="1"
				display={['block', 'block', 'flex', 'flex']}
				justifyContent="center"
				alignItems="center"
				sx={{
                    background: `transparent linear-gradient(137deg, #5E59FF 0%, #59FEF4 100%) 0% 0% no-repeat padding-box;`
                }}
				overflowX="hidden"
				p={['0', '0', '0', '20px']}
			>
                <Box
                    w="100%"
                    maxW={['100%', '100%', '100%', '450px']}
                    bg="white"
                    boxShadow='lg'
                    overflowX="hidden"
                    maxH="100%"
                    h={['100%','100%','auto','auto']}
                    rounded={['0', '0', 'xl', 'xl']}
                    p="40px"
                >
                    <Box py={['20px', '0', '0', '0']}>
                    <Heading mt={['35%', '35%', '0', '0']} as="h3" fontSize="xl" color="secondary.500" mb={8}>
                        What kind of app will you build?
                    </Heading>

                    <VStack>
                        <RadioInput
                            type='radio'
                            label='Fitness programs/training'
                            subtitle="Programs, community, training"
                            icon={<MdFitnessCenter size='1.2em' />}
                            value='3'
                            isChecked={industry == 3}
                            handleChange={handleChange}
                        />
                        <RadioInput
                            type='radio'
                            label='Organizations'
                            subtitle="Schools, businesses, clubs..."
                            icon={<TbHeartHandshake size='1.2em' />}
                            value='8'
                            isChecked={industry == 8}
                            handleChange={handleChange}
                        />
                        <RadioInput
                            type='radio'
                            label='Courses'
                            subtitle="Business, design, videography..."
                            icon={<GiOpenBook size='1.2em' />}
                            value='1'
                            isChecked={industry == 1}
                            handleChange={handleChange}
                        />
                        {/* <RadioInput
                            type='radio'
                            label='Coming soon'
                            icon={<MdGroups size='1.2em' />}
                            value='13'
                            isChecked={industry == 13}
                            handleChange={() => null}
                        /> */}
                        
                        <NavButtons forward={goforward} backward={gobackward} width={'100%'} data={value} />
                        
                    </VStack>
                    </Box>
                </Box>
			</Box>

            <Box
				display={['none', 'None', 'none', 'block']}
				flex="1"
				bg="primary.100"
				pos="relative"
			>
                <Image
                    h="100vh"
                    w="100%"
                    objectFit="cover"
                    objectPosition='center'
                    loading='lazy'
					src="https://i.imgur.com/erpuzav.jpg"
                />
            

				<Box
					pos="absolute"
					top="0"
					left="0"
					w="100%"
					h="100%"
					bg="rgba(0, 0, 0, .05)"
				></Box>
			</Box>
		</Box>
	)
}

export default StepThree
