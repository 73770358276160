import { useState, useRef } from 'react'
import { Box, Image, Heading, Text, Stack, FormControl,
	FormLabel, Input, InputGroup, InputRightElement, useToast,
	Button, Link
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { FcGoogle } from 'react-icons/fc'
import { MdArrowForwardIos} from 'react-icons/md'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { Link as RRLink, useLocation, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { clearErrMsg, userRegister } from '../../redux'
import { toastConfig } from '../../helpers/appConfig'
import { isPublicDomain } from '../../helpers/utils'
import { useSelector } from 'react-redux'
import { getRequest } from '../../services/base.service'
import '../../components/auth/auth.css'

const AuthRegister = ({ auth, handleUserRegister, handleClearErrMsg }) => {
	const [showPass, setShowPass] = useState(false)
	const { regErrors } = useSelector((state) => state.auth)
	const handleTogglePass = () => setShowPass(!showPass)
	const [working, setWorking] = useState(false)

	const nameRef= useRef('')
	const emailRef= useRef('')
	const passwordRef= useRef('')

	const {
		register,
		formState: { errors },
	} = useForm()
	const toast = useToast()

	function handleGoogleLogin() {
		getRequest(`auth/google-login`)
			.then((res) => {
				setWorking(true)
				window.location.href = res
			})
			.catch((err) => {
				console.log('ERR: ', err)
			})
	}

	function handleSubmit(e) {
		if (e.key === 'Enter' || e.type === 'click') {
			const data = {
				name: nameRef.current.value,
				email: emailRef.current.value.toLowerCase(),
				password: passwordRef.current.value
			}

			handleUserRegister(data, (message, status) => {
				toast(toastConfig(message, status))
			})
		}
	}

	let location = useLocation()
	if (auth.user) {
		return <Navigate to="/signup/2" state={{ from: location }} />
	}

	if (isPublicDomain()) {
		return <Navigate to="/" />
	}

	return (
		<Box display={'flex'} h="100vh" bg="secondary.100" color="secondary.700">
			<Box
				flex="1"
				display={['block', 'block', 'flex', 'flex']}
				justifyContent="center"
				alignItems="center"
				sx={{
                    background: `transparent linear-gradient(137deg, #5E59FF 0%, #59FEF4 100%) 0% 0% no-repeat padding-box;`
                }}
				overflowX="hidden"
				p={['0', '0', '0', '20px']}
			>
				<Box display={['none', 'none', 'block', 'block']}>
					<div class="custom-shape-divider-bottom-1656696063">
						<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
							<path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
						</svg>
					</div>
					<div class="custom-shape-divider-bottom-1656695281">
						<svg data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
							<path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
						</svg>
					</div>
				</Box>
				<Box
					w="100%"
					maxW={['100%', '100%', '100%', '450px']}
					bg="white"
                    boxShadow='lg'
					overflowX="hidden"
					maxH="100%"
                    h={['100%', '100%', 'auto', 'auto']}
					rounded={['0', '0', 'xl', 'xl']}
					p="40px"
					zIndex='2'
				>
					<Heading as="h3" fontSize="xl" fontweight='semibold' letterSpacing='wide' color="secondary.500" mb="1">
						Let's get started!
					</Heading>
					<Text>Register below</Text>
					<form
					>
						<Button
							w='full'
							maxW={'md'}
							variant='outline'
							mt={6}
							_hover={{
								borderColor: '#ddd',
								bg: '#eee'
							}}
							boxShadow='md'
							isLoading={working}
							onClick={handleGoogleLogin}
							display='flex'
							justifyContent='center'
						>
							<Text mr='auto'>
								<FcGoogle size='1.5em' />
							</Text>
							<Text fontSize='sm' fontWeight='semibold' mr='auto'>
								Sign up with Google
							</Text>
						</Button>
						<Stack spacing="20px" pt="40px">
							<FormControl variant='floating' id="authFullName">
								<FormLabel fontSize='xs'>Name</FormLabel>
								<Input
									borderColor="secondary.200"
									_hover={{
										borderColor: 'secondary.300',
									}}
									_focus={{
										borderColor: 'secondary.400',
									}}
									_placeholder={{
										color: 'secondary.400',
									}}
									{...register('name', {required: true})}
									rounded="lg"
									type="text"
									ref={nameRef}
									onKeyPress={handleSubmit}
								/>

								{errors.name && (
									<Text fontSize="sm" mt="2" color="red.500">
										This field is required
									</Text>
								)}
								{regErrors?.name && (
									<Text fontSize="sm" mt="2" color="red.500">
										{regErrors?.name[0]}
									</Text>
								)}
							</FormControl>

							<FormControl variant='floating' id="authEmail">
								<FormLabel fontSize='xs'>Email</FormLabel>
								<Input
									borderColor="secondary.200"
									_hover={{
										borderColor: 'secondary.300',
									}}
									_focus={{
										borderColor: 'secondary.400',
									}}
									_placeholder={{
										color: 'secondary.400',
									}}
									{...register('email', {required: true})}
									rounded="lg"
									type="text"
									textTransform='lowercase'
									ref={emailRef}
									onKeyPress={handleSubmit}
								/>

								{errors.email && (
									<Text fontSize="sm" mt="2" color="red.500">
										This field is required
									</Text>
								)}
								{regErrors?.email && (
									<Text fontSize="sm" mt="2" color="red.500">
										{regErrors?.email[0]}
									</Text>
								)}
							</FormControl>

							<FormControl variant='floating' id="authPassword">
								<FormLabel
									fontSize='xs'
								>
									Password
								</FormLabel>
								<InputGroup size='md'>
									<Input
										pr='4.5rem'
										borderColor="secondary.200"
										_hover={{
											borderColor: 'secondary.300',
										}}
										_focus={{
											borderColor: 'secondary.400',
										}}
										_placeholder={{
											color: 'secondary.400',
										}}
										{...register('password', {required: true})}
										rounded="lg"
										type={showPass ? 'text' : 'password'}
										ref={passwordRef}
										onKeyPress={handleSubmit}
									/>
									<InputRightElement>
										<Button
											bg="transparent"
											size="sm"
											_hover={{ backgroundColor: 'transparent' }}
											_focus={{
												boxShadow: 'none',
												backgroundColor: 'transparent',
											}}
											_active={{
												boxShadow: 'none',
												backgroundColor: 'transparent',
											}}
											onClick={handleTogglePass}
										>
											{showPass ? <FiEyeOff /> : <FiEye />}
										</Button>
									</InputRightElement>
								</InputGroup>

								{errors.password && (
									<Text fontSize="sm" mt="2" color="red.500">
										This field is required
									</Text>
								)}
								{regErrors?.password && (
									<Text fontSize="sm" mt="2" color="red.500">
										{regErrors?.password[0]}
									</Text>
								)}
							</FormControl>

							<Box pt="3">
								<Text as='small' color='gray.500'>By creating an account, you agree to our 
									{' '}<Link isExternal color='teal.500' href='https://drive.google.com/file/d/1mQ3ybguCVxKP07E3fTSEFM50gOedOyTo/view?usp=sharing'>Terms & Conditions</Link>
								</Text>
								<Button
									variant="primary"
									isLoading={auth.loading}
									loadingText=""
                                    rounded='sm'
									w="100%"
									mt='15px'
									letterSpacing='wide'
									display='flex'
									onClick={handleSubmit}
								>
									<Text ml='auto' fontWeight='bold'>Pick app name</Text>
									<Text ml='auto'><MdArrowForwardIos size='0.9em' /></Text>
								</Button>

								<Text mt="4" textAlign='center' fontSize='xs' fontWeight='semibold' color='gray.600'>
									Already have an account?
									<Link to="/login" as={RRLink} color="primary.500" ms="2">
										Login here
									</Link>
								</Text>
							</Box>
						</Stack>
					</form>
				</Box>
			</Box>

            <Box
				display={['none', 'None', 'none', 'block']}
				flex="1"
				bg="primary.100"
				pos="relative"
			>
				<Image
					h="100vh"
					w="100%"
					objectFit="cover"
                    objectPosition='40% 0'
					src="https://i.imgur.com/erpuzav.jpg"
				/>

				<Box
					pos="absolute"
					top="0"
					left="0"
					w="100%"
					h="100%"
					bg="rgba(0, 0, 0, .05)"
				></Box>

                <Box
					pos="absolute"
					top="0%"
					w="100%"
					h="100%"
					bg="rgba(0, 0, 0, .05)"
				>
                    <Image
                        h="70vh"
                        src="https://i.imgur.com/9iDJGLN.png"
                    />
                </Box>
			</Box>
		</Box>
	)
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		handleUserRegister: (data, callback) =>
			dispatch(userRegister(data, callback)),
		handleClearErrMsg: () => dispatch(clearErrMsg()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthRegister)
