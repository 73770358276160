const appStyles = {
	global: {
		body: {
			bg: 'white',
			color: 'secondary.800',
		},
		/* iframe: {
			width: '100% !important',
			height: '400px',
		}, */
	},
}

export default appStyles
