import React from 'react';
import { Box, Button, Link, Text, Image} from '@chakra-ui/react';
import Carousel from 'react-elastic-carousel';
import styled from 'styled-components';
import './Slider.css'

const Item = styled.div`
    max-width: 100%;
    width: 100%;
    max-height: 600px;
    height: ${props => `${props.height}px` || '600px'};
    margin: 0 0;
    display: flex;
    justify-content: ${props => props.justifyContent || 'flex-start'};
    align-items: ${props =>props.alignItems || 'flex-start'};
    background: url(${props => props.bgImage || 'none'}) no-repeat;
    background-color: ${props => props.bg || "transparent"};
    background-position: center;
    background-size: cover;
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    overflow: hidden;
`;

function Slider({component}) {
    
    const handleSlideClick = (link) => {
        if (link.hasLink) {
            window.open(link.url, '_blank').focus();
        }
    }

    return (
        <Carousel 
            itemsToScroll={1} 
            itemsToShow={component.itemToShow}
            itemPadding={[0, component.padding]}
            showArrows={false} 
            pagination={true}
            enableAutoPlay={component.autoPlay}
            autoPlaySpeed={5000}
        >
            {
                component.items.map(item => {

                    return (
                        <Item 
                            height={component.height}
                            bg={item.bgColor} 
                            justifyContent={item.justifyContent} 
                            alignItems={item.alignItems}
                            bgImage={component.imageAs === 'background' 
                                ? item.bgImage?.path ? item.bgImage?.path  : 'none' 
                                : 'none'
                            } 
                            key={item.id}
                            onClick={() => handleSlideClick(item.link)}
                        >
                            <Box 
                                p={2} maxWidth="100%"
                                width={component.imageAs ==='image-tag' ? '100%' : '50%'}
                                textAlign={item.innerAlignment}
                                mt={item.margin.top} mb={item.margin.bottom}
                                mr={item.margin.right} ml={item.margin.left}
                            >  

                                {
                                    component.imageAs ==='image-tag' 
                                    ?  
                                    <Box width="100%" rounded={4} display="flex" justifyContent="center" alignItems="center">
                                        <Image 
                                            src={item.bgImage?.path ? item.bgImage?.path  : '' } 
                                            width="100%" display="block" 
                                            rounded={4} 
                                        /> 
                                    </Box>    
                                    : null
                                }

                                {   
                                    item.header.visibility ? 
                                    <Text as="h2"
                                        pt={`${item.header.padding.top}px`} pr={`${item.header.padding.right}px`}
                                        pb={`${item.header.padding.bottom}px`} pl={`${item.header.padding.left}px`}
                                        fontSize={`${item.header.font.size}px`} fontWeight={item.header.font.weight}
                                        fontFamily={item.header.font.fontFamily === 'default' ? 'poppins' : item.header.font.fontFamily}
                                        textTransform={item.header.font.transform}
                                        color={item.header.font.color}
                                    >
                                        {item.header.data}
                                    </Text> : null
                                }
                                {
                                    item.text.visibility ? 
                                    <Text
                                        pt={`${item.text.padding.top}px`} pr={`${item.text.padding.right}px`}
                                        pb={`${item.text.padding.bottom}px`} pl={`${item.text.padding.left}px`}
                                        fontSize={`${item.text.font.size}px`} fontWeight={item.text.font.weight}
                                        fontFamily={item.text.font.fontFamily === 'default' ? 'poppins' : item.text.font.fontFamily}
                                        textTransform={item.text.font.transform}
                                        color={item.text.font.color} 
                                    >
                                        {item.text.data}
                                    </Text> : null
                                }
                                {
                                    item.button.visibility ? 
                                    <Button
                                        pt={`${item.button.padding.top}px`} pr={`${item.button.padding.right}px`}
                                        pb={`${item.button.padding.bottom}px`} pl={`${item.button.padding.left}px`}
                                        fontSize={`${item.button.font.size}px`} fontWeight={item.button.font.weight}
                                        fontFamily={item.button.font.fontFamily === 'default' ? 'poppins' : item.button.font.fontFamily}
                                        textAlign={item.button.font.align} textTransform={item.button.font.transform}
                                        color={item.button.font.color}
                                        bgColor={item.button.bgColor}
                                        width={item.button.width} height={item.button.height}
                                        borderTopWidth={`${item.button.border.top}px`} borderBottomWidth={`${item.button.border.bottom}px`}
                                        borderLeftWidth={`${item.button.border.left}px`} borderRightWidth={`${item.button.border.right}px`}
                                        borderColor={item.button.border.color}
                                        rounded={`${item.button.rounded.topLeft}px`}
                                    >
                                        {
                                            item.link.hasLink ?
                                            <Link href={item.link.url} isExternal>
                                                {item.button.data}
                                            </Link>
                                            : item.button.data
                                        }
                                    </Button> : null

                                }
                            </Box>
                        </Item>
                    )
                })
            }
            
        </Carousel>
    )
}

export default Slider
