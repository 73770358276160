import {
	Avatar,
	Box,
	Container,
	Flex,
	Heading,
	Input,
	InputGroup,
	InputLeftElement,
	Select,
	Table,
	Tbody,
	Td,
	Text,
	Tfoot,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { BsThreeDots } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { changePageTitle } from '../redux'
import { getRequest } from '../services/base.service'
import Paginate from '../components/Paginate'
import { Link } from 'react-router-dom'

const AdminSubscribers = () => {
	const [subscribers, setSubscribers] = useState([])
	const [searchTimeout, setSearchTimeout] = useState(null)
	const [limit] = useState(10)
	const [currentPage, setCurrentPage] = useState(1)
	const [pageCount, setPageCount] = useState(0)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(changePageTitle('Subscribers'))
		fetchSubscribers()
	}, [])

	const fetchSubscribers = (page = currentPage, params = {}) => {
		getRequest(`admin/subscribers`, { params: { ...params, limit, page } })
			.then(({ result }) => {
				setSubscribers(result.data)
				setPageCount(Math.ceil(result?.total / result?.per_page))
				setCurrentPage(page)
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const handleSearch = (event) => {
		clearTimeout(searchTimeout)

		setSearchTimeout(
			setTimeout(function () {
				fetchSubscribers(1, { search_string: event.target.value })
			}, 300)
		)
	}

	return (
		<Box flex="1" p="30">
			<Container maxW="container.lg">
				<Box bg="white" p="30px" borderRadius="15" boxShadow="md">
					{/* Filter bar */}
					<Flex justifyContent="space-between" mb="6">
						<Flex>
							<InputGroup minW={'200px'} flex="2">
								<InputLeftElement
									pointerEvents="none"
									children={<FiSearch />}
								/>
								<Input
									type="search"
									borderColor="secondary.400"
									_hover={{ borderColor: 'secondary.500' }}
									_focus={{ borderColor: 'secondary.500' }}
									_placeholder={{ color: 'secondary.400' }}
									rounded="full"
									placeholder="Search..."
									// value={searchTerm}
									onKeyUp={handleSearch}
								/>
							</InputGroup>

							{/* <Select
								rounded={'full'}
								ms={2}
								variant="outline"
								placeholder="Select Trainer"
								borderColor="secondary.400"
								_hover={{ borderColor: 'secondary.500' }}
								_focus={{ borderColor: 'secondary.500' }}
								_placeholder={{ color: 'secondary.400' }}
							>
								<option value={1}>ABC Trainer</option>
							</Select> */}
						</Flex>
					</Flex>

					<Table variant={'striped'}>
						<Thead>
							<Tr>
								<Th w={'60px'}></Th>
								<Th>Subscriber Info</Th>
								<Th>Joining Date</Th>
								<Th>Trainer</Th>
								{/* <Th isNumeric /> */}
							</Tr>
						</Thead>

						<Tbody>
							{subscribers.length > 0 ? (
								subscribers.map((subscriber, index) => (
									<Tr key={index}>
										<Td>
											<Avatar name={subscriber?.name} />
										</Td>
										<Td>
											<Heading
												as={'h3'}
												fontSize={'1rem'}
												fontWeight={'semibold'}
												color={'gray.700'}
											>
												{subscriber?.name}
											</Heading>

											<Text mt={2}>{subscriber?.email}</Text>
										</Td>
										<Td>June 12, 2022</Td>
										<Td>
											<Text
												as={Link}
												to={`/admin/trainers/details/${subscriber?.trainer?.id}`}
											>
												{subscriber?.trainer?.name}
											</Text>
										</Td>
										{/* <Td isNumeric>
									<Menu placement={'bottom-end'}>
										<MenuButton
											as={IconButton}
											bg={'transparent'}
											icon={<BsThreeDots />}
										/>
										<MenuList>
											<MenuItem>Edit</MenuItem>
										</MenuList>
									</Menu>
								</Td> */}
									</Tr>
								))
							) : (
								<Tr>
									<Td textAlign={'center'} colSpan={4}>
										<Text as={'strong'}>No subscriber found</Text>
									</Td>
								</Tr>
							)}
						</Tbody>
						{/* <Tfoot>
							<Tr>
								<Th w={'60px'}></Th>
								<Th>Subscriber Info</Th>
								<Th>Joining Date</Th>
								<Th>Trainer</Th>
							</Tr>
						</Tfoot> */}
					</Table>

					<Box mt="6">
						<Paginate onPageChange={fetchSubscribers} pageCount={pageCount} />
					</Box>
				</Box>
			</Container>
		</Box>
	)
}

export default AdminSubscribers
