import React, { useContext, useEffect, useState } from 'react'
import { Box, Fade } from '@chakra-ui/react'
import Columns from './Columns'
import { Draggable } from 'react-beautiful-dnd'
import SectionConfigButton from './SectionConfigButton'
import { PageBuilderContext } from '../context/PageBuilderContext'
import SectionAddButton from './SectionAddButton'

function Section() {
	const [state, dispatch] = useContext(PageBuilderContext)
	const [sectionList, setSectionList] = useState([])
	const { selected } = state

	useEffect(() => {
		setSectionList(state.section)
	}, [state.section])

	return (
		<>
			{sectionList.map((section, index) => (
				<Draggable key={section.id} draggableId={section.id} index={index}>
					{(provided) => (
						<Box
							key={section.id}
							ref={provided.innerRef}
							{...provided.draggableProps}
							className={
								selected?.type === 'section' && selected?.sectionIndex === index
									? 'selected-section'
									: 'section-wrapper'
							}
						>
							<Fade in={true}>
								<Box
									className="page-section"
									_hover={{ '& > .btn-section-add': { d: 'block' } }}
									mt={`${section.style?.margin?.top}px`}
									mb={`${section.style?.margin?.bottom}px`}
									ml={`${section.style?.margin?.left}px`}
									mr={`${section.style?.margin?.right}px`}
									pt={`${section.style.padding.top}px`}
									pb={`${section.style.padding.bottom}px`}
									pl={`${section.style.padding.left}px`}
									pr={`${section.style.padding.right}px`}
									bg={section.style.bgColor}
									backgroundImage={section.style.bgImage?.path}
									backgroundPosition="center"
									backgroundRepeat="no-repeat"
									backgroundSize="cover"
								>
									<Columns index={index} section={section} />

									<SectionConfigButton
										dragElement={{ ...provided.dragHandleProps }}
										sectionIndex={index}
									/>

									<Box
										d="none"
										className={`btn-section-add`}
										pos="absolute"
										top="calc(100% + 5px)"
										left="50%"
										transform="translate(-50%, -50%)"
										zIndex="99"
										pb="10px"
									>
										<SectionAddButton index={index} />
									</Box>
								</Box>
							</Fade>
						</Box>
					)}
				</Draggable>
			))}
		</>
	)
}

export default Section
