import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	FormControl,
	FormLabel,
	Heading,
	IconButton,
	Input,
	List,
	ListIcon,
	ListItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Select,
	Stack,
	Td,
	Text,
	Tr,
	useDisclosure,
	useToast,
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FiMoreVertical, FiEdit3, FiX } from 'react-icons/fi'
import { useLongPress } from 'use-long-press'
import { toastConfig } from '../helpers/appConfig'
import { postRequest } from '../services/base.service'

const CustomerRow = ({ getList, bigWindow, data, type }) => {
	const { isOpen: isSubscriptionOpen, onOpen: onSubscriptionOpen, onClose: onSubscriptionClose } = useDisclosure()
	const { isOpen: isCustomerOpen, onOpen: onCustomerOpen, onClose: onCustomerClose } = useDisclosure()
	const { isOpen: isUpdateOpen, onOpen: onUpdateOpen, onClose: onUpdateClose } = useDisclosure()
	const { isOpen: isPopoverOpen, onOpen: onPopoverOpen, onClose: onPopoverClose } = useDisclosure()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isDeleting, setIsDeleting] = useState(false)
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm()
	const toast = useToast()
	const [submissionErrors, setSubmissionErrors] = useState([])
	const cancelRef = useRef()

	function handleDeleteCustomer() {
		setIsDeleting(true)
		postRequest(`delete-customer`, {
			customer_id: data?.trainee?.id,
			subscription_id: type === 'free' ? data?.id : null,
			customer_stripe: type !== 'free' ? data?.customer?.id : null,
			type: type,
		})
			.then((res) => {
				setIsDeleting(false)
				toast(toastConfig(res.message, res.status))
				getList()
				onCustomerClose()
			})
			.catch((err) => {
				setIsDeleting(false)
				console.log(err.response.data.errors)
			})
	}

	function handleCancelSubscriber(action){
		setIsDeleting(true)
		postRequest(`delete-subscription`, {
			customer_id: data?.trainee?.id,
			subscription_stripe: type !== 'free' ? data?.id : null,
			subscription_id: type === 'free' ? data?.id : null,
			customer_stripe: data?.customer?.id,
			type: type,
			action: action
		})
			.then((res) => {
				setIsDeleting(false)
				toast(toastConfig(res.message, res.status))
				getList()
				onSubscriptionClose()
			})
			.catch((err) => {
				setIsDeleting(false)
				console.log(err.response.data.errors)
			})
	}

	function onSubmit(formData) {
		setIsSubmitting(true)

		postRequest(`update-customer`, {
			...formData,
			customer_id: data?.trainee?.id,
			customer_stripe: data?.customer?.id,
			type: type
		})
			.then((res) => {
				if (res.status !== 'error') {
					toast(toastConfig(res.message, res.status))
					onUpdateClose()
					reset()
					setSubmissionErrors([])
					getList()
				} else {
					setSubmissionErrors({ ...submissionErrors, product_id: res.message })
				}
				setIsSubmitting(false)
			})
			.catch((err) => {
				console.log(err.response.data.errors)
				setIsSubmitting(false)
				// setSubmissionErrors(err.response.data.errors)
			})
	}

	const bind = useLongPress(() => {
		onPopoverOpen()
	})
	
	return (
		<>
			<Modal isOpen={isUpdateOpen} onClose={onUpdateClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Update Customer</ModalHeader>

					<form onSubmit={handleSubmit(onSubmit)}>
						<ModalBody>
							<Stack spacing="20px">
								<FormControl id="subscriberName" isRequired>
									<FormLabel>Name</FormLabel>
									<Input
										type="text"
										placeholder="Name"
										rounded={'full'}
										defaultValue={type === 'free' ? data?.trainee.name : data?.customer?.name}
										{...register('name', { required: true })}
									/>
									{errors.name && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}
								</FormControl>
								<FormControl id="subscriberEmail" isRequired>
									<FormLabel>Email</FormLabel>
									<Input
										type="email"
										rounded={'full'}
										defaultValue={type === 'free' ? data?.trainee.email : data?.customer?.email}
										placeholder="Email address"
										{...register('email', { required: true })}
									/>

									{errors.email && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}

									{submissionErrors?.email && (
										<Text fontSize="sm" mt="2" color="red.500">
											{submissionErrors.email}
										</Text>
									)}
								</FormControl>
							</Stack>
						</ModalBody>

						<ModalFooter>
							<Button variant={'ghost'} mr={3} onClick={onUpdateClose}>
								Close
							</Button>
							<Button
								type="submit"
								loadingText={'Updating'}
								isLoading={isSubmitting}
								variant="primary"
							>
								Update Customer
							</Button>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>

			<Modal isOpen={isPopoverOpen} onClose={onPopoverClose}>
				<ModalOverlay>
					<ModalContent>
						<ModalHeader>{type === 'free' ? data?.trainee?.name : data?.customer?.name || ''}</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
						<List spacing={3}>
							<ListItem
								cursor="pointer"
								onClick={onUpdateOpen}
								_hover={{
									'& > .action-icon': { color: 'primary.500' },
									color: 'primary.500',
								}}
							>
								<ListIcon
									as={FiEdit3}
									me="2"
									className="action-icon"
									color="secondary.400"
								/>
								Update Customer
							</ListItem>
							<ListItem
								cursor="pointer"
								onClick={onSubscriptionOpen}
								_hover={{
									'& > .action-icon': { color: 'primary.500' },
									color: 'primary.500',
								}}
							>
								<ListIcon
									as={FiX}
									me="2"
									className="action-icon"
									color="secondary.400"
								/>
								Cancel Subscription
							</ListItem>

							<ListItem
								onClick={onCustomerOpen}
								cursor="pointer"
								_hover={{
									'& > .action-icon': { color: 'primary.500' },
									color: 'primary.500',
								}}
							>
								<ListIcon
									className="action-icon"
									as={FiX}
									me="2"
									color="secondary.400"
								/>
								Delete Customer
							</ListItem>
						</List>
						</ModalBody>
					</ModalContent>
				</ModalOverlay>
			</Modal>

			{/** Customer */}
			<AlertDialog
				isOpen={isCustomerOpen}
				leastDestructiveRef={cancelRef}
				onClose={onCustomerClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Delete Customer
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? Please note that this will also cancel their current subscriptions without refund. This cannot be undone.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button
								loadingText={'Deleting'}
								isLoading={isDeleting}
								colorScheme="red"
								onClick={() => handleDeleteCustomer(data)}
								ml={3}
							>
								Yes, delete
							</Button>
							<Button ml={3} ref={cancelRef} onClick={onCustomerClose}>
								Nevermind
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			{/** Subscription */}
			<AlertDialog
				isOpen={isSubscriptionOpen}
				leastDestructiveRef={cancelRef}
				onClose={onSubscriptionClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Cancel Subscription
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? If so, cancel with or without refund? (This action can't be undone)
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button
								loadingText={'Deleting'}
								isLoading={isDeleting}
								colorScheme="red"
								onClick={() => handleCancelSubscriber('refund')}
								ml={3}
							>
								With refund
							</Button>
							<Button
								loadingText={'Deleting'}
								isLoading={isDeleting}
								colorScheme="red"
								onClick={() => handleCancelSubscriber('cancel')}
								ml={3}
							>
								Without refund
							</Button>
							<Button ml={3} ref={cancelRef} onClick={onSubscriptionClose}>
								Nevermind
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			<Tr {...bind()} _hover={{ bg: 'gray.50' }}>
				<Td p={bigWindow ? 4 : 2}>{type === 'free' ? data?.trainee?.name : data?.customer?.name || ''}</Td>
				<Td p={bigWindow ? 4 : 2}>{type === 'free' ? data?.trainee?.email : data?.customer?.email || ''}</Td>
				{!bigWindow && (
					<Td p={bigWindow ? 4 : 2}>{type === 'free' ? data?.pricing?.title : data?.plan?.nickname || '-'}</Td>
				)}
				{bigWindow && (
					<>
						<Td p={bigWindow ? 4 : 2}>{data?.id || '-'}</Td>
						<Td p={bigWindow ? 4 : 2}>{type === 'free' ? data?.pricing?.title : data?.plan?.nickname || '-'}</Td>
						<Td p={bigWindow ? 4 : 2} isNumeric>
							<Popover 
								placement={bigWindow ? 'bottom-end' : 'left-start'} 
								sx={{
									'z-index': '9999 !important'
								}}
							>
								<PopoverTrigger>
									<IconButton
										variant={'ghost'}
										_hover={{ outline: 'none', boxShadow: 'none' }}
										_focus={{ outline: 'none', boxShadow: 'none' }}
										aria-label="Actions"
										icon={<FiMoreVertical />}
										p="0"
										right="2px"
									/>
								</PopoverTrigger>
								<PopoverContent
									maxW="150px"
									size="md"
									bg="white"
									style={{ boxShadow: 'none' }}
								>
									<PopoverBody
										textAlign={'left'}
										boxShadow="base"
										border="1px"
										rounded="md"
										borderColor="secondary.200"
										size="md"
									>
										<List spacing={3}>
											<ListItem
												cursor="pointer"
												onClick={onUpdateOpen}
												_hover={{
													'& > .action-icon': { color: 'primary.500' },
													color: 'primary.500',
												}}
											>
												<ListIcon
													as={FiEdit3}
													me="2"
													className="action-icon"
													color="secondary.400"
												/>
												Update Customer
											</ListItem>
											<ListItem
												cursor="pointer"
												onClick={onSubscriptionOpen}
												_hover={{
													'& > .action-icon': { color: 'primary.500' },
													color: 'primary.500',
												}}
											>
												<ListIcon
													as={FiX}
													me="2"
													className="action-icon"
													color="secondary.400"
												/>
												Cancel Subscription
											</ListItem>

											<ListItem
												onClick={onCustomerOpen}
												cursor="pointer"
												_hover={{
													'& > .action-icon': { color: 'primary.500' },
													color: 'primary.500',
												}}
											>
												<ListIcon
													className="action-icon"
													as={FiX}
													me="2"
													color="secondary.400"
												/>
												Delete Customer
											</ListItem>
										</List>
									</PopoverBody>
								</PopoverContent>
							</Popover>
						</Td>
					</>
				)}
			</Tr>
		</>
	)
}

export default CustomerRow
