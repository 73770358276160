import {
	Box,
	Button,
	Container,
	Flex,
	Grid,
	GridItem,
	Heading,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import MGPPricingCard from '../components/MGPPricingCard'
import MGPPricingForm from '../components/MGPPricingFrom'

const PricingManagement = () => {
	return (
		<Box flex="1" p="30">
			<Container maxW="container.lg">
				<Box bg="white" p="30px" borderRadius="15" boxShadow="md">
					<Flex alignItems={'center'} justifyContent={'space-between'}>
						<Heading fontSize={'1.1rem'}>Pricing Management</Heading>

						<MGPPricingForm />
					</Flex>

					<Grid mt={8} templateColumns={'repeat(3, 1fr)'} gap={6}>
						<GridItem>
							<MGPPricingCard />
						</GridItem>
					</Grid>
				</Box>
			</Container>
		</Box>
	)
}

export default PricingManagement
