import React, { useContext, useEffect, useState } from 'react'
import { Box, Stack, Text, Flex, Button, Spacer,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    FormControl, FormLabel, Radio, RadioGroup,
    NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Input,
    Select, InputGroup, InputRightAddon, Textarea, 
    Modal, ModalOverlay, ModalContent,
    Accordion,AccordionItem,AccordionButton,AccordionPanel,AccordionIcon,
    Collapse, Icon,
    useDisclosure, useToast
} from '@chakra-ui/react'
import { 
    FaCheckCircle, FaCheckSquare, FaArrowRight, FaArrowCircleRight, FaRegTrashAlt
} from 'react-icons/fa';
import debounce from 'lodash.debounce';
import { SketchPicker } from 'react-color';
import { PageBuilderContext } from '../../../context/PageBuilderContext';
import { paddingConfigHandler, textConfigHandler, borderConfigHandler, roundedConfigHandler, marginConfigHandler
} from '../../../helper/settingsHelper';
import { fonts } from '../../../helper/fonts'
import { v4 as uuid } from 'uuid'

function ListSetting() {
    const [ state, dispatch ] = useContext(PageBuilderContext);
    const { isOpen, onOpen, onClose } = useDisclosure(); 
    const [ selectedField, setSelectedField ] = useState(null)
    const toast = useToast();
    const { isOpen: bgIsOpen, onOpen: bgOnOpen, onClose: bgOnClose } = useDisclosure(); 
    const { selected } = state;
    let { style, listStyle, listItems, listIcon } = state.section[selected.sectionIndex].columns[selected.columnIndex].components[selected.compIndex];


    const selectedFieldHandler = (index) => {
        if (selectedField !== index) {
            setSelectedField(index)
        } else {
            setSelectedField(null)
        }
    }

    const marginHandler = (value, side) => {
        const margin = marginConfigHandler(style.margin, side, value);
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                margin: margin, 
                type:"margin", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }
 
    const paddingHandler = (value, side) => {
        const payload = paddingConfigHandler(style.padding, side, value);
        console.log(payload);
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                ...payload, 
                type:"padding", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const textHandler = (value, property) => {
        const payload = textConfigHandler(style.text, property, value);
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                ...payload, 
                type:"text", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const handleFontFamily = (e) => {
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                fontFamily: e.target.value,
                type:"text.fontFamily", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const bgColorHandler = debounce((value) => {
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                bgColor: value, 
                type:"bgColor", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }, 300)


    const myWidth = (e) => {
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                width: e.target.value, 
                type:"width", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }


    const borderHandler = debounce((value, side) => {
        const border = borderConfigHandler(style.border, side, value);
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                border: border,
                type:"border", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    },300)

    const alignmentHandler = (value) => {
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                justifyContent: value, 
                type:"justifyContent", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const roundingHandler = (e) => {
        const rounded = roundedConfigHandler(e.target.value);
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                rounded: rounded,
                type:"rounded", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const addItemToList = () => {
        const item = {
            id: uuid(),
            text: 'list text',
        }

        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                type:"list.addItem", 
                item: item,
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const deleteItemFromList = (index) => {
        if (listItems.length > 1) {
            dispatch({
                type:'COMPONENT_CONFIG_UPDATE', 
                payload:{
                    type:"list.deleteItem", 
                    index,
                    sectionIndex: selected.sectionIndex,
                    columnIndex:selected.columnIndex,
                    compIndex: selected.compIndex
                }
            });
        }else {
            toast({
                title: "Error!",
                description: "Minimum a item is needed",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
    }

    const handleItemTextChange = debounce((value, index) => {
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                type:"list.changeItemText", 
                index,
                value,
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }, 300)

    const handleListStyle = (value) => {
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                type:"list.listStyleChange", 
                value,
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const changeListIconHandler = (value) => {
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                type:"list.iconChange", 
                value,
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    useEffect(() => {
        return () => {
            // textHandler.cancel();
            bgColorHandler.cancel();
            handleItemTextChange.cancel();
            borderHandler.cancel();
        }
    }, []);

    return (
        <>
        <Box>
            <Tabs size="sm" isFitted  variant="unstyled">
                <TabList borderWidth="1px">
                    <Tab
                        fontSize=".75rem" fontWeight="600"
                        _focus={{outline:"none"}} 
                        _selected={{ bg: "gray.200" }}
                    >
                        Design
                    </Tab>
                    <Tab 
                        fontSize=".75rem" fontWeight="600"
                        _focus={{outline:"none"}} 
                        _selected={{ bg: "gray.200" }}
                    >
                        List
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel p={0}>

                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Margin:</Text>
                        <Box display="flex" flexWrap="wrap" borderBottomWidth="1px" p="10px">
                            <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pr="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Top</FormLabel>
                                    <NumberInput 
                                        size="xs" 
                                        onChange={(value) => marginHandler(value, "top")} 
                                        value={style.margin.top}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pl="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Right</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        onChange={(value) => marginHandler(value, "right")} 
                                        value={style.margin.right}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" pr="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Left</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        onChange={(value) => marginHandler(value, "left")} 
                                        value={style.margin.left}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput> 
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" pl="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Bottom</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        onChange={(value) => marginHandler(value, "bottom")} 
                                        value={style.margin.bottom}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                        </Box>

                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Padding:</Text>
                        <Box display="flex" flexWrap="wrap" borderBottomWidth="1px" p="10px">
                            <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pr="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Top</FormLabel>
                                    <NumberInput 
                                        size="xs" 
                                        onChange={(value) => paddingHandler(value, "top")} 
                                        value={style.padding.top}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pl="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Right</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        onChange={(value) => paddingHandler(value, "right")} 
                                        value={style.padding.right}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" pr="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Left</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        onChange={(value) => paddingHandler(value, "left")} 
                                        value={style.padding.left}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput> 
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" pl="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Bottom</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        onChange={(value) => paddingHandler(value, "bottom")} 
                                        value={style.padding.bottom}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                        </Box>

                        <Box display="flex" borderBottomWidth="1px" p="10px">
                            <Text fontSize=".8rem" fontWeight="600" mr="10px">Alignment:</Text>
                            <RadioGroup flex="1 1" value={style.justifyContent} onChange={alignmentHandler}>
                                <Stack direction="row">
                                    <Radio size="sm" value="flex-start">Left</Radio>
                                    <Radio size="sm" value="center">Center</Radio>
                                    <Radio size="sm" value="flex-end">Right</Radio>
                                </Stack>
                            </RadioGroup>
                        </Box>

                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Width:</Text>
                        <Box display="flex" flexWrap="wrap" borderBottomWidth="1px" p="10px">
                            <Flex mb={2}>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Width</Text>
                                <InputGroup flex="1 1" size="xs">
                                    <Input defaultValue={style.width} onChange={myWidth} />
                                    <InputRightAddon id="width" cursor="pointer" children="(%, px, auto)" />
                                </InputGroup>
                            </Flex>
                        </Box>
                        
                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Text Style:</Text>
                        <Box borderBottomWidth="1px" p="10px">
                            <Flex mb={2}> 
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Font Family</Text>
                                <Select flex="1 1" size="xs" value={style.fontFamily} onChange={handleFontFamily}>
                                    {
                                        fonts.map(font => (
                                            <option key={font.value} value={font.value}>{font.label}</option>
                                        ))
                                    }
                                </Select>
                                
                            </Flex>
                            <Flex mb={2}> 
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Size (px)</Text>
                                <NumberInput 
                                        flex="1 1"
                                        size="xs" 
                                        max={50} min={5} 
                                        value={style.text.size}
                                        onChange={(value) => textHandler(value, "size")}
                                    >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput> 
                            </Flex>
                            <Flex mb={2}>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Color</Text>
                                <InputGroup flex="1 1" size="xs">
                                    <Input value={style.text.color} onChange={(e) => textHandler(e.target.value, "color")} />
                                    <InputRightAddon id="primaryColor" cursor="pointer" bg={style.text.color} onClick={onOpen} />
                                </InputGroup>
                            </Flex>
                            <Flex mb={2}>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Weight</Text>
                                <Select flex="1 1" size="xs" value={style.text.weight} onChange={(e) => textHandler(e.target.value, "weight")}>
                                    {
                                        Array.from(["200","300","400","500","600","700","800"]).map(item => (
                                            <option key={item} value={item}>{item}</option>
                                        ))
                                    }
                                </Select>
                            </Flex>
                            <Flex mb={2}>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Align</Text>
                                <Select flex="1 1" size="xs" value={style.text.align} onChange={(e) => textHandler(e.target.value, "align")}>
                                    <option value="flex-start">Left</option>
                                    <option value="center">Center</option>
                                    <option value="flex-end">Right</option>
                                </Select>
                            </Flex>
                            <Flex mb={2}>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Transform</Text>
                                <Select flex="1 1" size="xs" value={style.text.transform} onChange={(e) => textHandler(e.target.value, "transform")}>
                                    <option value="none">None</option>
                                    <option value="uppercase">Uppercase</option>
                                    <option value="lowercase">Lowercase</option>
                                    <option value="capitalize">Capitalize</option>
                                </Select>
                            </Flex>
                        </Box>

                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Border:</Text>
                        <Box>
                            <Box display="flex" flexWrap="wrap" borderBottomWidth="1px" p="10px">
                                <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pr="5px">
                                    <Stack direction="row">
                                        <FormLabel fontSize=".75rem" fontWeight="600">Top</FormLabel>
                                        <NumberInput 
                                            size="xs" 
                                            max={50} min={0} 
                                            onChange={(value) => borderHandler(value,'top')} 
                                            value={style.border.top}
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>  
                                    </Stack>
                                </FormControl>
                                <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pr="5px">
                                    <Stack direction="row">
                                        <FormLabel fontSize=".75rem" fontWeight="600">Right</FormLabel>
                                        <NumberInput 
                                            size="xs" 
                                            max={50} min={0} 
                                            onChange={(value) => borderHandler(value, 'right')}
                                            value={style.border.right}
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>  
                                    </Stack>
                                </FormControl>
                                <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pr="5px">
                                    <Stack direction="row">
                                        <FormLabel fontSize=".75rem" fontWeight="600">Left</FormLabel>
                                        <NumberInput 
                                            size="xs" 
                                            max={50} min={0} 
                                            onChange={(value) => borderHandler(value,'left')}
                                            value={style.border.left}
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>  
                                    </Stack>
                                </FormControl>
                                <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pr="5px">
                                    <Stack direction="row">
                                        <FormLabel fontSize=".75rem" fontWeight="600">Bottom</FormLabel>
                                        <NumberInput 
                                            size="xs" 
                                            max={50} min={0} 
                                            onChange={(value) => borderHandler(value,'bottom')}
                                            value={style.border.bottom}
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>  
                                    </Stack>
                                </FormControl>
                            </Box>
                            
                            <Box display="flex" flexWrap="wrap" borderBottomWidth="1px" p="10px">
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Rounded</Text>
                                <InputGroup flex="1 1" size="xs">
                                    <Input value={style.rounded.topLeft} type="number" onChange={roundingHandler}  />
                                    <InputRightAddon id="primaryColor" cursor="pointer" children="px" />
                                </InputGroup>
                            </Box>
                        </Box>
                        
                        {/* <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Background:</Text>
                        <Box borderBottomWidth="1px" p="10px">
                            <Flex mb={2}>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Color</Text>
                                <InputGroup flex="1 1" size="xs">
                                    <Input value={style.bgColor} onChange={(e) => bgColorHandler(e.target.value)} />
                                    <InputRightAddon id="primaryColor" cursor="pointer" bg={style.bgColor} onClick={bgOnOpen} />
                                </InputGroup>
                            </Flex>
                        </Box> */}
                    </TabPanel>
                    <TabPanel p={0}>
                        <Box borderBottomWidth="1px" px="10px" py="15px">
                            <Text fontSize=".8rem" fontWeight="600" mr="10px" mb="5px">List Style:</Text>
                            <RadioGroup flex="1 1" value={listStyle} onChange={handleListStyle}>
                                <Stack direction="row" spacing="20px">
                                    <Radio size="sm" value="unordered">
                                        <Text fontSize=".75rem" fontWeight="500">Unordered</Text>
                                    </Radio>
                                    <Radio size="sm" value="ordered">
                                        <Text fontSize=".75rem" fontWeight="500">Ordered</Text>
                                    </Radio>
                                    <Radio size="sm" value="iconstyle">
                                        <Text fontSize=".75rem" fontWeight="500">With Icon</Text>
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                        </Box>

                        <Box borderBottomWidth="1px" px="10px" py="15px">
                            <Text fontSize=".8rem" fontWeight="600" mr="10px" mb="5px">List Icon:</Text>
                            <RadioGroup flex="1 1" defaultValue={listIcon} value={listIcon} onChange={changeListIconHandler}>
                                <Stack direction="row" spacing="20px">
                                    <Radio size="sm" value="FaCheckCircle">
                                        <Text fontSize=".75rem" fontWeight="500">
                                            <FaCheckCircle />
                                        </Text>
                                    </Radio>
                                    <Radio size="sm" value="FaCheckSquare">
                                        <Text fontSize=".75rem" fontWeight="500">
                                            <FaCheckSquare />
                                        </Text>
                                    </Radio>
                                    <Radio size="sm" value="FaArrowAltCircleRight">
                                        <Text fontSize=".75rem" fontWeight="500">
                                            <FaArrowCircleRight />
                                        </Text>
                                    </Radio>
                                    <Radio size="sm" value="FaArrowRight">
                                        <Text fontSize=".75rem" fontWeight="500">
                                            <FaArrowRight />
                                        </Text>
                                    </Radio>
                                    <Radio size="sm" value="none">
                                        <Text fontSize=".75rem" fontWeight="500">None</Text>
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                        </Box>
                    
                        <Box borderBottomWidth="1px" px="10px" py="15px">
                            <Text fontSize=".8rem" fontWeight="600" mr="10px" mb="5px">Items</Text>
                            <Box mt={4}>
                                {
                                    listItems.map((list, index) => (
                                        <Box boxShadow="sm" rounded="4px" py="8px" px="10px" mb="10px" borderWidth="1px" borderColor="gray.200" key={list.id}>
                                            <Box display="flex" alignItems="center">
                                                <Text 
                                                    fontSize=".8rem" fontWeight="500" 
                                                    flex="1 1" cursor="pointer" 
                                                    onClick={() => selectedFieldHandler(index)}
                                                >
                                                    {list.text.substring(0, 10) + '...'} 
                                                </Text>
                                                {
                                                    index !== 0 ? 
                                                    <Icon as={FaRegTrashAlt} w={3} h={3} color="gray.400" ml="10px" cursor="pointer" 
                                                        _hover={{color: 'gray.500'}}  
                                                        onClick={() => deleteItemFromList(index)}
                                                    /> : null
                                                }
                                            </Box>
                                            <Collapse in={selectedField === index ? true : false} animateOpacity>
                                                <Textarea 
                                                    size="sm" mt={2}
                                                    defaultValue={list.text} 
                                                    onChange={(e) => handleItemTextChange(e.target.value, index)} size="sm" rows="3" 
                                                />
                                            </Collapse>
                                        </Box>
                                    ))
                                }
                                <Box display="flex" justifyContent="center">
                                    <Button onClick={addItemToList} size="xs" rounded="4px" px="30px">Add</Button>
                                </Box>
                            </Box>
                          
                        </Box>
                    
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
        <Modal 
            isCentered
            onClose={onClose}
            isOpen={isOpen}
            motionPreset="slideInBottom"
            size="xs">

            <ModalOverlay />
            <ModalContent>
                <SketchPicker width="100%" color={ style.text.color } onChange={(color) => textHandler(color.hex, "color")}  /> 
            </ModalContent>
        </Modal>
        <Modal 
            isCentered
            onClose={bgOnClose}
            isOpen={bgIsOpen}
            motionPreset="slideInBottom"
            size="xs">
            <ModalOverlay />
            <ModalContent>
                <SketchPicker width="100%" color={ style.bgColor } onChangeComplete={(color) => bgColorHandler(color.hex)}  /> 
            </ModalContent>
        </Modal>
        </>
    )
}

export default ListSetting;
