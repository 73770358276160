import { useState, useEffect } from 'react'
import { Text, Box, Image, Spinner } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { postRequest } from '../../services/base.service'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout, profileUpdate } from '../../redux'

const PaymentSuccess = () => {
	const navigate = useNavigate()
	const [isCompleting, setIsCompleting] = useState(true)
	const dispatch = useDispatch()
	const user = useSelector((state) => state.auth.user)

	useEffect(() => {
		const sessionToken = sessionStorage.getItem('sessionToken')
		if (sessionToken) {
			verifyStripePayment(sessionToken)
		} else {
			navigate('/')
		}
	}, [])

	const verifyStripePayment = (stripeSessionToken) => {
		const trainer = JSON.parse(localStorage.getItem('trainer'))

		postRequest(`verify/stripe-payment`, {
			session_token: stripeSessionToken,
		})
			.then((res) => {
				dispatch(
					profileUpdate({
						...user,
						subscription_id: res.result?.subscription_id,
					})
				)
				// setIsCompleting(false)
				sessionStorage.removeItem('sessionToken')
				dispatch(
					logout((msg, status) => {
						console.log(msg, status)
						if (trainer?.redirect_url) {
							window.location.href = trainer?.redirect_url
						} else {
							setIsCompleting(false)
						}
					})
				)
			})
			.catch((error) => {
				sessionStorage.removeItem('sessionToken')
				setIsCompleting(false)
				if (error.response.status === 404) {
					navigate('/payment-failed')
				} else {
					navigate('/')
				}
			})
	}

	return (
		<Box pt="100px" h="100vh" bg="primary.100" overflowX="hidden">
			<Box
				w="100%"
				maxW="550px"
				mx="auto"
				p="6"
				h="auto"
				shadow={'sm'}
				rounded="10px"
				textAlign="center"
				bg="white"
			>
				{!isCompleting ? (
					<>
						<Image
							src="/images/icon-payment-success.svg"
							h="160px"
							mx="auto"
							alt=""
						/>

						<Text fontSize="2xl">Your payment is successful</Text>
						<Text>Thank you for your payment</Text>
						<Text
							display="block"
							mt="4"
							as={Link}
							to="/"
							style={{ textDecoration: 'underline' }}
						>
							Back to Home
						</Text>
					</>
				) : (
					<>
						<Spinner
							thickness="4px"
							speed="0.65s"
							emptyColor="gray.200"
							color="primary.500"
							size="xl"
						/>

						<Text mt="3" color="secondary.600">
							Payment completing...
						</Text>
					</>
				)}
			</Box>
		</Box>
	)
}

export default PaymentSuccess
