import { v4 as uuid } from 'uuid'
import { styleHelper } from './styleHelper'
import { camelCase } from 'lodash'

const createColumn = (columns) => {
	const sectionColumns = columns.map((col, index) => {
		let id = Math.round(Math.random(1000) * 1000)
		return {
			id: id,
			style: {
				width: col,
				padding: styleHelper().padding(0, 0, 0, 0),
				rounded: styleHelper().rounded(0, 0, 0, 0),
				boxShadow: 'none',
				bgColor: 'transparent',
			},
			components: [],
		}
	})
	return sectionColumns
}

// Create section with column by layout
export const createSectionLayout = (layout) => {
	const section = {
		id: '',
		style: {
			padding: styleHelper().padding(15, 15, 15, 15),
			margin: styleHelper().margin(),
			bgColor: styleHelper().bgColor,
			bgImage: {
				path: '',
				altText: '',
			},
		},
		fullWidth: false,
		columnGap: 10,
	}
	switch (layout) {
		case 'single-column':
			section.columns = createColumn([100])
			break
		case 'two-column':
			section.columns = createColumn([50, 50])
			break
		case 'three-column':
			section.columns = createColumn([33.33, 33.33, 33.33])
			break
	
		default:
			break
	}
	return section
}

export const addComponent = (type) => {
	const component = {
		id: uuid(),
	}
	switch (type) {
		case 'heading':
			return {
				...component,
				type: type,
				data: 'Heading text',
				markup: 'h2',
				link: {
					hasLink: false,
					url: '',
					newTab: true,
				},
				style: {
					padding: styleHelper().padding(),
					margin: styleHelper().margin(),
					bgColor: styleHelper().bgColor,
					text: styleHelper().text('18', '500'),
				},
			}
		case 'paragraph':
			return {
				...component,
				type: type,
				data: 'Paragraph Text',
				link: {
					hasLink: false,
					url: '',
					newTab: true,
				},
				markup: 'p',
				style: {
					padding: styleHelper().padding(),
					margin: styleHelper().margin(),
					bgColor: styleHelper().bgColor,
					text: styleHelper().text('14', '400'),
				},
			}
		case 'image':
			return {
				...component,
				type: type,
				url: {
					path: '',
					altText: '',
				},
				link: {
					hasLink: false,
					url: '',
					newTab: true,
				},
				style: {
					padding: styleHelper().padding(),
					margin: styleHelper().margin(),
					height: 'auto',
					width: '100%',
					border: styleHelper().border(0, 'none', 'gray.300'),
					rounded: styleHelper().rounded(0, 0, 0, 0),
					justifyContent: 'flex-start',
				},
			}

		case 'video':
			return {
				...component,
				type: type,
				src: {
					path: '',
					altText: '',
					type: 'internal',
					link: '',
				},
				link: {
					hasLink: false,
					url: '',
					newTab: true,
				},
				style: {
					width: '100',
					height: 'auto',
					justifyContent: 'flex-start',
				},
			}

		case 'button':
			return {
				...component,
				type: type,
				link: {
					hasLink: false,
					url: '',
					newTab: true,
				},
				data: 'Button Text',
				style: {
					padding: styleHelper().padding(10, 20, 10, 20),
					margin: styleHelper().margin(),
					height: 'auto',
					width: 'auto',
					border: styleHelper().border(0),
					rounded: styleHelper().rounded(4),
					bgColor: '#63B3ED',
					text: styleHelper().text('15', '500'),
					justifyContent: 'flex-start',
				},
			}

		case 'slider':
			return {
				...component,
				type: type,
				items: [
					{
						id: uuid(),
						bgColor: '#cddafd',
						bgImage: {
							path: '',
							altText: '',
						},
						justifyContent: 'center',
						alignItems: 'center',
						innerAlignment: 'center',
						margin: styleHelper().margin(),
						link: {
							hasLink: false,
							url: '',
							newTab: true,
						},
						text: {
							data: 'Paragraph Text',
							padding: styleHelper().padding(0, 0, 0, 0),
							margin: styleHelper().margin,
							font: styleHelper().text('14', '400'),
							visibility: true,
						},
						header: {
							data: 'Header Text',
							padding: styleHelper().padding(0, 0, 0, 0),
							margin: styleHelper().margin,
							font: styleHelper().text('24', '700'),
							visibility: true,
						},
						button: {
							data: 'Button Text',
							padding: styleHelper().padding(4, 8, 4, 8),
							margin: styleHelper().margin,
							height: 'auto',
							width: 'auto',
							visibility: true,
							border: styleHelper().border(0, 0, 0, 0),
							rounded: styleHelper().rounded(2, 2, 2, 2),
							bgColor: 'gray.50',
							font: styleHelper().text('14', '500'),
							url: '',
						},
					},
				],
				itemToShow: 1,
				autoPlay: true,
				padding: 0,
				height: 300,
				imageAs: 'background',
			}

		case 'testimonial':
			return {
				...component,
				type: type,
				items: [
					{
						id: uuid(),
						bgColor: '#cddafd',
						image: {
							path: '',
							altText: '',
						},
						heading: {
							data: 'John Doe',
						},
						subHeading: {
							data: 'Creative Designer',
						},
						text: {
							data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla egestas malesuada libero, id ultrices massa auctor at.',
						},
						socialLinks: [
							{
								id: uuid(),
								name: 'Facebook',
								icon: 'facebook',
								link: '',
							},

							{
								id: uuid(),
								name: 'Twitter',
								icon: 'twitter',
								link: '',
							},

							{
								id: uuid(),
								name: 'LinkedIn',
								icon: 'linkedin',
								link: '',
							},
						],
					},
				],
				itemToShow: 1,
				autoPlay: false,
				padding: 0,
				height: 350,

				image: {
					margin: styleHelper().margin(0, 0, 5, 0),
					padding: styleHelper().padding(0, 0, 0, 0),
					border: styleHelper().border(0),
					rounded: styleHelper().rounded(50),
				},

				heading: {
					margin: styleHelper().margin(0, 0, 5, 0),
					padding: styleHelper().padding(0, 0, 0, 0),
					font: styleHelper().text('18', '700', '#4a4a4a', 'left', 'uppercase'),
					fontFamily: 'Poppins',
					visibility: true,
				},
				subHeading: {
					margin: styleHelper().margin(0, 0, 5, 0),
					padding: styleHelper().padding(0, 0, 0, 0),
					font: styleHelper().text('14', '500', '#9b9b9b'),
					fontFamily: 'Poppins',
					visibility: true,
				},
				text: {
					padding: styleHelper().padding(0, 0, 0, 0),
					margin: styleHelper().margin(0, 0, 10, 0),
					font: styleHelper().text('22', '400', '#6b7072'),
					fontFamily: 'Poppins',
					visibility: true,
				},
			}

		case 'mediaObject':
			return {
				...component,
				type: type,

				style: {
					width: '100',
					justifyContent: 'flex-start',
					margin: styleHelper().margin(0, 0, 15, 0),
				},

				items: [
					{
						id: uuid(),
						bgColor: '#cddafd',
						image: {
							path: '',
							altText: '',
						},
						heading: {
							data: 'Heading',
						},
						subHeading: {
							data: 'Sub Heading',
						},
						text: {
							data: 'Lorem ipsum dolor sit amet, consectetur.',
						},
					},
				],

				image: {
					margin: styleHelper().margin(0, 0, 5, 0),
					padding: styleHelper().padding(0, 0, 0, 0),
					border: styleHelper().border(0),
					rounded: styleHelper().rounded(2),
					width: '100',
					height: '100',
					alignment: 'left',
				},

				heading: {
					margin: styleHelper().margin(0, 0, 5, 0),
					padding: styleHelper().padding(0, 0, 0, 0),
					font: styleHelper().text('18', '700', '#4a4a4a', 'left', 'uppercase'),
					fontFamily: 'Poppins',
					visibility: true,
				},

				subHeading: {
					margin: styleHelper().margin(0, 0, 5, 0),
					padding: styleHelper().padding(0, 0, 0, 0),
					font: styleHelper().text('14', '500', '#9b9b9b'),
					fontFamily: 'Poppins',
					visibility: false,
				},

				text: {
					padding: styleHelper().padding(0, 0, 0, 0),
					margin: styleHelper().margin(0, 0, 10, 0),
					font: styleHelper().text('16', '400', '#6b7072'),
					fontFamily: 'Poppins',
					visibility: true,
				},

				button: {
					padding: styleHelper().padding(0, 0, 0, 0),
					margin: styleHelper().margin(0, 0, 10, 0),
					border: styleHelper().border(0),
					rounded: styleHelper().rounded(50),
					font: styleHelper().text('16', '400', '#6b7072'),
					fontFamily: 'Poppins',
					visibility: false,
				},
			}

		case 'list':
			return {
				...component,
				type: type,
				style: {
					padding: styleHelper().padding(10, 10, 10, 10),
					margin: styleHelper().margin(),
					bgColor: styleHelper().bgColor,
					width: '100%',
					text: styleHelper().text('16', '400'),
					border: styleHelper().border(0),
					rounded: styleHelper().rounded(0, 0, 0, 0),
				},
				listStyle: 'unordered',
				listIcon: 'none',
				listItems: [{ id: uuid(), text: 'List text' }],
			}

		case 'accordion':
			return {
				...component,
				type: type,
				style: {
					padding: styleHelper().padding(0, 0, 0, 0),
					margin: styleHelper().margin(),
					bgColor: styleHelper().bgColor,
					width: '100%',
					border: styleHelper().border(0),
					rounded: styleHelper().rounded(0, 0, 0, 0),
					justifyContent: 'flex-start',
				},

				headingStyle: styleHelper().text('16', '500'),
				textStyle: styleHelper().text('15', '400'),

				items: [{ id: uuid(), heading: 'Heading text', text: 'Content text' }],
			}

		case 'divider':
			return {
				...component,
				type: type,
				style: {
					margin: styleHelper().margin(),
					width: '100%',
					border: styleHelper().border(),
					rounded: styleHelper().rounded(2),
					justifyContent: 'flex-start',
				},
			}

		case 'form':
			return {
				...component,
				type: type,
				name: 'My Form',
				fields: [
					{
						id: uuid(),
						label: 'Name',
						type: 'text',
						name: 'name',
						value: '',
						placeholder: 'Name',
						required: false,
						width: 100,
					},
					{
						id: uuid(),
						label: 'Email',
						type: 'email',
						name: 'email',
						value: '',
						placeholder: 'Email',
						required: false,
						width: 100,
					},
				],
				button: {
					text: 'Submit',
				},
				actions: [],
				formStyle: {
					width: '100',
					alignment: 'flex-start',
					bgColor: '#FFFFFF',
					border: styleHelper().border(0),
					padding: styleHelper().padding(15, 15, 15, 15),
					margin: styleHelper().margin(),
					columnGap: '0',
					rowGap: '10',
				},
				inputStyle: {
					size: 'md',
					variant: 'outline',
					color: '#212121',
					fontSize: '14',
					fontFamily: 'Poppins',
					border: styleHelper().border(1, 'solid', 'gray.200'),
					rounded: styleHelper().rounded(4, 4, 4, 4),
					placeholderColor: 'gray.400',
				},

				labelStyle: {
					fontSize: 14,
					fontFamily: 'Poppins',
					color: '#212121',
					gap: 8,
					display: true,
				},
				buttonStyle: {
					fullWidth: false,
					size: 'md',
					bgColor: 'gray.300',
					padding: styleHelper().padding(10, 25, 10, 25),
					justifyContent: 'flex-start',
					fontSize: '16',
					fontFamily: 'Poppins',
					color: '#212121',
					border: styleHelper().border(0, 'solid', 'gray.400'),
					rounded: styleHelper().rounded(4, 4, 4, 4),
				},
			}

		case 'sitelogo':
			return {
				...component,
				type: type,
				style: {
					margin: styleHelper().margin(),
					padding: styleHelper().padding(),
					width: '200px',
					height: 'auto',
					objectFit: 'contain',
					justifyContent: 'flex-start',
					alignItems: 'flex-start',
				},
			}

		case 'menus':
			return {
				...component,
				type: type,

				title: 'Menu Title',
				dataSource: '',

				style: {
					margin: styleHelper().margin(),
					padding: styleHelper().padding(),
				},

				menuStyle: {
					gap: 0,
					padding: styleHelper().padding(4, 0, 4, 0),
					font: styleHelper().text('15', '400'),
					fontFamily: 'Poppins',
					orientation: 'vertical',
				},

				titleStyle: {
					margin: styleHelper().margin(),
					padding: styleHelper().padding(0, 0, 0, 0),
					font: styleHelper().text('15', '400'),
					fontFamily: 'Poppins',
				},
			}

		case 'pricing':
			return {
				...component,
				type: type,
				style: {},
			}

		case 'embedCode':
			return {
				...component,
				type: type,
				embedCode: '',
			}

		default:
			return
	}
}

// Padding handler
export const paddingMarginUpdater = ({ top, right, bottom, left }) => {
	const padding = {
		top: top,
		right: right,
		bottom: bottom,
		left: left,
	}
	return padding
}

// Add item to slider
export const addItemToSlider = (component) => {
	const item = {
		id: uuid(),
		bgColor: '#cddafd',
		bgImage: {
			path: '',
			altText: '',
		},
		justifyContent: 'center',
		alignItems: 'center',
		innerAlignment: 'center',
		margin: styleHelper().margin(),
		link: {
			hasLink: false,
			url: '',
			newTab: true,
		},
		text: {
			data: 'Paragraph Text',
			padding: styleHelper().padding(0, 0, 0, 0),
			margin: styleHelper().margin,
			font: styleHelper().text('14', '400'),
			visibility: true,
		},
		header: {
			data: 'Header Text',
			padding: styleHelper().padding(0, 0, 0, 0),
			margin: styleHelper().margin,
			font: styleHelper().text('24', '700'),
			visibility: true,
		},
		button: {
			data: 'Button Text',
			padding: styleHelper().padding(4, 8, 4, 8),
			margin: styleHelper().margin,
			height: 'auto',
			width: 'auto',
			visibility: true,
			border: styleHelper().border(0, 0, 0, 0),
			rounded: styleHelper().rounded(2, 2, 2, 2),
			bgColor: 'gray.50',
			font: styleHelper().text('14', '500'),
			url: '',
		},
	}
	component.items.push(item)
}

// Item delete for Slider, Testimonial and Media Object
export const deleteItemForSlider = (items, index) => {
	const newItems = [...items]
	newItems.splice(index, 1)
	return newItems
}

export const addItemToTestimonial = (component) => {
	const item = {
		id: uuid(),
		bgColor: '#cddafd',
		image: {
			path: '',
			altText: '',
		},
		heading: {
			data: 'John Doe',
		},
		subHeading: {
			data: 'Creative Designer',
		},
		text: {
			data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla egestas malesuada libero, id ultrices massa auctor at.',
		},
		socialLinks: [
			{
				id: uuid(),
				name: 'Facebook',
				icon: 'facebook',
				link: '',
			},

			{
				id: uuid(),
				name: 'Twitter',
				icon: 'twitter',
				link: '',
			},

			{
				id: uuid(),
				name: 'LinkedIn',
				icon: 'linkedin',
				link: '',
			},
		],
	}

	component.items.push(item)
}

export const addItemToMediaObject = (component) => {
	const item = {
		id: uuid(),
		bgColor: '#cddafd',
		image: {
			path: '',
			altText: '',
		},
		heading: {
			data: 'Heading',
		},
		subHeading: {
			data: 'Sub Heading',
		},
		text: {
			data: 'Lorem ipsum dolor sit amet, consectetur.',
		},
	}

	component.items.push(item)
}

export const addFieldToForm = (component) => {
	const fields = [...component.fields]
	fields.push({
		id: uuid(),
		label: 'Field Name',
		type: 'text',
		name: 'fieldName',
		value: '',
		placeholder: 'Field Name',
		required: false,
		width: 100,
	})
	component.fields = fields
}

export const removeFromField = (component, index) => {
	const fields = [...component.fields]
	fields.splice(index, 1)
	component.fields = fields
}

export const copyFieldToForm = (component, index) => {
	const fields = [...component.fields]
	let copiedField = fields[index]
	copiedField = { ...copiedField, id: uuid() }
	fields.push(copiedField)
	component.fields = fields
}

export const updateFromField = (component, payload) => {
	const inputType = ['checkbox', 'radio', 'select']
	const fields = [...component.fields]
	let editableField = fields[payload.index]

	if (payload.property === 'type' && inputType.includes(payload.value)) {
		if (!editableField.hasOwnProperty('options')) {
			editableField.options = []
		}
	}
	if (payload.property === 'type' && !inputType.includes(payload.value)) {
		if (editableField.hasOwnProperty('options')) delete editableField.options
	}
	editableField[payload.property] = payload.value
	if (payload.property === 'label') {
		editableField.name = camelCase(payload.value)
	}

	component.fields = fields
}

export const reorderFromField = (component, sourceIndex, destinationIndex) => {
	const fields = [...component.fields]
	const [removed] = fields.splice(sourceIndex, 1)
	fields.splice(destinationIndex, 0, removed)
	component.fields = fields
}

// Parse data samples
export const parseData = (state) => {
	const { section } = state
	const files = []
	console.time('parse')
	section.forEach((section) => {
		if (section.style?.bgImage?.path) {
			files.push(section.style?.bgImage?.path.split('image/')[1])
		}
		section.columns.forEach((col) => {
			col.components.forEach((component) => {
				switch (component.type) {
					case 'image':
						if (component.url.path) {
							files.push(component.url.path.split('image/')[1])
						}
						break

					case 'slider':
						component.items.forEach((item) => {
							if (item.bgImage.path) {
								files.push(item.bgImage.path.split('image/')[1])
							}
						})
						break
					case 'testimonial':
					case 'mediaObject':
						component.items.forEach((item) => {
							if (item.image.path) {
								files.push(item.image.path.split('image/')[1])
							}
						})
						break
					default:
						break
				}
			})
		})
	})
	console.timeEnd('parse')
	console.log(files)
}
