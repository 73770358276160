import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import ReactPaginate from 'react-paginate'
import './paginate.css'

const Paginate = ({ onPageChange, pageCount }) => {
	return (
		<ReactPaginate
			breakLabel="..."
			className="react-paginate"
			nextLabel={<FiChevronRight />}
			onPageChange={(e) => onPageChange(e.selected + 1)}
			pageCount={pageCount}
			previousLabel={<FiChevronLeft />}
			renderOnZeroPageCount={null}
		/>
	)
}

export default Paginate
