import React, { useContext, useRef } from 'react'
import {
	Box,
	Button,
	Stack,
	AlertDialog,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	AlertDialogCloseButton,
	useDisclosure,
} from '@chakra-ui/react'
import { FiEdit, FiTrash } from 'react-icons/fi'
import { PageBuilderContext } from '../context/PageBuilderContext'

function ComponentConfigButton({ secIndex, colIndex, compIndex, name }) {
	const [state, dispatch] = useContext(PageBuilderContext)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()

	const selectComponent = () => {
		dispatch({
			type: 'SELECT_COMPONENT',
			payload: {
				sectionIndex: secIndex,
				columnIndex: colIndex,
				compIndex: compIndex,
				name: name,
			},
		})
	}

	const deleteComponent = () => {
		dispatch({
			type: 'DELETE_COMPONENT',
			payload: {
				sectionIndex: secIndex,
				columnIndex: colIndex,
				compIndex: compIndex,
			},
		})
		onClose()
	}

	return (
		<>
			<Box className="page-component-setting" roundedTop="2px">
				<Stack direction="row" spacing="4px">
					<Button
						rounded={4}
						size=".8rem"
						bg="transparent"
						_focus={{ outline: 'none' }}
						_active={{ outline: 'none', background: '#CBD5E0' }}
						onClick={selectComponent}
					>
						<FiEdit size=".8rem" />
					</Button>
					<Button
						rounded={4}
						size=".8rem"
						bg="transparent"
						_focus={{ outline: 'none' }}
						_active={{ outline: 'none', background: '#CBD5E0' }}
						onClick={onOpen}
					>
						<FiTrash size=".8rem" />
					</Button>
				</Stack>
			</Box>
			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={onClose}
				isOpen={isOpen}
				isCentered
				size="md"
			>
				<AlertDialogOverlay />
				<AlertDialogContent>
					<AlertDialogHeader
						color="gray.600"
						fontSize="1.1rem"
						fontWeight="700"
						borderBottomWidth="1px"
					>
						Are you sure?
					</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogFooter>
						<Button
							size="xs"
							px="15px"
							rounded={2}
							ref={cancelRef}
							onClick={onClose}
						>
							No
						</Button>
						<Button
							size="xs"
							px="15px"
							rounded={2}
							colorScheme="red"
							ml={3}
							onClick={deleteComponent}
						>
							Yes
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	)
}

export default ComponentConfigButton
