import React, { useState, useEffect, useContext, useRef } from 'react'
import {
	Box,
	Stack,
	Text,
	Flex,
	Button,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	FormControl,
	FormLabel,
	Checkbox,
	RadioGroup,
	Radio,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Input,
	Select,
	InputGroup,
	InputRightAddon,
	Textarea,
	SimpleGrid,
	Center,
	Modal,
	ModalOverlay,
	ModalContent,
	useDisclosure,
	Image,
} from '@chakra-ui/react'
import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import { PageBuilderContext } from '../../../context/PageBuilderContext'
import debounce from 'lodash.debounce'
import { SketchPicker } from 'react-color'
import { fonts } from '../../../helper/fonts'

function SliderSetting() {
	const [state, dispatch] = useContext(PageBuilderContext)
	const [selectedIndex, setSelectedIndex] = useState(0)
	const [color, setColor] = useState('#FFFFFF')
	const itemType = useRef(null)
	const itemProperty = useRef(null)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const {
		isOpen: isOpenMedia,
		onOpen: onOpenMedia,
		onClose: onCloseMedia,
	} = useDisclosure()
	const { selected } = state
	const { items, itemToShow, autoPlay, padding, height, imageAs } =
		state.section[selected.sectionIndex].columns[selected.columnIndex]
			.components[selected.compIndex]

	const addItem = () => {
		dispatch({
			type: 'COMPONENT_CONFIG_UPDATE',
			payload: {
				type: 'add_slider_item',
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const deleteItem = () => {
		if (items.length > 1) {
			dispatch({
				type: 'COMPONENT_CONFIG_UPDATE',
				payload: {
					type: 'delete_slider_item',
					itemIndex: selectedIndex,
					sectionIndex: selected.sectionIndex,
					columnIndex: selected.columnIndex,
					compIndex: selected.compIndex,
				},
			})
			selectSlide(0)
		}
	}

	const selectSlide = (index) => {
		setSelectedIndex(index)
	}

	const textHandler = debounce((e, type) => {
		items[selectedIndex][type].data = e.target.value // tabName = 'text', 'header', 'button'
		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				items: items,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 300)

	const paddingHandler = debounce((value, type, side) => {
		items[selectedIndex][type].padding[side] = value
		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				items: items,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 500)

	const fontHandler = debounce((value, type, property) => {
		items[selectedIndex][type].font[property] = value
		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				items: items,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 300)

	const contentVisibilityHandler = debounce((value, type) => {
		items[selectedIndex][type].visibility = value
		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				items: items,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 200)

	const buttonWidthHandler = debounce((e) => {
		items[selectedIndex].button.width = e.target.value
		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				items: items,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 400)

	const buttonHeightHandler = debounce((e) => {
		items[selectedIndex].button.height = e.target.value
		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				items: items,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 400)

	const imageHandler = (image) => {
		items[selectedIndex].bgImage = { path: '', altText: '' }
		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				items: items,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const buttonBorderHandler = debounce((value, property) => {
		items[selectedIndex].button.border[property] = value
		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				items: items,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 400)

	const buttonRadiusHandler = debounce((value) => {
		items[selectedIndex].button.rounded = {
			topLeft: value,
			topRight: value,
			bottomLeft: value,
			bottomRight: value,
		}
		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				items: items,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 400)

	const sliderLinkHandler = debounce((value) => {
		items[selectedIndex].link.url = value
		items[selectedIndex].link.hasLink = value ? true : false

		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				items: items,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 400)

	const openColorPicker = (type, property) => {
		itemType.current = type
		itemProperty.current = property
		onOpen()
	}

	const bgColorHandler = (value, element) => {
		if (element) {
			items[selectedIndex][element].bgColor = value
		} else {
			items[selectedIndex].bgColor = value
		}
		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				items: items,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const horizonatlAlignmentHandler = (e) => {
		items[selectedIndex].justifyContent = e.target.value
		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				items: items,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const verticalAlignmentHandler = (e) => {
		items[selectedIndex].alignItems = e.target.value
		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				items: items,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const contentAlignmentHandler = (e) => {
		items[selectedIndex].innerAlignment = e.target.value
		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				items: items,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const contentMarginHandler = (value, side) => {
		items[selectedIndex].margin[side] = value
		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				items: items,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const globalSetting = (value, property) => {
		if (property !== 'autoPlay') {
			value = parseInt(value)
		}
		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				property: property,
				value: value,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const handleImageViewType = (value) => {
		dispatch({
			type: 'BASE_SLIDER_UPDATE',
			payload: {
				property: 'imageAs',
				value: value,
				itemIndex: selectedIndex,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	useEffect(() => {
		if (itemType.current && itemProperty.current) {
			switch (itemProperty.current) {
				case 'font':
					fontHandler(color, itemType.current, 'color')
					break

				case 'border':
					buttonBorderHandler(color, 'color')
					break

				case 'bgColor':
					bgColorHandler(color, itemType.current)
					break

				case 'itemBgColor':
					bgColorHandler(color, null)
					break

				default:
					break
			}
		}
	}, [color])

	useEffect(() => {
		return () => {
			textHandler.cancel()
			paddingHandler.cancel()
			fontHandler.cancel()
			buttonWidthHandler.cancel()
			buttonHeightHandler.cancel()
			buttonBorderHandler.cancel()
			sliderLinkHandler.cancel()
			contentVisibilityHandler.cancel()
		}
	}, [])

	return (
		<>
			<Box>
				<Tabs size="sm" isFitted variant="unstyled">
					<TabList borderWidth="1px">
						<Tab
							fontSize=".65rem"
							fontWeight="600"
							_focus={{ outline: 'none' }}
							_selected={{ bg: 'gray.200' }}
						>
							Settings
						</Tab>
						<Tab
							fontSize=".65rem"
							fontWeight="600"
							_focus={{ outline: 'none' }}
							_selected={{ bg: 'gray.200' }}
						>
							Item
						</Tab>
						<Tab
							fontSize=".65rem"
							fontWeight="600"
							_focus={{ outline: 'none' }}
							_selected={{ bg: 'gray.200' }}
						>
							Content
						</Tab>
					</TabList>
					<TabPanels>
						{/* Slider Common settings */}
						<TabPanel p={0}>
							<Box p="10px" borderBottomWidth="1px" display="flex">
								<Text fontSize=".70rem" fontWeight="600" flex="1 1">
									Items To Show:
								</Text>
								<NumberInput
									size="xs"
									flex="1 1"
									min={1}
									value={itemToShow}
									onChange={(value) => globalSetting(value, 'itemToShow')}
								>
									<NumberInputField />
									<NumberInputStepper>
										<NumberIncrementStepper />
										<NumberDecrementStepper />
									</NumberInputStepper>
								</NumberInput>
							</Box>
							<Box p="10px" borderBottomWidth="1px" display="flex">
								<Text fontSize=".70rem" fontWeight="600" flex="1 1">
									Padding
								</Text>
								<NumberInput
									size="xs"
									flex="1 1"
									min={0}
									value={padding}
									onChange={(value) => globalSetting(value, 'padding')}
								>
									<NumberInputField />
									<NumberInputStepper>
										<NumberIncrementStepper />
										<NumberDecrementStepper />
									</NumberInputStepper>
								</NumberInput>
							</Box>
							<Box p="10px" borderBottomWidth="1px" display="flex">
								<Text fontSize=".70rem" fontWeight="600" flex="1 1">
									Height
								</Text>
								<NumberInput
									size="xs"
									flex="1 1"
									max={600}
									min={20}
									value={height}
									onChange={(value) => globalSetting(value, 'height')}
								>
									<NumberInputField />
									<NumberInputStepper>
										<NumberIncrementStepper />
										<NumberDecrementStepper />
									</NumberInputStepper>
								</NumberInput>
							</Box>
							<Box p="10px" borderBottomWidth="1px" display="flex">
								<Text fontSize=".70rem" fontWeight="600" flex="1 1">
									Enable Autoplay
								</Text>
								<Checkbox
									size="sm"
									value={autoPlay}
									onChange={(e) => globalSetting(e.target.checked, 'autoPlay')}
									isChecked={autoPlay}
								>
									<Text fontSize=".70rem" fontWeight="500">
										Enable
									</Text>
								</Checkbox>
							</Box>
							<Box p="10px" borderBottomWidth="1px">
								<Text fontSize=".75rem" fontWeight="600" mb={2}>
									Use image as:{' '}
								</Text>
								<RadioGroup
									defaultValue="background"
									value={imageAs}
									onChange={handleImageViewType}
								>
									<Stack direction="row">
										<Radio value="background" size="sm">
											<Text fontSize=".75rem" fontWeight="500">
												Background
											</Text>
										</Radio>
										<Radio value="image-tag" size="sm">
											<Text fontSize=".75rem" fontWeight="500">
												Image Tag
											</Text>
										</Radio>
									</Stack>
								</RadioGroup>
							</Box>
						</TabPanel>

						{/* Item settings */}
						<TabPanel p={0}>
							<Text
								fontSize=".70rem"
								fontWeight="600"
								p="10px"
								borderBottomWidth="1px"
							>
								Items:
							</Text>
							<SimpleGrid columns={[4]} spacing="5px" px={2} py={3}>
								{items.map((item, index) => (
									<Center
										key={item.id}
										fontSize=".65rem"
										fontWeight="500"
										h="50px"
										bg={index === selectedIndex ? 'gray.300' : 'white'}
										_hover={{
											backgroundColor: 'gray.300',
										}}
										borderWidth={1}
										rounded={4}
										cursor="pointer"
										onClick={() => selectSlide(index)}
									>
										Slide{index + 1}
									</Center>
								))}

								<Center
									h="50px"
									bg="gray.200"
									rounded={4}
									cursor="pointer"
									onClick={addItem}
								>
									<AddIcon />
								</Center>
							</SimpleGrid>
							<Box p="10px" borderBottomWidth="1px">
								<Button
									fontSize="14px"
									fontWeight="500"
									textTransform="uppercase"
									isFullWidth
									onClick={deleteItem}
								>
									Delete Selected Slide
								</Button>
							</Box>
							{selectedIndex !== null ? (
								<Box>
									<Text
										fontSize=".70rem"
										fontWeight="600"
										p="10px"
										borderBottomWidth="1px"
									>
										Slide Design
									</Text>
									<Box p={2}>
										<Flex mb={2}>
											<Text
												width="40%"
												fontSize=".70rem"
												fontWeight="500"
												mr={2}
											>
												Background Color
											</Text>
											<InputGroup flex="1 1" size="xs">
												<Input
													value={items[selectedIndex].bgColor}
													onChange={(e) => bgColorHandler(e.target.value)}
												/>
												<InputRightAddon
													id="primaryColor"
													cursor="pointer"
													bg={items[selectedIndex].bgColor}
													onClick={(e) =>
														openColorPicker('item', 'itemBgColor')
													}
												/>
											</InputGroup>
										</Flex>
										<Text
											fontSize=".70rem"
											fontWeight="500"
											mb={2}
											borderBottomWidth="1px"
										>
											Image:
										</Text>
										<Box borderBottomWidth="1px">
											{items[selectedIndex].bgImage && (
												<Box position="relative">
													<Image
														src={
															items[selectedIndex].bgImage?.path
																? items[selectedIndex].bgImage?.path
																: items[selectedIndex].bgImage
														}
													/>
													<Button
														size="xs"
														rounded={0}
														position="absolute"
														top={0}
														right={0}
														onClick={() => imageHandler('')}
													>
														<DeleteIcon />
													</Button>
												</Box>
											)}
											<Box lineHeight="20px" border="1px dashed #BDBDBD">
												<Button
													size="sm"
													onClick={() => onOpenMedia()}
													isFullWidth
													rounded={0}
												>
													{items[selectedIndex].bgImage
														? 'Change'
														: 'Set Image'}
												</Button>
											</Box>
										</Box>

										<Text
											fontSize=".70rem"
											fontWeight="600"
											pt={4}
											pb={1}
											borderBottomWidth="1px"
										>
											Alignment:
										</Text>
										<Flex py={1}>
											<Text
												width="40%"
												fontSize=".65rem"
												fontWeight="500"
												mr={2}
											>
												Horizontal
											</Text>
											<Select
												flex="1 1"
												size="xs"
												value={items[selectedIndex].justifyContent}
												onChange={horizonatlAlignmentHandler}
											>
												{Array.from([
													{ name: 'Left', value: 'flex-start' },
													{ name: 'Center', value: 'center' },
													{ name: 'Right', value: 'flex-end' },
												]).map((item) => (
													<option key={item.value} value={item.value}>
														{item.name}
													</option>
												))}
											</Select>
										</Flex>
										<Flex py={1}>
											<Text
												width="40%"
												fontSize=".65rem"
												fontWeight="500"
												mr={2}
											>
												Vertical
											</Text>
											<Select
												flex="1 1"
												size="xs"
												value={items[selectedIndex].alignItems}
												onChange={verticalAlignmentHandler}
											>
												{Array.from([
													{ name: 'Top', value: 'flex-start' },
													{ name: 'Center', value: 'center' },
													{ name: 'Bottom', value: 'flex-end' },
												]).map((item) => (
													<option key={item.value} value={item.value}>
														{item.name}
													</option>
												))}
											</Select>
										</Flex>

										<Flex py={1} borderBottomWidth="1px">
											<Text
												width="40%"
												fontSize=".65rem"
												fontWeight="500"
												mr={2}
											>
												Content Alignment:
											</Text>
											<Select
												flex="1 1"
												size="xs"
												value={items[selectedIndex].innerAlignment}
												onChange={contentAlignmentHandler}
											>
												{Array.from([
													{ name: 'Left', value: 'left' },
													{ name: 'Center', value: 'center' },
													{ name: 'Right', value: 'Right' },
												]).map((item) => (
													<option key={item.value} value={item.value}>
														{item.name}
													</option>
												))}
											</Select>
										</Flex>

										<Text
											fontSize=".70rem"
											fontWeight="600"
											mt={3}
											pb={1}
											borderBottomWidth="1px"
										>
											Margin:
										</Text>
										<Box
											display="flex"
											flexWrap="wrap"
											py={2}
											borderBottomWidth="1px"
										>
											<FormControl
												flex="1 1"
												flexBasis="auto"
												width="50%"
												mb={4}
												pr="5px"
											>
												<Stack direction="row">
													<FormLabel fontSize=".65rem" fontWeight="500">
														Top
													</FormLabel>
													<NumberInput
														size="xs"
														value={items[selectedIndex].margin.top}
														onChange={(value) =>
															contentMarginHandler(value, 'top')
														}
													>
														<NumberInputField />
														<NumberInputStepper>
															<NumberIncrementStepper />
															<NumberDecrementStepper />
														</NumberInputStepper>
													</NumberInput>
												</Stack>
											</FormControl>
											<FormControl
												flex="1 1"
												flexBasis="auto"
												width="50%"
												mb={4}
												pl="5px"
											>
												<Stack direction="row">
													<FormLabel fontSize=".65rem" fontWeight="500">
														Right
													</FormLabel>
													<NumberInput
														size="xs"
														value={items[selectedIndex].margin.right}
														onChange={(value) =>
															contentMarginHandler(value, 'right')
														}
													>
														<NumberInputField />
														<NumberInputStepper>
															<NumberIncrementStepper />
															<NumberDecrementStepper />
														</NumberInputStepper>
													</NumberInput>
												</Stack>
											</FormControl>
											<FormControl
												flex="1 1"
												flexBasis="auto"
												width="50%"
												pr="5px"
											>
												<Stack direction="row">
													<FormLabel fontSize=".65rem" fontWeight="500">
														Left
													</FormLabel>
													<NumberInput
														size="xs"
														value={items[selectedIndex].margin.left}
														onChange={(value) =>
															contentMarginHandler(value, 'left')
														}
													>
														<NumberInputField />
														<NumberInputStepper>
															<NumberIncrementStepper />
															<NumberDecrementStepper />
														</NumberInputStepper>
													</NumberInput>
												</Stack>
											</FormControl>
											<FormControl
												flex="1 1"
												flexBasis="auto"
												width="50%"
												pl="5px"
											>
												<Stack direction="row">
													<FormLabel fontSize=".65rem" fontWeight="500">
														Bottom
													</FormLabel>
													<NumberInput
														size="xs"
														value={items[selectedIndex].margin.bottom}
														onChange={(value) =>
															contentMarginHandler(value, 'bottom')
														}
													>
														<NumberInputField />
														<NumberInputStepper>
															<NumberIncrementStepper />
															<NumberDecrementStepper />
														</NumberInputStepper>
													</NumberInput>
												</Stack>
											</FormControl>
										</Box>
										<Text
											fontSize=".70rem"
											fontWeight="600"
											mt={4}
											mb={2}
											borderBottomWidth="1px"
										>
											Link:
										</Text>
										<Box borderBottomWidth="1px" py={2}>
											<FormControl key={items[selectedIndex].link.url}>
												<Textarea
													placeholder="Link url here"
													size="sm"
													fontSize=".65rem"
													resize="vertical"
													defaultValue={items[selectedIndex].link.url}
													onChange={(e) => sliderLinkHandler(e.target.value)}
													autoFocus={
														items[selectedIndex].link.url ? true : false
													}
												/>
											</FormControl>
										</Box>
									</Box>
								</Box>
							) : null}
						</TabPanel>

						{/* Content */}
						<TabPanel p={0}>
							<Text
								fontSize=".70rem"
								fontWeight="600"
								p="10px"
								borderBottomWidth="1px"
							>
								Items:
							</Text>
							<SimpleGrid columns={[4]} spacing="5px" px={2} py={3}>
								{items.map((item, index) => (
									<Center
										key={item.id}
										fontSize=".65rem"
										fontWeight="500"
										h="50px"
										bg={index === selectedIndex ? 'gray.300' : 'white'}
										_hover={{
											backgroundColor: 'gray.300',
										}}
										borderWidth={1}
										rounded={4}
										cursor="pointer"
										onClick={() => selectSlide(index)}
									>
										Slide{index + 1}
									</Center>
								))}

								<Center
									h="50px"
									bg="gray.200"
									rounded={4}
									cursor="pointer"
									onClick={addItem}
								>
									<AddIcon />
								</Center>
							</SimpleGrid>
							{selectedIndex !== null ? (
								<>
									{/* Sub tab */}
									{items.map((item, index) => {
										if (selectedIndex === index) {
											return (
												<Tabs
													key={item.id}
													size="sm"
													isFitted
													variant="unstyled"
												>
													<TabList borderWidth="1px">
														<Tab
															fontSize=".65rem"
															fontWeight="500"
															_focus={{ outline: 'none' }}
															_selected={{ bg: 'gray.200' }}
														>
															Header
														</Tab>
														<Tab
															fontSize=".65rem"
															fontWeight="500"
															_focus={{ outline: 'none' }}
															_selected={{ bg: 'gray.200' }}
														>
															Text
														</Tab>
														<Tab
															fontSize=".65rem"
															fontWeight="500"
															_focus={{ outline: 'none' }}
															_selected={{ bg: 'gray.200' }}
														>
															Button
														</Tab>
													</TabList>
													<TabPanels>
														{/* Header */}
														<TabPanel p={1}>
															<Box
																p="10px"
																borderBottomWidth="1px"
																display="flex"
															>
																<Checkbox
																	colorScheme="cyan"
																	size="sm"
																	value={items[selectedIndex].header.visibility}
																	isChecked={
																		items[selectedIndex].header.visibility
																	}
																	onChange={(e) =>
																		contentVisibilityHandler(
																			e.target.checked,
																			'header'
																		)
																	}
																>
																	<Text fontSize=".70rem" fontWeight="600">
																		Visibility
																	</Text>
																</Checkbox>
															</Box>
															<Box>
																<Text
																	fontSize=".70rem"
																	fontWeight="600"
																	py="10px"
																	px="10px"
																	borderBottomWidth="1px"
																>
																	Header Text:
																</Text>
																<Box borderBottomWidth="1px" p="10px">
																	<Textarea
																		name={`header_${selectedIndex}`}
																		size="xs"
																		fontWeight="500"
																		resize="vertical"
																		defaultValue={
																			items[selectedIndex].header.data
																		}
																		onChange={(e) => textHandler(e, 'header')}
																	/>
																</Box>

																<Text
																	fontSize=".70rem"
																	fontWeight="600"
																	p="10px"
																	borderBottomWidth="1px"
																>
																	Padding:
																</Text>
																<Box
																	display="flex"
																	flexWrap="wrap"
																	borderBottomWidth="1px"
																	p="10px"
																>
																	<FormControl
																		flex="1 1"
																		flexBasis="auto"
																		width="50%"
																		mb={4}
																		pr="5px"
																	>
																		<Stack direction="row">
																			<FormLabel
																				fontSize=".65rem"
																				fontWeight="600"
																			>
																				Top
																			</FormLabel>
																			<NumberInput
																				size="xs"
																				max={100}
																				min={0}
																				defaultValue={
																					items[selectedIndex].header.padding
																						.top
																				}
																				onChange={(value) =>
																					paddingHandler(value, 'header', 'top')
																				}
																			>
																				<NumberInputField />
																				<NumberInputStepper>
																					<NumberIncrementStepper />
																					<NumberDecrementStepper />
																				</NumberInputStepper>
																			</NumberInput>
																		</Stack>
																	</FormControl>
																	<FormControl
																		flex="1 1"
																		flexBasis="auto"
																		width="50%"
																		mb={4}
																		pl="5px"
																	>
																		<Stack direction="row">
																			<FormLabel
																				fontSize=".65rem"
																				fontWeight="600"
																			>
																				Right
																			</FormLabel>
																			<NumberInput
																				size="xs"
																				max={100}
																				min={0}
																				defaultValue={
																					items[selectedIndex].header.padding
																						.right
																				}
																				onChange={(value) =>
																					paddingHandler(
																						value,
																						'header',
																						'right'
																					)
																				}
																			>
																				<NumberInputField />
																				<NumberInputStepper>
																					<NumberIncrementStepper />
																					<NumberDecrementStepper />
																				</NumberInputStepper>
																			</NumberInput>
																		</Stack>
																	</FormControl>
																	<FormControl
																		flex="1 1"
																		flexBasis="auto"
																		width="50%"
																		pr="5px"
																	>
																		<Stack direction="row">
																			<FormLabel
																				fontSize=".65rem"
																				fontWeight="600"
																			>
																				Left
																			</FormLabel>
																			<NumberInput
																				size="xs"
																				max={100}
																				min={0}
																				defaultValue={
																					items[selectedIndex].header.padding
																						.left
																				}
																				onChange={(value) =>
																					paddingHandler(
																						value,
																						'header',
																						'left'
																					)
																				}
																			>
																				<NumberInputField />
																				<NumberInputStepper>
																					<NumberIncrementStepper />
																					<NumberDecrementStepper />
																				</NumberInputStepper>
																			</NumberInput>
																		</Stack>
																	</FormControl>
																	<FormControl
																		flex="1 1"
																		flexBasis="auto"
																		width="50%"
																		pl="5px"
																	>
																		<Stack direction="row">
																			<FormLabel
																				fontSize=".65rem"
																				fontWeight="600"
																			>
																				Bottom
																			</FormLabel>
																			<NumberInput
																				size="xs"
																				max={100}
																				min={0}
																				defaultValue={
																					items[selectedIndex].header.padding
																						.bottom
																				}
																				onChange={(value) =>
																					paddingHandler(
																						value,
																						'header',
																						'bottom'
																					)
																				}
																			>
																				<NumberInputField />
																				<NumberInputStepper>
																					<NumberIncrementStepper />
																					<NumberDecrementStepper />
																				</NumberInputStepper>
																			</NumberInput>
																		</Stack>
																	</FormControl>
																</Box>

																<Text
																	fontSize=".70rem"
																	fontWeight="600"
																	p="10px"
																	borderBottomWidth="1px"
																>
																	Font style:
																</Text>
																<Box borderBottomWidth="1px" p="10px">
																	<Flex mb={2}>
																		<Text
																			width="40%"
																			fontSize=".75rem"
																			fontWeight="500"
																			mr={2}
																		>
																			Font Family
																		</Text>
																		<Select
																			flex="1 1"
																			size="xs"
																			value={
																				items[selectedIndex].header.font
																					.fontFamily
																			}
																			onChange={(e) =>
																				fontHandler(
																					e.target.value,
																					'header',
																					'fontFamily'
																				)
																			}
																		>
																			{fonts.map((font) => (
																				<option
																					key={font.value}
																					value={font.value}
																				>
																					{font.label}
																				</option>
																			))}
																		</Select>
																	</Flex>
																	<Flex mb={2}>
																		<Text
																			width="40%"
																			fontSize=".65rem"
																			fontWeight="500"
																			mr={2}
																		>
																			Size (px)
																		</Text>
																		<NumberInput
																			flex="1 1"
																			size="xs"
																			max={100}
																			min={5}
																			defaultValue={
																				items[selectedIndex].header.font.size
																			}
																			onChange={(value) =>
																				fontHandler(value, 'header', 'size')
																			}
																		>
																			<NumberInputField />
																			<NumberInputStepper>
																				<NumberIncrementStepper />
																				<NumberDecrementStepper />
																			</NumberInputStepper>
																		</NumberInput>
																	</Flex>
																	<Flex mb={2}>
																		<Text
																			width="40%"
																			fontSize=".65rem"
																			fontWeight="500"
																			mr={2}
																		>
																			Color
																		</Text>
																		<InputGroup flex="1 1" size="xs">
																			<Input
																				defaultValue={
																					items[selectedIndex].header.font.color
																				}
																				onChange={(e) =>
																					fontHandler(
																						e.target.value,
																						'header',
																						'color'
																					)
																				}
																			/>
																			<InputRightAddon
																				id="primaryColor"
																				cursor="pointer"
																				bg={
																					items[selectedIndex].header.font.color
																				}
																				onClick={(e) =>
																					openColorPicker('header', 'font')
																				}
																			/>
																		</InputGroup>
																	</Flex>
																	<Flex mb={2}>
																		<Text
																			width="40%"
																			fontSize=".65rem"
																			fontWeight="500"
																			mr={2}
																		>
																			Weight
																		</Text>
																		<Select
																			flex="1 1"
																			size="xs"
																			value={
																				items[selectedIndex].header.font.weight
																			}
																			onChange={(e) =>
																				fontHandler(
																					e.target.value,
																					'header',
																					'weight'
																				)
																			}
																		>
																			{Array.from([
																				'200',
																				'300',
																				'400',
																				'500',
																				'600',
																				'700',
																				'800',
																			]).map((item) => (
																				<option key={item} value={item}>
																					{item}
																				</option>
																			))}
																		</Select>
																	</Flex>

																	<Flex mb={2}>
																		<Text
																			width="40%"
																			fontSize=".65rem"
																			fontWeight="500"
																			mr={2}
																		>
																			Transform
																		</Text>
																		<Select
																			flex="1 1"
																			size="xs"
																			value={
																				items[selectedIndex].header.font
																					.transform
																			}
																			onChange={(e) =>
																				fontHandler(
																					e.target.value,
																					'header',
																					'transform'
																				)
																			}
																		>
																			<option value="none">None</option>
																			<option value="uppercase">
																				Uppercase
																			</option>
																			<option value="lowercase">
																				Lowercase
																			</option>
																			<option value="capitalize">
																				Capitalize
																			</option>
																		</Select>
																	</Flex>
																</Box>
															</Box>
														</TabPanel>
														{/* Text */}
														<TabPanel p={1}>
															<Box
																p="10px"
																borderBottomWidth="1px"
																display="flex"
															>
																<Checkbox
																	colorScheme="cyan"
																	size="sm"
																	value={items[selectedIndex].text.visibility}
																	isChecked={
																		items[selectedIndex].text.visibility
																	}
																	onChange={(e) =>
																		contentVisibilityHandler(
																			e.target.checked,
																			'text'
																		)
																	}
																>
																	<Text fontSize=".70rem" fontWeight="600">
																		Visibility
																	</Text>
																</Checkbox>
															</Box>
															<Box>
																<Text
																	fontSize=".70rem"
																	fontWeight="600"
																	px="10px"
																	py="10px"
																	borderBottomWidth="1px"
																>
																	Paragraph Text:
																</Text>
																<Box borderBottomWidth="1px" p="10px">
																	<Textarea
																		size="xs"
																		fontWeight="500"
																		resize="vertical"
																		defaultValue={
																			items[selectedIndex].text.data
																		}
																		onChange={(e) => textHandler(e, 'text')}
																	/>
																</Box>

																<Text
																	fontSize=".70rem"
																	fontWeight="600"
																	p="10px"
																	borderBottomWidth="1px"
																>
																	Padding:
																</Text>
																<Box
																	display="flex"
																	flexWrap="wrap"
																	borderBottomWidth="1px"
																	p="10px"
																>
																	<FormControl
																		flex="1 1"
																		flexBasis="auto"
																		width="50%"
																		mb={4}
																		pr="5px"
																	>
																		<Stack direction="row">
																			<FormLabel
																				fontSize=".65rem"
																				fontWeight="600"
																			>
																				Top
																			</FormLabel>
																			<NumberInput
																				size="xs"
																				max={100}
																				min={0}
																				defaultValue={
																					items[selectedIndex].text.padding.top
																				}
																				onChange={(value) =>
																					paddingHandler(value, 'text', 'top')
																				}
																			>
																				<NumberInputField />
																				<NumberInputStepper>
																					<NumberIncrementStepper />
																					<NumberDecrementStepper />
																				</NumberInputStepper>
																			</NumberInput>
																		</Stack>
																	</FormControl>
																	<FormControl
																		flex="1 1"
																		flexBasis="auto"
																		width="50%"
																		mb={4}
																		pl="5px"
																	>
																		<Stack direction="row">
																			<FormLabel
																				fontSize=".65rem"
																				fontWeight="600"
																			>
																				Right
																			</FormLabel>
																			<NumberInput
																				size="xs"
																				max={100}
																				min={0}
																				defaultValue={
																					items[selectedIndex].text.padding
																						.right
																				}
																				onChange={(value) =>
																					paddingHandler(value, 'text', 'right')
																				}
																			>
																				<NumberInputField />
																				<NumberInputStepper>
																					<NumberIncrementStepper />
																					<NumberDecrementStepper />
																				</NumberInputStepper>
																			</NumberInput>
																		</Stack>
																	</FormControl>
																	<FormControl
																		flex="1 1"
																		flexBasis="auto"
																		width="50%"
																		pr="5px"
																	>
																		<Stack direction="row">
																			<FormLabel
																				fontSize=".65rem"
																				fontWeight="600"
																			>
																				Left
																			</FormLabel>
																			<NumberInput
																				size="xs"
																				max={100}
																				min={0}
																				defaultValue={
																					items[selectedIndex].text.padding.left
																				}
																				onChange={(value) =>
																					paddingHandler(value, 'text', 'left')
																				}
																			>
																				<NumberInputField />
																				<NumberInputStepper>
																					<NumberIncrementStepper />
																					<NumberDecrementStepper />
																				</NumberInputStepper>
																			</NumberInput>
																		</Stack>
																	</FormControl>
																	<FormControl
																		flex="1 1"
																		flexBasis="auto"
																		width="50%"
																		pl="5px"
																	>
																		<Stack direction="row">
																			<FormLabel
																				fontSize=".65rem"
																				fontWeight="600"
																			>
																				Bottom
																			</FormLabel>
																			<NumberInput
																				size="xs"
																				max={100}
																				min={0}
																				defaultValue={
																					items[selectedIndex].text.padding
																						.bottom
																				}
																				onChange={(value) =>
																					paddingHandler(
																						value,
																						'text',
																						'bottom'
																					)
																				}
																			>
																				<NumberInputField />
																				<NumberInputStepper>
																					<NumberIncrementStepper />
																					<NumberDecrementStepper />
																				</NumberInputStepper>
																			</NumberInput>
																		</Stack>
																	</FormControl>
																</Box>

																<Text
																	fontSize=".70rem"
																	fontWeight="600"
																	p="10px"
																	borderBottomWidth="1px"
																>
																	Font style:
																</Text>
																<Box borderBottomWidth="1px" p="10px">
																	<Flex mb={2}>
																		<Text
																			width="40%"
																			fontSize=".75rem"
																			fontWeight="500"
																			mr={2}
																		>
																			Font Family
																		</Text>
																		<Select
																			flex="1 1"
																			size="xs"
																			value={
																				items[selectedIndex].text.font
																					.fontFamily
																			}
																			onChange={(e) =>
																				fontHandler(
																					e.target.value,
																					'text',
																					'fontFamily'
																				)
																			}
																		>
																			{fonts.map((font) => (
																				<option
																					key={font.value}
																					value={font.value}
																				>
																					{font.label}
																				</option>
																			))}
																		</Select>
																	</Flex>
																	<Flex mb={2}>
																		<Text
																			width="40%"
																			fontSize=".65rem"
																			fontWeight="500"
																			mr={2}
																		>
																			Size (px)
																		</Text>
																		<NumberInput
																			flex="1 1"
																			size="xs"
																			max={100}
																			min={8}
																			defaultValue={
																				items[selectedIndex].text.font.size
																			}
																			onChange={(value) =>
																				fontHandler(value, 'text', 'size')
																			}
																		>
																			<NumberInputField />
																			<NumberInputStepper>
																				<NumberIncrementStepper />
																				<NumberDecrementStepper />
																			</NumberInputStepper>
																		</NumberInput>
																	</Flex>
																	<Flex mb={2}>
																		<Text
																			width="40%"
																			fontSize=".65rem"
																			fontWeight="500"
																			mr={2}
																		>
																			Color
																		</Text>
																		<InputGroup flex="1 1" size="xs">
																			<Input
																				defaultValue={
																					items[selectedIndex].text.font.color
																				}
																				onChange={(e) =>
																					fontHandler(
																						e.target.value,
																						'text',
																						'color'
																					)
																				}
																			/>
																			<InputRightAddon
																				id="primaryColor"
																				cursor="pointer"
																				bg={
																					items[selectedIndex].text.font.color
																				}
																				onClick={(e) =>
																					openColorPicker('text', 'font')
																				}
																			/>
																		</InputGroup>
																	</Flex>
																	<Flex mb={2}>
																		<Text
																			width="40%"
																			fontSize=".65rem"
																			fontWeight="500"
																			mr={2}
																		>
																			Weight
																		</Text>
																		<Select
																			flex="1 1"
																			size="xs"
																			defaultValue={
																				items[selectedIndex].text.font.weight
																			}
																			onChange={(e) =>
																				fontHandler(
																					e.target.value,
																					'text',
																					'weight'
																				)
																			}
																		>
																			{Array.from([
																				'200',
																				'300',
																				'400',
																				'500',
																				'600',
																				'700',
																				'800',
																			]).map((item) => (
																				<option key={item} value={item}>
																					{item}
																				</option>
																			))}
																		</Select>
																	</Flex>

																	<Flex mb={2}>
																		<Text
																			width="40%"
																			fontSize=".65rem"
																			fontWeight="500"
																			mr={2}
																		>
																			Transform
																		</Text>
																		<Select
																			flex="1 1"
																			size="xs"
																			defaultValue={
																				items[selectedIndex].text.font.transform
																			}
																			onChange={(e) =>
																				fontHandler(
																					e.target.value,
																					'text',
																					'transform'
																				)
																			}
																		>
																			<option value="none">None</option>
																			<option value="uppercase">
																				Uppercase
																			</option>
																			<option value="lowercase">
																				Lowercase
																			</option>
																			<option value="capitalize">
																				Capitalize
																			</option>
																		</Select>
																	</Flex>
																</Box>
															</Box>
														</TabPanel>
														{/* Button */}
														<TabPanel p={1} pb={4}>
															<Box
																p="10px"
																borderBottomWidth="1px"
																display="flex"
															>
																<Checkbox
																	colorScheme="cyan"
																	size="sm"
																	value={items[selectedIndex].button.visibility}
																	isChecked={
																		items[selectedIndex].button.visibility
																	}
																	onChange={(e) =>
																		contentVisibilityHandler(
																			e.target.checked,
																			'button'
																		)
																	}
																>
																	<Text fontSize=".70rem" fontWeight="600">
																		Visibility
																	</Text>
																</Checkbox>
															</Box>
															<Text
																fontSize=".70rem"
																fontWeight="600"
																px="10px"
																py="10px"
																borderBottomWidth="1px"
															>
																Button Text:
															</Text>
															<Box borderBottomWidth="1px" p="10px">
																<Textarea
																	size="xs"
																	fontWeight="500"
																	resize="vertical"
																	defaultValue={
																		items[selectedIndex].button.data
																	}
																	onChange={(e) => textHandler(e, 'button')}
																/>
															</Box>

															<Text
																fontSize=".70rem"
																fontWeight="600"
																p="10px"
																borderBottomWidth="1px"
															>
																Padding:
															</Text>
															<Box
																display="flex"
																flexWrap="wrap"
																borderBottomWidth="1px"
																p="10px"
															>
																<FormControl
																	flex="1 1"
																	flexBasis="auto"
																	width="50%"
																	mb={4}
																	pr="5px"
																>
																	<Stack direction="row">
																		<FormLabel
																			fontSize=".65rem"
																			fontWeight="600"
																		>
																			Top
																		</FormLabel>
																		<NumberInput
																			size="xs"
																			max={100}
																			min={0}
																			defaultValue={
																				items[selectedIndex].button.padding.top
																			}
																			onChange={(value) =>
																				paddingHandler(value, 'button', 'top')
																			}
																		>
																			<NumberInputField />
																			<NumberInputStepper>
																				<NumberIncrementStepper />
																				<NumberDecrementStepper />
																			</NumberInputStepper>
																		</NumberInput>
																	</Stack>
																</FormControl>
																<FormControl
																	flex="1 1"
																	flexBasis="auto"
																	width="50%"
																	mb={4}
																	pl="5px"
																>
																	<Stack direction="row">
																		<FormLabel
																			fontSize=".65rem"
																			fontWeight="600"
																		>
																			Right
																		</FormLabel>
																		<NumberInput
																			size="xs"
																			max={100}
																			min={0}
																			defaultValue={
																				items[selectedIndex].button.padding
																					.right
																			}
																			onChange={(value) =>
																				paddingHandler(value, 'button', 'right')
																			}
																		>
																			<NumberInputField />
																			<NumberInputStepper>
																				<NumberIncrementStepper />
																				<NumberDecrementStepper />
																			</NumberInputStepper>
																		</NumberInput>
																	</Stack>
																</FormControl>
																<FormControl
																	flex="1 1"
																	flexBasis="auto"
																	width="50%"
																	pr="5px"
																>
																	<Stack direction="row">
																		<FormLabel
																			fontSize=".65rem"
																			fontWeight="600"
																		>
																			Left
																		</FormLabel>
																		<NumberInput
																			size="xs"
																			max={100}
																			min={0}
																			defaultValue={
																				items[selectedIndex].button.padding.left
																			}
																			onChange={(value) =>
																				paddingHandler(value, 'button', 'left')
																			}
																		>
																			<NumberInputField />
																			<NumberInputStepper>
																				<NumberIncrementStepper />
																				<NumberDecrementStepper />
																			</NumberInputStepper>
																		</NumberInput>
																	</Stack>
																</FormControl>
																<FormControl
																	flex="1 1"
																	flexBasis="auto"
																	width="50%"
																	pl="5px"
																>
																	<Stack direction="row">
																		<FormLabel
																			fontSize=".65rem"
																			fontWeight="600"
																		>
																			Bottom
																		</FormLabel>
																		<NumberInput
																			size="xs"
																			max={100}
																			min={0}
																			defaultValue={
																				items[selectedIndex].button.padding
																					.bottom
																			}
																			onChange={(value) =>
																				paddingHandler(
																					value,
																					'button',
																					'bottom'
																				)
																			}
																		>
																			<NumberInputField />
																			<NumberInputStepper>
																				<NumberIncrementStepper />
																				<NumberDecrementStepper />
																			</NumberInputStepper>
																		</NumberInput>
																	</Stack>
																</FormControl>
															</Box>

															<Text
																fontSize=".70rem"
																fontWeight="600"
																p="10px"
																borderBottomWidth="1px"
															>
																Width & Height:
															</Text>
															<Box
																display="flex"
																flexWrap="wrap"
																borderBottomWidth="1px"
																p="10px"
															>
																<Flex mb={2}>
																	<Text
																		width="40%"
																		fontSize=".65rem"
																		fontWeight="500"
																		mr={2}
																	>
																		Width
																	</Text>
																	<InputGroup flex="1 1" size="xs">
																		<Input
																			defaultValue={
																				items[selectedIndex].button.width
																			}
																			onChange={buttonWidthHandler}
																		/>
																		<InputRightAddon
																			id="width"
																			cursor="pointer"
																			children="(%, px, auto)"
																		/>
																	</InputGroup>
																</Flex>
																<Flex>
																	<Text
																		width="40%"
																		fontSize=".65rem"
																		fontWeight="500"
																		mr={2}
																	>
																		Height
																	</Text>
																	<InputGroup flex="1 1" size="xs">
																		<Input
																			defaultValue={
																				items[selectedIndex].button.height
																			}
																			onChange={buttonHeightHandler}
																		/>
																		<InputRightAddon
																			id="primaryColor"
																			cursor="pointer"
																			children="(%, px, auto)"
																		/>
																	</InputGroup>
																</Flex>
															</Box>

															<Text
																fontSize=".70rem"
																fontWeight="600"
																p="10px"
																borderBottomWidth="1px"
															>
																Font style:
															</Text>
															<Box borderBottomWidth="1px" p="10px">
																<Flex mb={2}>
																	<Text
																		width="40%"
																		fontSize=".75rem"
																		fontWeight="500"
																		mr={2}
																	>
																		Font Family
																	</Text>
																	<Select
																		flex="1 1"
																		size="xs"
																		value={
																			items[selectedIndex].button.font
																				.fontFamily
																		}
																		onChange={(e) =>
																			fontHandler(
																				e.target.value,
																				'button',
																				'fontFamily'
																			)
																		}
																	>
																		{fonts.map((font) => (
																			<option
																				key={font.value}
																				value={font.value}
																			>
																				{font.label}
																			</option>
																		))}
																	</Select>
																</Flex>
																<Flex mb={2}>
																	<Text
																		width="40%"
																		fontSize=".65rem"
																		fontWeight="500"
																		mr={2}
																	>
																		Size (px)
																	</Text>
																	<NumberInput
																		flex="1 1"
																		size="xs"
																		max={50}
																		min={5}
																		defaultValue={
																			items[selectedIndex].button.font.size
																		}
																		onChange={(value) =>
																			fontHandler(value, 'button', 'size')
																		}
																	>
																		<NumberInputField />
																		<NumberInputStepper>
																			<NumberIncrementStepper />
																			<NumberDecrementStepper />
																		</NumberInputStepper>
																	</NumberInput>
																</Flex>
																<Flex mb={2}>
																	<Text
																		width="40%"
																		fontSize=".65rem"
																		fontWeight="500"
																		mr={2}
																	>
																		Color
																	</Text>
																	<InputGroup flex="1 1" size="xs">
																		<Input
																			defaultValue={
																				items[selectedIndex].button.font.color
																			}
																			onChange={(e) =>
																				fontHandler(
																					e.target.value,
																					'button',
																					'color'
																				)
																			}
																		/>
																		<InputRightAddon
																			id="primaryColor"
																			cursor="pointer"
																			bg={
																				items[selectedIndex].button.font.color
																			}
																			onClick={(e) =>
																				openColorPicker('button', 'font')
																			}
																		/>
																	</InputGroup>
																</Flex>
																<Flex mb={2}>
																	<Text
																		width="40%"
																		fontSize=".65rem"
																		fontWeight="500"
																		mr={2}
																	>
																		Weight
																	</Text>
																	<Select
																		flex="1 1"
																		size="xs"
																		defaultValue={
																			items[selectedIndex].button.font.weight
																		}
																		onChange={(e) =>
																			fontHandler(
																				e.target.value,
																				'button',
																				'weight'
																			)
																		}
																	>
																		{Array.from([
																			'200',
																			'300',
																			'400',
																			'500',
																			'600',
																			'700',
																			'800',
																		]).map((item) => (
																			<option key={item} value={item}>
																				{item}
																			</option>
																		))}
																	</Select>
																</Flex>
																{/* <Flex mb={2}>
                                                                    <Text width="40%" fontSize=".65rem" fontWeight="500" mr={2}>Align</Text>
                                                                    <Select flex="1 1" size="xs"
                                                                        defaultValue={items[selectedIndex].button.font.align}
                                                                        onChange={(e) => fontHandler(e.target.value, "button", "align")}
                                                                    >
                                                                        <option value="left">Left</option>
                                                                        <option value="center">Center</option>
                                                                        <option value="right">Right</option>
                                                                    </Select>
                                                                </Flex> */}
																<Flex mb={2}>
																	<Text
																		width="40%"
																		fontSize=".65rem"
																		fontWeight="500"
																		mr={2}
																	>
																		Transform
																	</Text>
																	<Select
																		flex="1 1"
																		size="xs"
																		defaultValue={
																			items[selectedIndex].button.font.transform
																		}
																		onChange={(e) =>
																			fontHandler(
																				e.target.value,
																				'button',
																				'transform'
																			)
																		}
																	>
																		<option value="none">None</option>
																		<option value="uppercase">Uppercase</option>
																		<option value="lowercase">Lowercase</option>
																		<option value="capitalize">
																			Capitalize
																		</option>
																	</Select>
																</Flex>
															</Box>

															<Flex borderBottomWidth="1px" p="10px">
																<Text
																	width="40%"
																	fontSize=".70rem"
																	fontWeight="600"
																	mr={2}
																>
																	Background Color
																</Text>
																<InputGroup flex="1 1" size="xs">
																	<Input
																		defaultValue={
																			items[selectedIndex].button.bgColor
																		}
																		onChange={(e) =>
																			bgColorHandler(e.target.value, 'button')
																		}
																	/>
																	<InputRightAddon
																		id="primaryColor"
																		cursor="pointer"
																		bg={items[selectedIndex].button.bgColor}
																		onClick={(e) =>
																			openColorPicker('button', 'bgColor')
																		}
																	/>
																</InputGroup>
															</Flex>

															<Text
																fontSize=".70rem"
																fontWeight="600"
																p="10px"
																borderBottomWidth="1px"
															>
																Border:
															</Text>
															<Box>
																<Box display="flex" flexWrap="wrap" p="10px">
																	<FormControl
																		flex="1 1"
																		flexBasis="auto"
																		width="50%"
																		mb={4}
																		pr="5px"
																	>
																		<Stack direction="row">
																			<FormLabel
																				fontSize=".65rem"
																				fontWeight="600"
																			>
																				Top
																			</FormLabel>
																			<NumberInput
																				size="xs"
																				max={50}
																				min={0}
																				defaultValue={
																					items[selectedIndex].button.border.top
																				}
																				onChange={(value) =>
																					buttonBorderHandler(value, 'top')
																				}
																			>
																				<NumberInputField />
																				<NumberInputStepper>
																					<NumberIncrementStepper />
																					<NumberDecrementStepper />
																				</NumberInputStepper>
																			</NumberInput>
																		</Stack>
																	</FormControl>
																	<FormControl
																		flex="1 1"
																		flexBasis="auto"
																		width="50%"
																		mb={4}
																		pr="5px"
																	>
																		<Stack direction="row">
																			<FormLabel
																				fontSize=".65rem"
																				fontWeight="600"
																			>
																				Right
																			</FormLabel>
																			<NumberInput
																				size="xs"
																				max={50}
																				min={0}
																				defaultValue={
																					items[selectedIndex].button.border
																						.right
																				}
																				onChange={(value) =>
																					buttonBorderHandler(value, 'right')
																				}
																			>
																				<NumberInputField />
																				<NumberInputStepper>
																					<NumberIncrementStepper />
																					<NumberDecrementStepper />
																				</NumberInputStepper>
																			</NumberInput>
																		</Stack>
																	</FormControl>
																	<FormControl
																		flex="1 1"
																		flexBasis="auto"
																		width="50%"
																		mb={4}
																		pr="5px"
																	>
																		<Stack direction="row">
																			<FormLabel
																				fontSize=".65rem"
																				fontWeight="600"
																			>
																				Left
																			</FormLabel>
																			<NumberInput
																				size="xs"
																				max={50}
																				min={0}
																				defaultValue={
																					items[selectedIndex].button.border
																						.left
																				}
																				onChange={(value) =>
																					buttonBorderHandler(value, 'left')
																				}
																			>
																				<NumberInputField />
																				<NumberInputStepper>
																					<NumberIncrementStepper />
																					<NumberDecrementStepper />
																				</NumberInputStepper>
																			</NumberInput>
																		</Stack>
																	</FormControl>
																	<FormControl
																		flex="1 1"
																		flexBasis="auto"
																		width="50%"
																		mb={4}
																		pr="5px"
																	>
																		<Stack direction="row">
																			<FormLabel
																				fontSize=".65rem"
																				fontWeight="600"
																			>
																				Bottom
																			</FormLabel>
																			<NumberInput
																				size="xs"
																				max={50}
																				min={0}
																				defaultValue={
																					items[selectedIndex].button.border
																						.bottom
																				}
																				onChange={(value) =>
																					buttonBorderHandler(value, 'bottom')
																				}
																			>
																				<NumberInputField />
																				<NumberInputStepper>
																					<NumberIncrementStepper />
																					<NumberDecrementStepper />
																				</NumberInputStepper>
																			</NumberInput>
																		</Stack>
																	</FormControl>
																</Box>
																<Flex mb={2} px="10px">
																	<Text
																		width="40%"
																		fontSize=".65rem"
																		fontWeight="500"
																		mr={2}
																	>
																		Border Color
																	</Text>
																	<InputGroup flex="1 1" size="xs">
																		<Input
																			defaultValue={
																				items[selectedIndex].button.font.color
																			}
																			onChange={(e) =>
																				buttonBorderHandler(
																					e.target.value,
																					'color'
																				)
																			}
																		/>
																		<InputRightAddon
																			id="primaryColor"
																			cursor="pointer"
																			bg={
																				items[selectedIndex].button.border.color
																			}
																			onClick={(e) =>
																				openColorPicker('button', 'border')
																			}
																		/>
																	</InputGroup>
																</Flex>
																<Box borderBottomWidth="1px" pb={2} px="10px">
																	<Stack direction="row">
																		<Text
																			width="50%"
																			fontSize=".65rem"
																			fontWeight="500"
																			mr={2}
																		>
																			Rounded
																		</Text>
																		<NumberInput
																			size="xs"
																			max={50}
																			min={0}
																			defaultValue={
																				items[selectedIndex].button.rounded
																					.topLeft
																			}
																			onChange={buttonRadiusHandler}
																		>
																			<NumberInputField />
																			<NumberInputStepper>
																				<NumberIncrementStepper />
																				<NumberDecrementStepper />
																			</NumberInputStepper>
																		</NumberInput>
																	</Stack>
																</Box>
															</Box>
														</TabPanel>
													</TabPanels>
												</Tabs>
											)
										} else {
											return null
										}
									})}
								</>
							) : null}
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
			<Modal
				isCentered
				onClose={onClose}
				isOpen={isOpen}
				motionPreset="slideInBottom"
				size="xs"
			>
				<ModalOverlay />
				<ModalContent>
					<SketchPicker
						width="100%"
						color={color}
						onChangeComplete={(color) => setColor(color.hex)}
					/>
				</ModalContent>
			</Modal>

			<Modal
				isCentered
				onClose={onCloseMedia}
				isOpen={isOpenMedia}
				motionPreset="slideInBottom"
				size="xs"
			>
				<ModalOverlay />
				<ModalContent>Image upload modal</ModalContent>
			</Modal>
		</>
	)
}

export default SliderSetting
