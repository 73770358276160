import {
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalContent,
	useDisclosure,
	FormControl,
	FormLabel,
	Input,
	Stack,
	useToast,
	Button,
	Text,
	Select,
} from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { postRequest } from '../services/base.service'
import { toastConfig } from '../helpers/appConfig'
import { useForm } from 'react-hook-form'
import { useState } from 'react'

const AddTrainer = ({ getList, industries }) => {
	const [isAdding, setIsAdding] = useState(false)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm()
	const toast = useToast()
	const [submissionErrors, setSubmissionErrors] = useState([])

	const onSubmit = (data) => {
		setIsAdding(true)
		postRequest('admin/add-trainer', data)
			.then((res) => {
				setIsAdding(false)
				toast(toastConfig(res.message, res.status))
				onClose()
				reset()
				setSubmissionErrors([])
				getList()
			})
			.catch((err) => {
				setIsAdding(false)
				setSubmissionErrors(err.response.data.errors)
			})
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Add Trainer</ModalHeader>

					<form onSubmit={handleSubmit(onSubmit)}>
						<ModalBody>
							<Stack spacing="20px">
								<FormControl id="subscriberName" isRequired>
									<FormLabel>Name</FormLabel>
									<Input
										type="text"
										rounded={'full'}
										placeholder="Name"
										{...register('name', { required: true })}
									/>
									{errors.name && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}
								</FormControl>
								<FormControl id="subscriberEmail" isRequired>
									<FormLabel>Email</FormLabel>
									<Input
										type="email"
										rounded={'full'}
										placeholder="Email address"
										{...register('email', { required: true })}
									/>

									{errors.email && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}

									{submissionErrors?.email && (
										<Text fontSize="sm" mt="2" color="red.500">
											{submissionErrors.email[0]}
										</Text>
									)}
								</FormControl>

								<FormControl id="subscriberPassword" isRequired>
									<FormLabel>Password</FormLabel>
									<Input
										type="text"
										rounded={'full'}
										placeholder="Password"
										{...register('password', { required: true })}
									/>

									{errors.password && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}

									{submissionErrors?.password && (
										<Text fontSize="sm" mt="2" color="red.500">
											{submissionErrors.password[0]}
										</Text>
									)}
								</FormControl>

								<FormControl id="businessType">
									<FormLabel>Business Type</FormLabel>

									<Select
										placeholder="Business Type"
										borderColor="secondary.200"
										_hover={{
											borderColor: 'secondary.300',
										}}
										_focus={{
											borderColor: 'secondary.400',
										}}
										_placeholder={{
											color: 'secondary.400',
										}}
										rounded="full"
										{...register('industry_id', { required: true })}
									>
										{industries.map((industry, index) => (
											<option key={index} value={industry.id}>
												{industry.name}
											</option>
										))}
									</Select>

									{errors.industry_id && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}
								</FormControl>

								<FormControl id="appName" isRequired>
									<FormLabel>App Name</FormLabel>
									<Input
										borderColor="secondary.200"
										_hover={{
											borderColor: 'secondary.300',
										}}
										_focus={{
											borderColor: 'secondary.400',
										}}
										_placeholder={{
											color: 'secondary.400',
										}}
										rounded="full"
										type="text"
										{...register('app_name', { required: true })}
										placeholder="App name"
									/>

									{errors.app_name && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}
								</FormControl>

								<FormControl id="socialPlatform">
									<FormLabel>Your Main Social Media Platform</FormLabel>

									<Select
										placeholder="Select Platform"
										borderColor="secondary.200"
										_hover={{
											borderColor: 'secondary.300',
										}}
										_focus={{
											borderColor: 'secondary.400',
										}}
										_placeholder={{
											color: 'secondary.400',
										}}
										rounded="full"
										{...register('social_media', { required: false })}
									>
										<option value="Instagram">Instagram</option>
										<option value="YouTube">YouTube</option>
										<option value="TikTok">TikTok</option>
										<option value="Other">Other</option>
									</Select>
								</FormControl>

								<FormControl id="handleName">
									<FormLabel>Platform Handle Name</FormLabel>
									<Input
										borderColor="secondary.200"
										_hover={{
											borderColor: 'secondary.300',
										}}
										_focus={{
											borderColor: 'secondary.400',
										}}
										_placeholder={{
											color: 'secondary.400',
										}}
										rounded="full"
										type="text"
										{...register('social_media_handler_name', {
											required: false,
										})}
										placeholder="@username"
									/>
								</FormControl>
								<FormControl id="commissionPercentage">
									<FormLabel>Commission (%)</FormLabel>
									<Input
										min={0}
										max={100}
										maxLength={3}
										minLength={1}
										type={'number'}
										borderColor="secondary.200"
										_hover={{
											borderColor: 'secondary.300',
										}}
										_focus={{
											borderColor: 'secondary.400',
										}}
										_placeholder={{
											color: 'secondary.400',
										}}
										rounded="full"
										defaultValue={12}
										{...register('mgp_commission', {
											required: false,
										})}
										placeholder="Commission percentage"
									/>
								</FormControl>

								<FormControl id="accountId">
									<FormLabel>Account ID</FormLabel>
									<Input
										type={'text'}
										borderColor="secondary.200"
										_hover={{
											borderColor: 'secondary.300',
										}}
										_focus={{
											borderColor: 'secondary.400',
										}}
										_placeholder={{
											color: 'secondary.400',
										}}
										rounded="full"
										defaultValue={12}
										{...register('account_id', {
											required: false,
										})}
										placeholder="Account ID"
									/>
								</FormControl>
							</Stack>
						</ModalBody>

						<ModalFooter>
							<Button variant={'ghost'} mr={3} onClick={onClose}>
								Close
							</Button>
							<Button
								type="submit"
								loadingText={'Adding'}
								isLoading={isAdding}
								variant="primary"
							>
								Add Trainer
							</Button>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>

			<Button leftIcon={<FiPlus />} onClick={onOpen} variant="primary">
				Add Trainer
			</Button>
		</>
	)
}

export default AddTrainer
