import React, { useContext, useEffect } from 'react'
import { Box, Stack, Text, Flex,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    FormControl, FormLabel,
    NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Input,
    Select, InputGroup, InputRightAddon, Textarea, 
    Modal, ModalOverlay, ModalContent,
    useDisclosure
} from '@chakra-ui/react'
import debounce from 'lodash.debounce';
import { SketchPicker } from 'react-color';
import { PageBuilderContext } from '../../../context/PageBuilderContext';
import { borderConfigHandler, roundedConfigHandler, marginConfigHandler
} from '../../../helper/settingsHelper';

function DividerSetting() {
    const [ state, dispatch ] = useContext(PageBuilderContext);
    const { isOpen, onOpen, onClose } = useDisclosure(); 
    const { selected } = state;
    let { style } = state.section[selected.sectionIndex].columns[selected.columnIndex].components[selected.compIndex];
    let debounceEvent = null;

    const marginHandler = (value, side) => {
        const margin = marginConfigHandler(style.margin, side, value);
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                margin: margin, 
                type:"margin", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const myWidth = (e) => {
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                width: e.target.value, 
                type:"width", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const borderHandler = (value, side) => {
        const border = borderConfigHandler(style.border, side, value);
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                border: border,
                type:"border", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const borderColorHandler = debounce((value) => {
        let border = style.border;
        border.color = value;
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                border: border,
                type:"border", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    },400)

    const roundingHandler = (e) => {
        const rounded = roundedConfigHandler(e.target.value);
        dispatch({
            type:'COMPONENT_CONFIG_UPDATE', 
            payload:{
                rounded: rounded,
                type:"rounded", 
                sectionIndex: selected.sectionIndex,
                columnIndex:selected.columnIndex,
                compIndex: selected.compIndex
            }
        });
    }

    const debouncedChangeHandler = (...arg) => {
        debounceEvent = debounce(...arg);
        return ((e) => {
            if(typeof e === 'object'){
                e.persist();
            }
            return debounceEvent(e);
        })
    };


    const nonPersistDebounceHandler = debounce((value, side) => borderHandler(value, side), 400)

    useEffect(() => {
        return () => {
            debounceEvent.cancel();
            nonPersistDebounceHandler.cancel();
            borderColorHandler.cancel();
        }
    }, []);

    return (
        <>
        <Box>
            <Tabs size="sm" isFitted  variant="unstyled">
                <TabList borderWidth="1px">
                    <Tab
                        fontSize=".75rem" fontWeight="600"
                        _focus={{outline:"none"}} 
                        _selected={{ bg: "gray.200" }}
                    >
                        Design
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel p={0}>

                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Margin:</Text>
                        <Box display="flex" flexWrap="wrap" borderBottomWidth="1px" p="10px">
                            <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pr="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Top</FormLabel>
                                    <NumberInput 
                                        size="xs" 
                                        max={100} min={0} 
                                        onChange={(value) => marginHandler(value, "top")} 
                                        value={style.margin.top}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" mb={4} pl="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Right</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        max={100} min={0} 
                                        onChange={(value) => marginHandler(value, "right")} 
                                        value={style.margin.right}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" pr="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Left</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        max={100} min={0} 
                                        onChange={(value) => marginHandler(value, "left")} 
                                        value={style.margin.left}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput> 
                                </Stack>
                            </FormControl>
                            <FormControl flex="1 1" flexBasis="auto" width="50%" pl="5px">
                                <Stack direction="row">
                                    <FormLabel fontSize=".75rem" fontWeight="600">Bottom</FormLabel>
                                    <NumberInput 
                                        size="xs"
                                        max={100} min={0} 
                                        onChange={(value) => marginHandler(value, "bottom")} 
                                        value={style.margin.bottom}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>  
                                </Stack>
                            </FormControl>
                        </Box>

                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Width & Height:</Text>
                        <Box display="flex" flexWrap="wrap" borderBottomWidth="1px" p="10px">
                            <Flex mb={2}>
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Width</Text>
                                <InputGroup flex="1 1" size="xs">
                                    <Input defaultValue={style.width} onChange={myWidth} />
                                    <InputRightAddon id="width" cursor="pointer" children="(%, px, auto)" />
                                </InputGroup>
                            </Flex>
                        </Box>
                        
                        

                        <Text fontSize=".8rem" fontWeight="600" p="10px" borderBottomWidth="1px">Border:</Text>
                        <Box>
                            <Box display="flex" flexWrap="wrap" px="10px" py="5px">
                                <FormLabel width="38%" fontSize=".75rem" fontWeight="500">Width</FormLabel>
                                <NumberInput 
                                    size="xs" 
                                    max={50} min={0} 
                                    flex="1 1"
                                    onChange={(value) => nonPersistDebounceHandler(value,'top')} 
                                    defaultValue={style.border.top}
                                >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput> 
                            </Box>
                            
                            <Box display="flex" flexWrap="wrap" py="5px" px="10px">
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Rounded</Text>
                                <InputGroup flex="1 1" size="xs">
                                    <Input defaultValue={style.rounded.topLeft} type="number" onChange={debouncedChangeHandler(roundingHandler, 400)}  />
                                    <InputRightAddon id="primaryColor" cursor="pointer" children="px" />
                                </InputGroup>
                            </Box>

                            <Flex mb={2} flexWrap="wrap" borderBottomWidth="1px" py="5px" px="10px">
                                <Text width="40%" fontSize=".75rem" fontWeight="500" mr={2}>Color</Text>
                                <InputGroup flex="1 1" size="xs" key={style.border.color}>
                                    <Input defaultValue={style.border.color} onChange={(e) => borderColorHandler(e.target.value)}  />
                                    <InputRightAddon id="primaryColor" cursor="pointer" bg={style.border.color} onClick={onOpen} />
                                </InputGroup>
                            </Flex>
                        </Box>
                        
                       
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
        <Modal 
            isCentered
            onClose={onClose}
            isOpen={isOpen}
            motionPreset="slideInBottom"
            size="xs">

            <ModalOverlay />
            <ModalContent>
                <SketchPicker width="100%" color={ style.border.color } onChange={(color) => borderColorHandler(color.hex)}  /> 
            </ModalContent>
        </Modal>
        </>
    )
}

export default DividerSetting;
