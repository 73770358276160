import { useState, useEffect } from 'react'
import { Box, Image, Heading, Text, Stack, FormControl, FormLabel,
	Input, Button, Center
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import './auth.css'

const StepTwo = ({ value, logout, onChange, nextStep }) => {
	const [appName, setAppName] =  useState('')
	const [businessName, setBusinessName] = useState('')
	const [loggingOut, setLoggingOut] = useState(false)

    const {
		formState: { errors },
	} = useForm()

	function handleChange(e) {
		e.preventDefault()

		setBusinessName(e.currentTarget.value)
		let string = e.currentTarget.value.replace(/[^A-Z0-9]+/ig, '-')
		string = string.toLowerCase()
		setAppName(string)
	}

    function goforward(e) {
		if (e.key === 'Enter' || e.type === 'click') {
			nextStep()
		}
    }

	function handleLogout(e) {
		e.preventDefault()

		setLoggingOut(true)
		logout()
	}

	useEffect(() => {
		onChange({
			...value,
			app_name: appName,
			business_name: businessName
		})
	}, [appName])

	return (
		<Box display={'flex'} h="100vh" bg="secondary.100" color="secondary.700">
			<Box
				flex="1"
				display={['block', 'block', 'flex', 'flex']}
				justifyContent="center"
				alignItems="center"
				sx={{
                    background: `transparent linear-gradient(137deg, #5E59FF 0%, #59FEF4 100%) 0% 0% no-repeat padding-box;`
                }}
				overflowX="hidden"
				p={['0', '0', '0', '20px']}
			>
				<Box display={['none', 'none', 'block', 'block']}>
					<div class="custom-shape-divider-bottom-1656696063">
						<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
							<path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
						</svg>
					</div>
					<div class="custom-shape-divider-bottom-1656695281">
						<svg data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
							<path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
						</svg>
					</div>
				</Box>
				<Box
					w="100%"
					maxW={['100%', '100%', '100%', '450px']}
					bg="white"
                    boxShadow='lg'
					overflowX="hidden"
					maxH="100%"
                    h={['100%', '100%', 'auto', 'auto']}
					rounded={['0', '0', 'xl', 'xl']}
					p="40px"
					zIndex='2'
				>
					{!value.auth ? (
						<>
							<Heading mt={['50%', '50%', '0', '0']} as="h3" fontSize="2xl" letterSpacing='wide' fontWeight='black' color="secondary.500" mb="1">
								Pick your app name!
							</Heading>

							<Stack pt="30px" as='form'>
								<FormControl variant='floating' id="appName">
									<FormLabel fontSize='xs'>App Name</FormLabel>
									<Input
										borderColor="secondary.200"
										_hover={{
											borderColor: 'secondary.300',
										}}
										_focus={{
											borderColor: 'secondary.400',
										}}
										_placeholder={{
											color: 'secondary.400',
										}}
										defaultValue={appName ?? value.app_name}
										onKeyPress={goforward}
										onChange={handleChange}
										rounded="sm"
										type="text"
										name='app_name'
									/>

									{errors.app_name && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}
								</FormControl>

								<Box pt="3">
									<Button
										variant="primary"
										w="100%"
										onClick={goforward}
										disabled={!value.app_name}
									>
										Pick Your Features
									</Button>
								</Box>
							</Stack>
							<Center>
								<Button 
									mt={2} 
									loadingText={'Starting over'}
									isLoading={loggingOut}
									fontSize='xs' 
									variant='link' 
									cursor='pointer' 
									onClick={handleLogout}
								>
									Start over
								</Button>
							</Center>
						</>
					) : (
						<Heading my='60px' textAlign='center' fontSize='2xl' color='purple.700'>Your dashboard in 3...2...1...</Heading>
					)}
					
				</Box>
			</Box>

            <Box
				display={['none', 'None', 'none', 'block']}
				flex="1"
				bg="primary.100"
				pos="relative"
			>
				<Image
					h="100vh"
					w="100%"
					objectFit="cover"
                    objectPosition='35% 0'
					src="https://i.imgur.com/erpuzav.jpg"
				/>

				<Box
					pos="absolute"
					top="0"
					left="0"
					w="100%"
					h="100%"
					bg="rgba(0, 0, 0, .05)"
				></Box>

                <Box
					pos="absolute"
					top="0%"
					w="100%"
					h="100%"
					bg="rgba(0, 0, 0, .05)"
				>
                    <Image
                        h="70vh"
                        src="https://i.imgur.com/9iDJGLN.png"
                    />
                </Box>
			</Box>
		</Box>
	)
}

export default StepTwo
