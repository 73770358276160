import {
	FormControl,
	FormLabel,
	FormHelperText,
	Textarea,
} from '@chakra-ui/react'
import { useContext } from 'react'
import { PageBuilderContext } from '../../context/PageBuilderContext'

const EmbedCodeSettings = () => {
	const [state, dispatch] = useContext(PageBuilderContext)

	const { selected } = state

	let { embedCode } =
		state.section[selected.sectionIndex]?.columns[selected.columnIndex]
			?.components[selected.compIndex]

	const handleInputOnChange = (e) => {
		dispatch({
			type: 'COMPONENT_CONFIG_UPDATE',
			payload: {
				type: 'embedCode',
				embedCode: e.target.value,
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	return (
		<FormControl>
			<FormLabel>Embed Code</FormLabel>
			<Textarea
				onChange={handleInputOnChange}
				h="200px"
				defaultValue={embedCode}
				placeholder="Enter embed code"
			/>
			{/* <FormHelperText>We'll never share your email.</FormHelperText> */}
		</FormControl>
	)
}

export default EmbedCodeSettings
