import React from 'react'
import { Provider } from 'react-redux'
import store from './redux/store'
import ReactDOM from 'react-dom'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import appTheme from './app-theme'
import '@fontsource/roboto'
import '@fontsource/montserrat'
import '@fontsource/poppins'
import App from './App'
import reportWebVitals from './reportWebVitals'
import axios from 'axios'
import NewFonts from './app-theme/components/newFonts'

axios.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (error?.response?.status === 401) {
			localStorage.removeItem('user')
			window.location.reload()
		}

		return Promise.reject(error)
	}
)

ReactDOM.render(
	<Provider store={store}>
		<ColorModeScript initialColorMode={appTheme.config.initialColorMode} />

		<ChakraProvider theme={appTheme}>
			<NewFonts />
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</ChakraProvider>
	</Provider>,
	document.getElementById('root')
)

reportWebVitals()
