import { useState, useEffect, useRef, useMemo } from 'react'
import { Box, Image, Heading, Flex, FormLabel, Input, 
    VStack, Text, Link
} from '@chakra-ui/react'
import { MdArrowForwardIos } from 'react-icons/md'
import Slider from 'react-slick'
import NavButtons from '../NavButtons'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import './auth.css'

const RadioInput = ({ image, value, caption, isChecked, handleChange, subtitle }) => {
    function handleRadioChange(e) {
        const {value} = e.currentTarget
        handleChange(value)
    }

    return (
        <Box 
            id={value}
            sx={{
                display: 'inline-block',
            }}
        >
            <Input
                type="radio"
                sx={{
                    display: 'none',
                }}
                name='template'
                id={'label-' + value}
                value={value}
                checked={isChecked}
                onChange={handleRadioChange}
            />
            <FormLabel 
                htmlFor={'label-' + value}
                sx={{
                    cursor: 'pointer'
                }}
                >
                <Box
                    sx={{
                        filter: isChecked ? 'drop-shadow(1px -1px 16px #504CD6);' : ''
                    }}
                    margin="auto"
                    justifyContent="center"
                    alignItems="center"
                    display='flex'
                    flexDirection="column"
                    w={isChecked ? ['80%','80%','78%','66%','75%','72%'] : ['80%','80%','78%','66%','70%','66%']}
                    zIndex='99'
                >
                    { subtitle ? (
                        <Box>
                            <Text align="center" color="gray.900">
                                {caption}
                            </Text>
                            <Text align="center" fontSize="sm">
                                {subtitle}
                            </Text>
                        </Box>
                        )
                        :
                        <Text align='center' zIndex={['99','99','1','1']} mb={['-4%','-4%','0','0']} color="gray.900">{caption}</Text>}
                    <Image
                        src={image}
                    />
                </Box>
            </FormLabel>
        </Box>
    )
}

const StepFour = ({ value, onChange, prevStep, nextStep }) => {
    const [template, setTemplate] = useState('')
    const [bigWindow, setBigWindow] = useState(true)
    const slider = useRef()
    
    const imagesArray = {
        1: [
            {name: '/images/courses/Business.png', value: 'JSi0Ttfvcecgm7Of8cAc', caption: 'Business'},
            {name: '/images/courses/Design.png', value: 'NEZZDjHN4IrsZ6hUYup5', caption: 'Design'},
            {name: '/images/courses/Marketing.png', value: 'UOowa98IE24Mtjpzbg0X', caption: 'Marketing'},
        ],
        3: [
            {name: '/images/women-gym.png', value: 'YC9S8JrSWF025x8njeI3', caption: 'Women\'s Gym Split'},
            {name: '/images/men-gym.png', value: 'bYowWXfNxJHwpCwBPliI', caption: 'Men\'s Gym Split'},
            {name: '/images/coaching.png', value: 'GnoXR02dnODXwcSd4iFK', caption: '1-on-1 Coaching'},
            {name: '/images/yoga.png', value: 'C0pAFriHmT080p6bv9DN', caption: 'Yoga'},
        ],
        8: [
            {name: '/images/organizations/school app.png', value: 'FTFSuxRC7YDukQpQomzA', caption: 'School app', subtitle: 'sports, news, clubs, resources'},
            {name: '/images/organizations/church app.png', value: 'T4FGmTRey4t4lMWhTs5a', caption: 'Church app', subtitle: 'events, community, news'},
            {name: '/images/organizations/club app.png', value: 'PAF52xCpCp69uYl5dwGf', caption: 'Club app', subtitle: 'sports, hobbies, interests'},
        ]
    }

    const images = useMemo(() => {
        setTemplate(imagesArray[value.industry_id][1].value)
        return imagesArray[value.industry_id]
    }, [value.industry_id])

    const settings = {
        className: "center",
        focusOnSelect: true,
        arrows: false,
        dots: bigWindow ? false : true,
        infinite: true,
        centerMode: bigWindow ? true : false,
        speed: 200,
        slidesToShow: bigWindow ? 3 : 1,
        slidesToScroll: 1,
        useTransform: bigWindow ? true : false,
        initialSlide: 1,
        focusOnSelect: true,
        appendDots: dots => <ul style={{ margin: '0px', bottom: '0' }}>{dots}</ul>,
        beforeChange: (oldIndex, newIndex) => {
            setTemplate(images[newIndex].value)
        }, 
    }

    function gobackward() {
        prevStep()
    }

    function goforward() {
        nextStep()
    }

    function handleChange(val) {
        setTemplate(val)
    }

    function next() {
        slider.current.slickNext()
    }

    useEffect(() => {
        if (window.innerWidth < 600) {
            setBigWindow(false)
        }
    }, [])

    useEffect(() => {
        onChange({
            ...value,
            mobile_template: template
        })
    }, [template])

    return (
		<Box display={'flex'} h="100vh" bg="secondary.100" color="secondary.700">
			<Box
				justifyContent="center"
				alignItems="center"
				sx={{
                    zIndex: '2',
                    background: 'transparent linear-gradient(137deg, #5E59FF 0%, #59FEF4 100%) 0% 0% no-repeat padding-box;',
                    boxShadow: '4px 3px 14px #0000007D;'
                }}
				overflow="hidden"
				px={['0', '0', '20%', '20%']}
			>
				<Box
					w="100%"
					bg="white"
                    boxShadow='lg'
					overflowX="hidden"
                    overflowY='auto'
					maxH="100%"
                    h={['100%','100%','auto','auto']}
					rounded={['0', '0', 'xl', 'xl']}
					p='50px'
                    pt={bigWindow ? '50px' : '20px'}
                    pr={bigWindow ? '0' : '50px'}
                    mt={['0', '0', '5%', '5%']}
				>
                    <Box py={['15px', '0', '0', '0']}>
                        <Flex justifyContent='center'>
                            <VStack spacing={0}>
                                <Heading as="h3" fontSize="2xl" fontWeight='black' color="secondary.500" mb="1">
                                    Choose a template
                                </Heading>
                                <Text>All templates are fully customizable</Text>
                            </VStack>
                        </Flex>

                        <Box position='relative' w="100%">
                            {bigWindow && (
                                <Box
                                    position='absolute'
                                    bg='white'
                                    w='10%'
                                    top='0'
                                    left='-10'
                                    h='100%'
                                    zIndex='9999'
                                ></Box>
                            )}
                            <Slider ref={curr => slider.current = curr} {...settings} >
                                {images.map(({name, value, caption, subtitle}, id) => (
                                    <RadioInput 
                                        key={id}
                                        image={name}
                                        value={value}
                                        caption={caption}
                                        subtitle={subtitle}
                                        isChecked={template === value}
                                        handleChange={handleChange}
                                    />
                                ))}
                            </Slider>
                            {bigWindow && (
                                <Box
                                    display='flex' 
                                    justifyContent='center'
                                    position='relative' 
                                    mt='-3%'
                                    zIndex='999'
                                >
                                    <Box
                                        as='button'
                                        rounded='full'
                                        bg='gray.100'
                                        p={3}
                                        boxShadow='lg'
                                        onClick={next}
                                    >
                                        <MdArrowForwardIos size='1em' />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                        

                        <Box mt={3} display='flex' justifyContent='center'>
                            <Text as='small'>
                                You picked the {images.filter(img => template ? img.value === template : img.value === images[1].value)[0].caption} template
                            </Text>
                        </Box>
                        <Box display='flex' justifyContent={'center'} pb={['10', '10', '0', '0']}>
                            <NavButtons forward={goforward} backward={gobackward} width={'50%'} data={value} />
                        </Box>
                    </Box>
				</Box>
			</Box>
		</Box>
	)
}

export default StepFour
