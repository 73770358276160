import React, { useContext, useEffect, useState } from 'react'
import { Box, Center } from '@chakra-ui/react'
import ComponentView from './ComponentView'
import ColumnConfigButton from './ColumnConfigButton'
import { Droppable } from 'react-beautiful-dnd'
import { PageBuilderContext } from '../context/PageBuilderContext'

function Columns({ section, index }) {
	const [state] = useContext(PageBuilderContext)
	const [cols, setCols] = useState([])
	const { selected } = state

	useEffect(() => {
		setCols(section.columns)
	}, [state.section, index])

	return (
		<Box my={0} mx="auto">
			<Box display="flex" my={0} mx={`-${section.columnGap}px`}>
				{cols.map((col, colIndex) => (
					<Droppable
						key={colIndex}
						type="componentDrop"
						droppableId={`${index}-${colIndex}`}
					>
						{(provided, snapshot) => (
							<Box
								{...provided.droppableProps}
								ref={provided.innerRef}
								key={col.id}
								flexGrow="0"
								flexShrink="0"
								flexBasis="auto"
								width={`${col.style.width}%`}
								className={
									selected?.type === 'column' &&
									selected?.sectionIndex === index &&
									selected?.columnIndex === colIndex
										? 'page-column selected-column'
										: 'page-column'
								}
								px={`${section.columnGap}px`}
								bg={snapshot.isDraggingOver ? 'gray.100' : 'transparent'}
							>
								<Box
									// bg={col.style.bgColor}
									bg={col.style.bgColor}
									height="100%"
									rounded={`${col.style.rounded?.topLeft}px`}
									pt={`${col.style.padding.top}px`}
									pb={`${col.style.padding.bottom}px`}
									pr={`${col.style.padding.right}px`}
									pl={`${col.style.padding.left}px`}
									shadow={col.style.boxShadow}
								>
									{col.components.length ? (
										<ComponentView
											secIndex={index}
											colIndex={colIndex}
											components={col.components}
										/>
									) : (
										<Box
											p="10px"
											bg="gray.100"
											borderColor="gray.300"
											borderWidth={snapshot.isDraggingOver ? '0px' : '1px'}
											borderStyle="dashed"
											height="100%"
										>
											<Center height="100%" color="gray.500" fontSize="14px">
												{/* <AddIcon w={3} h={3} color="gray.500" /> */}
												{`${col.style.width}%`}
											</Center>
										</Box>
									)}
								</Box>
								<ColumnConfigButton secIndex={index} colIndex={colIndex} />
								{provided.placeholder}
							</Box>
						)}
					</Droppable>
				))}
			</Box>
		</Box>
	)
}

export default Columns
