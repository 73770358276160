import React, { useState } from "react";
import {
  Button,
  useToast,
  Flex,
  Grid,
  GridItem,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Container,
  Box,
  InputGroup,
  Input,
  InputRightElement,
  HStack,
} from "@chakra-ui/react";
import { FiSidebar } from "react-icons/fi";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";
import { mainDomain, toastConfig } from "../../helpers/appConfig";

function Header({ submitButtonRef, formDataLoading }) {
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const user = useSelector((state) => state.auth?.user);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCopyURL = () => {
    const landingPageURL = document.getElementById("landingPageURL");
    landingPageURL.select();
    landingPageURL.setSelectionRange(0, 99999); /* For mobile devices */
    navigator.clipboard.writeText(landingPageURL.value);
    toast(toastConfig("Copied", "success"));
  };

  const onSubmit = () => {
    setIsSubmitting(true);
    submitButtonRef?.current?.click();
  };

  return (
    <>
      <Flex
        alignItems="center"
        pt="1"
        pb="2"
        ps="30px"
        pe={["16px", "18px", "20px"]}
        bg="white"
        borderBottom="1px"
        borderColor="secondary.200"
      >
        <Flex alignItems="center" w="100%">
          <Text as="span" fontSize="xl">
            <FiSidebar />
          </Text>
          <Text
            fontSize="lg"
            ms="2"
            fontWeight="semibold"
            color="secondary.800"
            mr="20"
          >
            Homepage
          </Text>

          <HStack ml={6} my={1}>
            <Button
              variant="outline"
              size={["sm", "sm", "sm", "sm", "sm", "md"]}
              onClick={() => onOpen()}
              color="primary.500"
              rounded="md"
              border="2px"
              borderColor="primary.500"
              fontSize={["xs", "xs", "xs", "xs", "xs", "sm"]}
              py={2}
              width={["10em", "10em", "10em", "10em", "12em", "14em"]}
            >
              Preview on mobile
            </Button>

            <Button
              as="a"
              href={`https:\/\/${user.domain}.gameplanapps.com`}
              size={["sm", "sm", "sm", "sm", "sm", "md"]}
              target="_blank"
              variant="outline"
              color="primary.500"
              rounded="md"
              border="2px"
              borderColor="primary.500"
              fontSize={["xs", "xs", "xs", "xs", "xs", "sm"]}
              py={2}
              width={["10em", "10em", "10em", "10em", "12em", "14em"]}
            >
              View Page
            </Button>
          </HStack>
        </Flex>
        <Button
          type="submit"
          isLoading={formDataLoading}
          loadingText="Saving..."
          variant="primary"
          boxShadow="none"
          fontSize="sm"
          fontWeight="normal"
          width={["10em", "10em", "12em", "16em", "18em", "20em"]}
          height="2.6em"
          mr={["280", "280", "280", "280", "280", "300"]}
          onClick={onSubmit}
        >
          Save &amp; Publish
        </Button>
      </Flex>

      <Modal onClose={onClose} size={"full"} bg="white" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent bg="white">
          <ModalCloseButton />
          <ModalBody overflowX="hidden" pt="50px">
            <Container maxW="container.lg">
              <Grid
                h="100%"
                templateRows="repeat(1, 1fr)"
                templateColumns="repeat(12, 1fr)"
                gap={"30px"}
              >
                <GridItem colSpan={8} bg="white">
                  <Box
                    mx="auto"
                    h="667px"
                    w="375px"
                    overflowX="hidden"
                    borderWidth="10px"
                    borderStyle="solid"
                    borderColor="primary.300"
                    bg="primary.200"
                    rounded="15px"
                  >
                    <iframe
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      src={`//${user?.domain}.${mainDomain}`}
                      title={mainDomain}
                    />
                  </Box>
                </GridItem>
                <GridItem colSpan={4} bg="white">
                  <Flex justifyContent={"center"}>
                    <QRCode
                      value={`${user?.domain}.${mainDomain}`}
                      bgColor="white"
                      fgColor="#252525"
                      level="Q"
                      title={"Scan to browse on your device"}
                      size="256"
                    />
                  </Flex>

                  <Text textAlign="center" mt="3" color="secondary.600">
                    Scan to browse on your device
                  </Text>

                  <Box mt="6">
                    <Text color="secondary.700" fontSize="sm">
                      Copy and share where you want
                    </Text>
                    <InputGroup size="md" mt="2">
                      <Input
                        id="landingPageURL"
                        pr="4.5rem"
                        rounded="full"
                        isReadOnly
                        type={"text"}
                        defaultValue={`${user?.domain}.${mainDomain}`}
                        placeholder="url"
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          onClick={handleCopyURL}
                          variant="primary"
                          size="sm"
                        >
                          Copy
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </Box>
                </GridItem>
              </Grid>
            </Container>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Header;
