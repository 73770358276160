import { Box, Text } from '@chakra-ui/react'

const CardInfo = ({ data }) => {
	return (
		<Box border="1px" borderColor="secondary.200" p="5" rounded="10px">
			<Text
				fontSize="2.7rem"
				mb="1"
				color="secondary.900"
				fontWeight="semibold"
			>
				{data.value}
			</Text>

			<Text textTransform="uppercase">{data.label}</Text>
		</Box>
	)
}

export default CardInfo
