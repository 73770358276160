import React from 'react'
import Page from './components/Page'
import PageBuilderProvider from './context/PageBuilderContext'

function PageBuilder() {
	return (
		<PageBuilderProvider>
			<Page />
		</PageBuilderProvider>
	)
}

export default PageBuilder
