import { useRef } from 'react'
import {
	Box,
	Text,
	Popover,
	PopoverTrigger,
	IconButton,
	PopoverContent,
	PopoverBody,
	List,
	Heading,
	ListItem,
	ListIcon,
	Button,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	useToast,
	Center,
} from '@chakra-ui/react'
import { useState } from 'react'
import {
	FiMoreVertical,
	FiEdit3,
	FiX,
	FiCopy,
	FiArrowRight,
	FiCheckCircle,
	FiTrash,
} from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { toastConfig } from '../helpers/appConfig'
import { isPublicDomain } from '../helpers/utils'
import { deleteRequest, getRequest } from '../services/base.service'
import AddPricing from './AddPricing'
import { useEffect } from 'react'

const PricingCard = ({
	data,
	updateList,
	handleGetStart,
	tempPricingId,
	isGettingStart,
	status,
	type
}) => {
	const user = useSelector((state) => state.auth.user)
	const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false)
	const cancelRef = useRef()
	const toast = useToast()
	const [isDeleting, setIsDeleting] = useState(false)
	const [period, setPeriod] = useState('')
	const stripeStatus = status

	const onAlertDialogClose = () => setIsAlertDialogOpen(false)

	const copyText = (text) => {
		navigator.clipboard.writeText(text).then(
			function () {
				toast(toastConfig('Copied', 'success'))
			},
			function (err) {
				toast(toastConfig('Sorry! Could not copy text.', 'error'))
			}
		)
	}

	useEffect(() => {
		if (data.type === 'recurring') {
			if (data.pricing_type === 'day' || data.pricing_type === 'daily') {
				setPeriod('Billed every day')
			}
			else if (data.pricing_type === 'week' || data.pricing_type === 'week') {
				setPeriod('Billed every week')
			}
			else if ((data.pricing_type === 'month' || data.pricing_type === 'monthly' || data.pricing_type === 'monthly_6') && data.interval_count === 6) {
				setPeriod('Billed every 6 months') 
			}
			else if ((data.pricing_type === 'month' || data.pricing_type === 'monthly' || data.pricing_type === 'monthly_3') && data.interval_count === 3) {
				setPeriod('Billed every 3 months') 
			}
			else if (data.pricing_type === 'month' || data.pricing_type === 'monthly') {
				setPeriod('Billed every month') 
			}
			else if (data.pricing_type === 'year' || data.pricing_type === 'yearly') {
				setPeriod('Billed every year')
			}
		}
		else {
			setPeriod('Billed only once')
		}
	}, [data])

	return (
		<Box
			w="100%"
			p="15px"
			bg="white"
			border="1px"
			shadow="lg"
			borderColor="secondary.200"
			borderRadius="10"
			alignItems="center"
			pos="relative"
		>
			{data.type !== 'free' && (
				<Heading fontSize="xl" color="secondary.500" textAlign="center" as="h4">
					{data.title}
				</Heading>
			)}
			{data?.annual_saving > 0 && (
				<Text textAlign="center" mt="1" fontSize="sm" color="secondary.600">
					Save {data?.annual_saving} %
				</Text>
			)}
			{data?.trial_day > 0 && (
				<Text textAlign="center" mt="1" fontSize="sm" color="secondary.600">
					{data?.trial_day} days free
				</Text>
			)}
			<Text
				m={data.type === 'free' && 6}
				mt={data.type !== 'free' && 1}
				fontSize={data.type === 'free' ? '3xl' : '5xl'}
				textAlign="center"
				fontWeight="semibold"
				color="secondary.500"
			>
				{data.type === 'free' ? data.title : data.price}
				<Text
					as="sub"
					bottom="0"
					fontWeight="normal"
					color="secondary.700"
					fontSize="xl"
				>
					{data.type !== 'free' ? ` ${data.currency?.toUpperCase()}` : ''}
				</Text>
			</Text>
			{data.type !== 'free' && (
				<Text
					fontSize="small"
					textAlign="center"
					color="secondary.700"
					fontWeight="normal"
				>
					{period}
				</Text>
			)}

			<AlertDialog
				isOpen={isAlertDialogOpen}
				leastDestructiveRef={cancelRef}
				onClose={onAlertDialogClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Delete Pricing
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onAlertDialogClose}>
								Cancel
							</Button>
							<Button
								colorScheme="red"
								isLoading={isDeleting}
								loadingText={'Deleting...'}
								onClick={() => {
									setIsDeleting(true)
									deleteRequest(`pricing/${data.id}`)
										.then((res) => {
											toast(toastConfig(res.message, res.status))
											updateList()
											onAlertDialogClose()
											setIsDeleting(false)
										})
										.catch((error) => {
											toast(
												toastConfig(
													error.response.data.message,
													error.response.data.status
												)
											)
											setIsDeleting(false)
										})
								}}
								ml={3}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			{!isPublicDomain() && stripeStatus && type !== 'free' && (
				<Center mt="2">
					<Button
						leftIcon={<FiCopy />}
						onClick={() => copyText(data?.stripe_link_url)}
						variant={'ghost'}
						size={'sm'}
					>
						Copy Payment Link
					</Button>
				</Center>
			)}

			{type === 'free' && (
				<Center mt="2">
					<Button
						leftIcon={<FiCopy />}
						onClick={() => copyText(data?.stripe_link_url)}
						variant={'ghost'}
						size={'sm'}
					>
						Copy Payment Link
					</Button>
				</Center>
			)}

			{!isPublicDomain() && (
				<Popover placement="bottom-end">
					<PopoverTrigger>
						<IconButton
							_hover={{ outline: 'none', boxShadow: 'none' }}
							_focus={{ outline: 'none', boxShadow: 'none' }}
							aria-label="Actions"
							icon={<FiMoreVertical />}
							pos="absolute"
							top="4px"
							p="0"
							right="2px"
						/>
					</PopoverTrigger>
					<PopoverContent
						maxW="150px"
						size="md"
						bg="white"
						style={{ boxShadow: 'none' }}
					>
						<PopoverBody
							boxShadow="base"
							border="1px"
							rounded="md"
							borderColor="secondary.200"
							size="md"
						>
							<List spacing={3}>
								<ListItem
									cursor="pointer"
									_hover={{
										'& > .action-icon': { color: 'primary.500' },
										color: 'primary.500',
									}}
								>
									<AddPricing updateList={updateList} data={data} />
								</ListItem>
								<ListItem
									onClick={() => setIsAlertDialogOpen(true)}
									display="flex"
									alignItems="center"
									cursor="pointer"
									_hover={{
										'& > .action-icon': { color: 'primary.500' },
										color: 'primary.500',
									}}
								>
									<ListIcon
										as={FiTrash}
										me="2"
										className="action-icon"
										color="secondary.400"
									/>
									Delete
								</ListItem>
							</List>
						</PopoverBody>
					</PopoverContent>
				</Popover>
			)}

			{isPublicDomain() && (
				<Box pt="4" textAlign="center">
					<Button
						size="lg"
						//isDisabled={data.id === user?.subscription_id}
						isLoading={tempPricingId === data.stripe_price_id && isGettingStart}
						loadingText="Getting started..."
						rightIcon={
							data.id === user?.subscription_id ? (
								<FiCheckCircle />
							) : (
								<FiArrowRight />
							)
						}
						//onClick={() => handleGetStart(data.stripe_price_id)}
						onClick={() => window.location.href = data?.stripe_link_url}
						variant={'primary'}
					>
						&nbsp; &nbsp; &nbsp; &nbsp;
						{/*data.id === user?.subscription_id ? 'Subscribed' : `Start Now`*/}
						Start Now
						&nbsp; &nbsp; &nbsp; &nbsp;
					</Button>
				</Box>
			)}
			
		</Box>
	)
}

export default PricingCard
