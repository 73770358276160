import { Box, Container } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { changePageTitle } from '../redux'
import Iframe from 'react-iframe'

const News = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(changePageTitle('Resources'))
	})	

	useEffect(() => {
		const bigWindow = window.innerWidth > 600
		let x = document.getElementById('iframe');
    x.style.height = `${window.innerHeight - (bigWindow? 70 : 130)}px`
	}, [])


	return (
		<Box flex="1" mb="16" width="100%">
			<Iframe 
				url="https://www.gameplanapps.com/dashboard" 
				id="iframe"
				width='100%' 
				overflow='hidden' 
				className='background-dark' 
			/>
		</Box>
	)
}


export default News


