import React, { useEffect, useContext, useRef } from 'react'
import {
	Box,
	Button,
	Stack,
	Icon,
	Radio,
	RadioGroup,
	AlertDialog,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogOverlay,
	AlertDialogCloseButton,
	useDisclosure,
	Text,
} from '@chakra-ui/react'
import { FiEdit, FiTrash } from 'react-icons/fi'
import { PageBuilderContext } from '../context/PageBuilderContext'

function ColumnConfigButton({ secIndex, colIndex }) {
	const [state, dispatch] = useContext(PageBuilderContext)
	const [value, setValue] = React.useState('right')
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()

	const columnLength = useRef(false)

	useEffect(() => {
		if (
			state.section[secIndex].columns.length > 2 &&
			colIndex > 0 &&
			colIndex < state.section[secIndex].columns.length - 1
		) {
			columnLength.current = true
		} else {
			columnLength.current = false
		}
	})

	const deleteColumn = () => {
		onOpen()
	}

	const selectColumn = () => {
		dispatch({
			type: 'SELECT_COLUMN',
			payload: { sectionIndex: secIndex, columnIndex: colIndex },
		})
	}

	return (
		<>
			<Box className="page-column-setting" roundedTop="2px">
				<Stack direction="row" spacing="4px">
					<Button
						rounded={4}
						size=".8rem"
						bg="transparent"
						_focus={{ outline: 'none' }}
						_active={{ outline: 'none', background: '#CBD5E0' }}
						onClick={selectColumn}
					>
						<FiEdit size=".8rem" />
					</Button>
					<Button
						rounded={4}
						size=".8rem"
						bg="transparent"
						_focus={{ outline: 'none' }}
						_active={{ outline: 'none', background: '#CBD5E0' }}
						onClick={deleteColumn}
					>
						<FiTrash size=".8rem" />
					</Button>
				</Stack>
			</Box>
			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={onClose}
				isOpen={isOpen}
				isCentered
				size="md"
			>
				<AlertDialogOverlay />
				<AlertDialogContent>
					<AlertDialogHeader
						color="gray.600"
						fontSize="1.1rem"
						fontWeight="700"
						borderBottomWidth="1px"
					>
						Are you sure?
					</AlertDialogHeader>
					{columnLength.current && (
						<AlertDialogBody>
							<Text py={2} fontSize=".9rem" fontWeight="600" color="gray.500">
								Choose merge side
							</Text>
							<RadioGroup onChange={setValue} value={value}>
								<Stack direction="row" spacing="10px">
									<Radio value="right" colorScheme="gray">
										<Icon viewBox="0 0 100 100" color="gray.500" mr="8px">
											<rect
												x="0"
												y="0"
												width="25"
												height="100"
												fill="currentColor"
											/>
											<rect
												x="30"
												y="0"
												width="70"
												height="100"
												fill="#cddafd"
											/>
										</Icon>
										<Text
											as="span"
											fontSize=".8rem"
											color="gray.500"
											fontWeight="500"
										>
											Right column
										</Text>
									</Radio>
									<Radio value="left" colorScheme="gray">
										<Icon viewBox="0 0 100 100" color="gray.500" mr="8px">
											<rect
												x="0"
												y="0"
												width="70"
												height="100"
												fill="#cddafd"
											/>
											<rect
												x="75"
												y="0"
												width="25"
												height="100"
												fill="currentColor"
											/>
										</Icon>
										<Text
											as="span"
											fontSize=".8rem"
											color="gray.500"
											fontWeight="500"
										>
											Left column
										</Text>
									</Radio>
								</Stack>
							</RadioGroup>
						</AlertDialogBody>
					)}
					<AlertDialogCloseButton />
					<AlertDialogFooter>
						<Button
							size="xs"
							px="15px"
							rounded={2}
							ref={cancelRef}
							onClick={onClose}
						>
							No
						</Button>
						<Button
							size="xs"
							px="15px"
							rounded={2}
							colorScheme="red"
							ml={3}
							onClick={() => {
								const payload = {
									secIndex: secIndex,
									colIndex: colIndex,
								}
								payload.mergeSide = columnLength.current ? value : null
								dispatch({
									type: 'REMOVE_COLUMN',
									payload: payload,
								})
							}}
						>
							Yes
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	)
}

export default ColumnConfigButton
