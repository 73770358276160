import { useState, useEffect } from 'react'
import {
	Box,
	Grid,
	Flex,
	Text,
	Heading,
	Button,
	Badge,
	Alert,
	AlertIcon,
	chakra,
	Spinner,
	Center,
} from '@chakra-ui/react'
import { postRequest, getRequest } from '../../services/base.service'
import mapDate from '../../helpers/mapDate'

const AccountPaymentSettings = () => {
	const [initLoading, setInitLoading] = useState(false)
	const [stripeStatus, setStripeStatus] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [createdAt, setCreatedAt] = useState({})

	const getStripeStatus = () => {
		setInitLoading(true)
		getRequest('stripe-status')
			.then((res) => {
				setStripeStatus(res.result.status)
				const date = mapDate(res.result.created_at)
				setCreatedAt(date)
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => setInitLoading(false))
	}

	useEffect(() => {
		getStripeStatus()
	}, [])

	const connectStripeHandler = () => {
		setIsLoading(true)
		postRequest('stripe-connect', { redirect_url: window?.location?.href })
			.then((res) => {
				window.location.assign(res.result.url)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	if (initLoading) {
		return (
			<Center h="200px">
				<Spinner
					thickness="6px"
					speed="0.65s"
					emptyColor="gray.200"
					color="blue.500"
					size="xl"
				/>
			</Center>
		)
	} else {
		return (
			<>
				<Flex justifyContent="space-between" mb="3">
					<Heading as="h3" fontSize="xl" color="secondary.500">
						Connected Accounts
					</Heading>
					<Button
						isLoading={isLoading}
						variant={stripeStatus ? 'solid' : 'primary'}
						_hover={{ variant: stripeStatus ? 'solid' : 'primary' }}
						cursor={stripeStatus ? 'not-allowed' : 'cursor'}
						onClick={() => {
							if (!stripeStatus) {
								connectStripeHandler()
							}
						}}
					>
						{stripeStatus ? 'Connected' : 'Connect Stripe'}
					</Button>
				</Flex>

				{stripeStatus ? (
					<Grid
						templateColumns={[
							'repeat(1, 1fr)',
							'repeat(2, 1fr)',
							'repeat(2, 1fr)',
							'repeat(3, 1fr)',
						]}
						gap={6}
					>
						<Box rounded="xl" border="1px" borderColor="secondary.200" p="4">
							<Text
								mb="2"
								fontSize="xl"
								color="secondary.500"
								fontWeight="semibold"
								letterSpacing=".07rem"
							>
								Stripe
								<Badge ml="3" colorScheme="green">
									Connected
								</Badge>
							</Text>

							{/* <Text mb="1">username@example.com</Text> */}
							<Text>
								Connected date: {` `}
								{`${createdAt?.month?.substring(0, 3)} ${createdAt?.day}, ${
									createdAt?.year
								}`}
							</Text>
						</Box>
					</Grid>
				) : (
					<Alert status="warning" mt="50px" boxShadow="md" rounded="sm">
						<AlertIcon />
						Account Not Connected.
						<chakra.span
							ml="8px"
							color="gray.700"
							_hover={{
								cursor: 'pointer',
								textDecoration: 'underline',
							}}
							onClick={connectStripeHandler}
						>
							Connect
						</chakra.span>
					</Alert>
				)}
			</>
		)
	}
}

export default AccountPaymentSettings
