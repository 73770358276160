import {
	Container,
	Box,
	Flex,
	Avatar,
	Text,
	Button,
	FormControl,
	Input,
	Stack,
	useToast,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	Image,
	PopoverArrow,
	PopoverCloseButton,
} from '@chakra-ui/react'
import { FiLogOut, FiUser } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { toastConfig } from '../helpers/appConfig'
import { login, logout } from '../redux'

const LandingPageHeader = () => {
	const toast = useToast()
	const auth = useSelector((state) => state.auth)
	const dispatch = useDispatch()
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()
	const landingTrainerInfo = useSelector(
		(state) => state.helpers.landingTrainerInfo
	)

	return (
		<Container maxW="container.lg">
			<Flex
				as="header"
				pt="2"
				pb="2"
				alignItems="center"
				justifyContent="center"
			>
				<Box>
					<Text
						fontSize="xl"
						as={Link}
						to="/"
						fontWeight="semibold"
						letterSpacing="0.03rem"
					>
						{landingTrainerInfo?.logo ? (
							<Image h="50px" src={landingTrainerInfo?.logo} alt={''} />
						) : (
							landingTrainerInfo?.business_name
						)}
					</Text>
				</Box>
			</Flex>
		</Container>
	)
}

export default LandingPageHeader
