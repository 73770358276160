import { Modal, Flex, ModalOverlay, ModalHeader, ModalBody, ModalFooter,
	ModalContent, useDisclosure, FormControl, FormLabel, Input, Stack, 
	Text, Box, Select, useToast, Collapse
} from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import { FiEdit3, FiPlus } from 'react-icons/fi'
import { useForm } from 'react-hook-form'
import { postRequest } from '../services/base.service'
import { toastConfig } from '../helpers/appConfig'
import { useState, useEffect, useRef } from 'react'

const AddPricing = ({ data, updateList }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [pricingType, setPricingType] = useState(
		'monthly' || data?.subscription_type
	)
	const [submitting, setSubmitting] = useState(false)
	const [curr, setCurr] = useState('USD')
	const [currencies, setCurrencies] = useState({})
	const [type, setType] = useState('recurring')
	const {
		register,
		reset,
		formState: { errors },
	} = useForm()
	const toast = useToast()

	const annualSavingRef = useRef('')
	const priceRef = useRef('')
	const titleRef = useRef('')
	const trialDayRef = useRef('')

	const handleUpdateData = (id, formData) => {
		postRequest(`pricing/${data.id}`, {
			...formData,
			pricing_type: pricingType,
			_method: 'PUT',
		}).then((res) => {
			setSubmitting(false)
			onClose()
			reset()
			toast(toastConfig(res.message, res.status))
			updateList(
				'?sort=id,desc&?select=id,pricing_type,price,trial_day,annual_saving'
			)
		})
	}

	function handlePricingSubmit(e) {
		if (e.key === 'Enter' || e.type === 'click') {
			e.preventDefault()

			setSubmitting(true)

			if (data) {
				handleUpdateData(data.id, {
					title: titleRef.current.value,
				})
			} 
			else {
				postRequest(`pricing`, {
					annual_saving: annualSavingRef.current.value,
					price: priceRef.current.value,
					pricing_type: pricingType,
					title: titleRef.current.value,
					trial_day: trialDayRef.current.value,
					currency: curr,
					type: type
				})
				.then((res) => {
					setSubmitting(false)
					onClose()
					reset()
					toast(toastConfig(res.message, res.status))
					updateList(
						'?sort=id,desc&?select=id,pricing_type,price,trial_day,annual_saving'
					)
				})
			}	
		}
	}

	async function getCurrencies() {
		const response = await fetch('https://openexchangerates.org/api/currencies.json')
		const res = await response.json()
		setCurrencies(res)
	}

	useEffect(() => {
		let isCanceled = false

		if (!isCanceled) getCurrencies()

		return () => isCanceled = true
	}, [])

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent bg="white" color="secondary.800">
					<ModalHeader>{!data ? 'Add Pricing' : 'Update Pricing'}</ModalHeader>

					<form>
						<ModalBody>
							<Stack spacing="20px">
								<FormControl id="pricingTitle">
									<FormLabel>Title</FormLabel>
									<Input
										type="text"
										{...register('title', { required: true })}
										defaultValue={data?.title || ''}
										placeholder="Name"
										ref={titleRef}
										onKeyPress={handlePricingSubmit}
									/>
									{errors.title && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}
								</FormControl>

								{!data && 
									<>
									<Box bg='gray.100' p={5} rounded='lg'>

									<FormControl id="pricingPrice">
											<FormLabel fontSize='sm' fontWeight='black'>Price</FormLabel>
											<Flex>
												<Input
													bg='white'
													type="number"
													_focus={{
														outline: 'none'
													}}
													roundedTopRight='none'
													roundedBottomRight='none'
													step=".01"
													flex='2'
													{...register('price', { required: true })}
													defaultValue={data?.price || 0}
													placeholder="Price ($)"
													ref={priceRef}
													onKeyPress={handlePricingSubmit}
												/>
												<Select
													bg='gray.50'
													_focus={{
														outline: 'none'
													}}
													roundedTopLeft='none'
													roundedBottomLeft='none'
													flex='1'
													{...register('currency', { required: true })}
													value={data?.currency ?? curr ?? null}
													onChange={e => setCurr(e.target.value)}
												>
													{Object.entries(currencies).map((currency, id) => (
														<option key={id} value={currency[0]}>{currency[0] + ' - ' + currency[1]}</option>
													))}
												</Select>
											</Flex>
											{errors.price && (
												<Text fontSize="sm" mt="2" color="red.500">
													This field is required
												</Text>
											)}
											<Flex
												mt={3}
												justifyContent='center'
											>
												<Box
													sx={{
														display: 'inline-block',
													}}
												>
													<Input
														type="radio"
														sx={{
															display: 'none',
														}}
														name='industry_id'
														id='recurring'
														value='recurring'
														checked={type === 'recurring'}
														onChange={e => setType(e.currentTarget.value)}
													/>
													<FormLabel 
														htmlFor='recurring'
														sx={{
															cursor: 'pointer'
														}}
													>
														<Box
															bg='white'
															border={type === 'recurring' ? '2px' : '1px'}
															borderColor={type === 'recurring' ? 'purple.300' : 'gray.400'}
															px={2}
															py={1}
															rounded='lg'
														>
															Recurring
														</Box>
													</FormLabel>
												</Box>
												
												<Box
													sx={{
														display: 'inline-block',
													}}
												>
													<Input
														type="radio"
														sx={{
															display: 'none',
														}}
														name='industry_id'
														id='one_time'
														value='one_time'
														checked={type === 'one_time'}
														onChange={e => setType(e.currentTarget.value)}
													/>
													<FormLabel 
														htmlFor='one_time'
														sx={{
															cursor: 'pointer'
														}}
													>
														<Box
															bg='white'
															border={type === 'one_time' ? '2px' : '1px'}
															borderColor={type === 'one_time' ? 'purple.300' : 'gray.400'}
															px={2}
															py={1}
															rounded='lg'
														>
															One time
														</Box>
													</FormLabel>
												</Box>
											</Flex>
										</FormControl>
										<Collapse in={type === 'recurring'} animateOpacity>
											<FormControl mt={4} id="billingPeriod">
												<FormLabel fontSize='sm' fontWeight='black'>Billing period</FormLabel>
												<Select
													id='pricing_type'
													bg='white'
													borderColor='blue.600'
													{...register('pricing_type', { required: true })}
													value={data?.pricing_type ?? pricingType ?? null}
													onChange={e => setPricingType(e.target.value)}
												>
													<option value="daily">Daily</option>
													<option value="weekly">Weekly</option>
													<option value="monthly">Monthly</option>
													<option value="monthly_3">Every 3 months</option>
													<option value="monthly_6">Every 6 months</option>
													<option value="yearly">Yearly</option>
												</Select>
												{errors.pricing_type && (
													<Text fontSize="sm" mt="2" color="red.500">
														This field is required
													</Text>
												)}
											</FormControl>
										</Collapse>
									</Box>
									<Collapse in={type === 'recurring'} animateOpacity>
										<FormControl id="pricingSavings">
											<FormLabel>Save (%)</FormLabel>
											<Input
												type="number"
												max="100"
												min="0"
												step=".01"
												defaultValue={data?.annual_saving || 0}
												{...register('annual_saving', { required: false })}
												placeholder="Save annually"
												ref={annualSavingRef}
												onKeyPress={handlePricingSubmit}
											/>
										</FormControl>

										<FormControl id="pricingTrialDays">
											<FormLabel mt={4}>Free Trial Period (days)</FormLabel>
											<Input
												type="number"
												min="0"
												step="1"
												defaultValue={data?.trial_day || 0}
												{...register('trial_day', { required: false })}
												placeholder="0"
												ref={trialDayRef}
												onKeyPress={handlePricingSubmit}
											/>
										</FormControl>
									</Collapse>
									</>
								}
							</Stack>
						</ModalBody>

						<ModalFooter>
							<Button variant={'ghost'} mr={3} onClick={onClose}>
								Close
							</Button>
							<Button 
								type="submit" 
								variant="primary" 
								isLoading={submitting}
                    			loadingText={!data ? 'Adding...' : 'Updating...'}
								onClick={handlePricingSubmit}
							>
								{!data ? 'Add Pricing' : 'Update Pricing'}
							</Button>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>

			{!data ? (
				<Button leftIcon={<FiPlus />} onClick={onOpen} variant="primary">
					Add Pricing
				</Button>
			) : (
				<Text as={Flex} alignItems="center" onClick={onOpen}>
					<FiEdit3 />{' '}
					<Text as={'span'} ms="3">
						Edit
					</Text>
				</Text>
			)}
		</>
	)
}

export default AddPricing
