import React, { lazy } from 'react'
import { Box, Container } from '@chakra-ui/react'
import ComponentViewer from './ComponentViewer'

function Column({ cols, columnGap }) {
	return (
		<Box display="flex" my={0} mx={`-${columnGap}px`}>
			{cols.map((col) => {
				return (
					<Box
						key={col.id}
						flexGrow="0"
						flexShrink="0"
						flexBasis="auto"
						width={`${col.style.width}%`}
						px={`${columnGap}px`}
					>
						<Box
							bg={col.style.bgColor}
							rounded={`${col.style.rounded?.topLeft}px`}
							shadow={col.style.boxShadow}
							height="100%"
							pt={[`${col.style.padding.top}px`]}
							pb={[`${col.style.padding.bottom}px`]}
							pr={[`${col.style.padding.right}px`]}
							pl={[`${col.style.padding.left}px`]}
						>
							<ComponentViewer components={col.components} />
						</Box>
					</Box>
				)
			})}
		</Box>
	) 
}

export default Column
