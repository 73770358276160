import { 
  Modal,
  ModalOverlay, 
  ModalContent, 
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Text,
  ModalBody
} from '@chakra-ui/react'

const AppBuilderVideoModal = ({isOpen, onClose}) => {
  return (
    <Modal 
      size="4xl" 
      isOpen={isOpen} 
      onClose={onClose} 
      isCentered
      style={{backgroundColor: 'white !important'}}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mb="2" textAlign="center" fontWeight="extrabold" color="gray.900">
          Welcome
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div style={{position: 'relative', paddingBottom: '56.25%', height: 0}}>
            <iframe 
              src="https://www.loom.com/embed/b6514722b5ad4ad8a46384936cbc105e" 
              frameborder="0" 
              webkitallowfullscreen 
              mozallowfullscreen 
              allowfullscreen 
              style={{
                position: 'absolute', 
                top: 0, 
                left: 0, 
                width: '100%', 
                height: '100%',
                backgroundColor: '#fff'
              }}>
            </iframe>
          </div>
        </ModalBody>
        <ModalFooter justifyContent="center" flexDirection="column">
          <Text fontSize="xl" color="black" fontWeight="900">
            Dashboard overview
          </Text>
          <Text fontSize="sm">
            Videos can be found in the "Resources" tab.
          </Text>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AppBuilderVideoModal
