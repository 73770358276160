import { useEffect, useState } from 'react'
import {Box, Container, Alert, AlertDescription, Flex, 
	Text, Button, useDisclosure
} from '@chakra-ui/react'
import { connect } from "react-redux";
import { useSearchParams } from 'react-router-dom'
import { changePageTitle } from '../redux';
import WebappBuilderVideoModal from '../components/WebappBuilderVideoModal'
import AppBuilderVideoModal from '../components/AppBuilderVideoModal';

const WebAppBuilder = ({ auth, changePageTitle }) => {
	const [bigWindow, setBigWindow] = useState(true)
	const url = process.env.REACT_APP_API_BASE_URL.substring(process.env.REACT_APP_API_BASE_URL.length - 7)
	const base = process.env.REACT_APP_API_BASE_URL.replace(url, '')
	const [pageTitle] = useState('Build')
	const [searchParams, setSearchParams] = useSearchParams()

	const { isOpen, onOpen, onClose } = useDisclosure()
	const { isOpen: isVideoModalOpen, onOpen: onVideoModalOpen, onClose: onVideoModalClose } = useDisclosure()

	const appIds = [
		"drozf40XuiVOyFVhEMJc",
		"7cMv69weLE9cOzADRiuC"
	]

	let embeddedUrl = `${base}/dashboard/`;
	if (appIds.includes(auth.user.firebase_app_id)) {
		embeddedUrl = `${base}/dashboards/${auth.user.firebase_app_id}/`
	}

	useEffect(() => {
		changePageTitle(pageTitle)
	})

	useEffect(() => {
		if (window.innerWidth < 600) {
            setBigWindow(false)
        }
	}, [])
	
	useEffect(() => {
		if (localStorage.getItem('webappBuilderModal') && localStorage.getItem('dashboardVideoModal')) {
			return
		}
		if (searchParams.get('m') !== 'hello') {
			onOpen()
			localStorage.setItem('webappBuilderModal', true)
		} else {
			onVideoModalOpen()
			localStorage.setItem('dashboardVideoModal', true)
		} 
	}, [])

	return (
		<>
			{bigWindow ? (
				<Box display='flex' justifyContent='center' pb={3}>
					<Container display={['none', 'none', 'none', 'block']} sx={{maxWidth: 'none'}} w='100%' ps='0' pe='0' h='calc(100vh - 65px)'>
						<iframe 
							title='App Dashboard' 
							src={ embeddedUrl }
							frameBorder='0' 
							style={{
								overflow: 'hidden',
								height: '100%',
								width: '100%'
							}}
							width="100%" 
							height="calc(100vh - 65px)"
						/>
					</Container>
				</Box>
			) : (
				<Box w='100%' h='90vh' overflowY='hidden'>
					<Box 
						roundedTop='lg'
						mx={4} 
						px={8}
						bg='white'
						h='100%'
					>
						<Flex justifyContent='center' pt={10}>
							<Alert
								status="warning"
								variant="subtle"
								rounded='md'
								flexDirection="column"
								alignItems="center"
								justifyContent="center"
								textAlign="center"
								bg='white'
								border='1px solid #ccc'
								px={8}
								py={8}
								my={8}
							>
								<AlertDescription maxWidth="sm" fontWeight='black' color='black'>
									<Text>Edit from your phone using the Gameplan Studio app or switch to desktop.</Text>	
								</AlertDescription>
							</Alert>
						</Flex>

						<Flex justifyContent='center' mb="3">
							<Button
								as="a"
								w="80%"
								href="http://onelink.to/gfank3"
								variant="primary"
							>
								Gameplan Studio App
							</Button>
						</Flex>
					</Box>
				</Box>
			)}
			<WebappBuilderVideoModal isOpen={isOpen} onClose={onClose} />
			<AppBuilderVideoModal isOpen={isVideoModalOpen} onClose={onVideoModalClose} />
		</>
		
	)
}

const mapDispatchToProps = (dispatch) => {
	return {
		changePageTitle: (title) => dispatch(changePageTitle(title)),
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WebAppBuilder)
