import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AppAccountLayout from "../layouts/AppAccountLayout";
import AppLayout from "../layouts/AppLayout";
import AccountPasswordChange from "../pages/account/AccountPasswordChange";
import AccountPaymentSettings from "../pages/account/AccountPaymentSettings";
import AccountProfile from "../pages/account/AccountProfile";
import AppAccounts from "../pages/AppAccounts";
import AppBuilder from "../pages/AppBuilder";
import WebAppBuilder from "../pages/WebAppBuilder";
import AuthLogin from "../pages/auth/AuthLogin";
import AuthRegister from "../pages/auth/AuthRegister";
import SignupStepTwo from "../pages/auth/SignupStepTwo";
import Dashboard from "../pages/Dashboard";
import NotFound from "../pages/errors/NotFound";
import Pricing from "../pages/Pricing";
import Subscribers from "../pages/Subscribers";
import Trainers from "../pages/Trainers";
import TrainerLandingPage from "../pages/TrainerLandingPage";
import AuthRequire from "./AuthRequire";
import IsLandingPage from "./IsLandingPage";
import SiteLayout from "../layouts/SiteLayout";
import AccountEndUserProfile from "../pages/account-end-user/AccountEndUserProfile";
import PaymentSuccess from "../pages/success/PaymentSuccess";
import PaymentFailed from "../pages/errors/PaymentFaild";
import TrainerOnboard from "../pages/TrainerOnboard";
import AdminSubscribers from "../pages/AdminSubscribers";
import PricingPlans from "../pages/PricingPlans";
import TrainerDetails from "../pages/TrainerDetails";
import AppImages from "../pages/AppImages";
import PricingManagement from "../pages/PricingManagement";
import News from "../pages/News";
import Customers from "../pages/Customers";
import CheckboxContent from "../components/Checkbox/CheckboxContent";

const AppRoutes = ({ auth }) => {
  return (
    <Router>
      <Routes>
        {/* Landing page */}
        <Route
          path="/"
          element={
            <IsLandingPage>
              <SiteLayout />
            </IsLandingPage>
          }
        >
          <Route index element={<TrainerLandingPage />} />
          <Route
            path={`account/profile`}
            element={
              <AuthRequire>
                <AccountEndUserProfile />
              </AuthRequire>
            }
          />
        </Route>

        <Route path={`payment-success`} element={<PaymentSuccess />} />

        <Route path={`payment-failed`} element={<PaymentFailed />} />

        {/* Main app :: Trainer */}
        {auth.user?.role === "trainer" && (
          <Route
            path={"/trainer"}
            element={
              <AuthRequire>
                <AppLayout />
              </AuthRequire>
            }
          >
            <Route
              index
              element={<Navigate to={`/trainer/webapp-builder`} />}
            />
            <Route path={`dashboard`} element={<Dashboard />} />

            <Route path={`page-builder`} element={<AppBuilder />} />
            <Route path={`webapp-builder`} element={<WebAppBuilder />} />
            <Route path={`app-accounts`} element={<AppAccounts />} />
            <Route path={`pricing`} element={<Pricing />} />
            <Route path={`customers`} element={<Customers />} />
            <Route path={`subscribers`} element={<Subscribers />} />
            <Route path={`checklist`} element={<CheckboxContent />} />
            <Route path={`app-images`} element={<AppImages />} />
            <Route path={`pricing-plans`} element={<PricingPlans />} />
            <Route path={`news`} element={<News />} />

            {/* Account */}
            <Route path={`account`} element={<AppAccountLayout />}>
              <Route index element={<Navigate to={`profile`} />} />
              <Route path={`profile`} element={<AccountProfile />} />
              <Route
                path={`payment-settings`}
                element={<AccountPaymentSettings />}
              />
              <Route
                path={`change-password`}
                element={<AccountPasswordChange />}
              />
            </Route>
          </Route>
        )}

        {/* Main app :: Admin */}
        {auth.user?.role === "admin" && (
          <Route
            path={"/admin"}
            element={
              <AuthRequire>
                <AppLayout />
              </AuthRequire>
            }
          >
            <Route index element={<Navigate to={`/admin/dashboard`} />} />
            <Route path={`dashboard`} element={<Dashboard />} />
            <Route path={`trainers`} element={<Trainers />} />
            <Route path={`trainers/details/:id`} element={<TrainerDetails />} />
            <Route path={`subscribers`} element={<AdminSubscribers />} />
            <Route
              path={`pricing-management`}
              element={<PricingManagement />}
            />
            <Route path={`trainer-onboard`} element={<TrainerOnboard />} />
          </Route>
        )}

        {/* Auth Routes */}
        <Route path="/login" element={<AuthLogin />} />
        <Route path="/register" element={<AuthRegister />} />
        <Route path="/signup/2" element={<SignupStepTwo />} />
        {/* Errors pages */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AppRoutes);
