import React, { useContext, useEffect } from 'react'
import {
	Box,
	Text,
	Flex,
	Button,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Select,
	FormControl,
	Textarea,
	Input,
	InputGroup,
	InputRightAddon,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
} from '@chakra-ui/react'
import { SketchPicker } from 'react-color'
import { PageBuilderContext } from '../../../context/PageBuilderContext'
import debounce from 'lodash.debounce'

function VideoSetting() {
	const [state, dispatch] = useContext(PageBuilderContext)
	const { isOpen, onClose } = useDisclosure()
	const {
		isOpen: isOpenMedia,
		onOpen: onOpenMedia,
		onClose: onCloseMedia,
	} = useDisclosure()
	const { selected } = state
	let { style, src } =
		state.section[selected.sectionIndex].columns[selected.columnIndex]
			.components[selected.compIndex]

	const videoHandler = (video, type = 'internal') => {
		let videoObject = {}
		if (type === 'external') {
			if (video !== '') {
				videoObject = { ...src, type: 'external', link: video }
			} else {
				videoObject = { ...src, type: 'internal', link: '' }
			}
		}
		dispatch({
			type: 'COMPONENT_CONFIG_UPDATE',
			payload: {
				src: videoObject,
				type: 'videoUpload',
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	const maxWidthHandler = debounce((value) => {
		dispatch({
			type: 'COMPONENT_CONFIG_UPDATE',
			payload: {
				width: value,
				type: 'width',
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}, 400)

	const horizonatlAlignmentHandler = (e) => {
		dispatch({
			type: 'COMPONENT_CONFIG_UPDATE',
			payload: {
				justifyContent: e.target.value,
				type: 'justifyContent',
				sectionIndex: selected.sectionIndex,
				columnIndex: selected.columnIndex,
				compIndex: selected.compIndex,
			},
		})
	}

	useEffect(() => {
		return () => {
			maxWidthHandler.cancel()
		}
	}, [])

	return (
		<>
			<Box>
				<Tabs size="sm" isFitted variant="unstyled">
					{/* <TabList borderWidth="1px">
						<Tab
							fontSize=".75rem"
							fontWeight="600"
							_focus={{ outline: 'none' }}
							_selected={{ bg: 'gray.200' }}
						>
							Design
						</Tab>
						<Tab
							fontSize=".75rem"
							fontWeight="600"
							_focus={{ outline: 'none' }}
							_selected={{ bg: 'gray.200' }}
						>
							General
						</Tab>
					</TabList> */}
					<TabPanels>
						<TabPanel p={0}>
							{/* <Text
								fontSize=".8rem"
								fontWeight="600"
								p="10px"
								borderBottomWidth="1px"
							>
								Video:
							</Text>
							<Box borderBottomWidth="1px" p="10px">
								<Box lineHeight="20px" border="1px dashed #BDBDBD">
									<Button
										size="sm"
										onClick={() => onOpenMedia()}
										isFullWidth
										rounded={0}
									>
										Upload Video
									</Button>
								</Box>
							</Box> */}

							<Box borderBottomWidth="1px" p="10px 0">
								<Text fontSize=".8rem" fontWeight="600" mb={1}>
									External Video Link
								</Text>
								<Textarea
									size="xs"
									value={src?.link}
									onChange={(e) => videoHandler(e.target.value, 'external')}
								/>
								<Text
									m={0}
									pt="2"
									fontWeight="500"
									fontSize="12px"
									color="gray.500"
								>
									<em>Note:</em> Support only youtube video
								</Text>
								{/* <Text m={0} fontWeight="500" fontSize="10px" color="gray.500">
									Ex: https://www.youtube.com/embed/668nUCeBHyY
								</Text> */}
							</Box>

							<Box
								display="flex"
								flexWrap="wrap"
								borderBottomWidth="1px"
								p="10px 0"
							>
								<Flex mb={2}>
									<Text width="40%" fontSize=".8rem" fontWeight="600" mr={2}>
										Max Width:
									</Text>
									<InputGroup flex="1 1" size="xs">
										<Input
											defaultValue={style.width}
											onChange={(e) => maxWidthHandler(e.target.value)}
										/>
										<InputRightAddon id="width" cursor="pointer" children="%" />
									</InputGroup>
								</Flex>
							</Box>
							<Flex py={1} flexWrap="wrap" borderBottomWidth="1px" p="10px 0">
								<Text width="40%" fontSize=".80rem" fontWeight="600" mr={2}>
									Horizontal
								</Text>
								<Select
									flex="1 1"
									size="xs"
									value={style.justifyContent}
									onChange={horizonatlAlignmentHandler}
								>
									{Array.from([
										{ name: 'Left', value: 'flex-start' },
										{ name: 'Center', value: 'center' },
										{ name: 'Right', value: 'flex-end' },
									]).map((item) => (
										<option key={item.value} value={item.value}>
											{item.name}
										</option>
									))}
								</Select>
							</Flex>
						</TabPanel>
						<TabPanel p={0}></TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
			<Modal
				isCentered
				onClose={onClose}
				isOpen={isOpen}
				motionPreset="slideInBottom"
				size="xs"
			>
				<ModalOverlay />
				<ModalContent>
					<SketchPicker width="100%" />
				</ModalContent>
			</Modal>

			<Modal
				isCentered
				onClose={onCloseMedia}
				isOpen={isOpenMedia}
				motionPreset="slideInBottom"
				size="xs"
			>
				<ModalOverlay />
				<ModalContent>Video Upload Modal</ModalContent>
			</Modal>
		</>
	)
}

export default VideoSetting
