const buttonStyles = {
	baseStyle: {
		fontWeight: 'normal',
		rounded: 'full',
	},
	variants: {
		primary: {
			bg: 'primary.500',
			color: 'primary.100',
			boxShadow: '0px 3px 6px #00000029;',
			rounded: 'md',
			h: '2.8em',
			fontWeight: '900',
			fontSize: 'lg',
			_disabled: {
				bg: 'primary.800',
				color: 'white',
			},
			_hover: {
				bg: 'primary.800',
				color: 'white',
				_disabled: {
					bg: 'primary.800',
					color: 'white',
				},
			},
			_focus: {
				bg: 'primary.800',
			},
		},
		secondary: {
			bg: 'secondary.100',
			boxShadow: '0px 3px 6px #00000029;',
			rounded: 'md',
			h: '2.7em',
			fontWeight: '900',
			fontSize: 'lg',
			_hover: {
				bg: 'gray.100',
				_disabled: {
					bg: 'primary.800',
					color: 'white',
				},
			},
		},
		_focus: {
			bg: 'gray.100',
		},
	},
}

export default buttonStyles
