import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    ModalCloseButton,
    Text
  } from '@chakra-ui/react'
import rocket from "../assets/rocket.gif"

const AppModal = ({ isOpen, onClose }) => {
    // const { isOpen, onOpen, onClose } = useDisclosure()
    return (
      <>
        {/* <Button onClick={onOpen}>Open Modal</Button> */}
  
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        

          <ModalOverlay />
          <ModalContent className='rocket-main-body'>
            <ModalCloseButton />
            <ModalBody className='rocket-model' textAlign="center">
              <div className='model-img'>
              <img src={rocket}></img>

              </div>

              <h1>Success!</h1>
              <p>We will send you an email with the status of your app in 5-10 days</p> 
              <div style={{position: 'relative', paddingBottom: '56.25%', height: 0, marginTop: "30px"}}>
                <iframe 
                  src="https://www.loom.com/embed/b7c2032843c44621ab0eda165209bd80" 
                  frameborder="0" 
                  webkitallowfullscreen 
                  mozallowfullscreen 
                  allowfullscreen 
                  style={{
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    width: '100%', 
                    height: '100%',
                    backgroundColor: '#fff'
                  }}>
                </iframe>
              </div>
            </ModalBody>
            <ModalFooter justifyContent="center" flexDirection="column">
              <Text fontSize="xl" color="black" fontWeight="900">
                How to have a successful launch
              </Text>
              <Text fontSize="sm">
                Videos can be found in the "Resources" tab.
              </Text>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

export default AppModal
