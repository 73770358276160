import { combineReducers } from 'redux'
import authReducer from './auth/authReducer'
import helperReducer from './helpers/helperReducer'

const rootReducer = combineReducers({
	auth: authReducer,
	helpers: helperReducer,
})

export default rootReducer
