import { useContext, useState } from 'react'
import { Box, Text, Stack, Input, Button, Image,
	useDisclosure, useToast, Tooltip, Icon, Divider, 
	Collapse, HStack, Alert, AlertDescription, 
	AlertTitle, Flex
} from '@chakra-ui/react'
import { FiUpload } from 'react-icons/fi'
import { RiFileCopyLine } from 'react-icons/ri'
import { MdArrowForwardIos, MdOutlineArrowDropDown, 
	MdOutlineArrowDropUp 
} from 'react-icons/md'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { postRequest } from '../services/base.service'
import { profileUpdate } from '../redux'
import { toastConfig } from '../helpers/appConfig'
import { BsQuestionCircle } from 'react-icons/bs'
import { PageBuilderContext } from '../PageBuilder/context/PageBuilderContext'
import PricingModal from './PricingModal'
import Explain from './Explain'

const SidebarSettings = ({ bigWindow, submitButtonRef, setFormDataLoading }) => {
	const user = useSelector((state) => state.auth.user)
	const [ state ] = useContext(PageBuilderContext)
	const [ icon, setIcon ] = useState(user?.icon ?? user?.app_icon ?? null)
	const [ iconObj, setIconObj ] = useState()
	const [formData, setFormData] = useState({
		ios_applink: user?.ios_applink ? user.ios_applink : '',
		android_applink: user?.android_applink? user.android_applink : '',
		terms_of_service: user?.terms_of_service ? user.terms_of_service: '',
		tracking: user?.tracking ? user.tracking: ''
	})
	const [ isSubmitting, setIsSubmitting ] = useState(false)
	const { isOpen: isFormOpen, onToggle: onFormToggle } = useDisclosure()
	const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()
	
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()
	const dispatch = useDispatch()
	const toast = useToast()
	const [validationErrors, setValidationErrors] = useState(null)
	const [isShowingLogo, setIsShowingLogo] = useState(user?.is_show_logo)

	useState(() => {
		console.log(user)
	}, [isShowingLogo])

	const browseImage = (e, name) => {
		if (e.target.files.length > 0) {
			const objURL = URL.createObjectURL(e.target.files[0])
			setIcon(objURL)
			setIconObj(e.target.files[0])
		}
	}

	function updatePage() {
		setIsSubmitting(true)
		const section = [...state.section]
		const data = {
			title: 'Home',
			content: {
				section: section,
			},
		}

		postRequest('pages', data)
			.then((response) => {
				toast({
					status: 'success',
					title: 'Content saved',
					variant: 'left-accent',
					position: 'top-right',
					isClosable: true,
				})
				setIsSubmitting(false)
			})
			.catch((error) => {
				setIsSubmitting(false)
				console.log(error)
			})
	}

	function copyText(e, text) {
		e.preventDefault()

		const url = text + '.gameplanapps.com'
		navigator.clipboard.writeText(url).then(
			function () {
				toast(toastConfig('Copied', 'success'))
			},
			function (err) {
				toast(toastConfig('Sorry! Could not copy text.', 'error'))
			}
		)
	}

	const onFormDataChange = (value, field) => {
		setFormData({
			...formData,
			[field]: value
		})
	}

	function handleSubmitSetting(e) {
		e.preventDefault()
		if (!iconObj && !formData.ios_applink && !formData.android_applink && !formData.terms_of_service) {
			toast(toastConfig('Please fill out the settings', 'error'))
			return;
		}
		setFormDataLoading(true)
		const data = new FormData(e.target)
		data.append('business_name', e.target.domain.value)
		data.append('icon', iconObj)
		data.append('ios_applink', formData.ios_applink)
		data.append('android_applink', formData.android_applink)
		if (bigWindow)
			data.append('terms_of_service', formData.terms_of_service)
		data.append('is_show_logo', isShowingLogo)
		data.append('tracking', formData.tracking)
		
		postRequest(`trainer-profile-settings`, data)
			.then((res) => {
				setFormDataLoading(false)
				dispatch(
					profileUpdate({
						...user,
						business_name: res.result?.business_name,
						icon: res.result?.app_icon,
						domain: res.result?.domain,
						is_show_logo: res.result?.is_show_logo,
						redirect_url: res.result?.redirect_url,
						ios_applink: res.result?.ios_applink,
						android_applink: res.result?.android_applink,
						terms_of_service: res.result?.terms_of_service,
						tracking: res.result?.tracking
					})
				)
				setValidationErrors(null)
			})
			.then(() => {
				updatePage()
			})
			.catch((error) => {
				setValidationErrors(error.response?.data?.errors)
				setIsSubmitting(false)
			})
	}

	return (
		<Box
			px={bigWindow ? '30px' : '4px'}
			py={bigWindow ? '30px' : '20px'}
			w="250px"
			borderRight={bigWindow ? '1px' : ''}
			bg="white"
			overflowY={bigWindow ? 'auto' : ''}
			maxH={bigWindow ? 'calc(100vh - 127px)' : '100%'}
			borderColor="secondary.200"
		>
			{bigWindow && 
				<Text
					mb="20px"
					fontSize="sm"
					textTransform="uppercase"
					letterSpacing=".1rem"
					color="secondary.400"
				>
					Settings
				</Text>
			}

			<Box as="form" onSubmit={handleSubmitSetting}>
				<Button
          ref={submitButtonRef}
          type="submit"
					display={'none'}
        >
          Submit
        </Button>
				<Stack spacing={5}>
					<Box>
						{bigWindow ? (
							<Text as={'strong'} mb={3} display={'block'} fontSize={'.85rem'}>
								Site Name
								<Explain label="This is the website URL you can sell your app from. You can also sell from a different website by pasting the payments links " />
							</Text>
						) : (
							<>
							<Flex justifyContent='center'>
								<Alert
									status="warning"
									variant="subtle"
									rounded='md'
									flexDirection="column"
									alignItems="center"
									justifyContent="center"
									textAlign="center"
									bg='white'
									border='1px solid #ccc'
									px={2}
									py={8}
									my={8}
								>
									<AlertDescription maxWidth="sm" fontWeight='black' color='black'>
										<Text>Better on a desktop!</Text>
										<Text>To edit your landing page, open on a desktop browser.</Text>	
									</AlertDescription>
								</Alert>
							</Flex>
							<Button
								as='a'
								variant='outline'
								w="100%"
								mt={4}
								color='primary.500'
								rounded='md'
								border='2px'
								borderColor='primary.500'
								fontSize='md'
								fontWeight='normal'
								h='2.8em'
								display='flex'
								justifyContent='center'
								href={`https:\/\/${user.domain}.gameplanapps.com`}
							>
								View landing page
							</Button>

							</>
						)}
						
						{bigWindow && (
							<>
								<HStack>
									<Input
										rounded="full"
										border="1px"
										defaultValue={user?.domain}
										borderColor="secondary.200"
										type="text"
										name="domain"
										fontSize='sm'
									/>
									<Box h='40px'>
										<Text
											ml='90%'
											color={'gray.600'}
											_hover={{
												color: 'primary.500',
											}}
											onClick={(e) => copyText(e, user?.domain)}
											as={'button'}
										>
											<RiFileCopyLine />
										</Text>
										<Text fontSize='0.6rem' fontWeight='black' lineHeight='0.1'>.gameplanapps.com</Text>
									</Box>
								</HStack>
								{validationErrors?.domain && (
									<Text fontSize="sm" mt="2" color="red.500">
										{validationErrors.domain[0]}
									</Text>
								)}
							</>
						)}
					</Box>
				</Stack>

				<Box my={8}>
					
				</Box>

				<Button
					isLoading={isSubmitting}
					loadingText="Saving..."
					variant='outline'
					w="100%"
					color='primary.500'
					rounded='md'
					border='2px'
					borderColor='primary.500'
					fontSize={bigWindow ? 'md' : 'lg'}
					fontWeight='normal'
					h='2.8em'
					display='flex'
					justifyContent='center'
					py={bigWindow ? '' : '0'}
					onClick={onModalOpen}
				>
					<Text
						ml='auto'
					>
						Sign-up forms
					</Text>
					<Text 
						as='span'
						ml='auto'
					>
						<MdArrowForwardIos size='0.9em' />
					</Text>
				</Button>

				<PricingModal 
					isOpen={isModalOpen}
					onClose={onModalClose} 
					bigWindow={bigWindow} 
					browseImage={browseImage}
					icon={icon}
					onFormDataChange={onFormDataChange}
					copyText={copyText}
					updatePage={updatePage}
				/>
			</Box>
		</Box>
	)
}

export default SidebarSettings
