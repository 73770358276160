import {
	Modal,
	Flex,
	ModalOverlay,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalContent,
	useDisclosure,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Text,
	useToast,
} from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import { FiEdit3, FiPlus } from 'react-icons/fi'
import { useForm } from 'react-hook-form'
import { postRequest } from '../services/base.service'
import { toastConfig } from '../helpers/appConfig'
import { useState, useEffect, useRef } from 'react'

const AddCoupon = ({ data, updateList }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [pricingType, setPricingType] = useState(
		'monthly' || data?.subscription_type
	)
	const [submitting, setSubmitting] = useState(false)
	const {
		register,
		reset,
		formState: { errors },
	} = useForm()
	const toast = useToast()

	const nameRef = useRef('')
	const promoCodeRef = useRef('')
	const amountOffRef = useRef('')
	const percentOffRef = useRef('')
	const durationRef = useRef('')

	const handleUpdateData = (id, formData) => {
		postRequest(`coupon/${id}`, {
			...formData,
			pricing_type: pricingType,
			_method: 'PUT',
		}).then((res) => {
			onClose()
			reset()
			toast(toastConfig(res.message, res.status))
			updateList(
				'?sort=id,desc&?select=id,pricing_type,price,trial_day,annual_saving'
			)
		})
	}

	function handleCouponSubmit(e) {
		e.preventDefault()

		if (data) {
			handleUpdateData(data.coupon?.id, {
				name: nameRef.current.value,
				code: promoCodeRef.current.value,
				amount_off: amountOffRef.current.value,
				percent_off: percentOffRef.current.value,
				duration_in_months: durationRef.current.value
			})
		} 
		else {
			postRequest(`coupon`, {
				name: nameRef.current.value,
				code: promoCodeRef.current.value,
				amount_off: amountOffRef.current.value,
				percent_off: percentOffRef.current.value,
				duration_in_months: durationRef.current.value
			}).then((res) => {
				onClose()
				reset()
				toast(toastConfig(res.message, res.status))
				updateList(
					'?sort=id,desc&?select=id,pricing_type,price,trial_day,annual_saving'
				)
			})
		}
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent bg="white" color="secondary.800">
					<ModalHeader>Add Coupon</ModalHeader>
					<form>
						<ModalBody>
							<Stack spacing="20px">
								<FormControl id="couponName" isRequired>
									<FormLabel>Name</FormLabel>
									<Input
										type="text"
										{...register('name', { required: true })}
										defaultValue={data?.name || ''}
										placeholder="Name"
										ref={nameRef}
									/>
									{errors.name && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}
								</FormControl>
								<FormControl id="couponCode" isRequired>
									<FormLabel>Promo Code</FormLabel>
									<Input
										type="text"
										{...register('code', { required: true })}
										defaultValue={data?.code || ''}
										placeholder="PROMOCODE"
										ref={promoCodeRef}
									/>
									{errors.name && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}
								</FormControl>
								<FormControl id="couponAmountOff" isRequired>
									<FormLabel>Amount Off ($)</FormLabel>
									<Input
										type="number"
										step=".01"
										{...register('amount_off', { required: true })}
										defaultValue={data?.amount_off / 100 || 0}
										placeholder="Amount Off ($)"
										ref={amountOffRef}
									/>
									{errors.amount_off && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}
								</FormControl>

								<FormControl id="couponPercentOff" isRequired>
									<FormLabel>Percent Off (%)</FormLabel>
									<Input
										type="number"
										max="100"
										min="0"
										step=".01"
										defaultValue={data?.percent_off || 0}
										{...register('percent_off', { required: false })}
										placeholder="Percent Off (%)"
										ref={percentOffRef}
									/>
									{errors.amount_off && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}
								</FormControl>


								<FormControl id="couponDurationInMonths" isRequired>
									<FormLabel>Duration in months</FormLabel>
									<Input
										type="number"
										min="1"
										step="1"
										defaultValue={data?.duration_in_months || 1}
										{...register('duration_in_months', { required: false })}
										placeholder="1"
										ref={durationRef}
									/>
									{errors.duration_in_months && (
										<Text fontSize="sm" mt="2" color="red.500">
											This field is required
										</Text>
									)}
								</FormControl>
							</Stack>
						</ModalBody>

						<ModalFooter>
							<Button variant={'ghost'} mr={3} onClick={onClose}>
								Close
							</Button>
							<Button 
								type="submit" 
								variant="primary"
								isLoading={submitting}
                    			loadingText={!data ? 'Adding...' : 'Updating...'}
								onClick={handleCouponSubmit}
							>
								{!data ? 'Add Coupon' : 'Update Coupon'}
							</Button>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>

			{!data ? (
				<Button leftIcon={<FiPlus />} onClick={onOpen} variant="primary">
					Add Coupon
				</Button>
			) : (
				<Text as={Flex} alignItems="center" onClick={onOpen}>
					<FiEdit3 />{' '}
					<Text as={'span'} ms="3">
						Edit
					</Text>
				</Text>
			)}
		</>
	)
}

export default AddCoupon
