import React from 'react'
import { Box, Text, Grid, Icon } from '@chakra-ui/react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import componentsFormat from '../helper/componentFormat'

function ComponentList() {
	function getStyle(style, snapshot) {
		if (!snapshot.isDropAnimating) {
			return style
		}
		return {
			...style,
			// cannot be 0, but make it super tiny
			transitionDuration: `0.001s`,
		}
	}

	return (
		<>
			<Text
				py={4}
				fontWeight="semibold"
				fontSize="lg"
				color="secondary.800"
				letterSpacing="0.03rem"
			>
				ADD BLOCK
			</Text>

			<Droppable
				droppableId="droppableComponent"
				type="componentDrop"
				isDropDisabled={true}
			>
				{(provided, snapshot) => (
					<Grid
						ref={provided.innerRef}
						{...provided.droppableProps}
						columns={2}
						templateColumns="repeat(2, 1fr)"
						gap={[`15px`,`15px`,`15px`,`15px`,`15px`,`20px`]}
					>
						{componentsFormat.map((item, index) => (
							<Draggable key={item.type} draggableId={item.type} index={index}>
								{(provided, snapshot) => {
									return (
										<React.Fragment>
											<Box
												px="10px" py="25px"
												textAlign="center"
												rounded="10px"
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={getStyle(
													provided.draggableProps.style,
													snapshot
												)}
												bg={
													snapshot.isDragging ? 'gray.200' : 'gray.100'
												}
											>
												{item.icon && <Icon as={item.icon} color="gray.500" />}

												{item.type === 'mediaObject' ? (
													<Icon ml="2px" viewBox="0 0 40 40" color="gray.400">
														<rect
															x="0"
															y="0"
															width="20"
															height="5"
															fill="currentColor"
														/>
														<rect
															x="0"
															y="10"
															width="40"
															height="5"
															fill="currentColor"
														/>
														<rect
															x="0"
															y="20"
															width="40"
															height="5"
															fill="currentColor"
														/>
													</Icon>
												) : null}

												<Text mt="2px" textTransform="uppercase" fontSize=".70rem" fontWeight="400">
													{item.name} {item.icon}
												</Text>
											</Box>

											{snapshot.isDragging && (
												<Box
													px="10px" py="25px"
													textAlign="center"
													rounded="10px"
													bg="gray.100"
												>
													{item.icon && <Icon as={item.icon} />}
													{item.type === 'mediaObject' ? (
														<Icon ml="2px" viewBox="0 0 40 40" color="gray.300">
															<rect
																x="0"
																y="0"
																width="20"
																height="5"
																fill="currentColor"
															/>
															<rect
																x="0"
																y="10"
																width="40"
																height="5"
																fill="currentColor"
															/>
															<rect
																x="0"
																y="20"
																width="40"
																height="5"
																fill="currentColor"
															/>
														</Icon>
													) : null}
													
													<Text mt="2px" textTransform="uppercase" fontSize=".70rem" fontWeight="400">
														{item.name}
													</Text>
												</Box>
											)}
										</React.Fragment>
									)
								}}
							</Draggable>
						))}
					</Grid>
				)}
			</Droppable>
		</>
	)
}

export default ComponentList
