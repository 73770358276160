import {
	Box,
	Button,
	Container,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	Heading,
	Input,
	Stack,
	Text,
	useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FiSave } from 'react-icons/fi'
import { postRequest } from '../services/base.service'

const TrainerOnboard = () => {
	const [validationError, setValidationError] = useState(null)
	const [isSaving, setIsSaving] = useState(false)

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm()
	const toast = useToast()

	return (
		<Box flex="1" p="30">
			<Container maxW="container.lg">
				<Box bg="white" p="30px">
					{/* Card header */}
					<Flex justifyContent="space-between" mb="6">
						<Heading as="h3" size="md">
							Trainer Registration
						</Heading>
					</Flex>
					{/* end::Card header */}

					<Box
						onSubmit={handleSubmit((formData) => {
							setIsSaving(true)
							postRequest(`stripe-import-subscribers`, formData)
								.then((res) => {
									toast(res.message, res.status)
									reset()
									setIsSaving(false)
								})
								.catch((error) => {
									setValidationError(error.response?.data?.errors)
									setIsSaving(false)
								})
						})}
						as="form"
					>
						<Grid gap={'5'} templateColumns="repeat(2, 1fr)">
							<FormControl id="trainerName">
								<FormLabel>Name</FormLabel>
								<Input
									type="text"
									rounded={'full'}
									{...register('name', { required: true })}
									placeholder="Trainer name"
								/>

								{errors.name && (
									<Text fontSize="sm" mt="2" color="red.500">
										This field is required
									</Text>
								)}
								{validationError?.name && (
									<Text fontSize="sm" mt="2" color="red.500">
										{errors.validationError.name[0]}
									</Text>
								)}
							</FormControl>
							<FormControl id="trainerEmail">
								<FormLabel>Email</FormLabel>
								<Input
									type="email"
									rounded={'full'}
									{...register('email', { required: true })}
									placeholder="Trainer email"
								/>

								{errors.email && (
									<Text fontSize="sm" mt="2" color="red.500">
										This field is required
									</Text>
								)}
								{validationError?.email && (
									<Text fontSize="sm" mt="2" color="red.500">
										{errors.validationError.email[0]}
									</Text>
								)}
							</FormControl>
							<FormControl id="appName">
								<FormLabel>App Name</FormLabel>
								<Input
									type="text"
									rounded={'full'}
									{...register('app_name', { required: true })}
									placeholder="App name"
								/>

								{errors.app_name && (
									<Text fontSize="sm" mt="2" color="red.500">
										This field is required
									</Text>
								)}
								{validationError?.app_name && (
									<Text fontSize="sm" mt="2" color="red.500">
										{errors.validationError.app_name[0]}
									</Text>
								)}
							</FormControl>
							<FormControl id="stripeAccessID">
								<FormLabel>Stripe Access ID</FormLabel>
								<Input
									type="text"
									rounded={'full'}
									{...register('stripe_acc_id', { required: true })}
									placeholder="Stripe access ID"
								/>

								{errors.stripe_acc_id && (
									<Text fontSize="sm" mt="2" color="red.500">
										This field is required
									</Text>
								)}
								{validationError?.stripe_acc_id && (
									<Text fontSize="sm" mt="2" color="red.500">
										{errors.validationError.stripe_acc_id[0]}
									</Text>
								)}
							</FormControl>
							<FormControl id="stripeProductID">
								<FormLabel>Stripe Product ID</FormLabel>
								<Input
									type="text"
									rounded={'full'}
									{...register('stripe_product_id', { required: true })}
									placeholder="Stripe product ID"
								/>

								{errors.stripe_product_id && (
									<Text fontSize="sm" mt="2" color="red.500">
										This field is required
									</Text>
								)}
								{validationError?.stripe_product_id && (
									<Text fontSize="sm" mt="2" color="red.500">
										{errors.validationError.stripe_product_id[0]}
									</Text>
								)}
							</FormControl>
							<FormControl id="stripePriceID">
								<FormLabel>Stripe Price ID</FormLabel>
								<Input
									type="text"
									rounded={'full'}
									{...register('stripe_price_id', { required: true })}
									placeholder="Stripe price ID"
								/>

								{errors.stripe_price_id && (
									<Text fontSize="sm" mt="2" color="red.500">
										This field is required
									</Text>
								)}
								{validationError?.stripe_price_id && (
									<Text fontSize="sm" mt="2" color="red.500">
										{errors.validationError.stripe_price_id[0]}
									</Text>
								)}
							</FormControl>

							<FormControl id="trainerPassword">
								<FormLabel>Password</FormLabel>
								<Input
									type="password"
									rounded={'full'}
									{...register('password', { required: true })}
									placeholder="Enter password"
								/>

								{errors.password && (
									<Text fontSize="sm" mt="2" color="red.500">
										This field is required
									</Text>
								)}
								{validationError?.password && (
									<Text fontSize="sm" mt="2" color="red.500">
										{errors.validationError.password[0]}
									</Text>
								)}
							</FormControl>
						</Grid>

						<Button
							w="200px"
							mt="5"
							isLoading={isSaving}
							loadingText="Saving..."
							type="submit"
							variant={'primary'}
							leftIcon={<FiSave />}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Container>
		</Box>
	)
}

export default TrainerOnboard
