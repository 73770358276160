const appColors = {
	primary: {
		100: '#f6f6ff',
		200: '#e3e2ff',
		300: '#d0cfff',
		400: '#bdbbff',
		500: '#5e59ff',
		600: '#9794ff',
		700: '#8480ff',
		800: '#716dff',
		900: '#5e59ff',
	},
	secondary: {
		100: '#f5f5f5',
		200: '#dddde4',
		300: '#bcbccb',
		400: '#a6a6b9',
		500: '#333341',
		600: '#85859f',
		700: '#6f6f8e',
		800: '#444457',
		900: '#333341',
	},
}

export default appColors
