import { extendTheme } from '@chakra-ui/react'
import appStyles from './appStyles'
import appColors from './appColors'
import buttonStyles from './components/buttonStyles'
import inputStyles from './components/inputStyles'
import modalStyles from './components/modalStyles'
import alertDialogStyles from './components/alertDialogStyles'

const activeLabelStyles = {
	transform: "scale(0.85) translateY(-24px)"
};

const appTheme = extendTheme({
	styles: appStyles,
	colors: appColors,
	fonts: {
		heading: 'Roboto',
		body: 'Roboto',
		listings: 'Poppins'
	},
	components: {
		Button: buttonStyles,
		Input: inputStyles,
		Form: {
			variants: {
				floating: {
					container: {
						label: {
							top: 0,
							left: 0,
							zIndex: 2,
							position: "absolute",
							backgroundColor: "white",
							pointerEvents: "none",
							mx: 3,
							px: 1,
							my: 2,
							transformOrigin: "left top",
							transform: "translateY(-18px)"
						}
					}
				}
			}
		},
	},
	config: {
		initialColorMode: 'dark',
		useSystemColorMode: false,
	},
})

export default appTheme
