import { Box, Text } from '@chakra-ui/react'
import { ResponsiveContainer, AreaChart, Area } from 'recharts'

const StatsCard = ({ data, chartData }) => {
	return (
		<Box
			border="1px"
			pos={'relative'}
			borderColor="secondary.200"
			pt={3}
			pb={5}
			px="5"
			bg={'white'}
			// pb={'60px'}
			rounded="10px"
		>
			<Box pos={'relative'} zIndex={2}>
				<Text fontSize="2rem" mb="1" color="primary.500" fontWeight="bold">
					{data.value}
				</Text>

				<Text
					// textTransform="uppercase"
					color={'gray.600'}
					fontSize={'1.1rem'}
				>
					{data.label}
				</Text>
			</Box>

			<Box
				h={'60px'}
				opacity={0.2}
				roundedBottomRight={'10px'}
				roundedBottomLeft={'10px'}
				overflow={'hidden'}
				w={'100%'}
				pos={'absolute'}
				zIndex={0}
				bottom={0}
				left={0}
			>
				{chartData && (
					<ResponsiveContainer w={'100%'} h={'100%'}>
						<AreaChart
							width={500}
							height={60}
							data={chartData}
							margin={{
								top: 0,
								right: 0,
								left: 0,
								bottom: 0,
							}}
						>
							<Area
								type="monotone"
								dataKey="value"
								stroke="#5e59ff"
								fill="#5e59ff"
							/>
						</AreaChart>
					</ResponsiveContainer>
				)}
			</Box>
		</Box>
	)
}

export default StatsCard
