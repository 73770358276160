import React, { useContext, useState, useEffect } from "react";
import { Box, Container } from "@chakra-ui/react";
import SectionAddButton from "./SectionAddButton";
import { PageBuilderContext } from "../context/PageBuilderContext";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Header from "./Header";
import Settings from "./Settings";
import Section from "./Section";
import SidebarSettings from "../../components/SidebarSettings";
import "./Page.css";
import { getRequest } from "../../services/base.service";
import { useRef } from "react";

function Page(props) {
  const [state, dispatch] = useContext(PageBuilderContext);
  const [bigWindow, setBigWindow] = useState(true);
  const [formDataLoading, setFormDataLoading] = useState(false);
  const submitButtonRef = useRef(null);
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.source.droppableId === "pageBuilder") {
      dispatch({ type: "SECTION_DRAG_END", payload: { result: result } });
    }

    if (result.source.droppableId === "droppableComponent") {
      const sectionIndex = parseInt(
        result.destination.droppableId.split("-")[0]
      );
      const columnIndex = parseInt(
        result.destination.droppableId.split("-")[1]
      );
      dispatch({
        type: "DROP_COMPONENT",
        payload: {
          sectionIndex: sectionIndex,
          columnIndex: columnIndex,
          type: result.draggableId,
        },
      });
    }

    // Form component label reordering...
    if (
      result.source.droppableId.includes("formFieldList") &&
      result.destination.droppableId.includes("formFieldList")
    ) {
      const indexes = result.source.droppableId.split("-");
      const [sectionIndex, columnIndex, compIndex] = indexes;
      dispatch({
        type: "FORM_COMPONENT_UPDATE",
        payload: {
          type: "field.reorder",
          destinationIndex: result.destination.index,
          sourceIndex: result.source.index,
          sectionIndex: sectionIndex,
          columnIndex: columnIndex,
          compIndex: compIndex,
        },
      });
    }
  };

  useEffect(() => {
    if (window.innerWidth < 600) {
      setBigWindow(false);
    }
    getRequest("trainer/pages").then((response) => {
      const { result } = response;
      if (result) {
        dispatch({
          type: "FETCH_DATA",
          payload: {
            section: result.content.section,
            name: "Home",
          },
        });
      }
    });
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {bigWindow ? (
        <>
          <Header
            submitButtonRef={submitButtonRef}
            formDataLoading={formDataLoading}
          />
          <Droppable droppableId="pageBuilder" type="sectionDrop">
            {(provided, snapshot) => (
              <Box display="flex" bg="gray.300">
                <SidebarSettings
                  bigWindow={bigWindow}
                  submitButtonRef={submitButtonRef}
                  setFormDataLoading={setFormDataLoading}
                />
                {/* main droppable area */}

                <Box
                  h="84.25vh"
                  overflowY="auto"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  px={2}
                  py={5}
                  flex="1 1"
                  bg={snapshot.isDraggingOver ? "gray.200" : "transparent"}
                >
                  <Container p={0} maxW={412}>
                    <Box bg="#FFFFFF">
                      <Section />
                      {provided.placeholder}

                      <Box p="20px">
                        <SectionAddButton isBlocked={true} />
                      </Box>
                    </Box>
                  </Container>
                </Box>

                {/* Settings Sidebar */}
                <Box
                  h="84.25vh"
                  overflowX="hidden"
                  width={["300px", "300px", "300px", "300px", "300px", "320px"]}
                  borderTopWidth="1px"
                  borderLeftWidth={1}
                  bg="white"
                  shadow="sm"
                  p={[
                    "0 15px",
                    "0 15px",
                    "0 15px",
                    "0 15px",
                    "0 15px",
                    "0 20px",
                  ]}
                >
                  <Settings />
                </Box>
              </Box>
            )}
          </Droppable>
        </>
      ) : (
        <Box w="100%" px={4}>
          <Box
            display="flex"
            justifyContent="center"
            bg="white"
            w="100%"
            h="100%"
            pb="calc(64px + 10%)"
            roundedTop="lg"
          >
            <SidebarSettings bigWindow={bigWindow} />
          </Box>
        </Box>
      )}
    </DragDropContext>
  );
}

export default Page;
