import { useState, useEffect, useRef } from 'react'
import { Box, Container, Spinner, Table, Thead, Tr, Th, Tbody, Td, 
	Text, Button, Flex, Input, InputGroup, InputLeftElement, 
	InputRightElement, InputRightAddon, useDisclosure
} from '@chakra-ui/react'
import { FiChevronLeft, FiChevronRight, FiSearch } from 'react-icons/fi'
import { MdFilterAlt } from 'react-icons/md'
import ReactPaginate from 'react-paginate'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { changePageTitle } from '../redux'
import { getRequest, postRequest } from '../services/base.service'
import CustomerRow from '../components/CustomerRow'
import CustomerVideoModal from '../components/CustomerVideoModal'

const Customers = () => {
	const [customers, setCustomers] = useState([])
	const [freeCustomers, setFreeCustomers] = useState([])
	const [initFreeCustomers, setInitFreeCustomers] = useState([])
	const [bigWindow, setBigWindow] = useState(true)
	const [initLoading, setInitLoading] = useState(false)
	const [initPaidLoading, setInitPaidLoading] = useState(false)
	const [page, setPage] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const [seen, setSeen] = useState(0)
	const [pageCount, setPageCount] = useState(0)
	const [hasMore, setHasMore] = useState(false)
	const [isBackward, setIsBackward] = useState(false)
	const [isForward, setIsForward] = useState(false)
	const [searchString, setSearchString] = useState('')
	const [initPaidList, setInitPaidList] = useState([])
	const [submitting, setSubmitting] = useState(false)
	const [searchMessage, setSearchMessage] = useState(false)
	const { isOpen, onOpen, onClose } = useDisclosure()

	const dispatch = useDispatch()
	const queryRef = useRef('')
	const {
		register,
		reset,
		formState: { errors },
	} = useForm()

	function handleChange(e) {
		e.preventDefault()

		setSearchString(e.currentTarget.value)
	}

	function handleSearch(e) {
		if (e.key === 'Enter' || e.type === 'click') {
			e.preventDefault()

			setSubmitting(true)
			setInitPaidLoading(true)

			postRequest(`search-subscriptions`, {
				search_query: queryRef.current.value,
			})
			.then(({ result }) => {
				if (result.length === 0) {
					setInitPaidLoading(false)
					setSearchMessage(true)
					console.log(result)
				}
				setSubmitting(false)
				setInitPaidLoading(false)

				const searchCustomers = []
				for (let i = 0; i <= result.length -1; i++) {
					searchCustomers.push(result[i].data[0])
				}
				console.log(searchCustomers)
				setCustomers(searchCustomers)
			})
			.catch((err) => setInitPaidLoading(false))
		}
	}

	function getPaidList(direction) {
		if (!direction) {
			getRequest('load-paid-customers', {
				params: {
					limit: perPage,
				}
			})
			.then(({ result }) => {
				setCustomers(result.data)
				setInitPaidList(result.data)
				setHasMore(result.has_more)
				setInitPaidLoading(false)
			})
			.catch((err) => setInitPaidLoading(false))
		}

		if (direction === 'forward') {
			setIsForward(true)
			getRequest('load-paid-customers', {
				params: {
					limit: perPage,
					last_customer: customers[perPage - 1].id
				}
			})
			.then(({ result }) => {
				setCustomers(result.data)
				setHasMore(result.has_more)
				setIsForward(false)
				setInitPaidLoading(false)
			})
			.catch((err) => setInitPaidLoading(false))
		}

		if (direction === 'backward') {
			setIsBackward(true)
			getRequest('load-paid-customers', {
				params: {
					limit: perPage,
					first_customer: customers[0].id
				}
			})
			.then(({ result }) => {
				setCustomers(result.data)
				setHasMore(result.has_more)
				setIsBackward(false)
				setInitPaidLoading(false)
			})
			.catch((err) => setInitPaidLoading(false))
		}
	}

	function getFreeList() {
		getRequest('load-free-customers')
			.then(({result}) => {
				setPageCount(Math.ceil(result.length / perPage))
				setFreeCustomers(result)
				setInitFreeCustomers(result)
				setInitLoading(false)
			})
			.catch((err) => setInitLoading(false))
	}

	function searchZero() {
		if (queryRef.current.value === '') {
			setCustomers(initPaidList)
		}
	}

	useEffect(() => {
		if (window.innerWidth < 600) {
            setBigWindow(false)
        }
		setInitLoading(true)
		setInitPaidLoading(true)
		getFreeList()
		getPaidList()
		
		dispatch(changePageTitle('Customers'))
	}, [])

	useEffect(() => {
		setSeen(page * perPage)
	}, [page])

	useEffect(() => {
		if (searchString.length === 0) {
			setFreeCustomers(initFreeCustomers)
		} 
		else {
			const cus = freeCustomers.filter(customer => 
				customer.trainee.name.toLowerCase().includes(searchString.toLowerCase())
				|| customer.trainee.email.toLowerCase().includes(searchString.toLowerCase())
			)

		  	setFreeCustomers(cus) 
		}
	}, [searchString])

	useEffect(() => {
		if (localStorage.getItem('customerVideoModal')) {
			return
		}
		onOpen()
		localStorage.setItem('customerVideoModal', true)
	}, [])

	return (
		<>
		{!bigWindow && 
			<Flex justifyContent='center' w='100vw' mt={6} mb={10} px={4}>
				<InputGroup w='100%'>
					<Input
						type="search"
						bg='white'
						borderColor="secondary.400"
						_hover={{ borderColor: 'secondary.500' }}
						_focus={{ borderColor: 'secondary.500' }}
						_placeholder={{ color: 'secondary.400' }}
						rounded="md"
						placeholder="Search by name or email"
						ref={queryRef}
						onChange={searchZero}
						onKeyPress={handleSearch}
					/>
					<InputRightAddon 
						as='button' 
						bg='primary.500' 
						color='white'
						isLoading={submitting}
						loadingText={'Searching...'}
						onClick={handleSearch}
						children='Search' 
					/>
				</InputGroup>
			</Flex>
		}
		<Box flex="1" p={['0px', '25px', '30px', '30px']} pb={!bigWindow && '124px'} mt={['20px','0','0']}>
			<Container maxW={['container.lg','container.xl','container.xl']} >
				<Box bg="white" p={['10px','30px','30px']} borderRadius={['0','15','15']} boxShadow="md">
					{bigWindow && 
						<form>
							<Flex justifyContent="center" mb="6">
								<Flex>
									<InputGroup>
										<Input
											type="search"
											width='24em'
											borderColor="secondary.400"
											_hover={{ borderColor: 'secondary.500' }}
											_focus={{ borderColor: 'secondary.500' }}
											_placeholder={{ color: 'secondary.400' }}
											{...register('query', { required: false })}
											rounded="md"
											placeholder="Search Stripe by name or email"
											ref={queryRef}
											onChange={searchZero}
											onKeyPress={handleSearch}
										/>
										<InputRightAddon 
											as='button' 
											bg='primary.500' 
											color='white'
											isLoading={submitting}
											loadingText={'Searching...'}
											onClick={handleSearch}
											children='Search' 
										/>
									</InputGroup>
								</Flex>
							</Flex>
						</form>
					}
					<Table bg={'white'} variant={'striped'} >
						<Thead>
							<Tr>
								<Th p={bigWindow ? 4 : 2}>Customer Name</Th>
								<Th p={bigWindow ? 4 : 2}>Email Address</Th>
								{!bigWindow && <Th p={bigWindow ? 4 : 2}>Plan</Th>}
								{bigWindow && (
									<>
										<Th p={4}>Subscription ID</Th>
										<Th p={4}>Plan</Th>
										<Th p={4} isNumeric />
									</>
								)}
							</Tr>
						</Thead>
						<Tbody>
							{initPaidLoading ? (
								<Tr>
									<Td colSpan={8} textAlign={'center'}>
										<Spinner
											thickness="2px"
											speed="0.65s"
											emptyColor="gray.200"
											color="blue.500"
										/>
										<Text>Loading...</Text>
									</Td>
								</Tr>
							) : customers.length > 0 ? (
								customers.map((customer, index) => (
									<CustomerRow
										key={index}
										getList={getPaidList}
										data={customer}
										bigWindow={bigWindow}
										type='recurring'
									/>
								))
							) : (
								<Tr>
									<Td colSpan={8} fontWeight={'bold'} textAlign={'center'}>
										{searchMessage ? "Can't find anyone with those search terms" : 
										"No customers found"}
									</Td>
								</Tr>
							)}
						</Tbody>
					</Table>
					{customers.length > 0 && initPaidList.length > 0 && (
						<Box mt="6">
							<Button
								isLoading={isBackward}
								disabled={initPaidList[0].id === customers[0].id}
								onClick={() => getPaidList('backward')}
							>
								<FiChevronLeft />
							</Button>
							<Button
								ml={6}
								isLoading={isForward}
								disabled={!hasMore}
								onClick={() => getPaidList('forward')}
							>
								<FiChevronRight />
							</Button>
						</Box>
					)}
				</Box>
			</Container>
		</Box>
		<Text fontSize='3xl' align='center'>Free</Text>
		{!bigWindow && 
			<Flex justifyContent='center' w='100vw' mt={6} mb={10} px={4}>
				<InputGroup w='100%'>
					<Input
						type="search"
						bg='white'
						borderColor="secondary.400"
						_hover={{ borderColor: 'secondary.500' }}
						_focus={{ borderColor: 'secondary.500' }}
						_placeholder={{ color: 'secondary.400' }}
						rounded="full"
						placeholder="Filter"
						onChange={handleChange}
					/>
					<InputRightElement
						children={<MdFilterAlt />}
					/>
				</InputGroup>
			</Flex>
		}
		<Box flex="1" p={['0px', '25px', '30px', '30px']} pb={!bigWindow && '124px'} mt={['20px','0','0']}>
			<Container 
				maxW={['container.lg','container.xl','container.xl']} 
				pe={['0','0','0','1rem','1rem','1rem']} 
				ps={['0','0','0','1rem','1rem','1rem']}
			>
				<Box bg="white" p={['10px','30px','30px']} borderRadius={['0','15','15']} boxShadow="md">
					{bigWindow && 
						<Flex justifyContent="center" mb="6">
							<Flex>
								<InputGroup>
									<Input
										type="search"
										width='24em'
										borderColor="secondary.400"
										_hover={{ borderColor: 'secondary.500' }}
										_focus={{ borderColor: 'secondary.500' }}
										_placeholder={{ color: 'secondary.400' }}
										rounded="full"
										placeholder="Filter"
										onChange={handleChange}
									/>
									<InputRightElement
										children={<MdFilterAlt />}
									/>
								</InputGroup>
							</Flex>
						</Flex>
					}
					<Table bg={'white'} variant={'striped'}>
						<Thead>
							<Tr>
								<Th p={bigWindow ? 4 : 2}>Customer Name</Th>
								<Th p={bigWindow ? 4 : 2}>Email Address</Th>
								{!bigWindow && <Th p={bigWindow ? 4 : 2}>Plan</Th>}
								{bigWindow && (
									<>
										<Th p={4}>Subscription ID</Th>
										<Th p={4}>Plan</Th>
										<Th p={4} isNumeric />
									</>
								)}
							</Tr>
						</Thead>
						<Tbody>
							{initLoading ? (
								<Tr>
									<Td colSpan={8} textAlign={'center'}>
										<Spinner
											thickness="2px"
											speed="0.65s"
											emptyColor="gray.200"
											color="blue.500"
										/>
										<Text>Loading...</Text>
									</Td>
								</Tr>
							) : freeCustomers.length > 0 ? (
								freeCustomers.slice(
									seen, 
									seen + perPage
								)
								.map((customer, index) => (
									<CustomerRow
										key={index}
										getList={getFreeList}
										data={customer}
										bigWindow={bigWindow}
										type='free'
									/>
								))
							) : (
								<Tr>
									<Td colSpan={8} fontWeight={'bold'} textAlign={'center'}>
										No customers found
									</Td>
								</Tr>
							)}
						</Tbody>
					</Table>

					<Box mt="6">
					<ReactPaginate
						breakLabel="..."
						className="react-paginate"
						nextLabel={<FiChevronRight />}
						previousLabel={<FiChevronLeft />}
						pageCount={pageCount}
						onPageChange={({ selected }) => setPage(selected)}
						renderOnZeroPageCount={null}
					/>
					</Box>
				</Box>
			</Container>
		</Box>
		<CustomerVideoModal isOpen={isOpen} onClose={onClose} />
		</>
	)
}
export default Customers
